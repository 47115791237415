import React from "react";
import { StyledLogo } from "./styled";

const Header = ({ logoWidth, margin, history }) => {
  return (
    <div>
      <StyledLogo
        logoWidth={logoWidth}
        margin={margin}
        history={history}
        onClick={() => history && history.push("/menu")}
      >
        <img src="/assets/logo.png" alt="logo" />
      </StyledLogo>
    </div>
  );
};

export default Header;
