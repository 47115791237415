import React from "react";
import { connect } from "react-redux";
import { getBannerName } from "store/actions/abtest";
import { getCookie } from "store/helpers/cookies";

class InitailApp extends React.Component {
  componentDidMount() {
    if (getCookie("bannerId") === undefined) this.props.getBannerName();
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default connect(null, {
  getBannerName
})(InitailApp);
