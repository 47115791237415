import errors from './errors';

export const getErrorMessage = (message, context, field) => {
  const key = message.toLowerCase();
  const namespace = errors[context];
  const fieldErrors = field ? namespace[field] || errors[field] : null;

  return fieldErrors
    ? fieldErrors[key]
    : namespace && namespace[key]
    ? namespace[key]
    : errors[key] || 'Произошла непредвиденная ошибка, проверьте интернет соединение или повторите попытку позже';
};

export const getReducerError = (action, field, context) => {
  const error = action.errors.find(x => x.field === field);

  if (error) {
    return {
      field,
      message: getErrorMessage(error.message, context, field),
      code: error.message
    };
  }

  return null;
};

export const getReducerErrors = (action, context) => {
  const result = action.errors.map(
    item =>
      item && {
        field: item.field,
        message: getErrorMessage(item.message, context, item.field),
        code: item.message
      }
  );

  return result;
};
