import React from "react";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

import InputField from "../InputField";
import SelectField from "../SelectField";

import { StyledCampaignInfo } from "./styled";

const CampaignInfo = ({
  selectedDate,
  onChangeSelectedDate,
  getWorkOrdersList,
  onSelectWorkOrder,
  selectedWorkOrder
}) => {
  return (
    <StyledCampaignInfo>
      <ReactDatePicker
        customInput={
          <InputField
            label="Дата монтажа"
            type="text"
            placeholder="Дата монтажа"
            defaultValue={selectedDate.format("DD.MM.YYYY")}
          />
        }
        selected={selectedDate}
        onChange={e => onChangeSelectedDate(e ? e : moment())}
      />
      <SelectField
        loadOptions={getWorkOrdersList}
        onChange={onSelectWorkOrder}
        value={selectedWorkOrder}
      />
    </StyledCampaignInfo>
  );
};

export default CampaignInfo;
