import { takeEvery, call, put } from 'redux-saga/effects';
import generateSagaWorker, { get } from './generateSaga';
import { GET_NEWS_LIST, GET_NEWS } from '../constants/news';
import qs from 'qs';

const sagaGenerator = generateSagaWorker(put, call);

const getNewsList = get(sagaGenerator, action => `/api/v1/news?${qs.stringify(action.form)}`, GET_NEWS_LIST.types);

const getNews = get(sagaGenerator, action => `/api/v1/news/${action.form.id}`, GET_NEWS.types);

export default function* questionAnswer() {
  yield takeEvery(GET_NEWS_LIST.main, getNewsList);

  yield takeEvery(GET_NEWS.main, getNews);
}
