import React from "react";
import Modal from "react-modal";

import DocumentsIcon from "img/documents.png";

import { ModalStyle } from "../styled";

import { Row, Col, Doc } from "./styled";

function SoftwareModal({ ...props }) {
  return (
    <Modal {...props} style={ModalStyle} ariaHideApp={false}>
      <Row>
        <Col>
          <Doc.Link
            href="https://cdn.nebo.digital/assets/docs/functions.pdf"
            target={"_blank"}
          >
            <img width={75} src={DocumentsIcon} alt={DocumentsIcon} />
            <Doc.Description>
              Описание функциональности Nebo.Digital
            </Doc.Description>
          </Doc.Link>
        </Col>
        <Col>
          <Doc.Link
            href="https://cdn.nebo.digital/assets/docs/lifecycle.pdf"
            target={"_blank"}
          >
            <img width={75} src={DocumentsIcon} alt={DocumentsIcon} />
            <Doc.Description>Описание жизненного цикла ПО</Doc.Description>
          </Doc.Link>
        </Col>
      </Row>
    </Modal>
  );
}

export default SoftwareModal;
