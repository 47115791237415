import createReducer from "./createReducer";

import {
  GET_LIGHTBOXES,
  GET_FORMATS,
  POST_LIGHTBOXES_SCHEDULE,
  GET_SEASON_DISCOUNT,
  GET_METRO_STATIONS,
  GET_METRO_LINES,
  SELECT_LINE,
  GET_LIGHTBOX_REPORTS_IMAGES
} from "../constants/lightboxes";

const initialState = {
  boxes: [],
  boxesLoading: false,
  formats: [],
  formatsLoading: false,
  schedule: [],
  scheduleLoading: false,
  seasonalDiscountLoading: false,
  seasonalDiscount: 1,
  boxesLoaded: false,
  linesLoading: false,
  stations: [],
  stationsLoading: false,
  lines: [],
  selectedLine: null,
  lineStations: [],
  scheduleList: [],
  scheduleLoadingList: [],
  lightBoxPhotos: [],
  lightBoxSchema: null,
  lightBoxPhotosLoading: false
};

function updateCollection(items, key, newItem) {
  let isItemUpdated = false;
  const newItems = items.map(item => {
    if (item[key] === newItem[key]) {
      isItemUpdated = true;
      return newItem;
    }
    return item;
  });
  if (!isItemUpdated) {
    return newItems.concat([newItem]);
  }

  return newItems;
}

const consumers = {
  [GET_LIGHTBOX_REPORTS_IMAGES.start]: state => ({
    ...state,
    lightBoxSchema: null,
    lightBoxPhotos: [],
    lightBoxPhotosLoading: true
  }),
  [GET_LIGHTBOX_REPORTS_IMAGES.success]: (state, action) => ({
    ...state,
    lightBoxSchema: action.result.data.schemaImageUrl,
    lightBoxPhotos: action.result.data.photoUrls,
    lightBoxPhotosLoading: false
  }),
  [GET_LIGHTBOX_REPORTS_IMAGES.error]: (state, action) => ({
    ...state,
    lightBoxSchema: null,
    lightBoxPhotos: [],
    lightBoxPhotosLoading: false
  }),
  [GET_METRO_LINES.start]: state => ({
    ...state,
    linesLoading: true
  }),
  [GET_METRO_LINES.success]: (state, action) => ({
    ...state,
    linesLoading: false,
    lines: action.payload || []
  }),
  [GET_METRO_STATIONS.start]: state => ({
    ...state,
    stationsLoading: true
  }),
  [GET_METRO_STATIONS.success]: (state, action) => ({
    ...state,
    stationsLoading: false,
    stations: action.payload || []
  }),
  [GET_SEASON_DISCOUNT.main]: state => ({
    ...state,
    seasonalDiscountLoading: true
  }),
  [GET_SEASON_DISCOUNT.success]: (state, action) => ({
    ...state,
    seasonalDiscount: action.result.data.seasonalDiscount,
    seasonalDiscountLoading: false
  }),
  [GET_FORMATS.main]: state => ({
    ...state,
    formatsLoading: true
  }),
  [GET_FORMATS.success]: (state, action) => ({
    ...state,
    formatsLoading: false,
    formats: action.result.data.advertiseFormats
  }),
  [SELECT_LINE]: (state, action) => {
    const { line } = action;
    const lineStations = state.stations.find(
      ({ metroLineId }) => metroLineId === line._id
    );

    return {
      ...state,
      selectedLine: line,
      lineStations: lineStations.stations
    };
  },
  [GET_LIGHTBOXES.main]: state => {
    return { ...state, boxesLoading: true, boxesLoaded: false, boxes: [] };
  },
  [GET_LIGHTBOXES.success]: (state, action) => {
    const { metroLineId } = action.action.form;

    const allBoxes = updateCollection(state.boxes, "id", {
      id: metroLineId,
      data: action.result.data.lightBoxes || []
    });

    const boxesLine = allBoxes.find(sch => sch.id === state.selectedLine._id);

    return {
      ...state,
      boxesLoading: false,
      boxesLoaded: true,
      boxes: boxesLine.data
    };
  },
  [POST_LIGHTBOXES_SCHEDULE.main]: (state, action) => {
    const { metroLineId } = action.form;

    const scheduleLoadingList = updateCollection(
      state.scheduleLoadingList,
      "id",
      {
        id: metroLineId,
        loading: true
      }
    );

    const scheduleDataList = updateCollection(state.scheduleList, "id", {
      id: metroLineId,
      data: []
    });

    const scheduleData = scheduleDataList.find(
      sch => sch.id === state.selectedLine._id
    );
    const scheduleLoading = scheduleLoadingList.find(
      sch => sch.id === state.selectedLine._id
    );

    return {
      ...state,
      schedule: scheduleData.data,
      scheduleList: scheduleDataList,
      scheduleLoadingList: scheduleLoadingList,
      scheduleLoading: scheduleLoading.loading
    };
  },
  [POST_LIGHTBOXES_SCHEDULE.success]: (state, action) => {
    const { metroLineId } = action.action.form;

    const scheduleLoadingList = updateCollection(
      state.scheduleLoadingList,
      "id",
      {
        id: metroLineId,
        loading: false
      }
    );

    const scheduleDataList = updateCollection(state.scheduleList, "id", {
      id: metroLineId,
      data: action.result.data.enabledLightBoxes || []
    });

    const scheduleData = scheduleDataList.find(
      sch => sch.id === state.selectedLine._id
    );
    const scheduleLoading = scheduleLoadingList.find(
      sch => sch.id === state.selectedLine._id
    );

    return {
      ...state,
      schedule: scheduleData.data,
      scheduleList: scheduleDataList,
      scheduleLoadingList: scheduleLoadingList,
      scheduleLoading: scheduleLoading.loading
    };
  },
  [POST_LIGHTBOXES_SCHEDULE.error]: (state, action) => {
    const { metroLineId } = action.form;
    return {
      ...state,
      schedule: [],
      scheduleLoading: updateCollection(state.scheduleLoading, metroLineId, {
        id: metroLineId,
        loading: false
      })
    };
  }
};

export default createReducer(initialState, consumers);
