import { getDateLineSections } from "utils/multiCalculator";

const roundValue = value => Math.round(value * 100) / 100;

export const getInventoryForTypography = ({ basketInventories }) => {
  const dateSections = basketInventories.reduce(
    (acc, i) =>
      acc.concat(
        getDateLineSections({
          periods: i.availablePeriods,
          id: i.advertisingSpace.id
        }).map(p => ({ ...p, format: i.advertisingSpace.format.name }))
      ),
    []
  );

  return dateSections.map(period => ({
    lightBoxId: period.id,
    format: period.format
  }));
};

//Обновление корзины выбранных инвентарей
export const getBasketInventories = ({
  inventories,
  basketInventories,
  id
}) => {
  //Получаем корзину из стейта
  let newBasketInventories = basketInventories;

  const currentBasketInv = basketInventories.find(
    i => i.advertisingSpace.id === id
  );

  const currentInv = inventories.find(inv =>
    inv.advertisingSpaces.some(space => space.advertisingSpace.id === id)
  );

  // Если нет в выдаче то возвращаем уже отформатированную корзину
  if (!currentInv) {
    return newBasketInventories;
  }

  const currentSpace = currentInv.advertisingSpaces.find(
    space => space.advertisingSpace.id === id
  );

  //Если инвентарь есть в корзине - удаляем
  if (currentBasketInv && !currentSpace.selected) {
    return newBasketInventories.filter(
      i => i.advertisingSpace.id !== currentBasketInv.advertisingSpace.id
    );
  }
  //Если инвентаря нет в корзине - добавляем
  if (
    !currentBasketInv &&
    currentSpace.availablePeriods.some(i => i.selected)
  ) {
    newBasketInventories.push(currentSpace);

    return newBasketInventories;
  }

  return newBasketInventories.map(i =>
    i.advertisingSpace.id === id ? currentSpace : i
  );
};

//Обработка периодов для каждого инвентаря
export const selectPeriods = ({
  periods,
  basePrice,
  payload,
  isSelectedInvenory,
  selectedPeriods,
  type
}) => {
  let newSelectedPeriods;
  let lastPosition;

  if (!payload) {
    //При клике на корзину(выборе всех периодов)
    if (isSelectedInvenory) {
      //При отмене кнопки выбора
      newSelectedPeriods = [];
    } else {
      //При клике на кнопку выбора, находим индексы доступных периодов
      const availablePeriodsIndexes = periods.reduce((acc, period, idx) => {
        if (period.isAvailable) {
          acc.push(idx);
        }
        return acc;
      }, []);
      //Фильтруем индексы доступных периодов
      const filtredPeriodsIndexes = availablePeriodsIndexes.filter(
        (item, i) => {
          if (
            item + 1 === availablePeriodsIndexes[i + 1] &&
            i !== lastPosition
          ) {
            lastPosition = i + 1;
            return true;
          }
          return false;
        }
      );
      //Создаем пару для индексов доступных периодов
      newSelectedPeriods = filtredPeriodsIndexes.map(item => ({
        from: item,
        to: item + 1
      }));
    }
  } else {
    //При клике на отдельно выбранный период
    newSelectedPeriods = periods.reduce((acc, period, idx) => {
      const payloadPeriod =
        period.startDateFormat === payload.startDate &&
        period.endDateFormat === payload.endDate;
      //Работаем только над выбранным периодом
      if (payloadPeriod) {
        //Ищем период в массиве коэффициентов выбранных периодов
        const findedPeriod = selectedPeriods.find(
          p =>
            p.from === period.startDateFormat || p.to === period.startDateFormat
        );
        if (findedPeriod) {
          //Если находим - убираем
          acc = acc.filter(
            p =>
              p.from !== period.startDateFormat &&
              p.to !== period.startDateFormat
          );
        } else {
          //Не находим - добавляем
          const findedCrossPeriod = selectedPeriods.find(
            p => periods[idx + 1] && p.from === periods[idx + 1].startDateFormat
          );

          if (findedCrossPeriod) {
            //Если выбранный период пересекается с ранее выбранным, убираем старый выбор
            acc = acc.filter(p => p.from !== periods[idx + 1].startDateFormat);
          }
          //Добавляем коэффициент элемента периода, если не последний элемент и след. элемент после выбранного - свободный
          if (idx + 1 !== periods.length && periods[idx + 1].isAvailable) {
            acc.push({
              from: periods[idx].startDateFormat,
              to: periods[idx + 1].startDateFormat
            });
          }
        }
      }
      return acc;
    }, selectedPeriods);
  }
  //Выбираем или убираем выбор периодов исходя из массива коэффициентов
  const newPeriods = periods.map((period, idx) => ({
    ...period,
    selected: !!newSelectedPeriods.find(
      p => p.from === period.startDateFormat || p.to === period.startDateFormat
    )
  }));
  //Общая сумма всех выбранных периодов с учетом сезонника
  const filteredPeriods = newPeriods.filter(p => p.selected);
  const allPeriodDays = filteredPeriods.reduce((a, b) => a + b.periodDays, 0);

  const totalPrice = filteredPeriods.reduce(
    (acc, p) =>
      acc + (basePrice / allPeriodDays) * p.periodDays * p.seasonalDiscount,
    0
  );

  //При выборе периодов в корзине, не удаляем инвентрарь, даже если не выбрано ни одного периода
  const lbSelected =
    payload && type === "basket" ? true : newPeriods.some(p => p.selected);

  return {
    newPeriods,
    lbSelected,
    totalPrice,
    selectedPeriods: newSelectedPeriods
  };
};

// Обработка периода для инвентаря в корзины, которого нет в выдаче
export const updateBasketPeriods = (
  { availablePeriods, selectedPeriods, price },
  payload
) => {
  let newAvailablePeriods = availablePeriods;
  let newSelectedPeriods = selectedPeriods;

  newSelectedPeriods = availablePeriods.reduce((acc, period, idx) => {
    const payloadPeriod =
      period.startDateFormat === payload.startDate &&
      period.endDateFormat === payload.endDate;
    //Работаем только над выбранным периодом
    if (payloadPeriod) {
      //Ищем период в массиве коэффициентов выбранных периодов
      const findedPeriod = selectedPeriods.find(
        p =>
          p.from === period.startDateFormat || p.to === period.startDateFormat
      );
      if (findedPeriod) {
        //Если находим - убираем
        acc = acc.filter(
          p =>
            p.from !== period.startDateFormat && p.to !== period.startDateFormat
        );
      } else {
        //Не находим - добавляем
        const findedCrossPeriod = selectedPeriods.find(
          p =>
            newAvailablePeriods[idx + 1] &&
            p.from === newAvailablePeriods[idx + 1].startDateFormat
        );
        if (findedCrossPeriod) {
          //Если выбранный период пересекается с ранее выбранным, убираем старый выбор
          acc = acc.filter(
            p => p.from !== newAvailablePeriods[idx + 1].startDateFormat
          );
        }
        //Добавляем коэффициент элемента периода, если не последний элемент и след. элемент после выбранного - свободный
        if (
          idx + 1 !== newAvailablePeriods.length &&
          newAvailablePeriods[idx + 1].isAvailable
        ) {
          acc.push({
            from: newAvailablePeriods[idx].startDateFormat,
            to: newAvailablePeriods[idx + 1].startDateFormat
          });
        }
      }
    }
    return acc;
  }, selectedPeriods);

  newAvailablePeriods = newAvailablePeriods.map(period => ({
    ...period,
    selected: !!newSelectedPeriods.find(
      p => p.from === period.startDateFormat || p.to === period.startDateFormat
    )
  }));

  const filteredPeriods = newAvailablePeriods.filter(p => p.selected);
  const allPeriodDays = filteredPeriods.reduce((a, b) => a + b.periodDays, 0);

  const totalPrice = filteredPeriods.reduce(
    (acc, p) =>
      acc + (price / allPeriodDays) * p.periodDays * p.seasonalDiscount,
    0
  );

  return {
    availablePeriods: newAvailablePeriods,
    selectedPeriods: newSelectedPeriods,
    totalPrice
  };
};

export const multiCalculation = (state, actionType, lastState) => {
  const {
    basketInventories,
    basketOpen,
    typography,
    typographySelected,
    inventories
  } = state;

  // Принудительно скидываем значения итоговой цены до 0
  // все, чего нет в корзине
  let newInventories = inventories;
  if (inventories.length) {
    if (basketInventories.length) {
      newInventories = inventories.map(inv => {
        const newSpaces = inv.advertisingSpaces.map(space =>
          basketInventories.find(
            bi => bi.advertisingSpace.id === space.advertisingSpace.id
          )
            ? space
            : { ...space, summaryPrice: 0 }
        );

        return { ...inv, advertisingSpaces: newSpaces };
      });
    } else {
      newInventories = inventories.map(inv => {
        const newSpaces = inv.advertisingSpaces.map(space => ({
          ...space,
          summaryPrice: 0
        }));
        return { ...inv, advertisingSpaces: newSpaces };
      });
    }
  }
  //Расчет итоговой стоимости
  const summaryPrices = [];
  let totalPrice = basketInventories.reduce((acc, i, pos) => {
    // Увеличиваем цену пропорционально количеству выбранных периодов
    const { availablePeriods } = basketInventories[pos];
    const newPrice =
      (i.totalPrice * availablePeriods.filter(p => p.selected).length) / 2;

    summaryPrices.push(newPrice);
    return acc + newPrice;
  }, 0);

  // Добавляем увеличенную цену в качестве свойства
  basketInventories.forEach((inventory, i) => {
    inventory.summaryPrice = summaryPrices[i];
    // помимо самого, например из топ-3, прокидываем summaryPrice во все его копии в станциях
    newInventories = newInventories.map(inv => ({
      ...inv,
      advertisingSpaces: inv.advertisingSpaces.map(space => ({
        ...space,
        summaryPrice:
          space.availableId === inventory.availableId
            ? summaryPrices[i]
            : space.summaryPrice
      }))
    }));
  });

  //Расчет итогового отс
  const totalOts = basketInventories.reduce((acc, bi) => {
    const selectedPeriodsOts = bi.availablePeriods
      .filter(period => period.selected)
      .reduce((accomulate, p) => accomulate + bi.otsPerMonth / 2, 0);

    return acc + selectedPeriodsOts;
  }, 0);

  //Опираемся на этот массив, т.к. в нем уже отфильтрованы выбранные инвентари с невыбранными периодами
  const basketInvWithSelectedPeriods = basketInventories.filter(i =>
    i.availablePeriods.some(p => p.selected)
  );

  const totalTypography =
    typography && basketInvWithSelectedPeriods.length
      ? {
          price: getInventoryForTypography({ basketInventories }).reduce(
            (acc, i) => {
              const typographyPrices = typography.prices.find(
                p => p.format.replace(/м/gi, "m") === i.format
              );

              return acc + typographyPrices.value;
            },
            0
          ),
          deliveryPrice: typography.deliveryPrice
        }
      : {
          price: 0,
          deliveryPrice: 0
        };

  //Добавляем стоимость типографии и доставки к итоговой стоимости
  totalPrice =
    typographySelected && basketInvWithSelectedPeriods.length
      ? roundValue(totalPrice) +
        totalTypography.price +
        totalTypography.deliveryPrice
      : roundValue(totalPrice);

  return {
    ...state,
    inventories: newInventories,
    basketOpen: basketInventories.length ? basketOpen : false,
    summary: {
      totalCount: basketInvWithSelectedPeriods.length,
      totalOts: Math.ceil(totalOts),
      totalPrice,
      totalTypography
    }
  };
};
