import {
  SIGN_IN,
  WHO_AM_I,
  SELECT_TYPE_OF_WORK,
  SET_SELECTED_FILES,
  SET_PLATFORM_NUMBER,
  SET_SIDE,
  WORK_ORDER,
  INSTALLATION_PHOTO,
  SELECT_PLATFORM_NUMBER,
  CHANGE_SELECTED_DATE,
  WORK_ORDERS_BY_PLATFORM,
  SELECT_WORK_ORDER,
  RESET_PH_STATE,
  RESET_LOGIN_ERROR
} from "../constants/photoReports";

import { setTokenPh } from "utils/auth";

import {
  signInReport,
  whoAmIReport,
  workOrdersReport,
  importInstallationPhotoReport,
  workOrdersByPlatformReport,
  updateOneWorkOrderReport
} from "../graphql/query/photoReports";

export const workOrdersByPlatform = ({ form }) => async dispatch => {
  dispatch({
    type: WORK_ORDERS_BY_PLATFORM.start
  });
  workOrdersByPlatformReport({ form })
    .then(data => {
      const { nodes } = data.workOrders;

      dispatch({
        type: WORK_ORDERS_BY_PLATFORM.success,
        payload: nodes
      });
    })
    .catch(error => {
      dispatch({
        type: WORK_ORDERS_BY_PLATFORM.error
      });
      console.error(error);
    });
};

export const getWorkOrdersList = async ({ form }) => {
  return await workOrdersByPlatformReport({ form })
    .then(data => {
      const { nodes } = data.workOrders;
      return nodes.map((i, idx) => ({
        ...i,
        label: i.advertisingSpace.advertisingPlatform.platformNumber,
        value: idx
      }));
    })
    .catch(error => {
      console.error(error);
    });
};

export const importInstallationPhoto = ({ form }) => async dispatch => {
  const { stop, table } = form.file;

  dispatch({
    type: INSTALLATION_PHOTO.start
  });

  const stopSelectedFiles = stop.selectedFiles.map(i => ({
    file: i,
    photoType: stop.photoType
  }));
  const tableSelectedFiles = table.selectedFiles.map(i => ({
    file: i,
    photoType: table.photoType
  }));

  const accSelectedFiles = stopSelectedFiles.concat(tableSelectedFiles);

  await Promise.all(
    accSelectedFiles.map(({ photoType, file }) =>
      importInstallationPhotoReport({
        form: {
          photoType,
          file,
          workOrderId: form.workOrderId
        }
      })
    )
  )
    .then(() => {
      updateOneWorkOrderReport({
        form: {
          workOrderId: form.workOrderId,
          userId: form.userId,
          factualInstallationDate: form.factualInstallationDate,
          status: form.status
        }
      })
        .then(() => {
          dispatch({
            type: INSTALLATION_PHOTO.success
          });
        })
        .catch(error => {
          dispatch({
            type: INSTALLATION_PHOTO.error
          });
          console.error(error);
        });
    })
    .catch(error => {
      dispatch({
        type: INSTALLATION_PHOTO.error
      });
      console.error(error);
    });
};

export const workOrders = () => async dispatch => {
  dispatch({
    type: WORK_ORDER.start
  });
  workOrdersReport()
    .then(data => {
      const { nodes } = data.workOrders;

      dispatch({
        type: WORK_ORDER.success,
        payload: nodes[0]
      });
    })
    .catch(error => {
      dispatch({
        type: WORK_ORDER.error
      });
      console.error(error);
    });
};

export const signIn = ({ form }) => async dispatch => {
  dispatch({
    type: SIGN_IN.start
  });
  signInReport({ form })
    .then(data => {
      const { token, user } = data.signIn;

      setTokenPh({ token });

      dispatch({
        type: SIGN_IN.success,
        payload: user
      });
    })
    .catch(error => {
      dispatch({
        type: SIGN_IN.error
      });
      console.error(error);
    });
};

export const whoAmI = () => async dispatch => {
  dispatch({
    type: WHO_AM_I.start
  });
  whoAmIReport()
    .then(data => {
      dispatch({
        type: WHO_AM_I.success,
        payload: data.whoAmI
      });
    })
    .catch(error => {
      dispatch({
        type: WHO_AM_I.error
      });
      console.error(error);
    });
};

export const selectType = ({ name }) => async dispatch => {
  dispatch({
    type: SELECT_TYPE_OF_WORK,
    payload: name
  });
};

export const setSelectedFile = ({
  files,
  id,
  key,
  typeKey
}) => async dispatch => {
  dispatch({
    type: SET_SELECTED_FILES,
    payload: { files, id, key, typeKey }
  });
};

export const setPlatformNumber = platformNumber => async dispatch => {
  dispatch({
    type: SET_PLATFORM_NUMBER,
    payload: platformNumber
  });
};

export const selectSide = id => async dispatch => {
  dispatch({
    type: SET_SIDE,
    payload: id
  });
};

export const selectPlatformNumber = name => async dispatch => {
  dispatch({
    type: SELECT_PLATFORM_NUMBER,
    payload: name
  });
};

export const changeSelectedDate = date => async dispatch => {
  dispatch({
    type: CHANGE_SELECTED_DATE,
    payload: date
  });
};

export const selectWorkOrder = workOrder => async dispatch => {
  dispatch({
    type: SELECT_WORK_ORDER,
    payload: workOrder
  });
};

export const resetPhState = () => async dispatch => {
  dispatch({
    type: RESET_PH_STATE
  });
};

export const resetLoggedInError = () => async dispatch => {
  dispatch({
    type: RESET_LOGIN_ERROR
  });
};
