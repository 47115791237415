import { SET_IS_MOBILE, DISABLE_FOOTER } from "../constants/app";

export const setIsMobile = isMobile => ({
  type: SET_IS_MOBILE.main,
  isMobile
});

export const disableFooter = value => ({
  type: DISABLE_FOOTER,
  value
});
