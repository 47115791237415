import createReducer from "./createReducer";
import { RESET_ERROR_FEEDBACK, SEND_FEEDBACK } from "../constants/feedback";

const initialState = {
  isFeedbackLoading: false,
  successFeedback: null,
  errorFeedback: null
};

const consumers = {
  [SEND_FEEDBACK.main]: state => ({
    ...state,
    isFeedbackLoading: true,
    errorFeedback: null
  }),

  [SEND_FEEDBACK.success]: state => ({
    ...state,
    successFeedback: true
  }),

  [SEND_FEEDBACK.error]: state => ({
    ...state,
    errorFeedback: true
  }),

  [SEND_FEEDBACK.end]: state => ({
    ...state,
    isFeedbackLoading: false,
    successFeedback: null
  }),

  [RESET_ERROR_FEEDBACK]: state => ({
    ...state,
    errorFeedback: null
  })
};

export default createReducer(initialState, consumers);
