import { takeEvery, call, put } from "redux-saga/effects";
import generateSagaWorker, {
  generateSagaWorkerSaveBlobFile,
  get,
  postJson
} from "./generateSaga";
import { GET_CITYFORMAT_MEDIAPLAN } from "../constants/cityFormat";
import {
  GET_LINES,
  GET_STATIONS,
  SEARCH_STATIONS,
  GET_METRO_MONITORS_MEDIAPLAN_REPORT,
  GET_LIGHTBOXES_MEDIAPLAN_REPORT,
  GET_STICKERS_MEDIAPLAN_REPORT,
  GET_TRAMS_MEDIAPLAN_REPORT,
  GET_GLOBAL_DISCOUNT_FACTORS,
  GET_ADVERTISER_DISCOUNT_AMOUNTS,
  GET_STANDALONE_MEDIAPLAN_REPORT
} from "../constants/metro";
import qs from "qs";

const sagaGenerator = generateSagaWorker(put, call);
const sagaGeneratorSaveFile = generateSagaWorkerSaveBlobFile(put, call);

const getLines = get(
  sagaGenerator,
  () => "/api/v1/public/metro-lines?offset=0&limit=30&isActive=true",
  GET_LINES.types
);

const getStations = get(
  sagaGenerator,
  action =>
    `/api/v1/public/metro-stations?offset=0&limit=100&${qs.stringify(
      action.form
    )}`,
  GET_STATIONS.types
);

const searchStations = get(
  sagaGenerator,
  action =>
    `/api/v1/public/metro-stations?offset=0&limit=100&${qs.stringify(
      action.form
    )}`,
  SEARCH_STATIONS.types
);

const getMetroMonitorsMediaplanReport = postJson(
  sagaGeneratorSaveFile,
  () => "/api/v1/public/media-plan/getReportMetroMonitors",
  GET_METRO_MONITORS_MEDIAPLAN_REPORT.types
);

const getLightboxesMediaplanReport = postJson(
  sagaGeneratorSaveFile,
  () => "/api/v1/public/media-plan/getReportLightBoxes",
  GET_LIGHTBOXES_MEDIAPLAN_REPORT.types
);

const getStickersMediaplanReport = postJson(
  sagaGeneratorSaveFile,
  () => "/api/v1/public/media-plan/getReportStickers",
  GET_STICKERS_MEDIAPLAN_REPORT.types
);

const getTramsMonitorsMediaplanReport = postJson(
  sagaGeneratorSaveFile,
  () => "/api/v1/public/media-plan/getReportTramsMonitors",
  GET_TRAMS_MEDIAPLAN_REPORT.types
);

const getGlobalDiscountFactors = get(
  sagaGenerator,
  () => "/api/v1/global-discount-factors",
  GET_GLOBAL_DISCOUNT_FACTORS.types
);

const getAdvertiserDiscountAmounts = get(
  sagaGenerator,
  () => "/api/v1/public/discounts-per-volume",
  GET_ADVERTISER_DISCOUNT_AMOUNTS.types
);

const getStandaloneMediaplanReport = postJson(
  sagaGeneratorSaveFile,
  () => "/api/v1/public/media-plan/getReportStandaloneMonitors",
  GET_STANDALONE_MEDIAPLAN_REPORT.types
);

const getCityFormatMediaplan = postJson(
  sagaGeneratorSaveFile,
  () => "/api/v1/advertisers/media-plan/getReportCityFormat",
  GET_CITYFORMAT_MEDIAPLAN.types
);

export default function* metro() {
  yield takeEvery(GET_LINES.main, getLines);
  yield takeEvery(GET_STATIONS.main, getStations);
  yield takeEvery(SEARCH_STATIONS.main, searchStations);
  yield takeEvery(
    GET_METRO_MONITORS_MEDIAPLAN_REPORT.main,
    getMetroMonitorsMediaplanReport
  );
  yield takeEvery(
    GET_LIGHTBOXES_MEDIAPLAN_REPORT.main,
    getLightboxesMediaplanReport
  );
  yield takeEvery(
    GET_STICKERS_MEDIAPLAN_REPORT.main,
    getStickersMediaplanReport
  );
  yield takeEvery(
    GET_TRAMS_MEDIAPLAN_REPORT.main,
    getTramsMonitorsMediaplanReport
  );
  yield takeEvery(GET_GLOBAL_DISCOUNT_FACTORS.main, getGlobalDiscountFactors);
  yield takeEvery(
    GET_ADVERTISER_DISCOUNT_AMOUNTS.main,
    getAdvertiserDiscountAmounts
  );
  yield takeEvery(
    GET_STANDALONE_MEDIAPLAN_REPORT.main,
    getStandaloneMediaplanReport
  );
  yield takeEvery(GET_CITYFORMAT_MEDIAPLAN.main, getCityFormatMediaplan);
}
