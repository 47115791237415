import createReducer from './createReducer';

import { GET_FAQ } from '../constants/questionAnswer';

const initialState = {
  questionAnswer: [],
  questionAnswerLoading: false,
};

const consumers = {
  [GET_FAQ.main]: state => ({
    ...state,
    questionAnswerLoading: true
  }),
  [GET_FAQ.success]: (state, action) => ({
    ...state,
    questionAnswerLoading: false,
    questionAnswer: action.result.data.questionAnswer
  })
};

export default createReducer(initialState, consumers);
