import {
  multiCalculation,
  getBasketInventories,
  selectPeriods,
  updateBasketPeriods
} from "store/helpers/multiCalculator";
import qs from "qs";
import moment from "moment";

import { MSB } from "utils/const";

import { updateItem } from "utils/basket";

import {
  GET_INVENTORY,
  SELECT_INVENTORY,
  SELECT_PERIOD,
  TOGGLE_AVAILABLE,
  CHANGE_PAGINATION,
  RESET_MULTI_STATE,
  TOGGLE_POPUP,
  SET_DATE,
  SET_BUGET_RANGE,
  SET_ADDRESS,
  SET_SORT,
  SET_INVENTORY,
  SET_BASKET,
  GET_TYPOGRAPHY_LIST,
  SELECT_TYPOGRAPHY,
  SHOW_MORE_STATION_ITEMS,
  TOGGLE_DISPLAY_STATIONS,
  FILL_BASKET,
  SET_MAP_OPEN,
  REMOVE_ALREDY_USE_PERIODS,
  GET_MAP_STATIONS,
  SET_ACTIVE_STATION,
  SET_DISTANCE,
  CHANGE_SIZE,
  GET_FORMATS,
  RECOVER_FILTER,
  SET_OTS,
  CHANGE_SELECT_METRO_LINE,
  CHANGE_SELECT_METRO_STATION,
  GET_METRO_LINE_WITH_STATION,
  RECOVER_METRO_FILTER,
  RESET_SELECT_METRO_LINE,
  RESET_SELECT_METRO_STATION,
  SET_MOBILE_FILTER,
  SUBMIT_METRO_FILTER,
  TOGGLE_ALL_METRO_LINE,
  TOGGLE_ALL_METRO_STATION,
  SET_ACTIVE_EXIT,
  SET_SELECTED_STATION,
  RESET_MAP_INVENTORY
} from "store/constants/multiCalculator";
import { getBusyBasket, removeBusyItem } from "utils/basket";

const getQueryData = () => qs.parse(window.location.search.slice(1));

const getSorter = (payloadType, item) => {
  if (item.field === payloadType) {
    if (!item.direction) {
      return "ASC";
    }

    if (item.direction === "ASC") {
      return "DESC";
    }

    if (item.direction === "DESC") {
      return "ASC";
    }
  }

  return item.direction;
};

const queryData = getQueryData();

const { startDate, endDate, latitude, longitude, address } = queryData;

let isCorrect;

if (startDate) {
  isCorrect = moment(startDate).add(1, "d") >= moment();

  if (!isCorrect) {
    const updateQuery = qs.stringify({
      ...queryData,
      startDate: moment().format("YYYY-MM-DD")
    });
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      updateQuery;
    window.history.pushState({ path: newurl }, "", newurl);
  }
}

const { MIN_PRICE, MAX_PRICE, MIN_OTS, MAX_OTS, MAX_RADIUS } = MSB;

// Initial state
export const initialState = {
  inventories: [],
  inventoriesCount: 1,
  inventoriesLoading: false,
  basketInventories: [],
  basketOpen: false,
  typography: null,
  typographySelected: false,
  showInventoryActive: true,
  basketLoading: false,
  mapOpen: false,
  lastView: "grid", // || map
  activeStation: [],
  activeExit: null,
  pagination: {
    forcePage: 0,
    limit: 3,
    offset: 0
  },
  recoverFilter: false,
  filter: {
    radius: MAX_RADIUS,
    address: address ? address : "",
    startDate: startDate && isCorrect ? moment(startDate) : moment(),
    endDate: endDate
      ? moment(endDate)
      : moment()
          .year(2022)
          .endOf("Y"),
    latitude: parseFloat(latitude ? latitude : "55.75322"),
    longitude: parseFloat(longitude ? longitude : "37.622513"),
    price: {
      lower: MIN_PRICE,
      upper: MAX_PRICE
    },
    ots: {
      lower: MIN_OTS,
      upper: MAX_OTS
    },
    sizes: [],
    stationId: [],
    lineId: []
  },
  initSizes: [],
  sortItems: [
    {
      id: Math.random(),
      name: "Рекомендуем Вам",
      field: "default",
      direction: "",
      selected: true,
      display: true
    },
    {
      id: Math.random(),
      name: "Расстояние",
      field: "distance",
      direction: "",
      selected: false,
      display: !!address
    },
    {
      id: Math.random(),
      name: "Кол-во контактов",
      field: "ots",
      direction: "DESC",
      selected: false,
      display: true
    },
    {
      id: Math.random(),
      name: "Цена (мин.)",
      field: "price",
      direction: "ASC",
      selected: false,
      display: true
    },
    {
      id: Math.random(),
      name: "Цена (макс.)",
      field: "price",
      direction: "DESC",
      selected: false,
      display: true
    },
    {
      id: Math.random(),
      name: "Цена за 1000 контактов",
      field: "pricePer1kContacts",
      direction: "ASC",
      selected: false,
      display: true
    },
    {
      id: Math.random(),
      name: "Размер (мин.)",
      field: "formatName",
      direction: "ASC",
      selected: false,
      display: true
    },
    {
      id: Math.random(),
      name: "Размер (макс.)",
      field: "formatName",
      direction: "DESC",
      selected: false,
      display: true
    }
  ],
  popup: {
    distance: false,
    calendar: false,
    ots: false,
    price: false,
    size: false
  },
  mapStations: [],
  lastMetroType: null,
  metroLineAndStation: null,
  submitMetroFilter: null
};

// Reducers
export const multiCalculator = (
  state = multiCalculation(initialState),
  action
) => {
  const { type, payload } = action;

  switch (type) {
    //for MAP -->
    case GET_MAP_STATIONS.success:
      return {
        ...state,
        mapStations: payload.map(ms => ({
          ...ms,
          selected: false, //выбранная станция
          visible: false //при зуме станции, которые попадают в видимую область карты
        }))
      };

    case SET_ACTIVE_STATION:
      return {
        ...state,
        activeStation: payload,
        mapStations: state.mapStations.map(ms => ({
          ...ms,
          visible: !!payload.find(station => station.id === ms.id)
        }))
      };

    case SET_SELECTED_STATION:
      return {
        ...state,
        mapStations: state.mapStations.map(ms => ({
          ...ms,
          selected: payload && payload.id === ms.id,
          visible: !payload ? false : ms.visible
        }))
      };

    case SET_ACTIVE_EXIT:
      return {
        ...state,
        activeExit: payload
      };

    case RESET_MAP_INVENTORY:
      return {
        ...state,
        inventories: []
      };
    //<--

    case GET_INVENTORY.start:
      const { forcePage } = state.pagination;

      return {
        ...state,
        inventoriesLoading: true,
        inventories: forcePage === 0 ? [] : state.inventories
      };

    case GET_INVENTORY.success:
      const { newNodes, totalCount } = payload;
      const { limit } = state.pagination;

      const recoverFromBasket = nodes => {
        return nodes.map(node => {
          if (node.advertisingSpaces && node.advertisingSpaces.length) {
            return {
              ...node,
              advertisingSpaces: node.advertisingSpaces.map(space => {
                const findInBasket = state.basketInventories.find(
                  biSpace => biSpace.availableId === space.availableId
                );
                return findInBasket
                  ? {
                      ...space,
                      availablePeriods: space.availablePeriods.map(period => {
                        const findInBasketPeriod = findInBasket.availablePeriods.find(
                          biPeriod =>
                            biPeriod.startDateFormat === period.startDateFormat
                        );
                        return findInBasketPeriod ? findInBasketPeriod : period;
                      }),
                      selectedPeriods: findInBasket.selectedPeriods,
                      availableBlock: true,
                      selected: true,
                      summaryPrice: findInBasket.summaryPrice,
                      totalPrice: findInBasket.totalPrice
                    }
                  : space;
              })
            };
          } else return node;
        });
      };

      let newInventories = state.inventories.concat(
        recoverFromBasket(newNodes)
      );
      // открываем первые 3 станции, если они приходят первыми
      // и выбран любой фильтр, кроме стандартного
      if (
        !state.inventories.length &&
        state.sortItems.find(i => i.selected).field !== "default"
      ) {
        newInventories = newInventories.map(inv =>
          inv.advertisingPlatform
            ? {
                ...inv,
                advertisingPlatform: {
                  ...inv.advertisingPlatform,
                  idDisplayStations: true
                }
              }
            : inv
        );
      }

      // убираем проверку на пустые спейсы для показа пустых станцый
      // newInventories = newInventories.filter(
      //   inv => inv.advertisingSpaces.length
      // );

      // если есть топ-3 карточки, то вычитаем из общей пагинации
      const withBestOffers = newInventories.find(
        inv => !inv.advertisingPlatform
      );

      return multiCalculation(
        {
          ...state,
          inventories: newInventories,
          inventoriesLoading: false,
          showInventoryActive:
            totalCount > newInventories.length - (withBestOffers ? 1 : 0),
          inventoriesCount:
            totalCount >= limit ? Math.ceil(totalCount / limit) : 1
        },
        type,
        state
      );

    case GET_INVENTORY.error:
      return multiCalculation(
        {
          ...state,
          inventories: payload.newNodes,
          inventoriesLoading: false,
          inventoriesCount: 1
        },
        type,
        state
      );

    case SELECT_INVENTORY:
      const findInInv = state.inventories.find(inv =>
        inv.advertisingSpaces.some(space => space.availableId === payload.id)
      );
      const inventoriesSelect = state.inventories.map(inv => {
        const findSpace = inv.advertisingSpaces.find(
          space => space.advertisingSpace.id === payload.id
        );
        if (!findSpace) return inv;

        const isSelectedInvenory = findSpace.selected;
        const { newPeriods, totalPrice, selectedPeriods } = selectPeriods({
          periods: findSpace.availablePeriods,
          basePrice: findSpace.price,
          isSelectedInvenory,
          type: payload.type
        });

        findSpace.availablePeriods = newPeriods.map(p => ({
          ...p,
          isAlredyUse: false
        }));
        findSpace.selected = !isSelectedInvenory;
        findSpace.totalPrice = totalPrice;
        findSpace.selectedPeriods = selectedPeriods;

        updateItem(findSpace.advertisingSpace.id, []);
        removeBusyItem(payload.id, null);

        return {
          ...inv,
          advertisingSpaces: inv.advertisingSpaces.map(space =>
            space.advertisingSpace.id === findSpace.advertisingSpace.id
              ? findSpace
              : space
          )
        };
      });

      let newBasket = state.basketInventories;

      if (!findInInv && payload.type === "basket") {
        // Если нажата икнока удаления из корзины и его нет выдаче, то удаляем space
        updateItem(payload.id, []);
        removeBusyItem(payload.id, null);
        newBasket = newBasket.filter(space => space.availableId !== payload.id);
      }

      return multiCalculation(
        {
          ...state,
          inventories: inventoriesSelect,
          basketInventories: getBasketInventories({
            inventories: inventoriesSelect,
            basketInventories: newBasket,
            id: payload.id
          })
        },
        type,
        state
      );

    case SELECT_PERIOD:
      const findSpacesInInv = state.inventories.filter(inv =>
        inv.advertisingSpaces.find(space => space.availableId === payload.id)
      );
      const inventoriesPeriod = state.inventories.map(inv => {
        const findSpace = inv.advertisingSpaces.find(
          space => space.advertisingSpace.id === payload.id
        );
        if (!findSpace) return inv;
        const {
          newPeriods,
          lbSelected,
          totalPrice,
          selectedPeriods
        } = selectPeriods({
          periods: findSpace.availablePeriods,
          basePrice: findSpace.price,
          payload,
          selectedPeriods: findSpace.selectedPeriods,
          type: payload.type
        });

        findSpace.availablePeriods = newPeriods;
        findSpace.selected = lbSelected;
        findSpace.totalPrice = totalPrice;
        findSpace.selectedPeriods = selectedPeriods;

        updateItem(findSpace.advertisingSpace.id, selectedPeriods);

        return {
          ...inv,
          advertisingSpaces: inv.advertisingSpaces.map(space =>
            space.advertisingSpace.id === findSpace.advertisingSpace.id
              ? findSpace
              : space
          )
        };
      });

      let updateOnlyBasketInventory = state.basketInventories;
      // если нет в выдаче, то изменяем только корзину
      if (!findSpacesInInv.length) {
        updateOnlyBasketInventory = updateOnlyBasketInventory.map(biInv => {
          const findSpaceInBi = biInv.availableId === payload.id;
          if (findSpaceInBi) {
            const {
              availablePeriods,
              selectedPeriods,
              totalPrice
            } = updateBasketPeriods(biInv, payload);
            updateItem(biInv.advertisingSpace.id, selectedPeriods);
            return {
              ...biInv,
              availablePeriods,
              selectedPeriods,
              totalPrice
            };
          }
          return biInv;
        });
      }

      return multiCalculation(
        {
          ...state,
          inventories: inventoriesPeriod,
          basketInventories: getBasketInventories({
            inventories: inventoriesPeriod,
            basketInventories: updateOnlyBasketInventory,
            id: payload.id
          })
        },
        type,
        state
      );

    case TOGGLE_AVAILABLE:
      const inventoriesToggle = state.inventories.map(inv => {
        const newSpace = inv.advertisingSpaces.map(space => {
          if (
            space.advertisingSpace.id !== payload.id ||
            space.availableId !== payload.availableId
          ) {
            return space;
          } else {
            return { ...space, availableBlock: !space.availableBlock };
          }
        });

        return { ...inv, advertisingSpaces: newSpace };
      });

      const basketInventoriesToggle = state.basketInventories.map(space => {
        if (
          space.advertisingSpace.id !== payload.id ||
          space.availableId !== payload.availableId
        ) {
          return space;
        } else {
          return { ...space, availableBlock: !space.availableBlock };
        }
      });

      return {
        ...state,
        inventories: inventoriesToggle,
        basketInventories: getBasketInventories({
          inventories: inventoriesToggle,
          basketInventories: basketInventoriesToggle,
          id: payload.id
        })
      };

    case CHANGE_PAGINATION:
      const newForcePage = state.pagination.forcePage + 3;
      return {
        ...state,
        pagination: {
          ...state.pagination,
          offset: newForcePage,
          forcePage: newForcePage
        }
      };

    case SET_MAP_OPEN:
      return {
        ...state,
        lastView: payload ? "map" : state.lastView,
        mapOpen: payload,
        pagination: {
          ...state.pagination,
          limit: payload ? 9999 : 3
        }
      };

    case RESET_MULTI_STATE:
      return {
        ...state,
        inventories: [],
        inventoriesLoading: false,
        basketInventories: [],
        pagination: {
          forcePage: 0,
          offset: 0
        }
      };

    case TOGGLE_POPUP:
      const { value, popupType } = payload;
      return {
        ...state,
        popup: {
          ...{
            distance: false,
            calendar: false,
            ots: false,
            price: false,
            size: false
          },
          [popupType]: value
        }
      };

    case SET_ADDRESS.success:
      return {
        ...state,
        inventories: [],
        inventoriesLoading: true,
        pagination: { ...state.pagination, offset: 0, forcePage: 0 },
        filter: {
          ...state.filter,
          stationId: [], // сбрасываем станции в store
          address: payload.address,
          latitude: parseFloat(payload.latitude),
          longitude: parseFloat(payload.longitude)
        },
        metroLineAndStation: {
          stations: state.metroLineAndStation.stations.map(item => ({
            ...item,
            selected: false
          })),
          lines: state.metroLineAndStation.lines.map(item => ({
            ...item,
            selected: false
          }))
        },
        sortItems: state.sortItems.map(i => ({
          ...i,
          selected: i.field === "default",
          direction: "",
          display: i.field === "distance" && !payload.address ? false : true
        }))
      };

    case SET_DISTANCE:
      return {
        ...state,
        filter: {
          ...state.filter,
          radius: Number(payload)
        },
        pagination: { ...initialState.pagination }
      };

    case SET_OTS:
      return {
        ...state,
        filter: {
          ...state.filter,
          ots: payload
        },
        pagination: { ...initialState.pagination }
      };

    case SET_DATE:
      const { date, key } = payload;
      return {
        ...state,
        inventories: [],
        inventoriesLoading: true,
        pagination: { ...state.pagination, offset: 0, forcePage: 0 },
        filter: {
          ...(key !== "fullDate"
            ? {
                ...state.filter,
                [key]: date ? date : state.filter[key]
              }
            : {
                ...state.filter,
                startDate: date.startDate,
                endDate: date.endDate
              })
        }
      };

    case SET_BUGET_RANGE:
      return {
        ...state,
        inventories: [],
        inventoriesLoading: true,
        pagination: { ...state.pagination, offset: 0, forcePage: 0 },
        filter: {
          ...state.filter,
          price: payload
        },
        sortItems: state.sortItems.map(i => ({
          ...i,
          selected: i.field === "default",
          direction: ""
        }))
      };

    case SET_SORT:
      let newSortItems = [];
      newSortItems = state.sortItems.map(i => ({
        ...i,
        selected: i.id === payload
      }));

      return {
        ...state,
        inventories: [],
        inventoriesLoading: true,
        pagination: { ...state.pagination, offset: 0, forcePage: 0 },
        sortItems: newSortItems
      };

    case SET_INVENTORY:
      const inventories = state.inventories.map(i => {
        return {
          ...i,
          inventoryCard:
            i.advertisingSpace.id === payload.advertisingSpace.id
              ? !i.inventoryCard
              : false
        };
      });
      return {
        ...state,
        inventories
      };

    case SET_BASKET:
      return {
        ...state,
        // закрываем карту при открытии корзины
        ...(!state.basketOpen && {
          mapOpen: false,
          lastView: state.mapOpen ? "map" : "grid",
          pagination: {
            ...state.pagination,
            limit: 3
          }
        }),
        ...(state.lastView === "map" &&
          state.basketOpen && {
            lastView: "map",
            mapOpen: true,
            pagination: {
              ...state.pagination,
              limit: 9999
            }
          }),
        basketOpen: !state.basketOpen,
        basketInventories: !state.basketOpen
          ? state.basketInventories.map(i => ({ ...i, availableBlock: true }))
          : state.basketInventories
      };

    case GET_TYPOGRAPHY_LIST.success:
      return multiCalculation(
        {
          ...state,
          typography: {
            name: payload.name,
            deliveryPrice: payload.deliveryPrice,
            prices: payload.prices.map(price => ({
              value: price.value,
              format: price.advertiseFormat.name
            }))
          }
        },
        type,
        state
      );

    case SELECT_TYPOGRAPHY:
      return multiCalculation(
        {
          ...state,
          typographySelected: !state.typographySelected
        },
        type,
        state
      );

    case SHOW_MORE_STATION_ITEMS.main:
      return multiCalculation(
        {
          ...state,
          inventories: [
            ...state.inventories.map(inv => {
              if (
                inv.advertisingPlatform &&
                inv.advertisingPlatform.id === payload
              ) {
                const canLoadFourItem =
                  inv.advertisingPlatform.countDisplay + 4 <=
                  inv.advertisingSpaces.length;
                return {
                  ...inv,
                  advertisingPlatform: {
                    ...inv.advertisingPlatform,
                    countDisplay: canLoadFourItem
                      ? inv.advertisingPlatform.countDisplay + 4
                      : inv.advertisingSpaces.length
                  }
                };
              }
              return inv;
            })
          ]
        },
        type,
        state
      );

    case TOGGLE_DISPLAY_STATIONS.main:
      return multiCalculation(
        {
          ...state,
          inventories: [
            ...state.inventories.map(inv => {
              if (
                inv.advertisingPlatform &&
                inv.advertisingPlatform.id === payload
              ) {
                return {
                  ...inv,
                  advertisingPlatform: {
                    ...inv.advertisingPlatform,
                    idDisplayStations: !inv.advertisingPlatform
                      .idDisplayStations
                  }
                };
              }
              return inv;
            })
          ]
        },
        type,
        state
      );

    case FILL_BASKET.start:
      return multiCalculation(
        {
          ...state,
          basketLoading: true
        },
        type,
        state
      );

    case FILL_BASKET.success:
      return multiCalculation(
        {
          ...state,
          basketLoading: false,
          basketInventories: payload.basketInventories
        },
        type,
        state
      );

    case FILL_BASKET.error:
      return multiCalculation(
        {
          ...state,
          basketLoading: false
        },
        type,
        state
      );
    case REMOVE_ALREDY_USE_PERIODS.main:
      const busyBasket = getBusyBasket();
      let updateBasket = state.basketInventories;
      let updateInvs = state.inventories;

      const findInBusyBasket = busyBasket.find(b => b.i === payload);

      if (findInBusyBasket) {
        // удаляем все из storage
        removeBusyItem(payload, null);

        // из крзины
        updateBasket = updateBasket.map(space =>
          space.availableId !== payload
            ? space
            : {
                ...space,
                availablePeriods: space.availablePeriods.map(p => ({
                  ...p,
                  isAlredyUse: false
                }))
              }
        );

        // из выдачи
        updateInvs = updateInvs.map(inv => {
          let newInv = inv;
          if (inv.advertisingSpaces)
            newInv.advertisingSpaces = newInv.advertisingSpaces.map(space => ({
              ...space,
              availablePeriods:
                space.availableId !== payload
                  ? space.availablePeriods
                  : space.availablePeriods.map(p => ({
                      ...p,
                      isAlredyUse: false
                    }))
            }));
          return newInv;
        });
      }

      // если в space были кроме isAlredyUse все !isAvialable
      // то убираем из корзины
      updateBasket = updateBasket.filter(space =>
        space.availablePeriods.some(p => p.isAlredyUse || p.isAvailable)
      );

      return multiCalculation(
        {
          ...state,
          inventories: updateInvs,
          basketInventories: getBasketInventories({
            inventories: updateInvs,
            basketInventories: updateBasket,
            id: payload
          })
        },
        type,
        state
      );

    case GET_FORMATS.success:
      return {
        ...state,
        initSizes: payload
      };

    case CHANGE_SIZE:
      return {
        ...state,
        filter: {
          ...state.filter,
          sizes: payload
        },
        pagination: { ...initialState.pagination }
      };

    case RECOVER_FILTER.main:
      return {
        ...state,
        recoverFilter: true,
        filter: initialState.filter,
        pagination: { ...initialState.pagination }
      };

    case RECOVER_FILTER.end:
      return {
        ...state,
        recoverFilter: false
      };

    case SET_MOBILE_FILTER:
      const { address, distance, period, ots, price, sizes } = payload;

      return {
        ...state,
        filter: {
          ...state.filter,
          radius: distance,
          address,
          startDate: period.from,
          endDate: period.to,
          price: {
            lower: price.min,
            upper: price.max
          },
          ots: {
            lower: ots.min,
            upper: ots.max
          },
          sizes
        },
        pagination: { ...initialState.pagination }
      };

    case GET_METRO_LINE_WITH_STATION.success:
      return {
        ...state,
        metroLineAndStation: payload
      };

    case CHANGE_SELECT_METRO_LINE:
      return {
        ...state,
        metroLineAndStation: {
          ...state.metroLineAndStation,
          lines: state.metroLineAndStation.lines.map(line =>
            line.id === payload.id
              ? { ...line, selected: payload.selected }
              : line
          )
        }
      };
    case CHANGE_SELECT_METRO_STATION:
      return {
        ...state,
        metroLineAndStation: {
          ...state.metroLineAndStation,
          stations: state.metroLineAndStation.stations.map(station =>
            station.id === payload.id
              ? { ...station, selected: payload.selected }
              : station
          )
        }
      };

    case RESET_SELECT_METRO_LINE:
      return {
        ...state,
        metroLineAndStation: {
          ...state.metroLineAndStation,
          lines: state.metroLineAndStation.lines.map(line => ({
            ...line,
            selected: false
          }))
        }
      };
    case RESET_SELECT_METRO_STATION:
      return {
        ...state,
        metroLineAndStation: {
          ...state.metroLineAndStation,
          stations: state.metroLineAndStation.stations.map(station => ({
            ...station,
            selected: false
          }))
        }
      };
    case TOGGLE_ALL_METRO_LINE:
      const everySelectedLines = state.metroLineAndStation.lines.every(
        line => line.selected
      );
      return {
        ...state,
        metroLineAndStation: {
          ...state.metroLineAndStation,
          lines: state.metroLineAndStation.lines.map(line => ({
            ...line,
            selected: everySelectedLines ? false : true
          }))
        }
      };
    case TOGGLE_ALL_METRO_STATION:
      const everySelectedStations = state.metroLineAndStation.stations.every(
        station => station.selected
      );
      return {
        ...state,
        metroLineAndStation: {
          ...state.metroLineAndStation,
          stations: state.metroLineAndStation.stations.map(station => ({
            ...station,
            selected: everySelectedStations ? false : true
          }))
        }
      };

    case SUBMIT_METRO_FILTER.main:
      return {
        ...state,
        submitMetroFilter: true,
        filter: {
          ...state.filter,
          address: "",
          latitude: initialState.filter.latitude,
          longitude: initialState.filter.longitude,
          stationId:
            payload !== "stations"
              ? []
              : state.metroLineAndStation["stations"]
                  .filter(item => item.selected)
                  .map(item => item.id),
          lineId:
            payload !== "lines"
              ? []
              : state.metroLineAndStation["lines"]
                  .filter(item => item.selected)
                  .map(item => item.id),
          radius: MAX_RADIUS
        },
        lastMetroType: payload,
        pagination: { ...initialState.pagination }
      };
    case SUBMIT_METRO_FILTER.end:
      return {
        ...state,
        submitMetroFilter: false
      };

    case RECOVER_METRO_FILTER:
      return {
        ...state,
        metroLineAndStation: {
          stations: state.metroLineAndStation.stations.map(item => ({
            ...item,
            selected:
              state.lastMetroType === "stations" &&
              state.filter.stationId.includes(item.id)
          })),
          lines: state.metroLineAndStation.lines.map(item => ({
            ...item,
            selected:
              state.lastMetroType === "lines" &&
              state.filter.lineId.includes(item.id)
          }))
        }
      };

    default:
      return state;
  }
};
