import React, { useEffect } from "react";

import Modal from "react-modal";
import { ModalBlock, Block, CloseImg } from "./styled";

import close from "img/close.svg";

const customStyles = {
  overlay: {
    display: "flex",
    justifyContent: "center",
    overflow: "auto",
    background: "rgba(63, 63, 63, 0.6)",
    backdropFilter: "blur(2px)"
  },
  content: {
    border: "unset",
    inset: "unset",
    background: "unset",
    padding: "40px 20px"
  }
};

const ReactModal = ({ openModal, setOpenModal, children }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={() => setOpenModal(false)}
      style={customStyles}
    >
      <ModalBlock>
        <Block>
          <CloseImg
            src={close}
            alt="close"
            onClick={() => setOpenModal(false)}
          />
          {children}
        </Block>
      </ModalBlock>
    </Modal>
  );
};

export default ReactModal;
