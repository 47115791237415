import "react-datepicker/dist/react-datepicker.css";
import "react-input-range/lib/css/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toggle/style.css";

import React from "react";
import { render } from "react-dom";
import moment from "moment";
import * as locale from "moment/locale/ru";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { unregister } from "./registerServiceWorker";
import UnsopportedBrowser from "./components/UnsopportedBrowser";

moment.updateLocale("ru", locale);

unregister();

const isNotLegacyBrowser = () => {
  // currently app not working on IE11 cause there is no find in Array prototype,
  // if we add polyfill it working properly, but
  // IE also not supporting some using css features
  if (!Array.prototype.find) {
    return false;
  }

  return true;
};

if (isNotLegacyBrowser()) {
  render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById("root")
  );
} else {
  render(<UnsopportedBrowser />, document.getElementById("root"));
}
