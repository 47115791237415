import generateTypes from "./constantsGenerator";

export const GET_LINES = generateTypes("GET_LINES");

export const GET_STATIONS = generateTypes("GET_STATIONS");

export const SEARCH_STATIONS = generateTypes("SEARCH_STATIONS");

export const CLEAR_STATIONS = "CLEAR_STATIONS";

export const GET_METRO_MONITORS_MEDIAPLAN_REPORT = generateTypes(
  "GET_METRO_MONITORS_MEDIAPLAN_REPORT"
);

export const GET_LIGHTBOXES_MEDIAPLAN_REPORT = generateTypes(
  "GET_LIGHTBOXES_MEDIAPLAN_REPORT"
);

export const GET_STICKERS_MEDIAPLAN_REPORT = generateTypes(
  "GET_STICKERS_MEDIAPLAN_REPORT"
);

export const GET_TRAMS_MEDIAPLAN_REPORT = generateTypes(
  "GET_TRAMS_MEDIAPLAN_REPORT"
);

export const GET_GLOBAL_DISCOUNT_FACTORS = generateTypes(
  "GET_GLOBAL_DISCOUNT_FACTORS"
);

export const GET_ADVERTISER_DISCOUNT_AMOUNTS = generateTypes(
  "GET_ADVERTISER_DISCOUNT_AMOUNTS"
);

export const GET_STANDALONE_MEDIAPLAN_REPORT = generateTypes(
  "GET_STANDALONE_MEDIAPLAN_REPORT"
);

export const RESET_DISCOUNTS = "RESET_DISCOUNTS";
