import moment from "moment";
import {
  SIGN_IN,
  WHO_AM_I,
  SELECT_TYPE_OF_WORK,
  SET_PLATFORM_NUMBER,
  SET_SELECTED_FILES,
  SET_SIDE,
  WORK_ORDER,
  SELECT_PLATFORM_NUMBER,
  CHANGE_SELECTED_DATE,
  WORK_ORDERS_BY_PLATFORM,
  SELECT_WORK_ORDER,
  INSTALLATION_PHOTO,
  RESET_PH_STATE,
  RESET_LOGIN_ERROR
} from "../constants/photoReports";

const initialState = {
  searchPlatformNumber: "",
  platformNumber: "",
  selectedDate: moment(),
  workOrdersList: [],
  workOrder: null,
  user: null,
  loggedIn: false,
  loggedInError: "",
  sides: [
    {
      name: "Сторона A",
      id: "a",
      selected: true
    },
    {
      name: "Сторона B",
      id: "b",
      selected: false
    }
  ],
  files: {
    a: {
      table: {
        photoType: "transitSign",
        selectedFiles: [],
        previews: []
      },
      stop: {
        photoType: "poster",
        selectedFiles: [],
        previews: []
      }
    },
    b: {
      table: {
        photoType: "transitSign",
        selectedFiles: [],
        previews: []
      },
      stop: {
        photoType: "poster",
        selectedFiles: [],
        previews: []
      }
    }
  },
  typesOfWork: [
    { text: "Монтаж новой РК", selected: true, buttonText: "Завершить монтаж" }
    // { text: "Замена постера", selected: false, buttonText: "Завершить монтаж" },
    // { text: "Демонтаж", selected: false, buttonText: "Демонтировать" },
    // { text: "Перемонтаж", selected: false, buttonText: "Перемонтировать" },
    // {
    //   text: "Инвентаризация",
    //   selected: false,
    //   buttonText: "Инвентаризировать"
    // }
  ],
  selectedWorkOrder: null,
  installationPhotoLoad: false,
  installationPhotoLoading: false
};

export const photoReports = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    //Регистрация
    case SIGN_IN.start:
      return {
        ...state,
        loggedIn: false,
        loggedInError: ""
      };
    case SIGN_IN.success:
      return {
        ...state,
        loggedIn: true
      };

    case SIGN_IN.error:
      return {
        ...state,
        loggedInError: "Неправильный логин или пароль"
      };

    case WHO_AM_I.success:
      return {
        ...state,
        user: payload
      };

    case SELECT_TYPE_OF_WORK:
      return {
        ...state,
        typesOfWork: state.typesOfWork.map(type => ({
          ...type,
          selected: type.text === payload
        }))
      };

    case SET_SIDE:
      return {
        ...state,
        sides: state.sides.map(side => ({
          ...side,
          selected: side.id === payload
        }))
      };

    case SET_SELECTED_FILES:
      return {
        ...state,
        files: {
          ...state.files,
          [payload.id]: {
            ...state.files[payload.id],
            [payload.key]: {
              ...state.files[payload.id][payload.key],
              [payload.typeKey]: payload.files
            }
          }
        }
      };

    case SET_PLATFORM_NUMBER:
      return {
        ...state,
        searchPlatformNumber: payload,
        workOrdersList: []
      };

    case WORK_ORDER.success:
      return {
        ...state,
        workOrder: payload
      };

    case WORK_ORDERS_BY_PLATFORM.success:
      return {
        ...state,
        workOrdersList: payload.map(i => ({
          ...i,
          name: i.advertisingSpace.advertisingPlatform.platformNumber,
          selected: false
        }))
      };

    case SELECT_PLATFORM_NUMBER:
      return {
        ...state,
        platformNumber: payload,
        searchPlatformNumber: payload,
        workOrdersList: state.workOrdersList.map(i => ({
          ...i,
          selected: payload === i.name
        }))
      };

    case CHANGE_SELECTED_DATE:
      return {
        ...state,
        selectedDate: payload,
        selectedWorkOrder: null
      };

    case SELECT_WORK_ORDER:
      return {
        ...state,
        selectedWorkOrder: payload,
        files: payload
          ? {
              ...state.files,
              [payload.advertisingSpace.side.name]: {
                table: {
                  photoType: "transitSign",
                  selectedFiles: [],
                  previews: payload.transitSignPhotoUrl
                    ? [
                        {
                          preview: payload.transitSignPhotoUrl,
                          name: payload.transitSignPhotoUrl
                        }
                      ]
                    : []
                },
                stop: {
                  photoType: "poster",
                  selectedFiles: [],
                  previews: payload.photos
                    .map(({ url }) => url)
                    .map(url => ({
                      preview: url,
                      name: url
                    }))
                }
              }
            }
          : {
              a: {
                table: {
                  photoType: "transitSign",
                  selectedFiles: [],
                  previews: []
                },
                stop: {
                  photoType: "poster",
                  selectedFiles: [],
                  previews: []
                }
              },
              b: {
                table: {
                  photoType: "transitSign",
                  selectedFiles: [],
                  previews: []
                },
                stop: {
                  photoType: "poster",
                  selectedFiles: [],
                  previews: []
                }
              }
            },
        sides: state.sides.map(side => ({
          ...side,
          selected: payload
            ? side.id === payload.advertisingSpace.side.name
            : side.id === "a"
        }))
      };

    case INSTALLATION_PHOTO.start:
      return {
        ...state,
        installationPhotoLoad: false,
        installationPhotoLoading: true
      };

    case INSTALLATION_PHOTO.success:
      return {
        ...state,
        installationPhotoLoad: true,
        installationPhotoLoading: false
      };

    case RESET_LOGIN_ERROR:
      return {
        ...state,
        loggedInError: ""
      };

    case RESET_PH_STATE:
      return {
        ...state,
        installationPhotoLoad: false,
        searchPlatformNumber: "",
        selectedDate: moment(),
        selectedWorkOrder: null,
        installationPhotoLoading: false,
        files: {
          a: {
            table: {
              photoType: "transitSign",
              selectedFiles: [],
              previews: []
            },
            stop: {
              photoType: "poster",
              selectedFiles: [],
              previews: []
            }
          },
          b: {
            table: {
              photoType: "transitSign",
              selectedFiles: [],
              previews: []
            },
            stop: {
              photoType: "poster",
              selectedFiles: [],
              previews: []
            }
          }
        }
      };

    //Выбранный вид работы

    default:
      return state;
  }
};
