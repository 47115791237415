import { takeEvery, call, put } from "redux-saga/effects";
import { SEND_FEEDBACK } from "../constants/feedback";
import generateSagaWorker, { postJson } from "./generateSaga";

const sagaGenerator = generateSagaWorker(put, call);

const sendFeedback = postJson(
  sagaGenerator,
  () => "/api/v1/public/feedback",
  SEND_FEEDBACK.types
);

export default function* auth() {
  yield takeEvery(SEND_FEEDBACK.main, sendFeedback);
}
