import generateTypes from "./constantsGenerator";

export const SIGN_IN = generateTypes("SIGN_IN");
export const WHO_AM_I = generateTypes("WHO_AM_I");
export const WORK_ORDER = generateTypes("WORK_ORDER");
export const INSTALLATION_PHOTO = generateTypes("INSTALLATION_PHOTO");
export const WORK_ORDERS_BY_PLATFORM = generateTypes("WORK_ORDERS_BY_PLATFORM");

export const SELECT_TYPE_OF_WORK = "SELECT_TYPE_OF_WORK";
export const SET_SELECTED_FILES = "SET_SELECTED_FILES";
export const SET_PLATFORM_NUMBER = "SET_PLATFORM_NUMBER";
export const SELECT_PLATFORM_NUMBER = "SELECT_PLATFORM_NUMBER";
export const CHANGE_SELECTED_DATE = "CHANGE_SELECTED_DATE";
export const SELECT_WORK_ORDER = "SELECT_WORK_ORDER";
export const RESET_LOGIN_ERROR = "RESET_LOGIN_ERROR";
export const RESET_PH_STATE = "RESET_PH_STATE";
export const SET_SIDE = "SET_SIDE";
