import moment from "moment";
import {
  UPDATE_PRESENTATION_COUNT,
  UPDATE_PRESENTATION_COUNT_COMPLETED,
  TOGGLE_DAYS_WEEK,
  TOGGLE_MLINE,
  TOGGLE_ROUTE,
  SELECT_PRESENTATION_TIME,
  TOGGLE_PRESENTATION_FREQUENCY,
  RESET_GROUPS,
  UPDATE_CLIP_DURATION,
  UPDATE_CLIP_DURATION_COMPLETED,
  FIXED_BUDGET
} from "store/constants/monitorsCalculator";
import { otsRatio } from "utils/const";

export const createFormData = form => {
  const formData = new FormData();

  Object.keys(form).forEach(key => {
    formData.append(key, form[key]);
  });

  return formData;
};

export const getPlannerParams = (method, endDate) => {
  switch (method) {
    case "testfit":
      return {
        mode: "testfit",
        maxdate: endDate.subtract(0, "days").format("YYYY-MM-DD")
      };
    default:
      return {
        mode: "getmaxdate"
      };
  }
};

export const getPlannerError = (
  dates,
  endDateEditionDisabled,
  advice_result
) => {
  const isDiffBetweenDates =
    dates.fromFormat !== dates.newFrom.format("YYYY-MM-DD HH:mm:ss") ||
    dates.toFormat !== dates.newTo.format("YYYY-MM-DD HH:mm:ss");

  if (isDiffBetweenDates) {
    return "Период автоматически изменен";
  }

  switch (advice_result) {
    case "success":
      return "";
    case "dontfit":
      return "Кампания не может быть размещена с указанными параметрами";
    case "notimplemented":
      return "Данный способ расчетов еще не реализован";
    default:
      return "Неизвестная ошибка в расчетах, попробуйте изменить параметры кампании";
  }
};

export const updateOtsPeriods = ({
  presentationTime,
  mlines,
  reportsTimeIntervals,
  reportsMlines,
  daysWeek,
  clipDuration,
  campaignPeriods,
  metroMonitors
}) => {
  let metroLineIds = [];
  let metroMonitorIds = [];
  let timeIntervalIds = [];

  if (reportsMlines.length) {
    const lines = mlines.activeTargeting
      ? mlines.items.filter(item => item.selected)
      : mlines.items;

    metroLineIds = lines.map(line => {
      const exactLine = reportsMlines.find(
        reportLine => reportLine.id === line.reportId
      );

      return exactLine?.id;
    });
  }

  if (metroMonitors.items.length) {
    metroMonitorIds = metroLineIds.map(lineId => {
      const exactMetroMonitor = metroMonitors.items.find(
        metroMonitor => metroMonitor.metroLine.id === lineId
      );

      return exactMetroMonitor?.id;
    });
  }

  if (reportsTimeIntervals.length) {
    if (presentationTime.activeTargeting) {
      const pickedTimeIntervals = presentationTime.items.filter(
        item => item.selected
      );
      timeIntervalIds = pickedTimeIntervals.map(interval => {
        const exactReportsInterval = reportsTimeIntervals.find(item => {
          const intervalStart = interval.from.format("HH:mm:ssZ");
          const intervalEnd = interval.to.format("HH:mm:ssZ");
          const start = moment(item.startTime, "HH:mm:ssZ").format("HH:mm:ssZ");
          const end = moment(item.endTime, "HH:mm:ssZ").format("HH:mm:ssZ");

          return start === intervalStart && end === intervalEnd;
        });
        return exactReportsInterval?.id;
      });
    } else {
      timeIntervalIds = reportsTimeIntervals.map(interval => interval.id);
    }
  }

  const weekPartBaseName =
    (daysWeek.activeTargeting && daysWeek.items.every(i => i.selected)) ||
    !daysWeek.activeTargeting
      ? "wholeWeek"
      : daysWeek.items.find(({ value }) => value === "workday").selected
      ? "workdays"
      : "weekend";
  return campaignPeriods.map(period => {
    return {
      startDate: moment(period.startDate).format("YYYY-MM-DD"),
      endDate: moment(period.endDate).format("YYYY-MM-DD"),
      totalCount: period.presentationCountMonth,
      maxDuration: clipDuration.value,
      maxRunsPerHour: 1,
      timeIntervalIds,
      metroMonitorIds,
      weekPartBaseName
    };
  });
};

const numberFromPercent = (percent, number) => {
  return (number * percent) / 100;
};

const roundValue = value => Math.round(value * 100) / 100;

const isDayOff = date => {
  return date.isoWeekday() === 6 || date.isoWeekday() === 7;
};

const campaignDurationTram = (
  presentationCount,
  date,
  selectedMonitors,
  selectedMonitorsDayOff,
  frequency,
  hours
) => {
  const startDate = date.clone();
  let presentationCountAcc = 0;
  let durationTram = 0;

  while (presentationCountAcc < presentationCount) {
    presentationCountAcc +=
      frequency *
      hours *
      ((isDayOff(startDate) ? selectedMonitorsDayOff : selectedMonitors) * 0.7);

    durationTram += 1;
    startDate.add(1, "days");
  }

  return durationTram;
};

const getPercentFromCoefficient = (coefficient, reverse) => {
  const percent = reverse
    ? (1 - coefficient).toPrecision(12) * 100
    : (coefficient - 1).toPrecision(12) * 100;

  return percent;
};

const calcTotalPrice = ({ targetingList, basePrice, discount }) => {
  const targetingWithSum = targetingList.map(item => ({
    ...item,
    sum: numberFromPercent(item.percent, basePrice)
  }));
  const sumAllTargeting = targetingWithSum.reduce(
    (acc, item) => acc + item.sum,
    0
  );

  return (
    basePrice +
    sumAllTargeting -
    numberFromPercent(discount, basePrice + sumAllTargeting)
  );
};

const calcDisabledWidthLine = ({ max, min, step, width, stopValue }) => {
  const fullLine = max - min;
  const countStepFromRight = (fullLine - (stopValue - min)) / step;
  const countAllStep = fullLine / step;
  const stepByPixel = width / countAllStep;

  return stepByPixel * countStepFromRight;
};

const getAdvertisePlaceType = monitorType => {
  switch (monitorType) {
    case "mlines":
      return "metroMonitors";
    case "routes":
      return "monitorsTram";
    default:
      return "monitorsStandalone";
  }
};

const workingHoursPerDay = 19.5;
const countDaysMonth = 30;
const halfHoursDay = 39;

export const monitorsCalculation = (state, actionType, lastState) => {
  let {
    budget,
    priceOneSecond,
    $priceOneSecond,
    clipDuration,
    presentationFrequency,
    presentationTime,
    presentationCount,
    mlines,
    routes,
    daysWeek,
    advertisePlaces,
    seasonalDiscount,
    volumeDiscount,
    seasonalTableDiscount,
    priceOneSecondForBudget,
    reportsOtsPerPeriod,
    presentationPeriod,
    monitors
  } = state;
  let countContactsFrom;
  let countContactsTo;
  let primeTime;
  let hasNotPrimeTime;
  let targetingList = [];

  const monitorType = mlines.items.length
    ? "mlines"
    : routes.items.length
    ? "routes"
    : "monitors";
  const itemsByMonitorType =
    monitorType === "mlines"
      ? mlines
      : monitorType === "routes"
      ? routes
      : monitors;
  let notHaveMonitors = false;
  let basePriceWithoutDiscounts = 0;
  let selectedMonitorsDayOff = 0;

  if (advertisePlaces.length) {
    const advertisePlaceType = getAdvertisePlaceType(monitorType);
    const {
      options: { price, price2, ration, videoOptions }
    } = advertisePlaces.find(({ _id }) => _id === advertisePlaceType);

    if (monitorType === "mlines" || monitorType === "routes") {
      priceOneSecond = price2 && presentationCount.value > 1e6 ? price2 : price;
      priceOneSecondForBudget =
        price2 && presentationCount.value > 1e6 ? price2 : price;
      basePriceWithoutDiscounts = priceOneSecond * clipDuration.value * 1000;
      $priceOneSecond =
        price2 && presentationCount.value > 1e6 ? price2 : price;
      clipDuration.max = videoOptions.blockDuration;
      clipDuration.coefficient = ration.videoHronometrach;
      presentationFrequency.coefficient = ration.primeFrequency;
      daysWeek.coefficient = ration.anyDays;
      presentationTime.coefficient = ration.anyTime;
      presentationTime.coefficientTwo = ration.primeTime;
    } else {
      priceOneSecond = price;
      $priceOneSecond = price;
    }
  }

  const seasonalDiscountPercent = getPercentFromCoefficient(
    seasonalDiscount,
    true
  );

  if (seasonalDiscountPercent) {
    priceOneSecondForBudget -= numberFromPercent(
      priceOneSecondForBudget,
      seasonalDiscountPercent
    );
  }

  const isWorkday = daysWeek.items.find(({ value }) => value === "workday")
    .selected;
  const isHoliday = daysWeek.items.find(({ value }) => value === "holiday")
    .selected;

  const someSelectedMonitors = itemsByMonitorType.items.some(
    item => item.selected
  );

  let selectedMonitors = itemsByMonitorType.items.reduce(
    (acc, item) =>
      item.selected
        ? acc +
          item[
            monitorType === "mlines"
              ? "averageMonitorsAmount"
              : "monitorsAmount"
          ]
        : acc,
    0
  );

  if (monitorType === "routes") {
    //selectedMonitors для трамваев подразумевает в себе расчет мониторов работающих по будням
    selectedMonitors = itemsByMonitorType.items.reduce(
      (acc, item) =>
        item.selected
          ? acc +
            (!isHoliday || (isHoliday && isWorkday) ? item.monitorsAmount : 0)
          : acc,
      0
    );
    //Только для трамваев, расчет мониторов выходного дня
    selectedMonitorsDayOff = itemsByMonitorType.items.reduce(
      (acc, item) =>
        item.selected
          ? acc +
            (!isWorkday || (isHoliday && isWorkday) ? item.monitorsDayOff : 0)
          : acc,
      0
    );
  }

  if (!someSelectedMonitors) {
    notHaveMonitors = true;
    selectedMonitors = itemsByMonitorType.items.reduce(
      (acc, item) =>
        acc +
        (monitorType === "mlines"
          ? item.averageMonitorsAmount
          : item.monitorsAmount),
      0
    );

    if (monitorType === "routes") {
      selectedMonitors = itemsByMonitorType.items.reduce(
        (acc, item) =>
          !item.disabled
            ? acc +
              (!isHoliday || (isHoliday && isWorkday) ? item.monitorsAmount : 0)
            : acc,
        0
      );
      selectedMonitorsDayOff = itemsByMonitorType.items.reduce(
        (acc, item) =>
          !item.disabled
            ? acc +
              (!isWorkday || (isHoliday && isWorkday) ? item.monitorsDayOff : 0)
            : acc,
        0
      );
    }
  }

  //targeting
  itemsByMonitorType.activeTargeting = false;
  daysWeek.activeTargeting = false;
  presentationTime.activeTargeting = false;
  presentationFrequency.activeTargeting = false;
  clipDuration.activeTargeting = false;

  const activeMlineOrRoute = itemsByMonitorType.items.filter(
    item => item.selected
  );

  if (
    activeMlineOrRoute.length &&
    activeMlineOrRoute.length <
      (monitorType === "routes"
        ? itemsByMonitorType.items.filter(({ disabled }) => !disabled).length
        : itemsByMonitorType.items.length)
  ) {
    const coefficient =
      activeMlineOrRoute
        .map(({ ratioPrice }) => ratioPrice)
        .reduce((a, b) => a + b, 0) / activeMlineOrRoute.length;

    targetingList.push({
      name: "Геотаргетинг",
      nameBlock: monitorType,
      percent:
        monitorType === "mlines" || monitorType === "routes"
          ? getPercentFromCoefficient(coefficient)
          : 10
    });
    itemsByMonitorType.activeTargeting = true;
  }

  if (monitorType === "mlines" && clipDuration.value === clipDuration.max) {
    targetingList.push({
      name: "Таргетинг за длительность ролика",
      nameBlock: "clipDuration",
      percent: getPercentFromCoefficient(clipDuration.coefficient)
    });
    clipDuration.activeTargeting = true;
  }

  const activeDaysWeek = daysWeek.items.filter(item => item.selected).length;
  if (activeDaysWeek && activeDaysWeek < daysWeek.items.length) {
    targetingList.push({
      name: "Таргетинг за дни недели",
      nameBlock: "daysWeek",
      percent:
        monitorType === "mlines" || monitorType === "routes"
          ? getPercentFromCoefficient(daysWeek.coefficient)
          : 10
    });
    daysWeek.activeTargeting = true;
  }

  const activePresentationTime = presentationTime.items.filter(
    item => item.selected
  );
  if (
    activePresentationTime.length &&
    activePresentationTime.length < presentationTime.items.length
  ) {
    primeTime = activePresentationTime.some(
      item => item._id === "2" || item._id === "4"
    );

    hasNotPrimeTime = activePresentationTime.some(
      item => item._id !== "2" && item._id !== "4"
    );

    if (primeTime) {
      targetingList.push({
        name: "Временной таргетинг",
        nameBlock: "presentationTime",
        percent:
          monitorType === "mlines" || monitorType === "routes"
            ? getPercentFromCoefficient(presentationTime.coefficientTwo)
            : 30
      });

      presentationTime.activeTargeting = true;
    } else {
      targetingList.push({
        name: "Временной таргетинг",
        nameBlock: "presentationTime",
        percent:
          monitorType === "mlines" || monitorType === "routes"
            ? getPercentFromCoefficient(presentationTime.coefficient)
            : 10
      });
      presentationTime.activeTargeting = true;
    }
  }

  const activePresentationFrequency = presentationFrequency.items.filter(
    item => item.selected
  ).length;
  if (activePresentationFrequency) {
    targetingList.push({
      name: "Таргетинг за частоту показов в час",
      nameBlock: "presentationFrequency",
      percent:
        monitorType === "mlines"
          ? getPercentFromCoefficient(presentationFrequency.coefficient)
          : 10
    });
    presentationFrequency.activeTargeting = true;
  }

  // Убирем из таргета где процент равен - 0
  targetingList = targetingList.filter(({ percent }) => !!percent);

  // Умножаем на 0.8 если выбраны все таргеты (всего их 5)
  if (targetingList.length === 5 && monitorType === "mlines") {
    targetingList = targetingList.map(({ percent, ...target }) => ({
      ...target,
      percent: percent * 0.8
    }));
  }

  const selectedFrequency = presentationFrequency.items.filter(
    item => item.selected
  );
  let frequency = selectedFrequency.length ? selectedFrequency[0].value : 8;
  if (monitorType === "monitors") {
    frequency = selectedFrequency.length ? selectedFrequency[0].value : 30;
  }
  const hours =
    presentationTime.items
      .filter(item => item.selected)
      .map(item => (item.to - item.from) / 1000 / 60 / 60)
      .reduce((a, b) => a + b, 0) || workingHoursPerDay;

  const budgetValue = parseInt(budget.value, 10);
  const targetPrice = targetingList.reduce((acc, item) => {
    return acc + numberFromPercent(item.percent, priceOneSecondForBudget);
  }, 0);

  //Расчет при выбранной дате окончания или фиксированном бюджете
  if (
    !presentationPeriod.endDateEditionDisabled ||
    (budget.fixed &&
      (presentationCount.fix ||
        [
          TOGGLE_DAYS_WEEK,
          TOGGLE_MLINE,
          TOGGLE_ROUTE,
          SELECT_PRESENTATION_TIME,
          TOGGLE_PRESENTATION_FREQUENCY,
          RESET_GROUPS,
          UPDATE_CLIP_DURATION,
          UPDATE_CLIP_DURATION_COMPLETED,
          FIXED_BUDGET
        ].indexOf(actionType) !== -1))
  ) {
    if (targetingList.length) {
      priceOneSecondForBudget += targetPrice;
    }

    const isPresentationCountAction =
      [UPDATE_PRESENTATION_COUNT, UPDATE_PRESENTATION_COUNT_COMPLETED].indexOf(
        actionType
      ) !== -1;

    const { value } = presentationCount;
    let newValueCount = Math.floor(
      budgetValue / (priceOneSecondForBudget * clipDuration.value)
    );

    if (!presentationPeriod.endDateEditionDisabled) {
      if (monitorType === "routes") {
        let newValueCountForTrams = 0;
        for (
          const date = presentationPeriod.from.clone();
          date.isSameOrBefore(presentationPeriod.to);
          date.add(1, "days")
        ) {
          newValueCountForTrams +=
            frequency *
            hours *
            ((isDayOff(date) ? selectedMonitorsDayOff : selectedMonitors) *
              0.95);
        }

        newValueCount = newValueCountForTrams;
      } else {
        newValueCount =
          (presentationPeriod.to.diff(presentationPeriod.from, "days") + 1) *
          (frequency * hours * (selectedMonitors * 0.95));
      }
    }

    newValueCount =
      parseInt(newValueCount / presentationCount.step, 10) *
      presentationCount.step;

    if (monitorType === "mlines") {
      newValueCount = presentationPeriod.plannerFitCount;
    }

    if (newValueCount > presentationCount.max) {
      newValueCount = presentationCount.max;
    }

    newValueCount = newValueCount < presentationCount.min ? 0 : newValueCount;

    presentationCount.fix =
      isPresentationCountAction ||
      (newValueCount > value && !presentationCount.fix)
        ? false
        : true;

    presentationCount.value = presentationCount.fix ? newValueCount : value;
    presentationCount.stopValue = newValueCount;
    presentationCount.disabledWidthLine = calcDisabledWidthLine({
      max: presentationCount.max,
      min: presentationCount.min,
      step: presentationCount.step,
      width:
        document.body.clientWidth < 1260
          ? 320
          : presentationCount.widthInputRange,
      stopValue:
        newValueCount < presentationCount.min
          ? presentationCount.min
          : newValueCount
    });
  }

  if (presentationPeriod.endDateEditionDisabled && !budget.fixed) {
    presentationCount.stopValue = 0;
    presentationCount.disabledWidthLine = 0;
  }

  let campaignDuration = Math.ceil(
    presentationCount.value / (frequency * hours * (selectedMonitors * 0.7))
  );

  if (monitorType === "routes") {
    //Расчет длительности кампании для трамваев производится раздельно для будней и выходных
    campaignDuration = campaignDurationTram(
      presentationCount.value,
      presentationPeriod.from,
      selectedMonitors,
      selectedMonitorsDayOff,
      frequency,
      hours
    );
  }

  const isIgnoreCoefficient =
    monitorType === "mlines" || !presentationPeriod.endDateEditionDisabled;

  presentationPeriod.to =
    (presentationPeriod.endDateEditionDisabled ||
      presentationPeriod.from > presentationPeriod.to) &&
    !isIgnoreCoefficient
      ? moment(presentationPeriod.from).add(
          !isNaN(parseInt(campaignDuration)) ? campaignDuration + 2 : 3,
          "days"
        )
      : presentationPeriod.to;

  if (isIgnoreCoefficient) {
    campaignDuration =
      presentationPeriod.to.diff(presentationPeriod.from, "days") + 1;
  }

  const halfHours = hours * 2;
  const passengers = parseInt(
    itemsByMonitorType.items.reduce((acc, item) => {
      if (!item.selected && !notHaveMonitors) {
        return acc;
      }

      if (monitorType === "routes" && item.disabled) {
        return acc;
      }

      const passengersOnLine =
        (item.passengerTraffic * halfHours * campaignDuration) /
        (countDaysMonth * halfHoursDay);
      return acc + passengersOnLine;
    }, 0),
    10
  );

  if (primeTime && hasNotPrimeTime) {
    countContactsFrom = presentationCount.value * 18.87;
    countContactsTo = presentationCount.value * 7.5;
  } else if (primeTime) {
    countContactsFrom = presentationCount.value * 23.67;
    countContactsTo = presentationCount.value * 9;
  } else {
    countContactsFrom = presentationCount.value * 14.07;
    countContactsTo = presentationCount.value * 6;
  }

  //Разбиваем период РК на месяцы, считаем кол-во дней в каждом
  const divideCampaignPeriod = ({ startDate, endDate }) => {
    const acc = [];
    const currentStartDay = moment(startDate).startOf("d");
    const currentEndDay = moment(endDate).startOf("d");

    while (currentStartDay.isSameOrBefore(currentEndDay)) {
      const start = moment(currentStartDay);
      const end = currentStartDay
        .add(1, "M")
        .date(0)
        .isAfter(currentEndDay)
        ? moment(currentEndDay)
        : moment(currentStartDay.add(1, "M").date(0));

      acc.push({
        days: moment(end).diff(moment(start), "days") + 1,
        startDate: moment(start)
          .startOf("d")
          .add(12, "hours")
          .format(),
        endDate: moment(end)
          .startOf("d")
          .add(12, "hours")
          .format(),
        month: parseInt(currentStartDay.format("M"), 10)
      });
      currentStartDay.add(1, "M").date(1);
    }

    return acc.length
      ? acc
      : [
          {
            days: 1,
            month: parseInt(currentStartDay.format("M"), 10),
            startDate: moment(startDate)
              .startOf("d")
              .add(12, "hours")
              .format(),
            endDate: moment(endDate)
              .startOf("d")
              .add(12, "hours")
              .format()
          }
        ];
  };

  //Для каждого месяца рассчитываем плановое кол-во показов
  const divideCampaign = ({ startDate, endDate, presentationCount }) => {
    const periods = divideCampaignPeriod({
      startDate,
      endDate
    });

    //Дней в РК всего
    const campaignDays = moment(endDate).diff(moment(startDate), "days") + 1;

    //OTS за день
    const otsOneDay = Math.ceil(countContactsFrom / campaignDays);

    //Расчет кол-ва показов по каждому месяцу
    let dividedPresentationCount = periods.map((period, i) => ({
      ...period,
      presentationCountMonth: Math.round(
        (presentationCount * period.days) / campaignDays
      ),
      otsPerPeriod:
        reportsOtsPerPeriod && reportsOtsPerPeriod.length
          ? Math.ceil(reportsOtsPerPeriod[i])
          : Math.ceil(period.days * otsOneDay * otsRatio[period.month])
      //   otsPerPeriod: Math.ceil(period.days * otsOneDay * otsRatio[period.month])
    }));

    //Если в периоде несколько месяцов, то для последнего считаем по формуле: ВсегоПоказов - Сумма(ПоказовВМесяцеЗаПредыдущиеМесяцы)
    if (dividedPresentationCount.length > 1) {
      const presCountWithoutLast = dividedPresentationCount.reduce(
        (acc, item, idx) => {
          if (dividedPresentationCount.length !== idx + 1) {
            acc += item.presentationCountMonth;
          }
          return acc;
        },
        0
      );
      dividedPresentationCount = dividedPresentationCount.map((period, i) => ({
        ...period,
        presentationCountMonth:
          i + 1 === dividedPresentationCount.length
            ? presentationCount - presCountWithoutLast
            : period.presentationCountMonth
      }));
    }

    return dividedPresentationCount;
  };

  //Разбиваем РК на месяцы
  const dividedCampaign = divideCampaign({
    startDate: presentationPeriod.from,
    endDate: presentationPeriod.to,
    presentationCount: presentationCount.value
  });

  const filteredSeasonalTableDiscount = seasonalTableDiscount.length
    ? seasonalTableDiscount.filter(discount => {
        return discount.advertisePlaces.includes(
          getAdvertisePlaceType(monitorType)
        );
      })
    : [];

  //Расчитываем стоимость за 1000 показов для каждого периода с учетом сезонника
  const priceOneSecondPerPeriod = dividedCampaign.map(period => {
    //Находим в таблице сезонный коэф. для каждого периода
    let seasonalDiscPerPeriod;
    let find = {};
    if (filteredSeasonalTableDiscount.length) {
      find = filteredSeasonalTableDiscount.find(item => {
        const discountStart = moment(item.startDate);
        const discountEnd = moment(item.endDate);
        const periodStart = moment(period.startDate).utc();
        const periodEnd = moment(period.endDate).utc();
        return (
          discountStart.isSameOrBefore(periodStart) &&
          discountEnd.isSameOrAfter(periodEnd)
        );
      });
      seasonalDiscPerPeriod = find
        ? find.periods.find(i => i.month === period.month)
        : { ratio: 1 };
    } else {
      seasonalDiscPerPeriod = { ratio: 1 };
    }

    const seasonalDiscPercent = getPercentFromCoefficient(
      seasonalDiscPerPeriod.ratio,
      true
    );

    return {
      ...period,
      $priceOneSecond:
        $priceOneSecond -
        numberFromPercent($priceOneSecond, seasonalDiscPercent),
      priceOneSecond:
        priceOneSecond - numberFromPercent(priceOneSecond, seasonalDiscPercent)
    };
  });

  //Расчитываем базовую стоимость РК за каждый период с учетом таргетингов
  const campaignCostPerMonth = priceOneSecondPerPeriod.map(period => {
    //Базовая стоимость за каждый период
    const basePricePerPeriod =
      period.$priceOneSecond *
      clipDuration.value *
      period.presentationCountMonth;

    //Применяем таргеты
    const $priceWithTargetingPerPeriod = calcTotalPrice({
      targetingList,
      basePrice: basePricePerPeriod,
      discount: 0
    });

    const targetPricePerPeriod = targetingList.reduce((acc, item) => {
      return acc + numberFromPercent(item.percent, period.priceOneSecond);
    }, 0);

    //Определяем скидку за объем для каждого периода
    const discountPerVolumePerPeriod = volumeDiscount.find(
      factor =>
        moment(period.startDate).isSameOrAfter(factor.startDate) &&
        moment(period.endDate).isSameOrBefore(factor.endDate)
    ) || { rules: [] };

    return {
      ...period,
      basePricePerPeriod,
      targetPricePerPeriod,
      $priceWithTargetingPerPeriod,
      discountPerVolumePerPeriod
    };
  });

  const getDiscountPercentCampaign = ({ volumeDiscount, price }) => {
    const needRule = volumeDiscount.find(item => {
      return price >= item.priceFrom && price <= item.priceTo;
    });

    if (needRule) {
      return needRule.discountSize;
    }

    return 0;
  };

  //Расчет стоимости РК за весь период без скидки
  const campaignCost = campaignCostPerMonth.reduce(
    (a, b) => a + b.$priceWithTargetingPerPeriod,
    0
  );

  //Условия применения логики при меняющейся скидки за объём в течение всего периода РК (при учёте отсутствия личной скидки)
  const changedDiscountPerVolume = () =>
    campaignCostPerMonth.length > 1 &&
    campaignCostPerMonth.some(
      period =>
        period.discountPerVolumePerPeriod._id !==
        campaignCostPerMonth[0].discountPerVolumePerPeriod._id
    );

  //Расчет стоимости РК за весь период с применения скидок
  const campaignPeriods = campaignCostPerMonth.map(period => {
    //Находим скидку, либо личную, если нет - за объем
    let discountPercentCampaign = getDiscountPercentCampaign({
      price: campaignCost,
      volumeDiscount: period.discountPerVolumePerPeriod.rules
    });

    if (changedDiscountPerVolume()) {
      //Для каждого периода определяется размер скидки за объем на основе стоимости периода без скидки.
      discountPercentCampaign = getDiscountPercentCampaign({
        // price: period.basePricePerPeriod, // при расчете скидки от стоимости периода
        price: campaignCost,
        volumeDiscount: period.discountPerVolumePerPeriod.rules
      });
    }

    //Итоговая цена каждого периода
    const priceWithTargetingPerPeriod = calcTotalPrice({
      targetingList,
      basePrice: period.basePricePerPeriod,
      discount: discountPercentCampaign
    });

    //Стоимость за 1000 показов
    const priceOneThousand =
      clipDuration.value *
      1e3 *
      (period.priceOneSecond + period.targetPricePerPeriod);

    return {
      ...period,
      priceWithTargetingPerPeriod: roundValue(priceWithTargetingPerPeriod),
      priceOneThousand: roundValue(priceOneThousand),
      discount: discountPercentCampaign
    };
  });

  //Стоимость РК финальная
  const priceWithTargeting = campaignPeriods.reduce(
    (acc, period) => acc + period.priceWithTargetingPerPeriod,
    0
  );

  //Расчет скидки общей по кампании
  const discountPercent = roundValue(
    getPercentFromCoefficient(priceWithTargeting / campaignCost, true)
  );

  //Базовая цена кампании
  const basePrice = campaignPeriods.reduce(
    (acc, period) => acc + period.basePricePerPeriod,
    0
  );

  //OTS кампании
  countContactsFrom = campaignPeriods.reduce(
    (acc, period) => acc + period.otsPerPeriod,
    0
  );

  //Кол-во контактов
  const priceOneContact = [
    priceWithTargeting / countContactsFrom,
    priceWithTargeting / countContactsTo
  ];

  if (!basePrice) {
    return {
      ...lastState,
      presentationCount: {
        ...lastState.presentationCount,
        value: lastState.presentationCount.min
      },
      budget: {
        ...lastState.budget,
        error: !presentationPeriod.endDateEditionDisabled
          ? ""
          : "Вам необходимо увеличить бюджет"
      },
      presentationPeriod: {
        ...presentationPeriod,
        error: !presentationPeriod.endDateEditionDisabled
          ? "Вам необходимо увеличить дату окончания"
          : ""
      }
    };
  }

  return {
    ...state,
    campaignPeriods: campaignPeriods,
    budget: {
      ...budget
    },
    presentationCount: {
      ...presentationCount
    },
    presentationTime: {
      ...presentationTime
    },
    mlines: {
      ...mlines
    },
    routes: {
      ...routes
    },
    monitors: {
      ...monitors
    },
    daysWeek: {
      ...daysWeek
    },
    presentationPeriod: {
      ...presentationPeriod
    },
    statistics: {
      countContacts: [
        countContactsFrom.toLocaleString("ru-RU"),
        countContactsTo.toLocaleString("ru-RU")
      ],
      selectedMonitors: [
        selectedMonitors.toLocaleString("ru-RU"),
        monitorType === "routes" &&
          selectedMonitorsDayOff.toLocaleString("ru-RU")
      ],
      campaignDuration: [
        campaignDuration,
        isIgnoreCoefficient ? 0 : campaignDuration + 3
      ],
      passengers: passengers.toLocaleString("ru-RU"),
      priceOneContact: priceOneContact.map(item => item.toLocaleString("ru-RU"))
    },
    totalPrice: {
      basePriceNumber: basePrice,
      basePrice: roundValue(basePrice).toLocaleString("ru-RU"),
      priceWithTargeting: Math.round(priceWithTargeting * 100) / 100,
      discount: discountPercent,
      seasonalDiscount: seasonalDiscountPercent,
      basePriceWithoutDiscounts: basePriceWithoutDiscounts
    },
    targetingList: targetingList.map(item => ({
      ...item,
      sum: roundValue(
        numberFromPercent(item.percent, basePrice)
      ).toLocaleString("ru-RU")
    }))
  };
};
