import React, { useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  StyledLoadBlock,
  StyledLoadButton,
  StyledPreview,
  StyledPreviewImg,
  StyledPreviewName,
  StyledPreviewClose,
  StyledPreviewText
} from "./styled";

import { SELECT_WORK_ORDER } from "store/constants/photoReports";
import { setSelectedFile } from "store/actions/photoReports";

import close from "img/close.svg";

const LoadBlock = ({
  multiple,
  sideId,
  previews,
  selectedFiles,
  isTableCard
}) => {
  const actionKey = useMemo(() => (isTableCard ? "table" : "stop"), [
    isTableCard
  ]);
  const dispatch = useDispatch();
  const onSetSelectedFile = useCallback(
    (files, typeKey) =>
      dispatch(
        setSelectedFile({
          files,
          id: sideId,
          key: actionKey,
          typeKey
        })
      ),
    [dispatch, sideId, actionKey]
  );

  const onSelectPreview = useCallback(() => {
    if (!selectedFiles.length) {
      onSetSelectedFile([], "previews");
      return;
    }

    const objectUrl = selectedFiles.map(file => ({
      preview: URL.createObjectURL(file),
      name: file.name
    }));

    onSetSelectedFile(objectUrl, "previews");
  }, [selectedFiles, onSetSelectedFile]);

  const onSelectFile = useCallback(
    e => {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }

      onSetSelectedFile([...e.target.files], "selectedFiles");
    },
    [onSetSelectedFile]
  );

  const lastActionType = useSelector(({ lastActionType }) => lastActionType);

  useEffect(() => {
    if ([SELECT_WORK_ORDER].indexOf(lastActionType) === -1) {
      onSelectPreview();
    }

    return () => selectedFiles.map(file => URL.revokeObjectURL(file));
  }, [selectedFiles, onSelectPreview, lastActionType]);

  return (
    <StyledLoadBlock>
      {previews.map(({ preview, name }, idx) => (
        <StyledPreview key={`preview${idx}`}>
          <StyledPreviewImg>
            <img src={preview} alt="preview" />
          </StyledPreviewImg>
          <StyledPreviewName>
            <StyledPreviewText>{name}</StyledPreviewText>
          </StyledPreviewName>
          <StyledPreviewClose>
            <img
              src={close}
              alt="close"
              onClick={() =>
                onSetSelectedFile(
                  selectedFiles.filter(i => i.name !== name),
                  "selectedFiles"
                )
              }
            />
          </StyledPreviewClose>
        </StyledPreview>
      ))}
      <StyledLoadButton>
        <input
          onChange={onSelectFile}
          type="file"
          accept=".jpg, .jpeg, .png"
          multiple={multiple}
        />
        фото
      </StyledLoadButton>
    </StyledLoadBlock>
  );
};

export default LoadBlock;
