import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Header from "../Header";
import ProfileCard from "../ProfileCard";
import { Container, HeadContainer, MainBlock } from "../Layout";

import { selectType } from "store/actions/photoReports";

import { StyledMenuButton, StyledBodyContainer } from "./styled";

const Menu = ({ history }) => {
  const dispatch = useDispatch();
  const { typesOfWork } = useSelector(({ photoReports }) => photoReports);

  const onSelectType = name => {
    dispatch(selectType({ name }));
    history.push("/action-route");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);
  return (
    <>
      <HeadContainer>
        <MainBlock>
          <Header history={history} logoWidth={80} margin="48px 0 42px 0" />
        </MainBlock>
      </HeadContainer>
      <StyledBodyContainer>
        <MainBlock>
          <Container>
            <ProfileCard />
          </Container>
          <Container>
            {typesOfWork.map((btn, idx) => (
              <StyledMenuButton
                key={`${idx}btn`}
                onClick={() => onSelectType(btn.text)}
              >
                {btn.text}
              </StyledMenuButton>
            ))}
          </Container>
        </MainBlock>
      </StyledBodyContainer>
    </>
  );
};

export default Menu;
