import styled from "styled-components";

import { MainButton } from "../Layout";

export const StyledForgotPassword = styled.div`
  font-size: 1rem;
  display: flex;
  color: #4a4a4a;
  justify-content: flex-end;
  margin-bottom: 140px;
  cursor: pointer;
  transition: all 300ms;

  &:hover {
    opacity: 0.7;
  }
`;

export const StyledEnterButton = styled(MainButton)`
  margin-bottom: 56px;
`;
