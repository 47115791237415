import {
  SUBMIT_LOGIN,
  SUBMIT_SIGN_IN,
  SUBMIT_RESTORE,
  SUBMIT_SIGN_IN_VALIDATION,
  SUBMIT_RESTORE_CONFIRM,
  SUBMIT_SIGN_IN_CONFIRM,
  RESET_SIGN_IN,
  RESET_LOGIN,
  RESET_RESTORE,
  CONFIRM_RESET,
  SUBMIT_APPLICATION,
  GET_PROFILE,
  LOGOUT,
  //new
  RESET_FAST_ORDER,
  POST_FAST_ORDER,
  RESET_MSB_ERRORS,
  SUBMIT_REQUEST,
  SUBMIT_SIGN_IN_CONFIRM_MSB,
  RESET_REQUEST_SUCCESS,
  SIMPLE_MSB_REGISTRATION,
  CHEACK_EMAIL_REGISTER,
  GET_ENV
} from "../constants/auth";

export const login = (isLocalStorage, form) => ({
  type: SUBMIT_LOGIN,
  form,
  isLocalStorage
});

export const logout = () => ({
  type: LOGOUT
});

export const resetLogin = form => ({
  type: RESET_LOGIN,
  form
});

export const signIn = form => ({
  type: SUBMIT_SIGN_IN,
  form
});

export const resetSignIn = form => ({
  type: RESET_SIGN_IN,
  form
});

export const signInValidation = (prop, form) => ({
  type: SUBMIT_SIGN_IN_VALIDATION,
  form,
  prop
});

export const signInConfirm = form => ({
  type: SUBMIT_SIGN_IN_CONFIRM,
  form
});

export const restore = form => ({
  type: SUBMIT_RESTORE,
  form
});

export const resetRestore = () => ({
  type: RESET_RESTORE
});

export const restoreConfirm = form => ({
  type: SUBMIT_RESTORE_CONFIRM,
  form
});

export const resetConfirm = form => ({
  type: CONFIRM_RESET,
  form
});

export const submitApplication = form => ({
  type: SUBMIT_APPLICATION.main,
  form
});

export const confirm = form => ({
  type: SUBMIT_APPLICATION.main,
  form
});

export const getProfile = () => ({
  type: GET_PROFILE.main
});

export const oAuthLogin = result => ({
  type: SUBMIT_LOGIN.success,
  result
});

export const resetFastOrder = () => ({
  type: RESET_FAST_ORDER
});

export const postFastOrder = form => ({
  type: POST_FAST_ORDER.main,
  form
});

export const resetMsbErrors = form => ({
  type: RESET_MSB_ERRORS
});

export const submitQuestion = form => ({
  type: SUBMIT_REQUEST,
  form
});

export const signInConfirmMSB = form => ({
  type: SUBMIT_SIGN_IN_CONFIRM_MSB.main,
  form
});

export const resetQuestionSuccess = () => ({
  type: RESET_REQUEST_SUCCESS
});

export const simpleMsbRegistration = form => ({
  type: SIMPLE_MSB_REGISTRATION.main,
  form
});

export const CheckEmailRegister = email => ({
  type: CHEACK_EMAIL_REGISTER.main,
  email
});

export const getEnv = () => ({
  type: GET_ENV.main
});
