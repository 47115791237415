import generateTypes from "./constantsGenerator";

export const GET_LIGHTBOXES = generateTypes("GET_LIGHTBOXES");

export const GET_LIGHTBOX = generateTypes("GET_LIGHTBOX");

export const GET_FORMATS = generateTypes("GET_FORMATS");

export const POST_LIGHTBOXES_SCHEDULE = generateTypes(
  "POST_LIGHTBOXES_SCHEDULE"
);

export const GET_SEASON_DISCOUNT = generateTypes("GET_SEASON_DISCOUNT");

export const GET_METRO_STATIONS = generateTypes("GET_METRO_STATIONS");

export const GET_METRO_LINES = generateTypes("GET_METRO_LINES");

export const GET_LIGHTBOX_REPORTS_IMAGES = generateTypes(
  "GET_LIGHTBOX_REPORTS_IMAGES"
);

export const SELECT_LINE = "SELECT_LINE";

export const GET_LIGHT_BOXES_MEDIAPLAN = generateTypes(
  "GET_LIGHT_BOXES_MEDIAPLAN"
);
