import { all } from "redux-saga/effects";
import auth from "./auth";
import packages from "./packages";
import metro from "./metro";
import lightboxes from "./lightboxes";
import news from "./news";
import offerArchive from "./offerArchive";
import questionAnswer from "./questionAnswer";
import register from "./register";
import beautyMonitors from "./beautyMonitors";
import abtest from "./abtest";
import multiCalculator from "./multiCalculator";
import feedback from "./feedback";
import groundTransportation from "./groundTransportation";

export default function* rootSaga() {
  yield all([
    feedback(),
    multiCalculator(),
    abtest(),
    auth(),
    packages(),
    metro(),
    lightboxes(),
    news(),
    offerArchive(),
    questionAnswer(),
    register(),
    beautyMonitors(),
    groundTransportation()
  ]);
}
