import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "../../components/Modal";
import Tabs from "../Tabs";
import SignInForm from "../SignIn";
import LoginForm from "../LoginForm";
import { connect } from "react-redux";
import qs from "qs";
import { createEvent } from "analytics";
//import { Redirect } from 'react-router-dom';

export class Authorization extends Component {
  static propTypes = {
    location: PropTypes.object,
    redirectTo: PropTypes.string,
    successLogin: PropTypes.bool,
    successRestore: PropTypes.bool
  };

  state = {
    redirectTo: ""
  };

  componentDidMount() {
    const { redirectTo, location } = this.props;

    if (redirectTo) {
      this.setState({ redirectTo });
    }

    if (location && location.search) {
      const query = qs.parse(location.search.substr(1));
      if (query && query.redirect) {
        this.setState({ redirectTo: query.redirect });
      }
    }
  }

  componentDidUpdate = prevProps => {
    const { redirectTo } = this.state;
    const { successLogin, successRestore } = this.props;

    if (
      (prevProps.successLogin !== successLogin && successLogin) ||
      (prevProps.successRestore !== successRestore && successRestore)
    ) {
      if (redirectTo) {
        window.location = `/app/${redirectTo}`;
      } else {
        window.location = "/app/calculator";
      }
    }
  };

  render() {
    const { registration } = qs.parse(window.location.search.slice(1));
    const isDisplayRegistration = registration === "true";

    const tabs = [
      {
        key: "login",
        url: "/login",
        label: "Вход",
        component: () => <LoginForm redirectTo={this.state.redirectTo} />
      },
      {
        key: "sign-in",
        url: "/sign-in",
        label: "Регистрация",
        onClick: () => {
          createEvent({
            action: "EnterButton.Registration",
            category: "Landing"
          });
        },
        component: () => <SignInForm />
      }
    ];
    return (
      <Modal>
        <Tabs
          tabs={
            isDisplayRegistration
              ? tabs
              : tabs.filter(tab => tab.key === "login")
          }
        />
      </Modal>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  successLogin: auth.successLogin,
  successRegistration: auth.successRegistration,
  successRestore: auth.successRestore
});

export default connect(mapStateToProps)(Authorization);
