import React from "react";
import AsyncSelect from "react-select/async";
import InputMask from "react-input-mask";

import { customStyles } from "./SelectStyles";

import { StyledLabel, StyledSelect } from "./styled";

const SelectField = ({ loadOptions, onChange, value }) => {
  const Input = props => (
    <InputMask
      type="text"
      mask="9-99-******"
      maskChar={null}
      style={{
        border: "unset",
        color: "#fff",
        width: "100%",
        background: "unset",
        zIndex: 1
      }}
      {...props}
    />
  );

  return (
    <StyledSelect>
      <StyledLabel>{"Инвентарный номер остановки"}</StyledLabel>
      <AsyncSelect
        styles={customStyles}
        components={{ Input }}
        isSearchable
        isClearable
        value={value}
        placeholder="Инвентарный номер остановки"
        loadingMessage={() => "Поиск..."}
        noOptionsMessage={() => "Информация отсутствует"}
        onChange={onChange}
        loadOptions={loadOptions}
        // menuIsOpen={!value}
      />
    </StyledSelect>
  );
};

export default SelectField;
