import { takeEvery, call, put } from 'redux-saga/effects';
import generateSagaWorker, { get } from './generateSaga';
import { GET_OFFER_ARCHIVE, GET_LAST_OFFER_ARCHIVE } from '../constants/offerArchive';

const sagaGenerator = generateSagaWorker(put, call);

const getOfferArchive = get(sagaGenerator, () => '/api/v1/public/offer/archive/supplier/45592f6e-1827-45c3-80b0-b22f7f026bc6', GET_OFFER_ARCHIVE.types);

const getLastOfferArchive = get(sagaGenerator, () => '/api/v1/public/offer/supplier/45592f6e-1827-45c3-80b0-b22f7f026bc6', GET_LAST_OFFER_ARCHIVE.types);

export default function* offerArchive() {
  yield takeEvery(GET_OFFER_ARCHIVE.main, getOfferArchive);
  yield takeEvery(GET_LAST_OFFER_ARCHIVE.main, getLastOfferArchive);
}
