import { takeEvery, call, put } from 'redux-saga/effects';
import generateSagaWorker, { get } from './generateSaga';
import { GET_FAQ } from '../constants/questionAnswer';

const sagaGenerator = generateSagaWorker(put, call);

const getQuestionAnswer = get(sagaGenerator, action => `/api/v1/public/question-answer/${action.id}`, GET_FAQ.types);

export default function* questionAnswer() {
  yield takeEvery(GET_FAQ.main, getQuestionAnswer);
}
