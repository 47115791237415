import { getPeriods } from "store/actions/multiCalculator";

export const getDateLineSections = ({ periods, id }) => {
  let position = 0;
  const sections = [];
  const result = [];

  periods.forEach((item, i) => {
    if (!item.selected) {
      return (position = position + 1);
    }

    sections[position]
      ? (sections[position].to = i)
      : (sections[position] = { from: i, to: i });
  });

  sections
    .filter(item => !!item)
    .forEach(item => {
      result.push({
        ...periods[item.from],
        endDate: periods[item.to].endDate,
        id
      });
    });

  return result;
};

export const renderSpace = (space, platform, option) => {
  let newSpace = { ...space };

  if (platform) {
    newSpace.advertisingSpace.advertisingPlatform = { ...platform };
  }

  let displayAvialableBlock = false;
  let selected = false;

  if (option) {
    if (option.displayAvialableBlock)
      displayAvialableBlock = option.displayAvialableBlock;
    if (option.selected) selected = option.selected;
    if (option.totalPrice !== undefined)
      newSpace.totalPrice = option.totalPrice;
  }

  newSpace.selected = selected;
  newSpace.selectedPeriods = [];
  newSpace.inventoryCard = false;
  newSpace.summaryPrice = 0;
  newSpace.availableBlock = displayAvialableBlock;
  newSpace.hide = space.availablePeriods.length <= 1;
  newSpace.availablePeriods = getPeriods(
    space.availablePeriods,
    Math.ceil(space.otsPerMonth)
  );
  newSpace.availableId = space.advertisingSpace.id;
  newSpace.otsPerMonth = Math.ceil(space.otsPerMonth);
  newSpace.advertisingSpace.advertisingPlatform.address = `Москва, ${newSpace.advertisingSpace.advertisingPlatform?.parent?.name}, м.${newSpace.advertisingSpace.advertisingPlatform.name}`;

  return newSpace;
};

export const removeSinglePeriods = nodes => {
  return nodes.map(node => {
    if (node.advertisingSpaces) {
      return {
        ...node,
        advertisingSpaces: node.advertisingSpaces.map(space => {
          return {
            ...space,
            availablePeriods: space.availablePeriods.map((period, i) => {
              if (!period.isAvailable) return period;
              else {
                const prevDisabled = !space.availablePeriods[i - 1]
                  ? true
                  : !space.availablePeriods[i - 1].isAvailable;
                const nextDisabled = !space.availablePeriods[i + 1]
                  ? true
                  : !space.availablePeriods[i + 1].isAvailable;
                if (prevDisabled && nextDisabled)
                  return { ...period, isAvailable: false };
                else return period;
              }
            })
          };
        })
      };
    }
    return node;
  });
};
