import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  showOfferArchive,
  getLastOfferArchive
} from "../../store/actions/offerArchive";
import FacebookLogo from "../../img/facebook.svg";
import InstagramLogo from "../../img/instagram.svg";
import { ContainerMain } from "../Container";
import {
  StyledFooter,
  StyledFooterLogoBlock,
  StyledFooterLogo,
  StyledFooterList,
  StyledFooterTitle,
  StyledSocialLogo
} from "./styled";
import moment from "moment";

class FooterComponent extends Component {
  static propTypes = {
    getLastOfferArchive: PropTypes.func,
    lastOffer: PropTypes.string,
    showOfferArchive: PropTypes.func
  };

  componentDidMount() {
    this.props.getLastOfferArchive();
  }

  render() {
    return (
      <ContainerMain>
        <StyledFooter>
          <StyledFooterLogoBlock>
            <Link to={"/"}>
              <StyledFooterLogo
                src={"/assets/log-foot.svg"}
                alt="nebo.digital"
                width="200px"
              />
            </Link>
            <div>
              Информационно- <br />
              рекламный сервис (ИРС)
            </div>
            <br />© {moment().format("YYYY")} nebo.digital <br />
            Все права защищены
          </StyledFooterLogoBlock>
          <StyledFooterList>
            <StyledFooterTitle>Юридическая информация:</StyledFooterTitle>
            <a
              href={`/api/v1/public/offer/${this.props.lastOffer}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Публичная оферта
            </a>
            <a href="/activities" target="_blank" rel="noopener noreferrer">
              Виды деятельности
            </a>
            <span onClick={this.props.showOfferArchive}>Архив оферт</span>
            <a
              href="/law_on_advertising.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Закон о рекламе
            </a>
            <a href="/guarantee.pdf" target="_blank" rel="noopener noreferrer">
              Подтверждение партнёрства
            </a>
            <a href="/requisites.pdf" target="_blank" rel="noopener noreferrer">
              Реквизиты организации
            </a>
            <a
              href="/privacy_policy.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Политика конфиденциальности
            </a>
            <a href="/use_coocie.pdf" target="_blank" rel="noopener noreferrer">
              Правила использования cookie
            </a>
          </StyledFooterList>
          <StyledFooterList>
            <StyledFooterTitle>Клиентам:</StyledFooterTitle>
            <Link to={"/login"}>Вход в личный кабинет</Link>
            <Link to={"/social"}>Социальная реклама</Link>
            <Link to={"/faq"}>Вопросы и ответы</Link>
            <a href={"/software"}>ПО под заказ</a>
            <Link to={"/news"}>Новости</Link>
          </StyledFooterList>
          <StyledFooterList>
            <StyledFooterTitle>Продажи:</StyledFooterTitle>
            <a href="mailto:sales@nebo.digital">sales@nebo.digital</a>
            <StyledFooterTitle>Пожелания к качеству:</StyledFooterTitle>
            <a href="mailto: сomplain@nebo.digital">сomplain@nebo.digital</a>
            <StyledFooterTitle>Поддержка:</StyledFooterTitle>
            <a href="mailto:support@nebo.digital">support@nebo.digital</a>
          </StyledFooterList>
          <StyledFooterList>
            <StyledFooterTitle>Контакты для СМИ:</StyledFooterTitle>
            <a href="mailto: pr@nebo.digital">pr@nebo.digital</a>
            {/*<>
              <StyledFooterTitle>Социальные сети:</StyledFooterTitle>
              <a
                href="https://www.facebook.com/nebodigital.ads/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledSocialLogo src={FacebookLogo} />
                nebodigital.ads
              </a>
              <a
                href="https://www.instagram.com/nebo.digital/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledSocialLogo src={InstagramLogo} />
                nebo.digital
              </a>
            </>*/}
          </StyledFooterList>
        </StyledFooter>
      </ContainerMain>
    );
  }
}

const mapStateToProps = ({ offerArchive }) => ({
  lastOffer: offerArchive.lastOffer
});

const mapDispatchToProps = {
  showOfferArchive,
  getLastOfferArchive
};

export const Footer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterComponent);
