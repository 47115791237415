import { takeEvery, put } from "redux-saga/effects";
import { FILL_BASKET } from "store/constants/multiCalculator";
import { getBasketInventories } from "store/graphql/query/multiCalculator";

import { renderSpace, removeSinglePeriods } from "utils/multiCalculator";
import { addBusyItem, removeItem, getBusyBasket } from "utils/basket";

function* fillBasket({ payload }) {
  const { ids, startDate, endDate, basket, busyBasket } = payload;

  const newBiInv = yield getBasketInventories(ids, startDate, endDate).then(
    ({ findInventoryByCoords }) => {
      const { nodes } = findInventoryByCoords;

      // приводим к нормальному виду
      let spaces = nodes.map(space => {
        return renderSpace(
          {
            ...space,
            advertisingSpace: {
              ...space.advertisingSpace,
              advertisingPlatform: {
                ...space.advertisingSpace.advertisingPlatform,
                ...space.advertisingSpace.metroExit,
                name: space.advertisingSpace.metroExit.station.name,
                parent: {
                  ...space.advertisingSpace.metroExit.station,
                  name: space.advertisingSpace.metroExit.station.lines[0].name
                }
              }
            }
          },
          null,
          {
            totalPrice: 0,
            displayAvialableBlock: true,
            selected: true
          }
        );
      });

      spaces = spaces.map(space => {
        // находим space в корзине
        const findSpace = basket.find(b => b.i === space.advertisingSpace.id);
        const findBusySpace = busyBasket.find(
          b => b.i === space.advertisingSpace.id
        );

        if (!findSpace && !findBusySpace) return space;

        let updatedSelectedPeriods = findSpace?.p;

        const willRemoveSinglePeriods = []; // периоды, которые возможно осталить без пары

        let availablePeriods = space.availablePeriods.map(period => {
          // ищем совпанения с корзиной
          const findPeriod = findSpace?.p.find(
            p =>
              p.f === period.startDateFormat ||
              p.t === period.startDateFormat ||
              p.f === period.endDateFormat ||
              p.t === period.endDateFormat
          );
          // а так же с занятыми периодами
          const findBusyPeriod = findBusySpace?.p.find(
            p =>
              p.f === period.startDateFormat ||
              p.t === period.startDateFormat ||
              p.f === period.endDateFormat ||
              p.t === period.endDateFormat
          );
          if (findPeriod) {
            if (period.isAvailable) {
              return { ...period, selected: true };
            } else {
              updatedSelectedPeriods = updatedSelectedPeriods.filter(
                p => p.f !== findPeriod.f && p.t !== findPeriod.t
              );
              willRemoveSinglePeriods.push(findPeriod);
              return { ...period, isAlredyUse: true };
            }
          }
          if (findBusyPeriod) {
            if (!period.isAvailable) return { ...period, isAlredyUse: true };
          }

          return period;
        });

        // чистим одиночные периоды
        availablePeriods = availablePeriods.map(period => {
          const findInRemove = willRemoveSinglePeriods.find(
            p =>
              p.f === period.startDateFormat ||
              p.t === period.startDateFormat ||
              p.f === period.endDateFormat ||
              p.t === period.endDateFormat
          );
          if (findInRemove) {
            addBusyItem(space.advertisingSpace.id, {
              from: findInRemove.f,
              to: findInRemove.t
            }); // добавляем в занятый
            removeItem(space.advertisingSpace.id, {
              from: findInRemove.f,
              to: findInRemove.t
            }); // и убираем из выбранных
            return { ...period, selected: false };
          }
          return period;
        });

        // добавляем isAlredyUse для периодов из basketBusy, если не isAvialable
        const _busyBasket = getBusyBasket();
        if (_busyBasket !== null) {
          availablePeriods = availablePeriods.map(period => {
            // if ( period. )
            const findFromInBaset = _busyBasket.find(b =>
              b.p.some(
                p =>
                  p.f === period.startDateFormat ||
                  p.t === period.startDateFormat
              )
            );
            const findToInBaset = _busyBasket.find(b =>
              b.p.some(
                p =>
                  p.t === period.endDateFormat || p.f === period.startDateFormat
              )
            );

            return (findFromInBaset || findToInBaset) && !period.isAvailable
              ? {
                  ...period,
                  isAlredyUse: true
                }
              : period;
          });
        }

        const filteredPeriods = availablePeriods.filter(p => p.selected);
        const allPeriodDays = filteredPeriods.reduce(
          (a, b) => a + b.periodDays,
          0
        );

        const totalPrice = filteredPeriods.reduce((acc, p) => {
          return (
            acc +
            (space.price / allPeriodDays) * p.periodDays * p.seasonalDiscount
          );
        }, 0);

        return {
          ...space,
          availablePeriods,
          totalPrice,
          summaryPrice: totalPrice,
          selectedPeriods: updatedSelectedPeriods
            ? updatedSelectedPeriods.map(p => ({
                from: p.f,
                to: p.t
              }))
            : []
        };
      });

      return spaces;
    }
  );

  yield put({
    type: FILL_BASKET.success,
    payload: {
      basketInventories: removeSinglePeriods(
        removeSinglePeriods([{ advertisingSpaces: newBiInv }])
      )[0].advertisingSpaces
    }
  });
}

export default function* multiCalculator() {
  yield takeEvery(FILL_BASKET.start, fillBasket);
}
