import React, { Component } from "react";
import {
  ModalInputGroup,
  ModalRow,
  ModalCol,
  ModalButton,
  ModalHr
} from "../../components/Modal/styled";
import PropTypes from "prop-types";
import cn from "classnames";
import { resetRestore, restore } from "../../store/actions/auth";
import { connect } from "react-redux";
import warning from "../../img/field_warning.svg";
import Modal from "../../components/Modal";
import { ErrorEma } from "../../components/Layout/form";

const emailRe = /.+@.+\..+/i;

class RestoreForm extends Component {
  static propTypes = {
    emailError: PropTypes.object,
    onClose: PropTypes.func,
    resetRestore: PropTypes.func,
    submitForm: PropTypes.func,
    successRestore: PropTypes.bool
  };

  state = {
    model: {
      email: ""
    },
    validation: {},
    type: "password"
  };

  componentWillUnmount() {
    this.props.resetRestore();
  }

  updater = (prop, key = "value", condition = x => x) => ({ target }) => {
    const value = condition(target[key]);
    this.setState(({ model }) => ({
      model: {
        ...model,
        [prop]: typeof value === "string" ? value.replace(/^\s+/, "") : value
      }
    }));
  };

  validateEmail = email => {
    this.setState(
      ({ validation }) => ({
        validation: { ...validation, email: !emailRe.test(email) }
      }),
      this.props.emailError && this.props.resetRestore
    );
    return email;
  };

  updateEmail = this.updater("email", "value", this.validateEmail);

  isInvalid = () =>
    this.props.emailError ||
    Object.keys(this.state.model).some(x => !this.state.model[x]) ||
    Object.keys(this.state.validation).some(x => this.state.validation[x]);

  submitForm = () => this.props.submitForm(this.state.model);

  getEmailTooltip = () =>
    this.props.emailError
      ? this.props.emailError.message
      : this.state.validation.email
      ? "Ошибка в адресе электронной почты, пожалуйста введите корректный адрес"
      : "Введите email";

  getErrorEmail = () =>
    this.props.emailError ? "Неверный адрес электронной почты" : null;

  render() {
    return (
      <Modal handleClose={this.props.onClose}>
        {this.props.successRestore ? (
          <ModalRow className="center">
            <ModalCol className="center">
              <p>
                Для завершения восстановления пароля <br />
                перейти по ссылке, отправленной на указанную <br />
                электронную почту
              </p>
            </ModalCol>
          </ModalRow>
        ) : (
          <div>
            <ModalRow>
              <ModalCol>
                <h1 className="inner-title">Восстановление пароля</h1>
                <ModalHr />
                <ModalInputGroup
                  className={cn({
                    error: this.props.emailError || this.state.validation.email
                  })}
                >
                  <label htmlFor="">E-mail</label>
                  <input
                    type="text"
                    placeholder="Введите E-mail"
                    onChange={this.updateEmail}
                    title={this.getEmailTooltip()}
                  />
                  <span className="input-warning">
                    <img
                      src={warning}
                      alt="warning"
                      title={this.getEmailTooltip()}
                    />
                  </span>
                  <ErrorEma
                    className="error-text"
                    style={{ margin: "0px 0px -15px", lineHeight: "2" }}
                  >
                    {this.getErrorEmail()}
                  </ErrorEma>
                </ModalInputGroup>
              </ModalCol>
            </ModalRow>
            <ModalRow className="center">
              <ModalButton
                type="button"
                onClick={this.submitForm}
                disabled={this.isInvalid()}
              >
                Далее
              </ModalButton>
            </ModalRow>
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  emailError: auth.emailValidationError,
  successRestore: auth.successRestore
});
const mapDispatchToProps = {
  submitForm: restore,
  resetRestore
};
export default connect(mapStateToProps, mapDispatchToProps)(RestoreForm);
