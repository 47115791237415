import createReducer from "./createReducer";
import { GET_COORDS } from "../constants/msbCalculator";

const initialState = {
  coords: null
};

const consumers = {
  [GET_COORDS.main]: state => ({
    ...state
  }),
  [GET_COORDS.success]: (state, action) => ({
    ...state
  })
};

export default createReducer(initialState, consumers);
