import { takeEvery, call, put } from "redux-saga/effects";
import { GET_NAME_BANNER, SET_NAME_BANNER } from "../constants/abtest";
import generateSagaWorker, { postJson } from "./generateSaga";

const sagaGenerator = generateSagaWorker(put, call);

const getNameBanner = postJson(
  sagaGenerator,
  () => "/api/v1/ab",
  GET_NAME_BANNER.types
);

function* successGetNameBanner({ result }) {
  const { data } = result;
  const now = new Date();
  if (new Date(data.startDate) <= now && now <= new Date(data.endDate)) {
    document.cookie = `bannerId=${
      data.abValue
    }; path=/; expires=Tue, ${new Date(data.endDate).toUTCString()}`;
    yield put({
      type: SET_NAME_BANNER.main,
      name: data.abValue
    });
  }
}

export default function* auth() {
  yield takeEvery(GET_NAME_BANNER.main, getNameBanner);
  yield takeEvery(GET_NAME_BANNER.success, successGetNameBanner);
}
