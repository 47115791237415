import { Component } from "react";

const emailRe = /^(\S+@\S+?\.\S{2,8})/i;

export default class Form extends Component {
  state = {
    /** should be implemented on inherits */
    validation: {},
    type: "password"
  };

  updater = (
    prop,
    key = "value",
    condition = x => x,
    afterUpdate = () => {}
  ) => ({ target }) => {
    const value = condition(target[key]);
    this.setState(
      ({ model }) => ({
        model: {
          ...model,
          [prop]: typeof value === "string" ? value.replace(/^\s+/, "") : value
        }
      }),
      afterUpdate
    );
  };

  updaterEntities = (
    prop,
    key = "value",
    condition = x => x,
    afterUpdate = () => {}
  ) => ({ target }) => {
    const value = condition(target[key]);
    this.setState(
      ({ model }) => ({
        model: {
          ...model,
          legalEntity: {
            ...model.legalEntity,
            [prop]:
              typeof value === "string" ? value.replace(/^\s+/, "") : value
          }
        }
      }),
      afterUpdate
    );
  };

  noModelUpdater = (
    prop,
    key = "value",
    condition = x => x,
    afterUpdate = () => {}
  ) => ({ target }) => {
    const value = condition(target[key]);
    this.setState(
      {
        [prop]: typeof value === "string" ? value.replace(/^\s+/, "") : value
      },
      afterUpdate
    );
  };

  validator = (prop = "", validate) => value => {
    this.setState(({ validation }) => ({
      validation: { ...validation, [prop]: validate(value) }
    }));
    return value;
  };

  validateTextareaValue = this.validator(
    "modalTextAreaValue",
    modalTextAreaValue => !modalTextAreaValue
  );

  validateName = this.validator("fullName", name => !name);

  validateProxyNumber = this.validator(
    "proxyNumber",
    proxyNumber => !proxyNumber
  );

  validateRegistrationSertificateNumber = this.validator(
    "registrationSertificateNumber",
    registrationSertificateNumber => !registrationSertificateNumber
  );

  validateSignerFullName = this.validator("signerFullName", name => !name);

  validateBankName = this.validator("bankName", bankName => !bankName);

  validatePassword = this.validator(
    "password",
    password => !password || password.length < 6
  );

  validatePhone = this.validator(
    "phone",
    phone => !phone || phone.indexOf("_") !== -1
  );

  validateEmail = this.validator("email", email => !emailRe.test(email));

  validateInn = this.validator(
    "inn",
    inn => !inn || (inn.length !== 10 && inn.length !== 12)
  );

  validateOGRN = this.validator(
    "ogrn",
    ogrn => !ogrn || (ogrn.length !== 13 && ogrn.length !== 15)
  );

  validateBik = this.validator("bik", bik => !bik);

  validateCorrespondentAccount = this.validator(
    "correspondingAccount",
    correspondingAccount => !correspondingAccount
  );

  validateCheckingAccount = this.validator(
    "checkingAccount",
    checkingAccount => !checkingAccount
  );

  validateKpp = this.validator("kpp", kpp => !kpp || kpp.length !== 9);

  validateCompanyNameSign = this.validator(
    "companyName",
    companyName => !companyName
  );

  validateCompanyName = this.validator("name", name => !name);

  validateAddress = this.validator("address", address => !address);

  updateName = this.updater("fullName", "value", this.validateName);

  updateProxyNumber = this.updaterEntities("proxyNumber", "value");

  updateRegistrationSertificateNumber = this.updaterEntities(
    "registrationSertificateNumber",
    "value"
  );

  updateSignerFullName = this.updaterEntities(
    "signerFullName",
    "value",
    this.validateSignerFullName
  );

  updateBankName = this.updaterEntities(
    "bankName",
    "value",
    this.validateBankName
  );

  updateSex = this.updater("isFemale", "value", radioValue =>
    JSON.parse(radioValue)
  );

  updatePassword = this.updater("password", "value", this.validatePassword);

  updateTel = this.updater("phone", "value", this.validatePhone);

  updateEmail = this.updater("email", "value", this.validateEmail);

  updateINN = this.updaterEntities("inn", "value", this.validateInn);

  updateOGRN = this.updaterEntities("ogrn", "value", this.validateOGRN);

  updateBik = this.updaterEntities("bik", "value", this.validateBik);

  updateCorrespondentAccount = this.updaterEntities(
    "correspondingAccount",
    "value",
    this.validateCorrespondentAccount
  );

  updateCheckingAccount = this.updaterEntities(
    "checkingAccount",
    "value",
    this.validateCheckingAccount
  );

  updateKPP = this.updaterEntities("kpp", "value", this.validateKpp);

  updateCompanyName = this.updater("name", "value", this.validateCompanyName);

  updateCompanyNameSign = this.updater(
    "companyName",
    "value",
    this.validateCompanyNameSign
  );

  updateAddress = this.updaterEntities(
    "address",
    "value",
    this.validateAddress
  );

  updateModalTextAreaValue = this.updater(
    "modalTextAreaValue",
    "value",
    this.validateTextareaValue
  );

  updateRegistration = (name, e) => {
    const value = e.target.value;
    if (value !== "") {
      this.setState(({ model }) => ({
        model: {
          ...model,
          legalEntity: {
            ...model.legalEntity,
            [name]:
              typeof value === "string" ? value.replace(/^\s+/, "") : value
          }
        }
      }));
    } else {
      const legalEntity = this.state.model.legalEntity;
      delete legalEntity[name];
      this.setState(({ model }) => ({
        model: {
          ...model,
          legalEntity: {
            ...legalEntity
          }
        }
      }));
    }

    this.validateBasisFields(this.state.basis);
  };

  passwordToggle = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "input" ? "password" : "input"
    });
  };

  isNumber = e => {
    const re = /(\d|arrowleft|arrowright|tab|delete|backspace|ctrl|alt|meta|f\d+)/i;

    if (!re.test(e.key)) {
      if (
        e.key !== "Control" &&
        e.key !== "Command" &&
        e.key !== "v" &&
        e.key !== "V" &&
        e.key !== "м" &&
        e.key !== "М"
      ) {
        e.preventDefault();
      }
    }
  };

  isNumberPayment = e => {
    const re = /(\d|arrowleft|arrowright|tab|delete|backspace|ctrl|alt|meta|f|command|Command|cmd\d+)/i;

    if (!e.metaKey && (!e.currentTarget.validity.valid || !re.test(e.key))) {
      e.preventDefault();
    }
  };

  getPasswordTooltip = () =>
    this.state.validation.password
      ? "Минимальная длина пароля 6 символов"
      : "Введите пароль";

  getPasswordShowToolTip = () =>
    this.state.type === "input" ? "Показать пароль" : "Спрятать пароль";

  validateBasisFields = basis => {
    const validationFields = [
      {
        name: "proxyNumber",
        code: "proxy"
      },
      {
        name: "proxyDate",
        code: "proxy"
      },
      {
        name: "registrationSertificateNumber",
        code: "registrationSertificate"
      }
    ];

    for (const field of validationFields) {
      if (basis && basis.code === field.code) {
        if (!this.state.model.legalEntity[field.name]) {
          this.setState(({ validation }) => ({
            validation: {
              ...validation,
              [field.name]: true
            }
          }));
        } else {
          this.setState(({ validation }) => ({
            validation: {
              ...validation,
              [field.name]: false
            }
          }));
        }
      } else {
        this.setState(({ validation }) => ({
          validation: {
            ...validation,
            [field.name]: false
          }
        }));
      }
    }
  };
}
