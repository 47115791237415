import {
  SHOW_OFFER_ARCHIVE,
  HIDE_OFFER_ARCHIVE,
  GET_OFFER_ARCHIVE,
  GET_LAST_OFFER_ARCHIVE
} from '../constants/offerArchive';

export const showOfferArchive = () => ({
  type: SHOW_OFFER_ARCHIVE
});

export const hideOfferArchive = () => ({
  type: HIDE_OFFER_ARCHIVE
});

export const getOfferArchive = () => ({
  type: GET_OFFER_ARCHIVE.main
});

export const getLastOfferArchive = () => ({
  type: GET_LAST_OFFER_ARCHIVE.main
});
