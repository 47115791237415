import tinycolor from "tinycolor2";
import icons from "./iconsColored";

export const colors = {
  primary: "#29aff8",
  blue: "#29aff8",
  dark: "#04143a",
  gray: "#9ca6c2",
  green: "#2ea885",
  violet: "#4b2387",
  white: "#ffffff",
  black: "#000000",
  yellow: "#ffd900",
  red: "#D0021B",
  orange: "#da6224",
  purple: "#4b2387",
  pink: "rgb(199, 18, 157)",
  sky: "#29aff8",
  darkBlue: "#2c4174",
  ocean: "#12B8FF"
};

export const headerHeight = 80;

export default {
  ...colors,
  headerHeight,
  transparentize: (color, opacity) =>
    tinycolor(color)
      .setAlpha(opacity)
      .toRgbString(),
  saturate: (color, amount) =>
    tinycolor(color)
      .saturate(amount)
      .toHexString(),
  shade: (color, percent) => {
    if (percent > 0) {
      return tinycolor(color)
        .lighten(percent)
        .toHexString();
    }

    return tinycolor(color)
      .darken(percent)
      .toHexString();
  },
  ...icons
};
