import styled from "styled-components";

export const StyledTabBoard = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 68px;
`;

export const StyledTab = styled.div`
  display: flex;
  transition: all 300ms;
  flex: 0 0 40%;
  font-size: 1.5rem;
  color: #808080;
  flex-direction: column;
  cursor: pointer;
  ${({ selected }) =>
    selected &&
    `
      color: #12b8ff;
    `}

  &:hover {
    opacity: 0.7;
  }
`;

export const StyledHr = styled.hr`
  width: 100%;
  transition: all 300ms;
  margin: 0;
  border: 0;
  background: #fff;
  border-radius: 8px;
  height: 4px;

  ${({ selected }) =>
    selected &&
    `
      background: #12b8ff;
    `}
`;
