import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import useDebouncedCallback from "hooks/useDebouncedCallback";

import { setIsMobile } from "../store/actions/app";

import { BREAKPOINTS } from "utils/const";

export default WrappedComponent => ({ ...props }) => {
  /**
   * HOC позволяет избежать фиксированной ширины в стилях body
   * предназначен для изолированных страниц, а так же прокинуть флаг isMobile
   *
   * используется для:
   *  /app/msb-authorization
   *  /app/msb-confirm
   *  /app/calculator/step-3
   */

  const dispatch = useDispatch();

  const handleResize = useDebouncedCallback(() => {
    dispatch(
      setIsMobile(document.documentElement.clientWidth <= BREAKPOINTS.default)
    );
  }, 300);

  useEffect(() => {
    window.document.body.style.minWidth = "100%";
    dispatch(
      setIsMobile(document.documentElement.clientWidth <= BREAKPOINTS.default)
    );
    window.addEventListener("resize", handleResize);
    return () => {
      window.document.body.style.removeProperty("min-width");
      window.removeEventListener("resize", handleResize);
      dispatch(setIsMobile(null));
    };
  }, [dispatch, handleResize]);

  return <WrappedComponent {...props} />;
};
