import React, { useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import Header from "../Header";
import ProfileCard from "../ProfileCard";
import CampaignInfo from "../CampaignInfo";
import SideBlock from "../SideBlock";
import PhotoBlock from "../PhotoBlock";
import LoadModal from "./LoadModal";
import InfoStop from "./InfoStop";
import SidesInfo from "../SidesInfo";
import { Container, HeadContainer, MainBlock } from "../Layout";

import {
  selectSide,
  importInstallationPhoto,
  changeSelectedDate,
  getWorkOrdersList,
  selectWorkOrder,
  resetPhState
} from "store/actions/photoReports";

import {
  StyledBodyContainer,
  StyledBackButton,
  StyledStopInfo,
  StyledBlock,
  StyledEnterButton
} from "./styled";

import arrow from "img/arrow.svg";

const ActionPage = ({ history }) => {
  const dispatch = useDispatch();
  const {
    sides,
    typesOfWork,
    files,
    selectedDate,
    selectedWorkOrder,
    installationPhotoLoad,
    installationPhotoLoading,
    user
  } = useSelector(({ photoReports }) => photoReports);
  const onSelectSide = id => dispatch(selectSide(id));
  const onChangeSelectedDate = date => dispatch(changeSelectedDate(date));
  const onSelectWorkOrder = data => dispatch(selectWorkOrder(data));

  const selectedType = useMemo(() => typesOfWork.find(type => type.selected), [
    typesOfWork
  ]);
  const selectedSide = useMemo(() => sides.find(side => side.selected), [
    sides
  ]);

  const getWorkOrdersData = async input => {
    if (input.length > 4) {
      const workOrdersListNew = await getWorkOrdersList({
        from: {
          startDate: selectedDate
            .clone()
            .subtract(3, "days")
            .format("YYYY-MM-DD"),
          endDate: selectedDate
            .clone()
            .add(3, "days")
            .format("YYYY-MM-DD"),
          platformNumber: input
        }
      });

      return workOrdersListNew;
    } else {
      return [];
    }
  };

  useEffect(() => {
    return () => dispatch(resetPhState());
  }, [dispatch]);

  const completeInstallation = () => {
    dispatch(
      importInstallationPhoto({
        form: {
          workOrderId: selectedWorkOrder.id,
          photoType: "transitSign",
          file: files[selectedSide.id],
          userId: user.id,
          factualInstallationDate: moment().format("YYYY-MM-DD"),
          status: "installed"
        }
      })
    );
  };

  return (
    <>
      <HeadContainer>
        <MainBlock>
          <Header history={history} logoWidth={80} margin="48px 0 42px 0" />
        </MainBlock>
      </HeadContainer>
      <StyledBodyContainer>
        <MainBlock>
          <Container>
            <ProfileCard />
            <StyledBackButton onClick={() => history.push("/menu")}>
              <img src={arrow} alt="arrow" />
              {selectedType.text}
            </StyledBackButton>
            <CampaignInfo
              selectedDate={selectedDate}
              onChangeSelectedDate={onChangeSelectedDate}
              getWorkOrdersList={getWorkOrdersData}
              onSelectWorkOrder={onSelectWorkOrder}
              selectedWorkOrder={selectedWorkOrder}
            />
            {selectedWorkOrder && (
              <>
                <SidesInfo selectedWorkOrder={selectedWorkOrder} />
                <StyledStopInfo>
                  <InfoStop selectedWorkOrder={selectedWorkOrder} />
                </StyledStopInfo>
                <StyledBlock>
                  <SideBlock
                    selectedWorkOrder={selectedWorkOrder}
                    sides={sides}
                    selectSide={onSelectSide}
                  />
                  <PhotoBlock sides={sides} files={files} />
                </StyledBlock>
              </>
            )}
            <StyledEnterButton
              disabled={installationPhotoLoading}
              onClick={() =>
                !installationPhotoLoading && completeInstallation()
              }
            >
              {selectedType.buttonText}
            </StyledEnterButton>
            {installationPhotoLoad && (
              <LoadModal openModal={installationPhotoLoad} history={history} />
            )}
          </Container>
        </MainBlock>
      </StyledBodyContainer>
    </>
  );
};

export default ActionPage;
