import React from 'react';
import './legacy.css';
import logo from '../../img/logo.svg';

const browsers = [
  {
    name: 'Google Chrome',
    url: 'https://www.google.com/intl/ru_ALL/chrome/',
    icon: 'https://www.google.com/chrome/static/images/chrome-logo.svg'
  },
  {
    name: 'Mozilla Firefox',
    url: 'https://www.mozilla.org/ru/firefox/',
    icon: 'https://www.mozilla.org/media/img/logos/firefox/logo-quantum.9c5e96634f92.png'
  },
  {
    name: 'Opera',
    url: 'https://www.opera.com/ru',
    icon: 'https://www-static.operacdn.com/extension/opera/design/opera/static/images/opera_logo.svg'
  },
  {
    name: 'Safari',
    url: 'https://www.apple.com/safari/',
    os: 'apple',
    icon: 'https://www.apple.com/v/safari/i/images/overview/safari_icon_large.png'
  },
  {
    name: 'Microsoft Edge',
    url: 'https://www.microsoft.com/ru-ru/windows/microsoft-edge',
    os: 'windows',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d6/Microsoft_Edge_logo.svg'
  }
];

const userOs =
  navigator.userAgent.indexOf('Win') !== -1
    ? 'windows'
    : navigator.userAgent.indexOf('Mac') !== -1
    ? 'apple'
    : navigator.userAgent.indexOf('iOS') !== -1
    ? 'apple'
    : null;

const UnsopportedBrowser = () => (
  <div className="legacy-wrap">
    <div className="legacy-header">
      <a href="/">
        <img className="legacy-header__logo" src={logo} alt="NEBO.digital" />
      </a>
      <div className="legacy-header__description">
        Официальный оператор <br />
        рекламы в метрополитене
      </div>
    </div>
    <div className="legacy-content">
      <div className="legacy-content__header">
        В настоящий момент ваша версия <br />
        браузера не поддерживается
      </div>
      <div className="legacy-content__description">Для корректной работы вы можете открыть сайт в браузерах:</div>
      <div className="legacy-content__browsers">
        {browsers.map(function(browser) {
          if (!browser.os || (browser.os && browser.os === userOs)) {
            return (
              <a className="legacy-content__browser" href={browser.url} target="_blank" rel="noopener noreferrer">
                <img src={browser.icon} alt={browser.name} />
                {browser.name}
              </a>
            );
          }

          return null;
        })}
      </div>
    </div>
  </div>
);

export default UnsopportedBrowser;
