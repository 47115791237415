import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import warning from "../../img/field_warning.svg";
import password_show from "../../img/see_password.svg";
import password_hide from "../../img/hide_password.svg";
import {
  ModalInputGroup,
  MainFormGroup,
  ModalRow,
  ModalCol,
  StyledLinkPointer,
  ModalButton,
  ModalHr,
  ModalInputGroupWrapper
} from "../../components/Modal/styled";
import { ErrorInput } from "../../components/Layout/form";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { login, resetLogin } from "../../store/actions/auth";
import PropTypes from "prop-types";
import cn from "classnames";
import { GOOGLE_RECAPTCHA_TOKEN } from "../../utils/variables";
import { createEvent } from "analytics";

const emailRe = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

class LoginForm extends Component {
  static propTypes = {
    emailError: PropTypes.object,
    handleClose: PropTypes.func,
    redirectTo: PropTypes.string,
    resetLogin: PropTypes.func,
    submitForm: PropTypes.func,
    successLogin: PropTypes.bool,
    toggleRestoreModal: PropTypes.func
  };
  state = {
    model: {
      password: "",
      email: "",
      isLocalStorage: false
    },
    captchaVerified: true,
    validation: {},
    type: "password",
    clickPass: false,
    restoreModal: false
  };

  componentWillUnmount() {
    this.props.resetLogin();
  }

  updater = (prop, key = "value", condition = x => x) => ({ target }) => {
    const value = condition(target[key]);
    this.setState(
      ({ model }) => ({
        model: {
          ...model,
          [prop]: typeof value === "string" ? value.replace(/^\s+/, "") : value
        }
      }),
      this.props.emailError && this.props.resetLogin
    );
  };
  validatePassword = password => {
    this.setState(({ validation }) => ({
      validation: { ...validation, password: !password || password.length < 6 }
    }));
    return password;
  };

  validateEmail = email => {
    this.setState(({ validation }) => ({
      validation: { ...validation, email: !emailRe.test(email) },
      clickPass: true
    }));
    return email;
  };
  updatePassword = this.updater("password", "value", this.validatePassword);
  updateEmail = this.updater("email", "value");
  updateEmailWithValidate = this.updater("email", "value", this.validateEmail);
  isInvalid = () =>
    this.props.emailError ||
    Object.keys(this.state.model)
      .filter(x => x !== "isLocalStorage")
      .some(x => !this.state.model[x]) ||
    Object.keys(this.state.validation).some(x => this.state.validation[x]);
  submitForm = () => {
    createEvent({
      action: "EnterButton.Submit",
      category: "Landing"
    });
    this.props.submitForm(this.state.model.isLocalStorage, {
      email: this.state.model.email,
      password: this.state.model.password,
      gRecaptchaResponse:
        window.location.hostname === "nebo.digital"
          ? this.state.captchaVerified
          : ""
    });
  };
  updateStorageType = this.updater("isLocalStorage", "checked");
  passwordToggle = () =>
    this.setState({
      type: this.state.type === "input" ? "password" : "input"
    });

  getEmailTooltip = () =>
    this.props.emailError
      ? this.props.emailError.message
      : this.state.validation.email
      ? "Ошибка в адресе электронной почты, пожалуйста введите корректный адрес"
      : null;

  getPasswordTooltip = () =>
    this.state.validation.password
      ? "Минимальная длина пароля 6 символов"
      : null;

  getPasswordShowToolTip = () =>
    this.state.type === "input" ? "Показать пароль" : "Спрятать пароль";

  onPasswordReset = () => {
    this.props.toggleRestoreModal();
  };

  captchaVerifyCallback = token => {
    if (token) {
      this.setState({
        captchaVerified: token
      });
    } else {
      this.setState({
        captchaVerified: false
      });
    }
  };

  render() {
    if (this.props.successLogin) {
      if (this.props.redirectTo) {
        return <Redirect to={`/app/${this.props.redirectTo}`} />;
      }
      return <Redirect to={"/app/calculator"} />;
    }

    return (
      <div>
        <ModalInputGroup
          className={cn({
            error: this.props.emailError || this.state.validation.email
          })}
        >
          <ModalInputGroupWrapper>
            <label htmlFor="">Email</label>
            <input
              type="text"
              placeholder="Введите Email…"
              onChange={
                this.state.clickPass
                  ? this.updateEmailWithValidate
                  : this.updateEmail
              }
              autoFocus
            />
            <span className="input-warning">
              <img src={warning} alt="warning" />
            </span>
          </ModalInputGroupWrapper>
          <ErrorInput className="error-text">
            {this.getEmailTooltip()}
          </ErrorInput>
        </ModalInputGroup>
        <ModalInputGroup
          className={cn("password", {
            error: this.props.emailError || this.state.validation.password
          })}
        >
          <ModalInputGroupWrapper>
            <label htmlFor="">Пароль</label>
            <input
              type={this.state.type}
              placeholder="Введите Пароль…"
              onChange={this.updatePassword}
              onFocus={() => this.validateEmail(this.state.model.email)}
              onBlur={() => this.validatePassword(this.state.model.password)}
            />
            <span>
              <img
                onClick={this.passwordToggle}
                src={
                  this.state.type === "input" ? password_hide : password_show
                }
                alt="password"
                title={this.getPasswordShowToolTip()}
              />
            </span>
          </ModalInputGroupWrapper>
          <ErrorInput className="error-text">
            {this.getPasswordTooltip()}
          </ErrorInput>
        </ModalInputGroup>
        {/*{window.location.hostname === "nebo.digital" && (*/}
        {/*  <ModalInputGroup*/}
        {/*    className={cn("password", {*/}
        {/*      error: this.props.emailError || this.state.validation.password*/}
        {/*    })}*/}
        {/*  >*/}
        {/*    <ModalInputGroupWrapper>*/}
        {/*      <ReCAPTCHA*/}
        {/*        size={*/}
        {/*          window.matchMedia("(max-width: 1024px)").matches*/}
        {/*            ? "compact"*/}
        {/*            : "normal"*/}
        {/*        }*/}
        {/*        sitekey={GOOGLE_RECAPTCHA_TOKEN}*/}
        {/*        onChange={this.captchaVerifyCallback}*/}
        {/*      />*/}
        {/*    </ModalInputGroupWrapper>*/}
        {/*  </ModalInputGroup>*/}
        {/*)}*/}
        <ModalRow>
          <ModalCol>
            <MainFormGroup className="checkbox">
              Запомнить меня
              <input type="checkbox" onChange={this.updateStorageType} />
              <span />
            </MainFormGroup>
          </ModalCol>
          <ModalCol className="right">
            <StyledLinkPointer onClick={this.onPasswordReset}>
              Забыли пароль?
            </StyledLinkPointer>
          </ModalCol>
        </ModalRow>
        <ModalHr />
        <ModalRow className="center">
          <ModalButton
            onClick={this.submitForm}
            type="button"
            disabled={this.isInvalid()}
          >
            Вход
          </ModalButton>
        </ModalRow>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  emailError: auth.emailValidationError,
  successLogin: auth.successLogin
});
const mapDispatchToProps = {
  submitForm: login,
  resetLogin
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
