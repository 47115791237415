import React from "react";

import info from "img/info.svg";

const InfoStop = ({ selectedWorkOrder }) => {
  return (
    <>
      <img src={info} alt="info" />
      <span>
        {selectedWorkOrder.advertisingSpace.advertisingPlatform.displayName}
      </span>
    </>
  );
};

export default InfoStop;
