import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Footer } from "../Footer";
import NoticeComponent from "../Notice";

import { checkAdBlock } from "utils/blockerDetector";
import "css/index.css";
import { getEnv } from "store/actions/auth";

import { StyledBorder } from "./styled";

export class Page extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element)
    ])
  };

  state = {
    isUsingAdblock: false
  };

  componentDidMount = () => {
    this.props.getEnv();
    checkAdBlock.then(isUsingAdblock => {
      this.setState({
        isUsingAdblock
      });
    });
  };

  render() {
    return (
      <div className="page">
        {this.state.isUsingAdblock && !this.props.disableFooter && (
          <NoticeComponent name="adblock" type="warning">
            <div>
              Уважаемый клиент, вы используете программное обеспечение, которое
              может привести к некорректной работе нашего сайта.{" "}
              <b>Отключите приложения</b>, блокирующие рекламу на сайтах.
            </div>
          </NoticeComponent>
        )}
        {this.props.children}
        <StyledBorder />
        {!this.props.isMobile && !this.props.disableFooter && <Footer />}
      </div>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  isMobile: app.isMobile,
  disableFooter: app.disableFooter
});

const mapDispatchToProps = {
  getEnv
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
