import React, { useState } from "react";

import { StyledTabBoard, StyledTab, StyledHr } from "./styled";

const tabs = [
  {
    id: 1,
    name: "Вход"
  }
  // {
  //   id: 2,
  //   name: "Регистрация"
  // }
];

const Tabs = () => {
  const [selectedTab, setTab] = useState(1);

  return (
    <StyledTabBoard>
      {tabs.map(({ name, id }) => (
        <StyledTab
          key={`tab${id}`}
          onClick={() => setTab(id)}
          selected={id === selectedTab}
        >
          {name}
          <StyledHr selected={id === selectedTab} />
        </StyledTab>
      ))}
    </StyledTabBoard>
  );
};

export default Tabs;
