import generateTypes from "./constantsGenerator";

export const GET_INVENTORY = generateTypes("GET_INVENTORY");
export const SET_ADDRESS = generateTypes("SET_ADDRESS");
export const GET_TYPOGRAPHY_LIST = generateTypes("GET_TYPOGRAPHY_LIST");

export const SELECT_INVENTORY = "SELECT_INVENTORY";
export const CHANGE_PAGINATION = "CHANGE_PAGINATION";
export const SELECT_PERIOD = "SELECT_PERIOD";
export const TOGGLE_AVAILABLE = "TOGGLE_AVAILABLE";
export const RESET_MULTI_STATE = "RESET_MULTI_STATE";
export const TOGGLE_POPUP = "TOGGLE_POPUP";
export const SET_DISTANCE = "SET_DISTANCE";
export const SET_OTS = "SET_OTS";
export const SET_DATE = "SET_DATE";
export const SET_BUGET_RANGE = "SET_BUGET_RANGE";
export const SET_SORT = "SET_SORT";
export const SET_INVENTORY = "SET_INVENTORY";
export const SET_BASKET = "SET_BASKET";
export const SELECT_TYPOGRAPHY = "SELECT_TYPOGRAPHY";
export const SET_MAP_OPEN = "SET_MAP_OPEN";
export const SET_ACTIVE_STATION = "SET_ACTIVE_STATION";
export const SET_ACTIVE_EXIT = "SET_ACTIVE_EXIT";
export const CHANGE_SIZE = "CHANGE_SIZE";
export const SET_SELECTED_STATION = "SET_SELECTED_STATION";
export const RESET_MAP_INVENTORY = "RESET_MAP_INVENTORY";

export const GET_LIGHTBOXES_MEDIAPLAN = generateTypes(
  "GET_LIGHTBOXES_MEDIAPLAN"
);

export const SHOW_MORE_STATION_ITEMS = generateTypes("SHOW_MORE_STATION_ITEMS");

export const TOGGLE_DISPLAY_STATIONS = generateTypes("TOGGLE_DISPLAY_STATIONS");
export const FILL_BASKET = generateTypes("FILL_BASKET");
export const REMOVE_ALREDY_USE_PERIODS = generateTypes(
  "REMOVE_ALREDY_USE_PERIODS"
);
export const GET_MAP_STATIONS = generateTypes("GET_MAP_STATIONS");

export const GET_FORMATS = generateTypes("GET_FORMATS");

export const RECOVER_FILTER = generateTypes("RECOVER_FILTER");

export const SET_MOBILE_FILTER = "SET_MOBILE_FILTER";

export const GET_METRO_LINE_WITH_STATION = generateTypes(
  "GET_METRO_LINE_WITH_STATION"
);

export const CHANGE_SELECT_METRO_LINE = "CHANGE_SELECT_METRO_LINE";
export const CHANGE_SELECT_METRO_STATION = "CHANGE_SELECT_METRO_STATION";
export const RESET_SELECT_METRO_LINE = "RESET_SELECT_METRO_LINE";
export const RESET_SELECT_METRO_STATION = "RESET_SELECT_METRO_STATION";
export const TOGGLE_ALL_METRO_LINE = "TOGGLE_ALL_METRO_LINE";
export const TOGGLE_ALL_METRO_STATION = "TOGGLE_ALL_METRO_STATION";
export const SUBMIT_METRO_FILTER = generateTypes("SUBMIT_METRO_FILTER");
export const RECOVER_METRO_FILTER = "RECOVER_METRO_FILTER";
