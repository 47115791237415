export default {
  email: {
    'already in use': 'Пользователь с таким адресом электронной почты уже зарегистрирован. Попробуйте восстановить пароль.',
    'wrong format': 'Ошибка в адресе электронной почты, пожалуйста введите корректный адрес'
  },
  inn: {
    'already in use': 'Этот ИНН уже используется',
    'wrong format': 'Ошибка в поле ИНН, пожалуйста введите корректный ИНН'
  },
  restore: {
    'advertiser not found': 'Пользователь с таким адресом электронной почты не зарегистрирован в системе',
    'confirmation failed': 'Не удалось сменить пароль, ссылка для восстановления не активна',
  },
  gRecaptchaResponse: {
    'invalid': 'Ваше действие выглядит подозрительным, подтвердите что вы не робот'
  },
  'wrong password': 'Неверный логин/пароль',
  'advertiser not found': 'Неверный логин/пароль',
  'advertiser is not active': 'Данная учетная запись заблокирована',
  'advertiser is not confirmed': 'Данная учетная запись ещё не активирована',
}
