import styled from 'styled-components';

export const Notice = styled.div`
  position: relative;
  padding: 1rem;
  text-align: center;
  font-weight: 600;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
  z-index: 1;

  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  p {
    margin: 0 auto;
  }

  &.warning {
    background: #ff992c;
    color: #fff;
  }

  &.success {
    background: #51b98e;
    color: #fff;
  }

  &.error {
    background: #d24343;
    color: #fff;
  }
`;
