import { combineReducers } from "redux";
import auth from "./auth";
import lightboxes from "./lightboxes";
import packages from "./packages";
import metro from "./metro";
import news from "./news";
import offerArchive from "./offerArchive";
import questionAnswer from "./questionAnswer";
import { monitorsCalculator } from "./monitorsCalculator";
import register from "./register";
import beautyMonitors from "./beautyMonitors";
import { photoReports } from "./photoReports";
import { multiCalculator } from "./multiCalculator";
import msbCalculator from "./msbCalculator";
import abtest from "./abtest";
import app from "./app";
import feedback from "./feedback";
import { cityFormat } from "./cityFormat";
import { scheme } from "./scheme";

export default combineReducers({
  scheme,
  feedback,
  abtest,
  auth,
  lightboxes,
  packages,
  metro,
  news,
  offerArchive,
  questionAnswer,
  monitorsCalculator,
  register,
  beautyMonitors,
  photoReports,
  multiCalculator,
  msbCalculator,
  app,
  cityFormat,
  lastActionType: (state, action) => action.type
});
