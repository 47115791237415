import createReducer from './createReducer';

import { GET_NEWS_LIST, GET_NEWS } from '../constants/news';

const initialState = {
  news: {},
  newsList: [],
  newsLoading: false
};

const consumers = {
  [GET_NEWS_LIST.main]: state => ({
    ...state,
    newsLoading: true
  }),
  [GET_NEWS_LIST.success]: (state, action) => ({
    ...state,
    newsList: action.result.data.news || [],
    pagination: action.result.metadata || null,
    newsLoading: false
  }),
  [GET_NEWS.main]: (state, action) => ({
    ...state,
    news: action.form.news || {},
    newsLoading: true
  }),
  [GET_NEWS.success]: (state, action) => ({
    ...state,
    news: action.result.data.news || {},
    newsLoading: false
  })
};

export default createReducer(initialState, consumers);
