const generateTypes = main => {
  const start = `${main}_START`;
  const success = `${main}_SUCCESS`;
  const error = `${main}_ERROR`;
  const end = `${main}_END`;
  return {
    /** for watcher saga */
    main,
    /** for worker saga */
    types: [start, success, error, end],
    /** for reducers */
    start,
    success,
    error,
    end
  };
};

export default generateTypes;
