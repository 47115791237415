import styled from "styled-components";

import { MenuButton } from "../../Layout";

export const ModalHeader = styled.div`
  text-align: center;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.96);
  margin-bottom: 32px;
`;

export const ModalTitle = styled.div`
  text-align: center;
  font-size: 0.875rem;
  color: #4a4a4a;
  margin-bottom: 54px;
`;

export const StyledButtonBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 50px;
`;

export const StyledMenuButton = styled(MenuButton)`
  background: #ffd900;
  color: #000;
  font-size: 1.125rem;
  margin-bottom: 20px;
`;
