import createReducer from "./createReducer";
import {
  GET_LANDING_DOCS,
  POST_COMMENT,
  RESET_COMMENT,
  POST_FILE
} from "../constants/beautyMonitors";

const initialState = {
  landingDocs: [],
  commentLoad: false,
  commentLoaded: false,
  fileId: "",
  uploadLoading: false,
  uploadError: [],
  uploadCompleted: false
};

const consumers = {
  [GET_LANDING_DOCS.start]: state => ({
    ...state
  }),
  [GET_LANDING_DOCS.success]: (state, action) => ({
    ...state,
    landingDocs: action.result.data.landingDocs.reverse()
  }),
  [GET_LANDING_DOCS.error]: state => ({
    ...state
  }),
  [POST_COMMENT.main]: state => ({
    ...state,
    commentLoad: true,
    commentLoaded: false
  }),
  [POST_COMMENT.success]: state => ({
    ...state,
    commentLoad: false,
    commentLoaded: true
  }),
  [POST_COMMENT.error]: state => ({
    ...state,
    commentLoad: false,
    commentLoaded: false
  }),
  [RESET_COMMENT]: state => ({
    ...state,
    commentLoad: false,
    commentLoaded: false
  }),
  [POST_FILE.start]: state => {
    return {
      ...state,
      fileId: "",
      uploadLoading: true,
      uploadCompleted: false,
      uploadError: []
    };
  },
  [POST_FILE.success]: (state, action) => {
    return {
      ...state,
      fileId: action.result.data.tempFileId,
      uploadCompleted: true,
      uploadLoading: false,
      uploadError: []
    };
  },
  [POST_FILE.error]: (state, action) => {
    return {
      ...state,
      fileId: "",
      uploadLoading: false,
      uploadCompleted: false,
      uploadError: (action.error &&
        action.error.result.errors &&
        action.error.result.errors.map(e => ({
          type: e.field,
          message: e.message
        }))) || [
        {
          message: "Ошибка загрузки"
        }
      ]
    };
  }
};

export default createReducer(initialState, consumers);
