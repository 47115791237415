import React from "react";

import Modal from "../../Modal";

import { ModalHeader, StyledMenuButton, StyledButtonBlock } from "./styled";

const LoadModal = ({ openModal, setOpenModal, history }) => {
  return (
    <Modal openModal={openModal} setOpenModal={() => history.push("/menu")}>
      <ModalHeader>{`Постеры успешно смонтированы`}</ModalHeader>
      <StyledButtonBlock>
        <StyledMenuButton onClick={() => history.push("/menu")}>
          Перейти в меню
        </StyledMenuButton>
      </StyledButtonBlock>
    </Modal>
  );
};

export default LoadModal;
