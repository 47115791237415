import { takeEvery, call, put } from "redux-saga/effects";
import {
  SUBMIT_SIGN_IN_START,
  SUBMIT_SIGN_IN_SUCCESS,
  SUBMIT_SIGN_IN_ERROR,
  SUBMIT_SIGN_IN,
  SUBMIT_SIGN_IN_VALIDATION_START,
  SUBMIT_SIGN_IN_VALIDATION_SUCCESS,
  SUBMIT_SIGN_IN_VALIDATION_ERROR,
  SUBMIT_SIGN_IN_VALIDATION,
  SUBMIT_LOGIN,
  SUBMIT_RESTORE,
  SUBMIT_SIGN_IN_CONFIRM,
  SUBMIT_RESTORE_CONFIRM,
  SUBMIT_SIGN_IN_CONFIRM_START,
  SUBMIT_SIGN_IN_CONFIRM_ERROR,
  SUBMIT_SIGN_IN_CONFIRM_SUCCESS,
  SUBMIT_LOGIN_START,
  SUBMIT_LOGIN_SUCCESS,
  SUBMIT_LOGIN_ERROR,
  SUBMIT_RESTORE_START,
  SUBMIT_RESTORE_SUCCESS,
  SUBMIT_RESTORE_ERROR,
  SUBMIT_RESTORE_CONFIRM_START,
  SUBMIT_RESTORE_CONFIRM_SUCCESS,
  SUBMIT_APPLICATION,
  GET_PROFILE,
  SIMPLE_MSB_REGISTRATION,
  CHEACK_EMAIL_REGISTER,
  SUBMIT_LOGIN_END,
  GET_ENV
} from "../constants/auth";
import generateSagaWorker, {
  createStartAction,
  createErrorAction,
  createSuccessAction,
  POST,
  toJson,
  encodeFormData,
  get,
  postJson,
  createEndAction
} from "./generateSaga";

const sagaGenerator = generateSagaWorker(put, call);

const register = sagaGenerator({
  startAction: createStartAction(SUBMIT_SIGN_IN_START),
  url: () => "/api/v1/public/register/mail-registration",
  method: POST,
  getBody: encodeFormData("form"),
  getError: toJson,
  successAction: createSuccessAction(SUBMIT_SIGN_IN_SUCCESS),
  errorAction: createErrorAction(SUBMIT_SIGN_IN_ERROR, error => ({
    errors: error.result.errors
  }))
});

const registerValidate = sagaGenerator({
  startAction: createStartAction(SUBMIT_SIGN_IN_VALIDATION_START),
  url: () => "/api/v1/public/register/validate",
  method: POST,
  getBody: encodeFormData("form"),
  getError: toJson,
  successAction: createSuccessAction(SUBMIT_SIGN_IN_VALIDATION_SUCCESS),
  errorAction: createErrorAction(SUBMIT_SIGN_IN_VALIDATION_ERROR, error => ({
    errors: error.result.errors
  }))
});

const registerConfirm = sagaGenerator({
  startAction: createStartAction(SUBMIT_SIGN_IN_CONFIRM_START),
  url: () => "/api/v1/public/register/confirm",
  method: POST,
  getBody: encodeFormData("form"),
  getError: toJson,
  successAction: createSuccessAction(SUBMIT_SIGN_IN_CONFIRM_SUCCESS),
  errorAction: createErrorAction(SUBMIT_SIGN_IN_CONFIRM_ERROR)
});

const login = sagaGenerator({
  startAction: createStartAction(SUBMIT_LOGIN_START),
  url: () => "/api/v1/public/auth",
  method: POST,
  getBody: encodeFormData("form"),
  getError: toJson,
  successAction: createSuccessAction(SUBMIT_LOGIN_SUCCESS),
  errorAction: createErrorAction(SUBMIT_LOGIN_ERROR),
  endAction: createEndAction(SUBMIT_LOGIN_END)
});

const recover = sagaGenerator({
  startAction: createStartAction(SUBMIT_RESTORE_START),
  url: () => "/api/v1/public/recover",
  method: POST,
  getBody: encodeFormData("form"),
  getError: toJson,
  successAction: createSuccessAction(SUBMIT_RESTORE_SUCCESS),
  errorAction: createErrorAction(SUBMIT_RESTORE_ERROR)
});

const recoverConfirm = sagaGenerator({
  startAction: createStartAction(SUBMIT_RESTORE_CONFIRM_START),
  url: () => "/api/v1/public/recover/confirm",
  method: POST,
  getBody: encodeFormData("form"),
  getError: toJson,
  successAction: createSuccessAction(SUBMIT_RESTORE_CONFIRM_SUCCESS),
  errorAction: createErrorAction(SUBMIT_RESTORE_ERROR)
});

const submitApplication = postJson(
  sagaGenerator,
  () => "/api/v1/public/applications",
  SUBMIT_APPLICATION.types
);

const getProfile = get(
  sagaGenerator,
  () => "/api/v1/clients/profile",
  GET_PROFILE.types
);

const getEnv = get(sagaGenerator, () => "/api/v1/public/env", GET_ENV.types);

const simpleMsbRegistration = postJson(
  sagaGenerator,
  () => `/api/v1/public/register/simpleRegistration`,
  SIMPLE_MSB_REGISTRATION.types
);

const CheckEmailRegister = get(
  sagaGenerator,
  action => `/api/v1/public/register/checkEmail?email=${action.email}`,
  CHEACK_EMAIL_REGISTER.types
);

export default function* auth() {
  yield takeEvery(SUBMIT_SIGN_IN, register);
  yield takeEvery(SUBMIT_SIGN_IN_VALIDATION, registerValidate);
  yield takeEvery(SUBMIT_SIGN_IN_CONFIRM, registerConfirm);
  yield takeEvery(SUBMIT_LOGIN, login);
  yield takeEvery(SUBMIT_RESTORE, recover);
  yield takeEvery(SUBMIT_RESTORE_CONFIRM, recoverConfirm);
  yield takeEvery(SUBMIT_APPLICATION.main, submitApplication);
  yield takeEvery(GET_PROFILE.main, getProfile);
  yield takeEvery(SIMPLE_MSB_REGISTRATION.main, simpleMsbRegistration);
  yield takeEvery(CHEACK_EMAIL_REGISTER.main, CheckEmailRegister);
  yield takeEvery(GET_ENV.main, getEnv);
}
