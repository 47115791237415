import { gqlRequest } from "../";
import { gql } from "graphql-request";
import moment from "moment";
import { MSB } from "utils/const";

export const getInventoryReport = async (
  { form },
  { lastMetroType, metroLineAndStation }
) => {
  const {
    startDate,
    endDate,
    latitude,
    longitude,
    limit,
    offset,
    radius,
    sorting,
    price,
    ots,
    sizes,
    stationId,
    lineId
  } = form;

  const priceFilter =
    price.lower && price.upper
      ? {
          price: {
            between: {
              lower: Number(price.lower),
              upper: Number(price.upper)
            }
          }
        }
      : {};

  const formatFilter = sizes.length
    ? {
        formatId: {
          in: sizes
        }
      }
    : {};

  const stationFilter = stationId.length
    ? {
        stationId: {
          in: stationId
        }
      }
    : {};

  const lineFilter = lineId.length
    ? {
        lineId: {
          in: lineId
        }
      }
    : {};

  const query = gql`
    query(
      $input: AdSpaceByCoordsInput!
      $paging: AdSpaceByCoordsPaging
      $filter: AdSpaceByCoordsFilter
      $sorting: [AdSpaceByCoordsSort!]
    ) {
      findInventoryByCoords(
        input: $input
        paging: $paging
        filter: $filter
        sorting: $sorting
      ) {
        totalCount
        nodes {
          advertisingSpace {
            schemaImageUrl
            photos {
              id
              url
            }
            inventoryNumber
            id
            panoramaUrl
            metroExit {
              station {
                id
                name
                lines {
                  name
                }
              }
              id
              name
              latitude
              longitude
            }
            format {
              name
            }
            files
          }
          pricePer1kContacts
          price
          otsPerMonth
          distance
          pricePer1kContacts
          availablePeriods {
            startDate
            endDate
            basePrice
            seasonalDiscount
          }
        }
      }
    }
  `;
  const variables = {
    input: {
      productName: "lightBoxes",
      coords: [
        {
          latitude,
          longitude
        }
      ],
      radius: Number(radius).toFixed(2) / 1000,
      period: {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD")
      }
    },
    sorting: sorting.sortItems,
    paging: {
      limit,
      offset
    },
    filter: {
      otsPerMonth: {
        between: {
          lower: Number(ots.lower),
          upper: Number(ots.upper)
        }
      },
      ...priceFilter,
      ...formatFilter,
      ...stationFilter,
      ...lineFilter,
      isBooked: {
        is: false
      }
    }
  };
  return await gqlRequest(query, variables);
};

export const getStation = async (
  { form },
  { lastMetroType, metroLineAndStation }
) => {
  const {
    startDate,
    endDate,
    latitude,
    longitude,
    limit,
    offset,
    radius,
    sorting,
    price,
    activeStation,
    ots,
    sizes,
    stationId,
    lineId
  } = form;

  const priceFilter =
    price.lower && price.upper
      ? {
          price: {
            between: {
              lower: Number(price.lower),
              upper: Number(price.upper)
            }
          }
        }
      : {};

  const filterByStationId = activeStation?.length
    ? {
        stationId: { in: activeStation.map(as => as.id) }
      }
    : {};

  const formatFilter = sizes.length
    ? {
        formatId: {
          in: sizes
        }
      }
    : {};

  const stationFilter = stationId.length
    ? {
        stationId: {
          in: stationId
        }
      }
    : {};

  const lineFilter = lineId.length
    ? {
        lineId: {
          in: lineId
        }
      }
    : {};

  const query = gql`
    query(
      $input: AdSpaceByCoordsInput!
      $paging: AdSpaceByCoordsPaging
      $filter: AdSpaceByCoordsFilter
      $sorting: [AdSpaceByCoordsSort!]
    ) {
      findInventoryByCoordsAndPlatform(
        input: $input
        paging: $paging
        filter: $filter
        sorting: $sorting
      ) {
        totalCount
        nodes {
          exits {
            id
            latitude
            longitude
            name
            station {
              id
              name
              lines {
                id
                name
                displayName
                color
              }
            }
            advertisingSpaces {
              otsPerMonth
              price
              pricePer1kContacts
              isAvailableForSale
              distance
              availablePeriods {
                startDate
                endDate
                basePrice
                seasonalDiscount
              }
              advertisingSpace {
                schemaImageUrl
                id
                inventoryNumber
                description
                panoramaUrl
                angle
                photos {
                  id
                  url
                }
                format {
                  name
                }
                schemaImageUrl
              }
            }
          }
        }
      }
    }
  `;
  const variables = {
    input: {
      productName: "lightBoxes",
      coords: [
        {
          latitude,
          longitude
        }
      ],
      radius: activeStation?.length ? 9999 : Number(radius).toFixed(2) / 1000,
      period: {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD")
      }
    },
    sorting: sorting.sortItems,
    paging: {
      limit,
      offset
    },
    filter: {
      ...priceFilter,
      ...filterByStationId,
      ...formatFilter,
      ...stationFilter,
      ...lineFilter,
      otsPerMonth: {
        between: {
          lower: Number(ots.lower),
          upper: Number(ots.upper)
        }
      }
    }
  };
  return await gqlRequest(query, variables);
};

export const getInventoryOtsReport = async (inventories, inventoriesVars) => {
  const query = gql`
    query(${inventories
      .map((i, idx) => `$input${idx}: [LightboxesOTSInput]!`)
      .join(", ")}) {
      ${inventories
        .map((i, idx) => `input${idx}: getLightboxesOTS(input: $input${idx})`)
        .join("\n ")}
    }
  `;
  const variables = inventoriesVars;
  return await gqlRequest(query, variables);
};

export const getMarketingDocIdReport = async () => {
  const query = gql`
    query($filter: DocumentFilter) {
      documents(
        filter: $filter
        sorting: { field: "startDate", direction: DESC }
      ) {
        nodes {
          id
          startDate
          product {
            name
          }
        }
      }
    }
  `;

  const variables = {
    filter: {
      startDate: {
        lte: moment().format("YYYY-MM-DD")
      },
      documentTypes: {
        name: {
          eq: "ots"
        }
      },
      product: {
        name: {
          eq: "lightBoxes"
        }
      }
    }
  };
  return await gqlRequest(query, variables);
};

export const getMarketingOtsReport = async (id, lbId) => {
  const query = gql`
    query($filter: MarketingValueFilter) {
      marketingValues(filter: $filter) {
        totalCount
        nodes {
          id
          value
        }
      }
    }
  `;

  const variables = {
    filter: {
      marketingValueList: {
        advertisingSpace: {
          id: {
            eq: lbId
          }
        },
        document: {
          id: {
            eq: id
          }
        }
      }
    }
  };
  return await gqlRequest(query, variables);
};

export const getLightboxesMediaplan = async lightboxes => {
  const query = gql`
    query($input: LightboxesMediaplanInput!) {
      getLightboxesMediaplan(input: $input) {
        mediaplanUrl
      }
    }
  `;

  const variables = {
    input: {
      brandName: "",
      lightboxes
    }
  };
  return await gqlRequest(query, variables);
};

export const getBasketInventories = async (ids, startDate, endDate) => {
  const query = gql`
    query(
      $input: AdSpaceByCoordsInput!
      $paging: AdSpaceByCoordsPaging
      $filter: AdSpaceByCoordsFilter
      $sorting: [AdSpaceByCoordsSort!]
    ) {
      findInventoryByCoords(
        input: $input
        paging: $paging
        filter: $filter
        sorting: $sorting
      ) {
        totalCount
        nodes {
          advertisingSpace {
            schemaImageUrl
            photos {
              id
              url
            }
            inventoryNumber
            id
            panoramaUrl
            metroExit {
              station {
                id
                name
                lines {
                  name
                }
              }
              id
              name
              latitude
              longitude
            }
            format {
              name
            }
            files
          }
          pricePer1kContacts
          price
          otsPerMonth
          distance
          pricePer1kContacts
          availablePeriods {
            startDate
            endDate
            basePrice
            seasonalDiscount
          }
        }
      }
    }
  `;

  const variables = {
    input: {
      productName: "lightBoxes",
      coords: [
        {
          latitude: 55.75322,
          longitude: 37.622513
        }
      ],
      radius: Number(MSB.MAX_RADIUS).toFixed(2) / 1000,
      period: {
        startDate: startDate,
        endDate: endDate
      }
    },
    paging: {
      limit: 9999
    },
    filter: {
      id: {
        in: ids
      }
    }
  };

  return await gqlRequest(query, variables);
};

export const getMapInventories = async form => {
  const { startDate, endDate, price, activeStation, ots, sizes } = form;

  const priceFilter =
    price.lower && price.upper
      ? {
          price: {
            between: {
              lower: Number(price.lower),
              upper: Number(price.upper)
            }
          }
        }
      : {};

  const filterByStationId =
    activeStation && activeStation?.length
      ? {
          stationId: { in: activeStation.map(as => as.id) }
        }
      : {};

  const formatFilter = sizes.length
    ? {
        formatId: {
          in: sizes
        }
      }
    : {};

  const query = gql`
    query(
      $input: AdSpaceByPlatformInput!
      $paging: AdSpaceByCoordsPaging
      $filter: AdSpaceByCoordsFilter
      $sorting: [AdSpaceByCoordsSort!]
    ) {
      findSpacesByPlatform(
        input: $input
        paging: $paging
        filter: $filter
        sorting: $sorting
      ) {
        totalCount
        nodes {
          id
          name
          latitude
          longitude
          lines {
            name
            color
          }
          advertisingSpacesCount
        }
      }
    }
  `;

  const variables = {
    input: {
      productName: "lightBoxes",
      period: {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD")
      }
    },
    filter: {
      isBooked: {
        is: false
      },
      ...priceFilter,
      ...filterByStationId,
      ...formatFilter,
      otsPerMonth: {
        between: {
          lower: Number(ots.lower),
          upper: Number(ots.upper)
        }
      }
    },
    paging: {
      limit: 9999,
      offset: 0
    }
  };

  return await gqlRequest(query, variables);
};

export const getFormatesGQL = async () => {
  const query = gql`
    query Formats($where: FormatFilter) {
      formats(filter: $where) {
        nodes {
          id
          name
        }
      }
    }
  `;

  const variables = {
    where: {
      product: {
        name: {
          eq: "lightBoxes"
        }
      }
    }
  };

  return await gqlRequest(query, variables);
};

export const getMetroLine = async () => {
  const query = gql`
    query($paging: OffsetPaging) {
      metroLines(paging: $paging) {
        totalCount
        nodes {
          id
          name
          color
        }
      }
    }
  `;
  const variables = {
    paging: {
      limit: 500
    }
  };
  return await gqlRequest(query, variables);
};

export const getMetroStations = async () => {
  const query = gql`
    query($paging: OffsetPaging) {
      metroStations(paging: $paging) {
        totalCount
        nodes {
          id
          name
          lines {
            id
            color
          }
        }
      }
    }
  `;
  const variables = {
    paging: {
      limit: 500
    }
  };
  return await gqlRequest(query, variables);
};
