import createReducer from "./createReducer";
import { CHECK_SIGN_IN_CONFIRM } from "../constants/register";

const initialState = {
  successCheckConfirm: false,
  checkConfirmCalled: false,
  checkConfirmCalling: false
};

const consumers = {
  [CHECK_SIGN_IN_CONFIRM.success]: (state, action) => ({
    ...state,
    successCheckConfirm: action.result.data.isConfirmed,
    checkConfirmCalling: false
  }),
  [CHECK_SIGN_IN_CONFIRM.start]: state => ({
    ...state,
    checkConfirmCalled: true,
    checkConfirmCalling: true
  }),
  [CHECK_SIGN_IN_CONFIRM.error]: state => ({
    ...state,
    successCheckConfirm: false,
    checkConfirmCalling: false
  })
};

export default createReducer(initialState, consumers);
