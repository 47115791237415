import React, { Component, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import qs from "qs";
import store from "./store";
import "moment/locale/ru";
import Authorization from "./components/Authorization";
import OfferArchiveContainer from "./components/OfferArchive";
import Restore from "./components/Restore";
import RestoreConfirm from "./components/Restore/RestoreConfirm";

import Page from "./components/Page";
import InitialApp from "./components/InitialApp";
import { routes } from "./helpers/routes";
import { MobileDummy } from "./components/MobileDummy";
import Confirm from "./components/Confirm";
import PhotoReportsRoutes from "components/_PhotoReportsApp/Routes";

import Software from "routes/Software";

import { setToken } from "store/helpers/auth";
import PageLoader from "components/PageLoader/PageLoader";

class App extends Component {
  params = qs.parse(window.location.search.slice(1));

  componentDidMount() {
    if (this.params && this.params.landingName) {
      localStorage.setItem("new-msb-user-info", JSON.stringify(this.params));
    }

    /**
     * Авторизируем пользоватлея, если
     * есть в параметрах authToken
     */
    let params = new URL(document.location).searchParams;
    const authToken = params.get("authToken");
    if (authToken) {
      setToken({ result: { data: { token: authToken } } });
      params.delete("authToken");
      const updateUrl = new URL(
        window.location.origin + window.location.pathname
      );
      params.forEach((value, key) => updateUrl.searchParams.append(key, value));
      window.location.replace(updateUrl);
    }
  }

  render() {
    const subdomain = window.location.host.split(".")[1]
      ? window.location.host.split(".")[0] === "ph"
      : false;

    const isPublicRoute = window.location.pathname === "/software";

    if (!subdomain && !isPublicRoute) {
      const root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)

      root.setAttribute("class", "landing-html");
      document.body.classList.add("landing-body");
    }
    return (
      <Provider store={store}>
        {subdomain ? (
          <PhotoReportsRoutes />
        ) : isPublicRoute ? (
          <Switch>
            <Route path={"/software"} exact component={Software} />
          </Switch>
        ) : (
          <InitialApp>
            <Page>
              <Switch>
                <Suspense fallback={<PageLoader></PageLoader>}>
                  {routes.map(({ path, component }, index) => (
                    <Route
                      exact={true}
                      path={path}
                      component={component}
                      key={index}
                    />
                  ))}
                </Suspense>
              </Switch>
              <div>
                <Route
                  path={"/restore/confirm/:token?"}
                  component={RestoreConfirm}
                />
                <Route path={"/sign-in/confirm/:token?"} component={Confirm} />
                <Route path="/restore" exact component={Restore} />
                <Route
                  path={"/(login|sign-in)"}
                  exact
                  component={Authorization}
                />
              </div>
              <OfferArchiveContainer />
            </Page>
            <MobileDummy />
          </InitialApp>
        )}
      </Provider>
    );
  }
}

export default App;
