// Some helpers
import { monitorsCalculation } from "store/helpers/monitorsCalculator";
import moment from "moment";

import { GET_LINES } from "store/constants/metro";

import {
  RESET_STATE,
  TOGGLE_TOTAL_COLLAPSE,
  INPUT_BUDGET,
  FIXED_BUDGET,
  FIXED_BUDGET_STANDALONE,
  RESET_GROUPS,
  UPDATE_CLIP_DURATION,
  UPDATE_CLIP_DURATION_COMPLETED,
  UPDATE_PRESENTATION_COUNT,
  UPDATE_PRESENTATION_COUNT_COMPLETED,
  UPDATE_PRESENTATION_PERIOD,
  TOGGLE_MLINE,
  SELECT_PRESENTATION_TIME,
  HOVER_PRESENTATION_TIME,
  TOGGLE_PRESENTATION_FREQUENCY,
  TOGGLE_DAYS_WEEK,
  SET_SELECTION_BLOCK,
  UPDATE_CUSTOMER_CAMPAIGNAD,
  CLEAR_BUDGET_ERROR,
  CLEAR_PERIOD_ERROR,
  ENABLE_END_DATE_EDITING,
  UPDATE_STATE,
  GET_ROUTES,
  TOGGLE_ROUTE,
  GET_ADVERTISE_PLACES,
  GET_SEASONAL_DISCOUNT,
  UPDATE_SEASONAL_DISCOUNT,
  GET_VOLUME_DISCOUNT,
  GET_FACTOR_DISCOUNT,
  GET_DISTRICTS,
  SELECT_DISTRICT,
  HOVER_ROUTE,
  GET_SEASONAL_TABLE,
  GET_REPORTS_MLINES,
  GET_REPORTS_TIMEINTERVALS,
  GET_REPORTS_OTS,
  RECALCULATE_STORE,
  REPLACE_ALONE_STATE,
  REPLACE_METRO_TRAM_STATE,
  GET_MONITORS_ALONE,
  TOGGLE_MONITOR,
  DIVIDE_TOOGLE_ITEMS,
  HANDLE_TOGGLE_COLLAPSE,
  HANDLE_POPUP,
  GET_METRO_MONITORS
} from "store/constants/monitorsCalculator";

const getDisabledRoutes = ({ monitorsAmount, monitorsDayOff }, daysWeek) => {
  const isWorkday = daysWeek.find(({ value }) => value === "workday").selected;
  const isHoliday = daysWeek.find(({ value }) => value === "holiday").selected;
  if (isWorkday && isHoliday) {
    return false;
  }
  return (isWorkday && !monitorsAmount) || (isHoliday && !monitorsDayOff);
};

const getSelectedRoutes = (
  { monitorsAmount, monitorsDayOff, touched },
  daysWeek
) => {
  const isWorkday = daysWeek.find(({ value }) => value === "workday").selected;
  const isHoliday = daysWeek.find(({ value }) => value === "holiday").selected;
  if ((isWorkday && isHoliday) || (!isWorkday && !isHoliday)) {
    return touched;
  }

  if ((isWorkday && !monitorsAmount) || (isHoliday && !monitorsDayOff)) {
    return false;
  }

  return touched;
};

// Initial states
const initialState = {
  seasonalDiscount: 1,
  seasonalTableDiscount: [],
  volumeDiscount: [],
  factorDiscount: [],
  campaignPeriods: [],
  advertisePlaces: [],
  districts: [{ name: "Все", _id: "allDisticts", selected: true, count: 0 }],
  cities: [{ name: "Все", _id: "allCities", selected: true, count: 0 }],
  priceOneSecond: 0.04896,
  $priceOneSecond: 0.04896,
  priceOneSecondForBudget: 0.04896,
  selectionBlock: "",
  scrollTo: false,
  targetingList: [],
  total: {
    statisticsOpened: true,
    targeting: true
  },
  budget: {
    value: "",
    placeholder: "10 000 000 ₽",
    fixed: false,
    name: "budget",
    error: ""
  },
  clipDuration: {
    min: 5,
    max: 30,
    step: 5,
    value: 5,
    name: "clipDuration",
    coefficient: 1,
    activeTargeting: false
  },
  presentationCount: {
    min: 10000,
    max: 100000000,
    value: 10000,
    step: 10000,
    name: "presentationCount",
    fix: true,
    disabledWidthLine: 0,
    widthInputRange: 400,
    stopValue: 0,
    activeTargeting: false
  },
  presentationPeriod: {
    from: moment().startOf("d"),
    to: moment()
      .startOf("d")
      .add(3, "days"),
    name: "presentationPeriod",
    endDateEditionDisabled: true,
    error: "",
    errorId: ""
  },
  mlines: {
    items: [],
    name: "mlines",
    activeTargeting: false
  },
  monitors: {
    items: [],
    name: "monitors",
    activeTargeting: false
  },
  routes: {
    items: [],
    baseItems: [],
    name: "routes",
    activeTargeting: false
  },
  routesBus: {
    items: [],
    name: "routesBus",
    activeTargeting: false
  },
  metroMonitors: {
    items: [],
    name: "metroMonitors",
    activeTargeting: false
  },
  routesPrices: {
    items: [],
    name: "routesPrices",
    activeTargeting: false
  },
  cityFormats: {
    items: [],
    name: "cityFormats",
    activeTargeting: false
  },
  turnsTiles: {
    items: [],
    name: "turnsTiles",
    activeTargeting: false
  },
  pricesArray: 10,
  daysWeek: {
    coefficient: 1,
    items: [
      {
        value: "workday",
        selected: false
      },
      {
        value: "holiday",
        selected: false
      }
    ],
    name: "daysWeek",
    activeTargeting: false
  },
  presentationTime: {
    coefficient: 1,
    coefficientTwo: 1,
    items: [
      {
        from: moment(1000).set({
          hour: 5,
          minute: 30,
          second: 0,
          millisecond: 0
        }),
        to: moment(1000).set({
          hour: 8,
          minute: 0,
          second: 0,
          millisecond: 0
        }),
        _id: "1",
        halfHour: 5,
        selected: false,
        hovered: false,
        primeTime: false
      },
      {
        from: moment(1000).set({
          hour: 8,
          minute: 0,
          second: 0,
          millisecond: 0
        }),
        to: moment(1000).set({
          hour: 10,
          minute: 0,
          second: 0,
          millisecond: 0
        }),
        _id: "2",
        halfHour: 4,
        selected: false,
        hovered: false,
        primeTime: true
      },
      {
        from: moment(1000).set({
          hour: 10,
          minute: 0,
          second: 0,
          millisecond: 0
        }),
        to: moment(1000).set({
          hour: 17,
          minute: 30,
          second: 0,
          millisecond: 0
        }),
        _id: "3",
        halfHour: 15,
        selected: false,
        hovered: false,
        primeTime: false
      },
      {
        from: moment(1000).set({
          hour: 17,
          minute: 30,
          second: 0,
          millisecond: 0
        }),
        to: moment(1000).set({
          hour: 19,
          minute: 40,
          second: 0,
          millisecond: 0
        }),
        _id: "4",
        halfHour: 4,
        selected: false,
        hovered: false,
        primeTime: true
      },
      {
        from: moment(1000).set({
          hour: 19,
          minute: 40,
          second: 0,
          millisecond: 0
        }),
        to: moment(1000)
          .add(1, "days")
          .set({
            hour: 1,
            minute: 0,
            second: 0,
            millisecond: 0
          }),
        _id: "5",
        halfHour: 9,
        selected: false,
        hovered: false,
        primeTime: false
      }
    ],
    name: "presentationTime",
    activeTargeting: false
  },
  presentationFrequency: {
    coefficient: 1,
    items: [
      {
        value: 1,
        selected: false
      },
      {
        value: 2,
        selected: false
      },
      {
        value: 3,
        selected: false
      },
      {
        value: 4,
        selected: false
      }
    ],
    name: "presentationFrequency",
    activeTargeting: false
  },
  isPlannerRequestStart: false,
  isPlannerRequestCompleted: false,
  plannerRequestId: false,
  discountLoading: false,
  reportsMlines: [],
  reportsTimeIntervals: [],
  reportsMlinesError: {},
  reportsTimeIntervalsError: {},
  reportsOtsPerPeriod: [],
  storeRecalculated: false,
  reportsOtsLoaded: false,
  reportsOtsLoading: false,
  toogle: {},
  popupOpen: false
};

const initialStateStandalone = {
  ...initialState,
  priceOneSecond: 0.2848,
  $priceOneSecond: 0.2848,
  priceOneSecondForBudget: 0.2848,
  budget: {
    ...initialState.budget,
    placeholder: "100 000 ₽"
  },
  clipDuration: {
    ...initialState.clipDuration,
    max: 60
  },
  presentationCount: {
    ...initialState.presentationCount,
    max: 100000,
    step: 1000
  },
  presentationFrequency: {
    ...initialState.presentationFrequency,
    items: [
      {
        value: 10,
        selected: false
      },
      {
        value: 20,
        selected: false
      },
      {
        value: 30,
        selected: false
      },
      {
        value: 40,
        selected: false
      },
      {
        value: 50,
        selected: false
      },
      {
        value: 60,
        selected: false
      }
    ]
  }
};

let customState = initialState;

// Reducers
export const monitorsCalculator = (
  state = monitorsCalculation(initialState),
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case GET_METRO_MONITORS.success:
      return monitorsCalculation(
        {
          ...state,
          metroMonitors: {
            ...state.metroMonitors,
            items: payload.metroMonitors.nodes
          }
        },
        type,
        state
      );
    case GET_FACTOR_DISCOUNT.success:
      return monitorsCalculation(
        {
          ...state,
          factorDiscount: payload.length > 0 ? payload : []
        },
        type,
        state
      );
    case GET_SEASONAL_TABLE.success:
      return {
        ...state,
        seasonalTableDiscount: payload
      };
    case GET_DISTRICTS.success:
      return {
        ...state,
        districts: state.districts.concat(
          payload.map(({ ...district }) => ({
            ...district,
            selected: false,
            count: 0,
            underline: false
          }))
        )
      };
    case GET_VOLUME_DISCOUNT.success:
      return {
        ...state,
        volumeDiscount: payload
      };

    case GET_SEASONAL_DISCOUNT.success:
      return monitorsCalculation(
        {
          ...state,
          seasonalDiscount: payload
        },
        type,
        state
      );

    case GET_ADVERTISE_PLACES.success:
      return monitorsCalculation(
        {
          ...state,
          advertisePlaces: payload
        },
        type,
        state
      );

    case REPLACE_ALONE_STATE:
      customState = {
        ...initialStateStandalone,
        advertisePlaces: state.advertisePlaces,
        discount: state.discount,
        ignoreGlobalDiscountFactors: state.ignoreGlobalDiscountFactors,
        isAgency: state.isAgency,
        factorDiscount: state.factorDiscount,
        toogle: state.toogle,
        popupOpen: state.popupOpen
      };

      return monitorsCalculation(
        {
          ...initialStateStandalone,
          advertisePlaces: state.advertisePlaces,
          discount: state.discount,
          ignoreGlobalDiscountFactors: state.ignoreGlobalDiscountFactors,
          isAgency: state.isAgency,
          factorDiscount: state.factorDiscount,
          toogle: state.toogle,
          popupOpen: state.popupOpen
        },
        type,
        state
      );

    case REPLACE_METRO_TRAM_STATE:
      const { place } = payload;

      if (place === "metro") {
        customState = {
          ...initialState,
          advertisePlaces: state.advertisePlaces,
          discount: state.discount,
          ignoreGlobalDiscountFactors: state.ignoreGlobalDiscountFactors,
          isAgency: state.isAgency,
          factorDiscount: state.factorDiscount,
          toogle: state.toogle,
          popupOpen: state.popupOpen,
          presentationTime: {
            ...initialState.presentationTime,
            items: initialState.presentationTime.items.map(item => {
              if (item._id === "4") {
                return {
                  ...item,
                  to: moment(1000).set({
                    hour: 19,
                    minute: 40,
                    second: 0,
                    millisecond: 0
                  })
                };
              } else if (item._id === "5") {
                return {
                  ...item,
                  from: moment(1000).set({
                    hour: 19,
                    minute: 40,
                    second: 0,
                    millisecond: 0
                  })
                };
              } else {
                return {
                  ...item
                };
              }
            })
          }
        };
      } else {
        customState = {
          ...initialState,
          advertisePlaces: state.advertisePlaces,
          discount: state.discount,
          factorDiscount: state.factorDiscount,
          ignoreGlobalDiscountFactors: state.ignoreGlobalDiscountFactors,
          isAgency: state.isAgency,
          toogle: state.toogle,
          popupOpen: state.popupOpen
        };
      }
      return monitorsCalculation(
        {
          ...initialState,
          advertisePlaces: state.advertisePlaces,
          discount: state.discount,
          factorDiscount: state.factorDiscount,
          ignoreGlobalDiscountFactors: state.ignoreGlobalDiscountFactors,
          isAgency: state.isAgency,
          toogle: state.toogle,
          popupOpen: state.popupOpen,
          presentationTime:
            place === "metro"
              ? {
                  ...initialState.presentationTime,
                  items: initialState.presentationTime.items.map(item => {
                    if (item._id === "4") {
                      return {
                        ...item,
                        to: moment(1000).set({
                          hour: 19,
                          minute: 40,
                          second: 0,
                          millisecond: 0
                        })
                      };
                    } else if (item._id === "5") {
                      return {
                        ...item,
                        from: moment(1000).set({
                          hour: 19,
                          minute: 40,
                          second: 0,
                          millisecond: 0
                        })
                      };
                    } else {
                      return {
                        ...item
                      };
                    }
                  })
                }
              : initialState.presentationTime
        },
        type,
        state
      );

    case UPDATE_STATE:
      return {
        ...state,
        ...payload
      };

    case GET_ROUTES.success:
      return monitorsCalculation(
        {
          ...state,
          routes: {
            ...state.routes,
            items: payload,
            baseItems: payload
          }
        },
        type,
        state
      );

    case UPDATE_CUSTOMER_CAMPAIGNAD.start:
      return monitorsCalculation(
        {
          ...state,
          isPlannerRequestStart: true,
          isPlannerRequestCompleted: false,
          plannerRequestId: payload,
          storeRecalculated: false,
          reportsOtsLoaded: false,
          reportsOtsLoading: true
        },
        type,
        state
      );

    case UPDATE_CUSTOMER_CAMPAIGNAD.error:
      return monitorsCalculation(
        {
          ...state,
          isPlannerRequestStart: false,
          isPlannerRequestCompleted: false
        },
        type,
        state
      );

    case UPDATE_CUSTOMER_CAMPAIGNAD.success:
      const { plannerRequestId } = state;
      const {
        from,
        to,
        error,
        id,
        ignore,
        errorId,
        totalCount,
        fitCount
      } = payload;

      return plannerRequestId === id
        ? monitorsCalculation(
            {
              ...state,
              isPlannerRequestStart: false,
              isPlannerRequestCompleted: true,
              presentationPeriod: ignore
                ? state.presentationPeriod
                : {
                    ...state.presentationPeriod,
                    plannerTotalCount: totalCount,
                    plannerFitCount: fitCount,
                    from,
                    to,
                    error,
                    errorId
                  }
            },
            type,
            state
          )
        : state;

    case UPDATE_SEASONAL_DISCOUNT.start:
      return monitorsCalculation(
        {
          ...state,
          discountLoading: payload.discountLoading
        },
        type,
        state
      );

    case UPDATE_SEASONAL_DISCOUNT.success:
      return monitorsCalculation(
        {
          ...state,
          seasonalDiscount: payload.seasonalDiscount,
          discountLoading: payload.discountLoading
        },
        type,
        state
      );

    case ENABLE_END_DATE_EDITING:
      return {
        ...state,
        presentationPeriod: {
          ...state.presentationPeriod,
          endDateEditionDisabled: false
        }
      };

    case SET_SELECTION_BLOCK:
      return {
        ...state,
        selectionBlock: payload
      };

    case RESET_STATE:
      return monitorsCalculation(
        {
          ...customState,
          advertisePlaces: state.advertisePlaces
        },
        type,
        state
      );

    case TOGGLE_TOTAL_COLLAPSE:
      return {
        ...state,
        total: {
          ...state.total,
          [payload]: !state.total[payload]
        }
      };

    case DIVIDE_TOOGLE_ITEMS:
      const { campaignPeriods } = state;
      const dataToogle = {};

      campaignPeriods.forEach((item, idx) => {
        dataToogle[idx] = true;
      });

      return {
        ...state,
        toogle: dataToogle
      };

    case HANDLE_POPUP:
      const { popupOpen } = state;

      return {
        ...state,
        popupOpen: !popupOpen
      };

    case HANDLE_TOGGLE_COLLAPSE:
      return {
        ...state,
        toogle: {
          ...state.toogle,
          [payload]: !state.toogle[payload]
        }
      };

    case RESET_GROUPS:
      if (payload === "clipDuration") {
        return monitorsCalculation(
          {
            ...state,
            clipDuration: {
              ...customState.clipDuration
            },
            presentationCount: {
              ...customState.presentationCount
            }
          },
          type,
          state
        );
      }

      if (payload === "budget") {
        return monitorsCalculation(
          {
            ...state,
            mlines: {
              ...state.mlines,
              disabledEdit: false
            },
            budget: {
              ...customState.budget
            }
          },
          type,
          state
        );
      }

      if (payload === "mlines") {
        return monitorsCalculation(
          {
            ...state,
            mlines: {
              ...customState.mlines,
              items: state.mlines.items.map(item => ({
                ...item,
                selected: false
              }))
            }
          },
          type,
          state
        );
      }

      if (payload === "routes") {
        return monitorsCalculation(
          {
            ...state,
            routes: {
              ...customState.routes,
              items: state.routes.items.map(item => ({
                ...item,
                selected: false,
                touched: false,
                hide: false
              }))
            },
            districts: state.districts.map(({ _id, ...district }) => ({
              ...district,
              _id,
              selected: _id === "allDisticts" ? true : false,
              count: 0
            }))
          },
          type,
          state
        );
      }

      if (payload === "daysWeek") {
        return monitorsCalculation(
          {
            ...state,
            daysWeek: {
              ...customState.daysWeek
            },
            routes: {
              ...customState.routes,
              items: state.routes.items.map(item => ({
                ...item,
                disabled: false
              }))
            }
          },
          type,
          state
        );
      }

      if (payload === "monitors") {
        return monitorsCalculation(
          {
            ...state,
            monitors: {
              ...customState.monitors,
              items: state.monitors.items.map(item => ({
                ...item,
                selected: false
              }))
            }
          },
          type,
          state
        );
      }

      return monitorsCalculation(
        {
          ...state,
          [payload]: {
            ...customState[payload]
          }
        },
        type,
        state
      );

    case INPUT_BUDGET:
      return {
        ...state,
        budget: {
          ...state.budget,
          value: payload.replace(/\D/g, ""),
          error: ""
        }
      };

    case CLEAR_BUDGET_ERROR:
      return {
        ...state,
        budget: {
          ...state.budget,
          error: ""
        }
      };

    case CLEAR_PERIOD_ERROR:
      return {
        ...state,
        presentationPeriod: {
          ...state.presentationPeriod,
          error: ""
        }
      };

    case RECALCULATE_STORE:
      return monitorsCalculation(
        {
          ...state,
          storeRecalculated: true
        },
        type,
        state
      );

    case FIXED_BUDGET:
      const budgetValue = parseInt(state.budget.value);
      if (
        budgetValue < 2450 ||
        budgetValue > 100000000 ||
        !state.budget.value
      ) {
        return {
          ...state,
          budget: {
            ...state.budget,
            error:
              budgetValue > 100000000
                ? "Максимальный бюджет: 100 000 000 ₽"
                : "Минимальный бюджет: 2 450 ₽"
          }
        };
      }

      return monitorsCalculation(
        {
          ...state,
          budget: {
            ...state.budget,
            fixed: true
          }
        },
        type,
        state
      );

    case FIXED_BUDGET_STANDALONE:
      const budgetVal = parseInt(state.budget.value, 10);
      if (budgetVal < 14240 || budgetVal > 650000 || !state.budget.value) {
        return {
          ...state,
          budget: {
            ...state.budget,
            error:
              budgetVal > 650000
                ? "Максимальный бюджет: 650 000 ₽"
                : "Минимальный бюджет: 14 240 ₽"
          }
        };
      }

      return monitorsCalculation(
        {
          ...state,
          budget: {
            ...state.budget,
            fixed: true
          }
        },
        type,
        state
      );

    case UPDATE_CLIP_DURATION:
    case UPDATE_CLIP_DURATION_COMPLETED:
      return monitorsCalculation(
        {
          ...state,
          clipDuration: {
            ...state.clipDuration,
            value: parseInt(payload, 10)
          }
        },
        type,
        state
      );

    case UPDATE_PRESENTATION_COUNT:
    case UPDATE_PRESENTATION_COUNT_COMPLETED:
      const newPresentationCount = parseInt(payload, 10);
      const { stopValue } = state.presentationCount;
      if (stopValue && newPresentationCount > stopValue) {
        return {
          ...state,
          presentationCount: {
            ...state.presentationCount,
            fix: false,
            value: stopValue
          }
        };
      }
      return monitorsCalculation(
        {
          ...state,
          presentationCount: {
            ...state.presentationCount,
            fix: false,
            value: newPresentationCount
          }
        },
        type,
        state
      );

    case UPDATE_PRESENTATION_PERIOD:
      return monitorsCalculation(
        {
          ...state,
          presentationPeriod: {
            ...state.presentationPeriod,
            [payload.direction]: payload.date
          }
        },
        type,
        state
      );

    case GET_MONITORS_ALONE.success:
      const { monitorsStandalone } = payload;

      const itemsMonitors = monitorsStandalone
        .sort((a, b) => parseInt(a.number, 10) > parseInt(b.number, 10))
        .map(({ metroStation, ...monitor }) => ({
          ...monitor,
          name: metroStation.name,
          selected: false,
          monitorsAmount: 1,
          passengerTraffic: metroStation.passengerTraffic,
          metroStationId: metroStation._id
        }))
        .filter(line => line.monitorsAmount > 0);
      return monitorsCalculation(
        {
          ...state,
          monitors: {
            ...state.monitors,
            items: itemsMonitors
          }
        },
        type,
        state
      );

    case GET_LINES.success:
      const { metroLines } = action.result.data;
      const itemsMetroLines = metroLines
        .sort((a, b) => parseInt(a.number) > parseInt(b.number))
        .map(({ name, ...line }) => ({
          ...line,
          name: name === "Калининская" ? "Калининская-Солнцевская" : name,
          selected: false
        }))
        .filter(line => line.monitorsAmount > 0);

      return monitorsCalculation(
        {
          ...state,
          mlines: {
            ...state.mlines,
            items: itemsMetroLines
          }
        },
        type,
        state
      );

    case TOGGLE_MLINE:
      return monitorsCalculation(
        {
          ...state,
          selectionBlock: !state.mlines.items.filter(item => item.selected)
            .length
            ? "mlines"
            : state.selectionBlock,
          mlines: {
            ...state.mlines,
            items: state.mlines.items.map(({ _id, selected, ...item }) => ({
              ...item,
              _id,
              selected: _id === payload ? !selected : selected
            }))
          }
        },
        type,
        state
      );

    case TOGGLE_MONITOR:
      return monitorsCalculation(
        {
          ...state,
          selectionBlock: !state.monitors.items.filter(item => item.selected)
            .length
            ? "monitors"
            : state.selectionBlock,
          monitors: {
            ...state.monitors,
            items: state.monitors.items.map(({ _id, selected, ...item }) => ({
              ...item,
              _id,
              selected:
                payload === "all"
                  ? true
                  : _id === payload
                  ? !selected
                  : selected
            }))
          }
        },
        type,
        state
      );

    case HOVER_ROUTE:
      return {
        ...state,
        districts: state.districts.map(({ _id, ...district }) => ({
          ...district,
          _id,
          underline: payload
            ? payload.find(item => item._id === _id)
              ? true
              : false
            : false
        }))
      };

    case SELECT_DISTRICT:
      return monitorsCalculation(
        {
          ...state,
          selectionBlock: !state.routes.items.filter(item => item.selected)
            .length
            ? "routes"
            : state.selectionBlock,
          routes: {
            ...state.routes,
            items: state.routes.items.map(item => ({
              ...item,
              hide:
                payload === "allDisticts"
                  ? false
                  : item.districts.find(district => district._id === payload)
                  ? false
                  : true
            }))
            // payload === "allDisticts"
            //   ? state.routes.baseItems
            //   : state.routes.items.filter(item =>
            //       item.districts.find(district => district._id === payload)
            //     ) || []
          },
          districts: state.districts.map(({ selected, _id, ...district }) => ({
            ...district,
            _id,
            selected: _id === payload ? true : false
          }))
        },
        type,
        state
      );

    case TOGGLE_ROUTE:
      return monitorsCalculation(
        {
          ...state,
          selectionBlock: !state.routes.items.filter(item => item.selected)
            .length
            ? "routes"
            : state.selectionBlock,
          routes: {
            ...state.routes,
            items: state.routes.items.map(
              ({ _id, selected, touched, ...item }) => ({
                ...item,
                _id,
                selected:
                  payload === "all"
                    ? true
                    : _id === payload
                    ? !selected
                    : selected,
                touched:
                  payload === "all"
                    ? true
                    : _id === payload
                    ? !touched
                    : touched
              })
            )
          },
          districts: state.districts.map(({ count, _id, ...district }) => {
            const route = state.routes.items.find(item => item._id === payload);
            return {
              ...district,
              _id,
              count:
                payload === "all"
                  ? 0
                  : route
                  ? route.districts.reduce((acc, dist) => {
                      if (dist._id === _id && !route.selected) {
                        acc += 1;
                      } else if (dist._id === _id && route.selected) {
                        acc -= 1;
                      }
                      return acc;
                    }, count)
                  : count
            };
          })
        },
        type,
        state
      );

    case SELECT_PRESENTATION_TIME:
      return monitorsCalculation(
        {
          ...state,
          selectionBlock: !state.presentationTime.items.filter(
            item => item.selected
          ).length
            ? "presentationTime"
            : state.selectionBlock,
          presentationTime: {
            ...state.presentationTime,
            items: state.presentationTime.items.map(item => {
              const { _id, selected } = item;
              const { currentId, isMouseLeave } = payload;
              const isToggled = selected ? true : false;

              return {
                ...item,
                hovered: isMouseLeave ? false : isToggled,
                selected: _id === currentId ? !selected : selected
              };
            })
          }
        },
        type,
        state
      );

    case HOVER_PRESENTATION_TIME:
      return monitorsCalculation(
        {
          ...state,
          selectionBlock: !state.presentationTime.items.filter(
            item => item.selected
          ).length
            ? "presentationTime"
            : state.selectionBlock,
          presentationTime: {
            ...state.presentationTime,
            items: state.presentationTime.items.map(item => {
              const { _id } = item;
              const { isMouseLeave, currentId } = payload;
              const isToggled = _id === currentId;

              return {
                ...item,
                hovered: isMouseLeave ? false : isToggled
              };
            })
          }
        },
        type,
        state
      );

    // ВЫБОР ПРАЙМТАЙМА С РЭЙНДЖЕМ
    // case SELECT_PRESENTATION_TIME:
    // case HOVER_PRESENTATION_TIME:
    //   return monitorsCalculation(
    //     {
    //       ...state,
    //       selectionBlock: !state.presentationTime.items.filter(item => item.selected).length
    //         ? 'presentationTime'
    //         : state.selectionBlock,
    //       presentationTime: {
    //         ...state.presentationTime,
    //         items: state.presentationTime.items.map(item => {
    //           const { _id, selected } = item;
    //           const { lastId, nextId, isMouseLeave, isHover } = payload;
    //           const isToggled = _id >= lastId && _id <= nextId;

    //           return {
    //             ...item,
    //             hovered: isMouseLeave ? false : isToggled,
    //             selected: isHover ? selected : isToggled
    //           };
    //         })
    //       }
    //     },
    //     type,
    //     state
    //   );

    case TOGGLE_PRESENTATION_FREQUENCY:
      return monitorsCalculation(
        {
          ...state,
          selectionBlock: !state.presentationFrequency.items.filter(
            item => item.selected
          ).length
            ? "presentationFrequency"
            : state.selectionBlock,
          presentationFrequency: {
            ...state.presentationFrequency,
            items: state.presentationFrequency.items.map(item => ({
              ...item,
              selected:
                item.value === parseInt(payload, 10) && !item.selected
                  ? true
                  : false
            }))
          }
        },
        type,
        state
      );

    case TOGGLE_DAYS_WEEK:
      const daysWeek = state.daysWeek.items.map(
        ({ value, selected, ...item }) => ({
          ...item,
          value,
          selected: value === payload.value ? !selected : selected
        })
      );

      return monitorsCalculation(
        {
          ...state,
          selectionBlock: !state.daysWeek.items.filter(item => item.selected)
            .length
            ? "daysWeek"
            : state.selectionBlock,
          daysWeek: {
            ...state.daysWeek,
            items: daysWeek
          },
          routes:
            payload.type === "tram"
              ? {
                  ...state.routes,
                  items: state.routes.items.map(item => ({
                    ...item,
                    disabled: getDisabledRoutes(item, daysWeek),
                    selected: getSelectedRoutes(item, daysWeek)
                  }))
                }
              : state.routes
        },
        type,
        state
      );
    case GET_REPORTS_MLINES.success:
      return {
        ...state,
        reportsMlines: payload.advertisingPlatforms.nodes
      };
    case GET_REPORTS_MLINES.error:
      return {
        ...state,
        reportsMlinesError: payload.response.errors
      };
    case GET_REPORTS_TIMEINTERVALS.success:
      return {
        ...state,
        reportsTimeIntervals: payload.timeIntervals.nodes
      };
    case GET_REPORTS_TIMEINTERVALS.error:
      return {
        ...state,
        reportsTimeIntervalsError: payload.response.errors
      };
    case GET_REPORTS_OTS.main:
      return {
        ...state,
        reportsOtsLoaded: false,
        reportsOtsLoading: true
      };
    case GET_REPORTS_OTS.success:
      return monitorsCalculation(
        {
          ...state,
          reportsOtsPerPeriod: payload.getMetroMonitorsOTS,
          reportsOtsLoaded: true,
          reportsOtsLoading: false
        },
        type,
        state
      );
    case GET_REPORTS_OTS.error:
      return {
        ...state,
        reportsOtsPerPeriod: [],
        reportsOtsPerPeriodError: payload,
        reportsOtsLoaded: false,
        reportsOtsLoading: false
      };
    default:
      return state;
  }
};
