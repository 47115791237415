import { takeEvery, call, put } from "redux-saga/effects";
import { CHECK_SIGN_IN_CONFIRM } from "../constants/register";
import generateSagaWorker, {
  createStartAction,
  createErrorAction,
  createSuccessAction,
  POST,
  toJson,
  encodeFormData
} from "./generateSaga";

const sagaGenerator = generateSagaWorker(put, call);

const checkConfirm = sagaGenerator({
  startAction: createStartAction(CHECK_SIGN_IN_CONFIRM.start),
  url: () => "/api/v1/public/register/checkConfirm",
  method: POST,
  getBody: encodeFormData("form"),
  getError: toJson,
  successAction: createSuccessAction(CHECK_SIGN_IN_CONFIRM.success),
  errorAction: createErrorAction(CHECK_SIGN_IN_CONFIRM.error)
});

export default function* register() {
  yield takeEvery(CHECK_SIGN_IN_CONFIRM.main, checkConfirm);
}
