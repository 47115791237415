import { useRef, useState, useEffect } from "react";
import moment from "moment";
export { default as Outsider } from "./Outsider";

export const ucFirst = str => (str ? str[0].toUpperCase() + str.slice(1) : str);

export const getAdvertiserDiscountFactor = advertiserDiscount => {
  if (advertiserDiscount && advertiserDiscount.state === "active") {
    return (100 - advertiserDiscount.discount.value) / 100;
  }

  return 1;
};

export const formAvailableDates = (advertisePlace, monthAmountCalc) => {
  const result = [];

  const daysAmountStart =
    moment().daysInMonth() - (advertisePlace === "metroStickers" ? 1 : 7);
  const daysAmountMiddle = advertisePlace === "metroStickers" ? 16 : 8;

  //Начало след месяца от текущей даты
  const startDate = moment()
    .utc()
    .startOf("M")
    .add(1, "M");
  const endDate = moment()
    .utc()
    .startOf("M")
    .add(monthAmountCalc, "M");

  const deleteStartPeriod =
    moment()
      .utc()
      .date() > daysAmountStart;
  const deleteMiddlePeriod =
    moment()
      .utc()
      .date() < daysAmountMiddle;

  const twoWeeksPeriodStartDate = moment
    .utc("2020-12-01")
    .startOf("d")
    .format();
  const twoWeeksPeriodEndDate = moment
    .utc("2021-01-01")
    .startOf("d")
    .format();

  for (
    const date = moment(startDate);
    date.isSameOrBefore(endDate);
    date.add(1, "M")
  ) {
    if (deleteStartPeriod && date.isBefore(startDate.clone().add(1, "days"))) {
      if (
        date.isSame(twoWeeksPeriodStartDate) &&
        advertisePlace === "metroStickers"
      ) {
        result.push({
          startDate: date
            .clone()
            .add(15, "days")
            .startOf("d"),
          endDate: date
            .clone()
            .endOf("M")
            .startOf("d")
        });
      } else {
        result.push({
          startDate: date
            .clone()
            .add(15, "days")
            .startOf("d"),
          endDate: date
            .clone()
            .add(14, "days")
            .add(1, "M")
            .startOf("d")
        });
      }
    } else if (
      deleteMiddlePeriod &&
      date.isBefore(startDate.clone().add(1, "days"))
    ) {
      if (
        date
          .clone()
          .subtract(1, "M")
          .isSame(twoWeeksPeriodStartDate) &&
        advertisePlace === "metroStickers"
      ) {
        result.push(
          {
            startDate: date
              .clone()
              .add(15, "days")
              .subtract(1, "M")
              .startOf("d"),
            endDate: date
              .clone()
              .subtract(1, "M")
              .endOf("M")
              .startOf("d")
          },
          {
            startDate: date.clone().startOf("d"),
            endDate: date
              .clone()
              .add(14, "days")
              .startOf("d")
          },
          {
            startDate: date.clone().startOf("d"),
            endDate: date
              .clone()
              .endOf("M")
              .startOf("d")
          },
          {
            startDate: date
              .clone()
              .add(15, "days")
              .startOf("d"),
            endDate: date
              .clone()
              .add(14, "days")
              .add(1, "M")
              .startOf("d")
          }
        );
      } else {
        result.push(
          {
            startDate: date
              .clone()
              .add(15, "days")
              .subtract(1, "M")
              .startOf("d"),
            endDate: date
              .clone()
              .add(14, "days")
              .startOf("d")
          },
          {
            startDate: date.clone().startOf("d"),
            endDate: date
              .clone()
              .endOf("M")
              .startOf("d")
          },
          {
            startDate: date
              .clone()
              .add(15, "days")
              .startOf("d"),
            endDate: date
              .clone()
              .add(14, "days")
              .add(1, "M")
              .startOf("d")
          }
        );
      }
    } else {
      if (
        date.isSame(twoWeeksPeriodEndDate) &&
        advertisePlace === "metroStickers"
      ) {
        result.push(
          {
            startDate: date.clone().startOf("d"),
            endDate: date
              .clone()
              .add(14, "days")
              .startOf("d")
          },
          {
            startDate: date.clone().startOf("d"),
            endDate: date
              .clone()
              .endOf("M")
              .startOf("d")
          },
          {
            startDate: date
              .clone()
              .add(15, "days")
              .startOf("d"),
            endDate: date
              .clone()
              .add(14, "days")
              .add(1, "M")
              .startOf("d")
          }
        );
      } else {
        result.push(
          {
            startDate: date.clone().startOf("d"),
            endDate: date
              .clone()
              .endOf("M")
              .startOf("d")
          },
          {
            startDate: date
              .clone()
              .add(15, "days")
              .startOf("d"),
            endDate: date
              .clone()
              .add(14, "days")
              .add(1, "M")
              .startOf("d")
          }
        );
      }
    }
  }

  return result;
};

export const datesMultiCalculator = ({
  campaignStartDate,
  isDisplayNextYear
}) => {
  const result = [];

  const startDate = moment()
    .utc()
    .startOf("Y")
    .startOf("d");
  const endDate = moment()
    .add(isDisplayNextYear ? 1 : 0, "year")
    .utc()
    .endOf("Y")
    .startOf("d");

  for (
    const date = moment(startDate);
    date.isSameOrBefore(endDate);
    date.add(1, "M")
  ) {
    result.push(
      {
        label: date
          .clone()
          .format("MMM")
          .substr(0, 3),
        startDate: date.clone().startOf("M"),
        endDate: date.clone().add(14, "days")
      },
      {
        label: date
          .clone()
          .format("MMM")
          .substr(0, 3),
        startDate: date.clone().add(15, "days"),
        endDate: date.clone().endOf("M")
      }
    );
  }

  return result;
};

export const useRefState = initialValue => {
  const [state, setState] = useState(initialValue);
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  return [state, stateRef, setState];
};

export const formAvailableDatesCity = monthAmountCalc => {
  const result = [];

  const startDate = moment()
    .utc()
    .startOf("M")
    .startOf("d");
  const endDate = moment()
    .utc()
    .startOf("M")
    .startOf("d")
    .add(monthAmountCalc, "M");

  for (const date = startDate; date.isSameOrBefore(endDate); date.add(1, "M")) {
    result.push(
      {
        startDate: date.clone(),
        endDate: date.clone().endOf("M"),
        id: date.clone().format("MMMM-YYYY")
      },
      {
        startDate: date.clone(),
        endDate: date.clone().add(14, "days"),
        id: date.clone().format("MMMM-YYYY")
      },
      {
        startDate: date.clone().add(15, "days"),
        endDate: date.clone().endOf("M"),
        id: date
          .clone()
          .add(15, "days")
          .format("MMMM-YYYY")
      },
      {
        startDate: date.clone().add(15, "days"),
        endDate: date
          .clone()
          .add(14, "days")
          .add(1, "M"),
        id: date
          .clone()
          .add(15, "days")
          .format("MMMM-YYYY")
      }
    );
  }

  return result.filter(res => moment().isSameOrBefore(res.startDate));
};

export const formAvailableDatesLightCalculator = (
  monthAmountCalc,
  campaign
) => {
  const result = [];

  const preparedStartDate =
    campaign && moment(campaign.startDate).isBefore(moment())
      ? moment(campaign.startDate)
      : moment();

  const startDate = preparedStartDate
    .utc()
    .startOf("M")
    .startOf("d");
  const endDate = moment()
    .utc()
    .startOf("M")
    .startOf("d")
    .add(monthAmountCalc, "M");

  for (const date = startDate; date.isSameOrBefore(endDate); date.add(1, "M")) {
    result.push(
      {
        startDate: date.clone(),
        endDate: date.clone().endOf("M"),
        id: date.clone().format("MMMM-YYYY")
      },
      // {
      //   startDate: date.clone(),
      //   endDate: date.clone().add(14, "days"),
      //   id: date.clone().format("MMMM-YYYY")
      // },
      // {
      //   startDate: date.clone().add(15, "days"),
      //   endDate: date.clone().endOf("M"),
      //   id: date
      //     .clone()
      //     .add(15, "days")
      //     .format("MMMM-YYYY")
      // },
      {
        startDate: date.clone().add(15, "days"),
        endDate: date
          .clone()
          .add(14, "days")
          .add(1, "M"),
        id: date
          .clone()
          .add(15, "days")
          .format("MMMM-YYYY")
      }
    );
  }

  return result.filter(res => moment().isSameOrBefore(res.startDate));
};
