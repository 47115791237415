import styled from "styled-components";

export const Page = styled.div`
  padding-bottom: 4rem;
`;

export const PageInner = styled.div`
  padding: 0 20px;
`;

export const Bakcground = styled.div`
  background-color: #fff;
  padding-bottom: 2rem;
`;

export const Row = styled.div`
  display: flex;
  margin-top: 4rem;
`;

export const Col = styled.div`
  flex-basis: 50%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  position: relative;
`;

export const BackWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

export const BackArrowWrapper = styled.div``;

export const BackButton = styled.a`
  color: black;
  text-decoration: none;
  display: flex;
  border: none;
  align-items: center;
  background: transparent;
  & > ${BackArrowWrapper} {
    transition: transform 300ms;
  }
  &:hover {
    & > ${BackArrowWrapper} {
      transform: translateX(-10px);
    }
  }
`;

export const BackArrow = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid transparent;

  border-top-color: #1044a0;
  border-left-color: #1044a0;
  transform: rotateZ(314deg);
`;

export const MainTitle = styled.div`
  text-transform: uppercase;
  margin-top: 2rem;
  font-weight: 900;
  font-size: 2rem;
  text-align: left;
`;

export const MainDescription = styled.div`
  text-transform: uppercase;
  margin-top: 2rem;
  text-align: left;

  font-size: 1.1rem;
  font-weight: 400;
`;

export const HeadTitle = styled.div`
  font-size: 1.6rem;
  text-align: center;
  text-transform: uppercase;
`;

export const Description = styled.div`
  font-size: 1.2rem;
  & > p {
    margin-bottom: 1rem;
  }
`;

export const ListButtonDoc = styled.div`
  margin-top: 2rem;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

export const ButtonDoc = styled.button`
  display: block;
  border: none;
  background-color: #fff;
  padding: 0.5rem 1rem;
  margin: 0 1rem 1rem;
  border: 1px solid #1044a0;
  text-decoration: none;
  color: #000;
  border-radius: 5px;
  transition: background-color 300ms;

  &:hover {
    color: white;
    background-color: #1044a0;
  }
`;

export const ModalStyle = {
  overlay: {
    display: "flex",
    justifyContent: "center",
    overflow: "auto",
    background: "rgba(0, 18, 69, 0.75)",
    alignItems: "center"
  },
  content: {
    border: "unset",
    borderRadius: "2rem",
    inset: "unset",
    padding: 50,
    width: "100%",
    maxWidth: "min-content"
  }
};

export const BackgroundImage = styled.div`
  height: 300px;
  background: url(/assets/lightbox-photo.jpg);
  background-size: cover;
  margin: 0rem 2rem;
  border-radius: 1rem;
`;

export const PriceInfo = styled.div`
  text-align: center;
  margin: 1rem 0rem 3rem;
`;
