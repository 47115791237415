import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Authorization from "../Authorization";
import Menu from "../Menu";
import ActionPage from "../ActionPage";

import { getTokenPh } from "utils/auth";

import { whoAmI } from "store/actions/photoReports";

import { StyledBlock } from "./styled";

const Routes = props => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ photoReports }) => photoReports);
  const loggedIn = getTokenPh();

  useEffect(() => {
    if (loggedIn && !user) {
      dispatch(whoAmI());
    }
  }, [dispatch, loggedIn, user]);

  return (
    <StyledBlock>
      <Switch>
        <Route exact path={"/"} component={Authorization} />
        {loggedIn && (
          <>
            {!user ? (
              <div />
            ) : (
              <BrowserRouter>
                <div>
                  <Route exact path={"/action-route"} component={ActionPage} />
                  <Route exact path={"/menu"} component={Menu} />
                  {/*<Redirect to="/menu" />*/}
                </div>
              </BrowserRouter>
            )}
          </>
        )}
        <Redirect to="/" />
      </Switch>
    </StyledBlock>
  );
};

export default Routes;
