import React, { Component } from "react";
import password_show from "../../img/see_password.svg";
import password_changed from "../../img/password_changed.svg";
import password_hide from "../../img/hide_password.svg";
import {
  ModalInputGroup,
  ModalRow,
  ModalCol,
  ModalButton,
  ModalHr
} from "../../components/Modal/styled";
import PropTypes from "prop-types";
import { resetConfirm, restoreConfirm } from "../../store/actions/auth";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Modal from "../../components/Modal";
import { ErrorInput } from "../Layout/form";

class RestoreConfirm extends Component {
  static propTypes = {
    emailError: PropTypes.object,
    match: PropTypes.object,
    resetConfirm: PropTypes.func,
    restoreConfirm: PropTypes.func,
    successConfirm: PropTypes.bool
  };

  state = {
    model: {
      password: "",
      passwordRepeat: ""
    },
    validation: {},
    type: "password",
    error: ""
  };

  componentWillUnmount() {
    this.props.resetConfirm();
  }

  passwordToggle = () => {
    this.setState({
      type: this.state.type === "input" ? "password" : "input"
    });
  };

  updater = (prop, key = "value", condition = x => x) => ({ target }) => {
    const value = condition(target[key]);
    this.setState(({ model }) => ({
      model: {
        ...model,
        [prop]: typeof value === "string" ? value.replace(/^\s+/, "") : value
      }
    }));
  };

  validatePassword = password => {
    this.setState(({ validation }) => ({
      validation: {
        ...validation,
        password: !password || password.trim().length < 6
      }
    }));
    return password;
  };

  updatePassword = this.updater("password", "value", this.validatePassword);
  updateSecondPassword = this.updater(
    "passwordRepeat",
    "value",
    this.validatePassword
  );

  setPassword = () => {
    if (this.state.model.password.trim().length < 6) {
      return this.setState({ error: "Минимальная длина пароля 6 символов" });
    }
    if (this.state.model.password === this.state.model.passwordRepeat) {
      this.setState({ error: "" });
      this.props.restoreConfirm({
        password: this.state.model.password,
        token: this.props.match.params.token
      });
    } else {
      this.setState({ error: "Введенные пароли не совпадают!" });
    }
  };

  isInvalid = () =>
    Object.keys(this.state.model).some(
      x => !this.state.model[x]
    ) /* ||
    Object.keys(this.state.validation).some(x => this.state.validation[x])*/;

  getPasswordTooltip = () =>
    this.state.validation.password
      ? "Минимальная длина пароля 6 символов"
      : "Введите пароль";

  getPasswordShowToolTip = () =>
    this.state.type === "input" ? "Спрятать пароль" : "Показать пароль";

  showErrors = () => {
    return this.state.error;
  };

  render() {
    if (!this.props.match.params.token) {
      return <Redirect to="/login" />;
    }
    return (
      <Modal onCloseRedirect="/login">
        {!this.props.successConfirm ? (
          <div>
            <ModalRow>
              <ModalCol>
                <h1 className="inner-title">Восстановление пароля</h1>
                <ModalHr />
                <ModalInputGroup className="password">
                  <label htmlFor="">Новый пароль</label>
                  <input
                    type={this.state.type}
                    placeholder="Введите Пароль…"
                    onChange={this.updatePassword}
                  />
                  <span>
                    <img
                      onClick={this.passwordToggle}
                      src={
                        this.state.type === "input"
                          ? password_hide
                          : password_show
                      }
                      alt="password"
                      title={this.getPasswordShowToolTip()}
                    />
                  </span>
                  <ErrorInput className="error-text">
                    {this.getPasswordTooltip()}
                  </ErrorInput>
                </ModalInputGroup>
                <ModalInputGroup className="password">
                  <label htmlFor="">Продублируйте пароль</label>
                  <input
                    type={this.state.type}
                    placeholder="Продублируйте пароль…"
                    onChange={this.updateSecondPassword}
                  />
                  <span>
                    <img
                      onClick={this.passwordToggle}
                      src={
                        this.state.type === "input"
                          ? password_hide
                          : password_show
                      }
                      alt="password"
                      title={this.getPasswordShowToolTip()}
                    />
                  </span>
                  <ErrorInput className="error-text">
                    {this.getPasswordTooltip()}
                  </ErrorInput>
                </ModalInputGroup>
              </ModalCol>
            </ModalRow>
            <ErrorInput style={{ display: "block" }}>
              {this.showErrors()}
            </ErrorInput>
            <ModalRow className="center">
              <ModalButton
                type="button"
                onClick={this.setPassword}
                disabled={this.isInvalid()}
              >
                Сохранить
              </ModalButton>
            </ModalRow>
          </div>
        ) : (
          <div>
            <ModalRow className="center">
              <ModalCol className="center">
                <img src={password_changed} alt="" />
                <h1>Пароль был изменён</h1>
                <ModalHr />
                <p>
                  Теперь Вы можете{" "}
                  <Link to={"/login"}>войти в свой аккаунт</Link>
                </p>
              </ModalCol>
            </ModalRow>
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  emailError: auth.emailValidationError,
  successConfirm: auth.successConfirm
});
const mapDispatchToProps = {
  restoreConfirm,
  resetConfirm
};
export default connect(mapStateToProps, mapDispatchToProps)(RestoreConfirm);
