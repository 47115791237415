import generateTypes from './constantsGenerator';

export const GET_PACKAGES = generateTypes('GET_PACKAGES');
export const GET_PACKAGES_FOR_MIN_PRICE = generateTypes('GET_PACKAGES_FOR_MIN_PRICE');

export const GET_PACKAGES_FORMATS = generateTypes('GET_PACKAGES_FORMATS');

export const GET_PACKAGES_SIZES = generateTypes('GET_PACKAGES_SIZES');

export const POST_ADVERTISE_PACKAGES_SCHEDULE = generateTypes('POST_ADVERTISE_PACKAGES_SCHEDULE');
