import styled from 'styled-components';

export const Title = styled.div`
  font-size: 32px;
  line-height: 1;
  font-weight: 600;
  color: #000;
  text-align: center;
`;

export const Line = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  background: #e9e9e9;
  height: 1px;
`;

export const List = styled.div`
  ${({ showScroll }) =>
    showScroll
      ? `
		height: 200px;
		overflow-y: scroll;
	`
      : ''}
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Link = styled.a`
  color: #29aff8;
  font-size: 16px;
  margin-bottom: 20px;
  text-decoration: underline;
`;
