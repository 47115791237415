import React from 'react';
import './styles.css';

export const MobileDummy = () => (
  <div className="mobile">
    <div className="mobile__logo">
      <img src={'/assets/logo.svg'} alt="NEBO Digital" />
    </div>
    <h1 className="mobile__header">
      Официальный оператор <br />
      информационно-рекламного сервиса метро
    </h1>
    <p>
      Мобильная версия на данный момент недоступна. <br />
      Для того, чтобы воспользоваться сервисом используйте персональный компьютер.
    </p>
  </div>
);
