import React from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";

import {
  StyledInputField,
  StyledLabel,
  StyledInput,
  StyledError
} from "./styled";

const InputField = ({
  label,
  defaultValue,
  onChange,
  authStyle,
  error,
  ...props
}) => {
  return (
    <StyledInputField authStyle={authStyle}>
      <StyledInput>
        <StyledLabel isInputEmpty={!defaultValue} authStyle={authStyle}>
          {label}
        </StyledLabel>
        <InputMask
          onChange={onChange}
          defaultValue={defaultValue}
          className={error && "login-err"}
          {...props}
        />
        {error && <StyledError>{error}</StyledError>}
      </StyledInput>
    </StyledInputField>
  );
};

InputField.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default InputField;
