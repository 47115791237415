import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MainLoader } from './styled';

export default class Loader extends Component {
  static propTypes = {
    isInline: PropTypes.bool,
    size: PropTypes.string,
    theme: PropTypes.string
  };

  static getSizeFromPreset(size) {
    switch (size) {
      case 'xs':
        return '2px';
      case 'sm':
        return '4px';
      case 'md':
        return '6px';
      case 'lg':
        return '10px';
      case 'xl':
        return '14px';
      default:
        return '4px';
    }
  }

  constructor(props) {
    super();

    this.state = {
      size: Loader.getSizeFromPreset(props.size),
      isInline: props.isInline ? true : false
    };
  }

  render() {
    let cssClass = this.state.isInline ? 'inline' : '';
    cssClass += this.props.theme ? ` ${this.props.theme}` : '';

    return <MainLoader style={{ fontSize: this.state.size }} className={cssClass} />;
  }
}
