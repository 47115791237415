import createReducer from "./createReducer";
import { SET_NAME_BANNER } from "../constants/abtest";
import { getCookie } from "store/helpers/cookies";

const initialState = () => {
  const bannerName = getCookie("bannerId") || null;

  return {
    bannerName
  };
};

const consumers = {
  [SET_NAME_BANNER.main]: (state, { name }) => ({
    ...state,
    bannerName: name
  })
};

export default createReducer(initialState(), consumers);
