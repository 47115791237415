import styled from "styled-components";

import { BodyContainer, MainButton } from "../Layout";

export const StyledBodyContainer = styled(BodyContainer)`
  background: radial-gradient(
    94.49% 94.49% at 79.83% 96.5%,
    #12b8ff 0%,
    rgba(18, 184, 255, 0.81) 100%
  );
  backdrop-filter: blur(113px);
`;

export const StyledBackButton = styled.div`
  letter-spacing: 0.33px;
  color: #ffffff;
  font-size: 1.5rem;
  margin: 24px 0;
  cursor: pointer;
  transition: all 300ms;
  position: relative;

  img {
    margin-right: 8px;
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const StyledStopInfo = styled.div`
  font-size: 0.75rem;
  color: #ffffff;
  margin-bottom: 16px;

  span {
    vertical-align: 6px;
  }

  img {
    margin-right: 8px;
  }
`;

export const StyledBlock = styled.div`
  margin: 16px 0;
`;

export const StyledEnterButton = styled(MainButton)`
  margin: 32px 0 56px 0;
  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.3;
      cursor: not-allowed;
  `}
`;

export const StyledLoadFile = styled.label`
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  input {
    display: none;
  }
`;
