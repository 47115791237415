import styled from "styled-components";

export const StyledLogo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  ${({ history }) => history && "cursor: pointer"};

  img {
    width: ${({ logoWidth }) => (logoWidth ? `${logoWidth}px` : "148px")};
    margin: ${({ margin }) => (margin ? margin : "96px 0 160px 0")};
  }
`;
