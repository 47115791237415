import { takeEvery, call, put } from "redux-saga/effects";
import generateSagaWorker, {
  get,
  postJson,
  post,
  generateSagaWorkerSaveBlobFile
} from "./generateSaga";
import {
  GET_FORMATS,
  POST_LIGHTBOXES_SCHEDULE,
  GET_SEASON_DISCOUNT,
  GET_LIGHTBOX,
  GET_LIGHTBOX_REPORTS_IMAGES,
  GET_LIGHT_BOXES_MEDIAPLAN
} from "../constants/lightboxes";

const sagaGenerator = generateSagaWorker(put, call);
const sagaGeneratorSaveFile = generateSagaWorkerSaveBlobFile(put, call);

const getLightboxReportsImages = get(
  sagaGenerator,
  action =>
    `/api/v1/public/light-boxes/${action.form.lightBoxId}/reportsImages`,
  GET_LIGHTBOX_REPORTS_IMAGES.types
);

const getLightbox = get(
  sagaGenerator,
  action => `/api/v1/public/light-boxes/${action.form.boxId}`,
  GET_LIGHTBOX.types
);

const getLightboxFormats = get(
  sagaGenerator,
  () =>
    "/api/v1/public/data-formats?isActive=true&offset=0&limit=100&advertisePlaceId=lightBoxes",
  GET_FORMATS.types
);

const postLightboxesSchedule = postJson(
  sagaGenerator,
  () => "/api/v1/public/commercial-campaigns/lightboxes/schedule",
  POST_LIGHTBOXES_SCHEDULE.types
);

const getSeasonalDiscount = post(
  sagaGenerator,
  () => "/api/v1/seasonal-discounts/getDiscountRationMultiDays",
  GET_SEASON_DISCOUNT.types
);

const getLightBoxesMediaplan = postJson(
  sagaGeneratorSaveFile,
  () => "/api/v1/public/media-plan/lightBoxes",
  GET_LIGHT_BOXES_MEDIAPLAN.types
);

export default function* lightboxes() {
  yield takeEvery(GET_LIGHTBOX.main, getLightbox);
  yield takeEvery(GET_FORMATS.main, getLightboxFormats);
  yield takeEvery(POST_LIGHTBOXES_SCHEDULE.main, postLightboxesSchedule);
  yield takeEvery(GET_SEASON_DISCOUNT.main, getSeasonalDiscount);
  yield takeEvery(GET_LIGHTBOX_REPORTS_IMAGES.main, getLightboxReportsImages);
  yield takeEvery(GET_LIGHT_BOXES_MEDIAPLAN.main, getLightBoxesMediaplan);
}
