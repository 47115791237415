import styled, { keyframes, css } from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "../Layout";
import { colors } from "../../theme";

export const CLOSE_ICON_TYPE = {
  DEFAULT: "default",
  BIG_GRAY: "big_gray"
};

const ModalToggle = keyframes`
  from {opacity: 0; transform: scale(0.5);}
  to {opacity: 1; transform: scale(1);}
`;

const MODAL_PADDING = {
  TOP: 2.5,
  RIGHT: 4.375,
  BOTTOM: 1.5625,
  LEFT: 4.375
};

const ICON_PROPERTIES = {
  [CLOSE_ICON_TYPE.DEFAULT]: css`
    top: -2.6125rem;
    right: 0;
    height: 1.5rem;
    width: 1.5rem;
  `,
  [CLOSE_ICON_TYPE.BIG_GRAY]: css`
    top: 2.5rem;
    right: 2.5rem;
    height: 1.875rem;
    width: 1.875rem;
  `
};

export const ModalWindowScroller = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  pointer-events: none;
`;

export const ModalWindowWrapper = styled.div`
  ${({ fullModal }) =>
    !fullModal &&
    `
  padding: 4rem 1rem;
`}
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.4);
  & > .background {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 2rem;
    z-index: 999;
  }
`;

export const ModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 2rem;
  z-index: 998;
`;

export const ModalWindowBackground = styled.a`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0.4;
  background-color: #000;
  z-index: 1000;
`;

export const PhotoCarusel = styled.a`
  padding: 19.5rem 4.375rem 1.5625rem 4.375rem;
  width: 31.25rem;
  background: gray;
`;

export const ModalWindow = styled.div`
  ${({ fullModal }) => fullModal && `width: 100%;`}
  position: relative;
  z-index: 1000;
  transition: all 0.3s;
  pointer-events: all;
  background-color: ${props => props.lightBox || "#fff"};
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  animation: ${ModalToggle} 0.2s linear;
`;

export const ModalContainer = styled.div`
  ${({ fullModal }) =>
    !fullModal &&
    `
  padding: ${MODAL_PADDING.TOP}rem ${MODAL_PADDING.RIGHT}rem
    ${MODAL_PADDING.BOTTOM}rem ${MODAL_PADDING.LEFT}rem;
  width: 31.25rem;
`}

  &.wide {
    width: 33.75rem;
  }

  &.lite {
    width: 38.75rem;
  }

  &.no-paddding {
    padding: unset;
  }

  &.auto {
    width: auto;
  }

  &.prime {
    width: 70vw;
  }
  &.prime > img {
    width: 100%;
  }

  &.image {
    padding: 0;
    width: auto;
    max-width: 80vw;

    img {
      display: block;
      max-width: 100%;
      max-height: 88vh;
    }
  }

  & .modal-h {
    font-size: 1.875rem;
    font-weight: 300;
  }

  & .tabs-link {
    position: relative;
    // border-bottom: 1px solid #e0e3e5;
    display: flex;
    margin-bottom: 2.5rem;
    justify-content: space-between;
  }

  & .tabs-link a,
  & .tabs-link .tab {
    opacity: 0.23;
    color: #04143a;
    z-index: 1;
    padding-bottom: 1.25rem;
    font-size: 1.875rem;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-decoration: none;
  }

  & .tabs-link a.active,
  & .tabs-link .tab.active {
    opacity: 1;
    border-bottom: solid 3px ${colors.primary};
  }

  & .tabs-link hr {
    position: absolute;
    width: 100%;
    bottom: 1px;
    margin: 0;
    z-index: 0;
    border: 0;
    border-top: 1px solid #e0e3e5;
  }
`;

export const ModalClose = styled.div`
  position: absolute;
  ${({ iconType }) => ICON_PROPERTIES[iconType]};
  cursor: pointer;
  z-index: 9999;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const ModalInputGroup = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;

  & span {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    align-items: center;
    right: 1.25rem;
  }

  & span img {
    cursor: pointer;
  }

  & input {
    border-radius: 4px;
    border: solid 1px #e0e3e5;
    max-height: 3.25rem;
    width: 100%;
    padding: 1.5rem 2.375rem 0.75rem 1.5rem;
    font-size: 0.875rem;
    line-height: normal;

    &::placeholder {
      opacity: 0.5;
      font-size: 0.875rem;
      font-weight: 300;
    }
  }

  & textarea {
    border-radius: 4px;
    border: solid 1px #e0e3e5;
    max-height: 6rem;
    width: 100%;
    padding: 1.5rem 2.375rem 0.75rem 1.5rem;
    font-size: 0.875rem;
    line-height: normal;

    &::placeholder {
      opacity: 0.5;
      font-size: 0.875rem;
      font-weight: 300;
    }
  }

  & label {
    position: absolute;
    top: 0.625rem;
    left: 1.5rem;
    font-size: 0.625rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    opacity: 0.75;
  }

  &.error input {
    border-color: #d63a26;
  }

  &.error label {
    opacity: 0.9;
    color: #d63a26;
  }

  &.error span,
  &.password span,
  &.error .error-text {
    display: flex;
  }
`;
export const ModalValid = styled.div`
position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  &.photo{
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
  }

  & span {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    align-items: center;
    right: 1.25rem;
  }

  & span img {
    cursor: pointer;
    height: 11px;
    width: 11px;
  }

  & input {
    border-radius: 4px;
    border: solid 1px #e0e3e5;
    max-height: 3.25rem;
    width: 100%;
    padding: 1.5rem 2.375rem 0.75rem 1.5rem;
    font-size: 0.875rem;
    line-height: normal;

    &::placeholder {
      opacity: 0.5;
      font-size: 0.875rem;
      font-weight: 300;
    }
  }

  & textarea {
    border-radius: 4px;
    border: solid 1px #e0e3e5;
    max-height: 6rem;
    width: 100%;
    padding: 1.5rem 2.375rem 0.75rem 1.5rem;
    font-size: 0.875rem;
    line-height: normal;

    &::placeholder {
      opacity: 0.5;
      font-size: 0.875rem;
      font-weight: 300;
    }
  }

  & label {
    position: absolute;
    top: 0.625rem;
    left: 1.5rem;
    font-size: 0.625rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    opacity: 0.75;
  }

  &.error input {
    border-color: #d63a26;
  }

  &.error label {
    opacity: 0.9;
    color: #d63a26;
  }

  &.error span, &.password span, &.error .error-text {
    display: flex;
  }
`;
export const ModalInputGroupWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

export const ModalWarnEma = styled.div`
  width: 12%;
  display: inline-grid;
  position: relative;
`;

export const ModalWarnStr = styled.div`
  width: 12%;
  display: inline-grid;
  position: relative;
`;

export const MainFormGroup = styled.label`
  position: relative;
  margin-bottom: 1.5rem;

  & label {
    font-size: 1.125rem;
    font-weight: 300;

    top: 0;
    left: 0;
    position: absolute;
    transform: translate(0, 8px) scale(1);
  }

  & input {
    font-size: 1.125rem;
    border: 0;
    border-bottom: 1px solid #e0e3e5;
    padding: 10px 0;
    width: 100%;
    font-weight: 400;
    font-family: Circe;
  }

  & textarea {
    font-size: 1.125rem;
    border: 0;
    border-bottom: 1px solid #e0e3e5;
    padding: 10px 0;
    width: 100%;
    resize: none;
    font-weight: 300;
    font-family: Circe;
  }

  &.active label {
    transform: translate(0, -5px) scale(1);
    font-size: 0.875rem;
  }

  &.active input {
    border-bottom: solid 2px #0287c4;
  }

  &.checkbox {
    display: block;
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 0;
    padding-top: 0.25rem;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 300;

    & input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      width: 0;
      height: 0;
    }

    & span {
      position: absolute;
      top: 0.25rem;
      left: 0;
      height: 1.125rem;
      width: 1.125rem;
      background: #fff;
      border-radius: 2px;
      border: solid 1px #e0e3e5;
    }

    &:hover input ~ span,
    & input:focus ~ .span {
      background: #cccccc;
    }

    & input:checked ~ span {
      background: ${colors.primary};
    }

    & input:focus ~ span {
      outline: -webkit-focus-ring-color auto 5px;
    }

    &:hover input:not([disabled]):checked ~ span,
    & input:checked:focus ~ span {
      background: ${colors.primary};
    }

    & input:disabled ~ span {
      background: #e6e6e6;
      opacity: 0.6;
      pointer-events: none;
    }

    & span:after {
      box-sizing: unset;
      content: "";
      position: absolute;
      display: none;
    }

    & input:checked ~ span:after {
      display: block;
    }

    & span:after {
      left: 0.375rem;
      top: 0.0625rem;
      width: 0.1875rem;
      height: 0.5rem;
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    & input:disabled ~ span:after {
      border-color: #7b7b7b;
    }
  }
`;

export const ModalRow = styled.div`
  display: flex;
  flex-direction: row;

  &.center {
    justify-content: center;
  }
`;

export const ModalCol = styled.div`
  display: flex;
  flex: 1 1 100px;
  flex-direction: column;
  &.right {
    align-items: flex-end;
    justify-content: center;
    font-size: 0.875rem;
  }
  & h1 {
    font-weight: normal;
    margin: 20px 0 0 0;
  }
  & h1.inner-title {
    margin-top: 0;
  }
  &.center {
    text-align: center;
  }
  & p {
    margin-top: 0;
    font-size: 1rem;
    line-height: 1.3;
    font-weight: normal;
  }
`;

export const StyledLink = styled(Link)`
  color: ${colors.primary};
  text-decoration: none;
  font-weight: normal;
`;

export const StyledLinkDefault = styled.a`
  color: ${colors.primary};
  text-decoration: none;
  font-weight: normal;
`;

export const StyledLinkPointer = styled.a`
  color: ${colors.primary};
  text-decoration: none;
  font-weight: normal;
  cursor: pointer;
`;

export const ModalButton = styled.button`
  border-radius: 4px;
  font-size: 1rem;
  padding: 1.125rem 2.25rem;
  border: 0;
  color: #fff;
  cursor: pointer;
  background: ${colors.primary};

  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }
`;

export const ModalHr = styled.hr`
  border: 0;
  border-top: 1px solid #e0e3e5;
  margin: 25px 0;
`;

export const Title = styled.div`
  width: 460px;
  text-align: center;
  color: #000000;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: bold;

  &.no-width {
    width: unset;
  }

  &:not(:last-child) {
    padding-bottom: 40px;
    &:after {
      content: "";
      display: block;
      position: absolute;
      margin-top: 40px;
      left: 0;
      right: 0;
      height: 1px;
      background: #e9e9e9;
    }
  }
`;

export const ModalButtonGroup = styled.div`
  display: flex;
  align-items: center;

  ${Button} {
    width: 100%;
    margin: 0 0.5rem;
  }
`;
export const Body = styled.div`
  width: 460px;
  text-align: center;
  color: #4a4a4a;
  font-size: 1.125rem;
  line-height: 1.625rem;
  padding-bottom: 15px;

  p {
    margin-top: 36px;
    margin-bottom: 0;
  }

  &.no-width {
    width: unset;
  }

  ${Button}, p {
    margin-top: 20px;
    margin-bottom: 0;
  }
`;

export const Image = styled.div`
  margin: -${MODAL_PADDING.TOP}rem -${MODAL_PADDING.RIGHT}rem -${MODAL_PADDING.BOTTOM}rem -${MODAL_PADDING.LEFT}rem;

  img {
    display: block;
    border-radius: 10px;
    max-width: 62.5rem;
    max-height: 47.5rem;
  }
`;
