import styled from "styled-components";

export const StyledProfileCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -32px;
`;

export const StyledAvatar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  align-items: flex-start;

  img {
    width: 80px;
  }
`;

export const StyledProfession = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.75rem;
  color: #ffffff;
  cursor: default;
`;

export const StyledFullName = styled.div`
  display: flex;
  width: 100%;
  ffont-size: 1.5rem;
  color: #ffffff;
  cursor: default;
  letter-spacing: 0.33px;
`;
