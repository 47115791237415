import { colors } from '../index';

export default function(color) {
  let clr;

  if (colors[color]) {
    clr = colors[color].replace('#', '%23');
  }

  return `
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8">
        <path fill="none" fill-rule="evenodd" stroke="${clr}" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1l6 6 6-6"/>
      </svg>
    `;
}
