import moment from "moment";

// со скольки px начинается адаптивная верстка
export const BREAKPOINTS = {
  pages: {
    MSB_AUTH: 1024
  },
  default: 1024
};

export const advertisePlaces = {
  stickers: "metroStickers",
  metroMonitors: "metroMonitors",
  busMonitors: "busMonitors",
  lightBoxes: "lightBoxes"
};

export const otsRatio = {
  1: 0.881514849,
  2: 0.935986453,
  3: 1.057378865,
  4: 1.037076431,
  5: 0.995487868,
  6: 0.971902357,
  7: 0.914841583,
  8: 0.92611868,
  9: 1.007301833,
  10: 1.094714578,
  11: 1.068413464,
  12: 1.109263039
};

export const isRejected = stateId =>
  stateId === "primaryRejected" || stateId === "secondaryRejected";

export const calculateOts = ({ periods, stickersAmount, otsPerDay }) => {
  const otsOneDay = otsPerDay * (stickersAmount ? stickersAmount : 1);

  const periodsWithOts = periods.map(period => {
    const durationPeriod =
      moment(period.endDate).diff(period.startDate, "days") + 1;
    const periodMonth = moment(period.startDate).format("M");

    return {
      durationPeriod,
      otsOneDay,
      otsRatio: otsRatio[periodMonth],
      otsPerPeriod: durationPeriod * otsOneDay * otsRatio[periodMonth]
    };
  });

  return periodsWithOts.reduce(
    (acc, period) => Math.ceil((acc += period.otsPerPeriod)),
    0
  );
};

export const isSamePeriod = (p1, p2) =>
  p1.startDate.format("DD.MM.YYYY") === p2.startDate.format("DD.MM.YYYY") &&
  p1.endDate.format("DD.MM.YYYY") === p2.endDate.format("DD.MM.YYYY");

export const isCrossingPeriod = (p1, p2) =>
  p1.startDate.format("DD.MM.YYYY") === p2.startDate.format("DD.MM.YYYY") ||
  p1.endDate.format("DD.MM.YYYY") === p2.endDate.format("DD.MM.YYYY");

export const roundValue = value => Math.round(value * 100) / 100;

//Для медиапланов (статика)
export const rewriteSelectedItems = selectedItems => {
  //Расчет ots для пакета
  const calcOts = selectedItems.map(item => ({
    ...item,
    otsPerPckg: calculateOts(item)
  }));

  //Перерасчет поля price в periods[]
  const rewritePriceInPeriods = calcOts.map(item => ({
    ...item,
    periods: item.periods.map(period => ({
      ...period,
      price: (period.basePriceWithSeasonal * (100 - period.discount)) / 100
    }))
  }));

  //Перерасчет поля packagePrice в periods[]
  const rewritePackagePriceInPeriods = rewritePriceInPeriods.map(item => ({
    ...item,
    periods: item.periods.map(period => ({
      ...period,
      packagePrice: roundValue(
        period.price *
          (item.stickersAmount ? item.stickersAmount : 1) *
          (item.packagesAmountSh ? item.packagesAmountSh : 1)
      )
    }))
  }));

  //Перерасчет поля price в selectedItems
  const rewritePriceSelectedItems = rewritePackagePriceInPeriods.map(item => ({
    ...item,
    price: item.periods.reduce((acc, period) => acc + period.price, 0)
  }));

  //Перерасчет поля packagePrice в selectedItems
  const rewritepackagePriceSelectedItems = rewritePriceSelectedItems.map(
    item => ({
      ...item,
      packagePrice: roundValue(
        item.periods.reduce((acc, period) => acc + period.packagePrice, 0)
      )
    })
  );

  return rewritepackagePriceSelectedItems;
};

export const MSB = {
  MIN_PRICE: 5e3, // 5к
  MAX_PRICE: 16e4, // 160к

  MIN_RADIUS: 0,
  MAX_RADIUS: 4e4, // 40к в метрах, при отправке пересчитывается в км

  MIN_OTS: 0,
  MAX_OTS: 4e6 // 4 млн
};

export const DATE_PIKER = {
  WEEKDAYS_SHORT: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],

  MONTHS: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь"
  ]
};
