import moment from "moment";
import {
  GET_INVENTORY,
  GET_MAP_STATIONS,
  SELECT_INVENTORY,
  CHANGE_PAGINATION,
  SELECT_PERIOD,
  TOGGLE_AVAILABLE,
  RESET_MULTI_STATE,
  TOGGLE_POPUP,
  SET_DATE,
  SET_BUGET_RANGE,
  SET_ADDRESS,
  SET_SORT,
  SET_INVENTORY,
  SET_BASKET,
  GET_TYPOGRAPHY_LIST,
  SELECT_TYPOGRAPHY,
  GET_LIGHTBOXES_MEDIAPLAN,
  SHOW_MORE_STATION_ITEMS,
  TOGGLE_DISPLAY_STATIONS,
  FILL_BASKET,
  SET_MAP_OPEN,
  SET_ACTIVE_STATION,
  CHANGE_SIZE,
  GET_FORMATS,
  RECOVER_FILTER,
  SET_DISTANCE,
  SET_OTS,
  SUBMIT_METRO_FILTER,
  CHANGE_SELECT_METRO_LINE,
  CHANGE_SELECT_METRO_STATION,
  GET_METRO_LINE_WITH_STATION,
  RECOVER_METRO_FILTER,
  RESET_SELECT_METRO_LINE,
  RESET_SELECT_METRO_STATION,
  SET_MOBILE_FILTER,
  TOGGLE_ALL_METRO_LINE,
  TOGGLE_ALL_METRO_STATION,
  SET_ACTIVE_EXIT,
  SET_SELECTED_STATION,
  RESET_MAP_INVENTORY
} from "store/constants/multiCalculator";

import {
  getInventoryReport,
  getStation,
  getLightboxesMediaplan,
  getMapInventories,
  getFormatesGQL,
  getMetroLine,
  getMetroStations
} from "../graphql/query/multiCalculator";

import { renderSpace, removeSinglePeriods } from "utils/multiCalculator";
import { datesMultiCalculator } from "utils";

export const getPeriods = (availablePeriods, periodsOts) => {
  const newAvailablePeriods = availablePeriods.map((period, idx) => ({
    ...period,
    endDate: moment(period.endDate).format("DD-MM-YYYY"),
    startDate: moment(period.startDate).format("DD-MM-YYYY"),
    ots: periodsOts
  }));
  const maxDate = moment(
    Math.max(...availablePeriods.map(el => new Date(el.startDate)))
  );
  const isDisplayNextYear = maxDate.get("year") > moment().get("year");
  const schedulePeriods = datesMultiCalculator({ isDisplayNextYear }).map(
    period => ({
      ...period,
      endDateFormat: moment(period.endDate).format("DD-MM-YYYY"),
      startDateFormat: moment(period.startDate).format("DD-MM-YYYY")
    })
  );

  return schedulePeriods.map(period => {
    const findedPeriod = newAvailablePeriods.find(
      p =>
        p.startDate === period.startDateFormat &&
        p.endDate === period.endDateFormat
    );
    return {
      ...period,
      selected: false,
      isAvailable: !!findedPeriod,
      basePrice: findedPeriod ? findedPeriod.basePrice : 0,
      seasonalDiscount: findedPeriod ? findedPeriod.seasonalDiscount : 1,
      periodDays: moment(period.endDate).diff(period.startDate, "days") + 1,
      monthDays: moment(period.startDate).daysInMonth(),
      ots: findedPeriod ? findedPeriod.ots : 0
    };
  });
};

const getInventoryOffers = async (form, payload) => {
  if (
    !form.sorting.isDefault ||
    form.forcePage !== 0 ||
    form.activeStation?.length
  ) {
    return [];
  }

  const getOffer = async (sorting, arr) => {
    const sortItems = form.address
      ? [{ field: "distance", direction: "ASC" }, sorting]
      : [sorting];
    const offer = await getInventoryReport(
      {
        form: {
          ...form,
          sorting: {
            sortItems
          },
          offset: 0,
          limit: 3
        }
      },
      payload
    );
    const offersArr = offer?.findInventoryByCoords?.nodes;
    return (
      offersArr.find(
        i => arr.exceptionArr.indexOf(i.advertisingSpace.id) === -1
      ) || {}
    );
  };

  const accessible = await getOffer(
    {
      field: "price",
      direction: "ASC"
    },
    { exceptionArr: [] }
  );

  const passable = await getOffer(
    {
      field: "ots",
      direction: "DESC"
    },
    {
      exceptionArr: [
        ...(accessible?.advertisingSpace
          ? [accessible.advertisingSpace.id]
          : [])
      ]
    }
  );
  const optimal = await getOffer(
    {
      field: "pricePer1kContacts",
      direction: "ASC"
    },
    {
      exceptionArr: [
        ...(accessible?.advertisingSpace
          ? [accessible.advertisingSpace.id]
          : []),
        ...(passable?.advertisingSpace ? [passable.advertisingSpace.id] : [])
      ]
    }
  );

  return [
    {
      ...accessible,
      inventoryType: { text: "самый дешевый", id: "accessible" }
    },
    {
      ...passable,
      inventoryType: { text: "самый проходимый", id: "passable" }
    },
    { ...optimal, inventoryType: { text: "оптимальный", id: "optimal" } }
  ];
};

export const getMapStations = form => async dispatch => {
  dispatch({
    type: GET_MAP_STATIONS.start
  });

  getMapInventories(form)
    .then(async ({ findSpacesByPlatform }) => {
      dispatch({
        type: GET_MAP_STATIONS.success,
        payload: findSpacesByPlatform.nodes
      });
    })
    .catch(error => {
      dispatch({
        type: GET_MAP_STATIONS.error,
        payload: []
      });
      console.error(error);
    });
};

export const getInventory = (form, payload) => async dispatch => {
  dispatch({
    type: GET_INVENTORY.start
  });

  getStation(
    {
      form: {
        ...form,
        sorting: {
          ...form.sorting,
          sortItems: form.address
            ? [
                { field: "distance", direction: "ASC" },
                ...form.sorting.sortItems
              ]
            : form.sorting.sortItems
        }
      }
    },
    payload
  )
    .then(async ({ findInventoryByCoordsAndPlatform }) => {
      const inventoryOffers = await getInventoryOffers(form, payload);

      // мерджим все спейсы обратно в платформу из-за изменения модели графа
      findInventoryByCoordsAndPlatform.nodes = findInventoryByCoordsAndPlatform.nodes.map(
        node => {
          const allSpaces = [];
          node.exits.forEach(exit =>
            exit.advertisingSpaces.forEach(space => allSpaces.push(space))
          );
          return {
            advertisingPlatform: {
              ...node.exits[0].station,
              parent: {
                ...node.exits[0].station.lines[0]
              }
            },
            exits: node.exits.map(e => ({
              ...e,
              countSpaces: e.advertisingSpaces.length,
              advertisingSpaces: e.advertisingSpaces
                .filter(as => as.availablePeriods.length > 1)
                .map(space =>
                  renderSpace(space, {
                    ...node.exits[0].station,
                    parent: {
                      ...node.exits[0].station.lines[0]
                    }
                  })
                )
            })),
            advertisingSpaces: allSpaces
          };
        }
      );

      let unitedNodes = [
        ...(inventoryOffers.length
          ? [
              {
                advertisingSpaces: inventoryOffers
                  .filter(
                    space =>
                      space?.availablePeriods?.length &&
                      space.availablePeriods.length > 1
                  )
                  .map(space => {
                    return {
                      ...space,
                      advertisingSpace: {
                        ...space.advertisingSpace,
                        advertisingPlatform: {
                          name: space.advertisingSpace.metroExit.station.name,
                          parent: {
                            ...space.advertisingSpace.metroExit.station.lines[0]
                          }
                        }
                      }
                    };
                  })
              }
            ]
          : [])
      ].concat(
        findInventoryByCoordsAndPlatform.nodes.map(inv => ({
          ...inv,
          advertisingPlatform: {
            ...inv.advertisingPlatform,
            parent: {
              name: inv.advertisingPlatform.lines[0].name
            }
          },
          advertisingSpaces: inv.advertisingSpaces.filter(
            space => space.availablePeriods.length > 1
          ) // замена .hide
        }))
      );

      unitedNodes = unitedNodes.map(node => {
        const newNode = { ...node };
        const newSpaces = newNode.advertisingSpaces.map(space =>
          renderSpace(space, newNode.advertisingPlatform)
        );
        if (newNode.advertisingPlatform) {
          // показываем первые 3 ЛБ в станции
          newNode.advertisingPlatform.countDisplay =
            newNode.advertisingSpaces.length >= 4
              ? 4
              : newNode.advertisingSpaces.length;

          newNode.advertisingPlatform.idDisplayStations = false;
          newNode.advertisingPlatform.isEmptyStaton = node.advertisingSpaces.every(
            space => !space.availablePeriods
          );
        }

        newNode.advertisingSpaces = newSpaces;
        return newNode;
      });

      dispatch({
        type: GET_INVENTORY.success,
        payload: {
          newNodes: removeSinglePeriods(removeSinglePeriods(unitedNodes)), // прохоим 2 раза, на случай, если останутся одиночные после первого
          totalCount: findInventoryByCoordsAndPlatform.totalCount
        }
      });
    })
    .catch(error => {
      dispatch({
        type: GET_INVENTORY.error,
        payload: { newNodes: [], totalCount: 0 }
      });
      console.error(error);
    });
};

export const showMoreStationItems = idStation => ({
  type: SHOW_MORE_STATION_ITEMS.main,
  payload: idStation
});

export const toggleDisplayStations = idStation => ({
  type: TOGGLE_DISPLAY_STATIONS.main,
  payload: idStation
});

export const setAddress = (value, apiKey) => async dispatch => {
  dispatch({
    type: SET_ADDRESS.start
  });
  fetch(
    `https://geocode-maps.yandex.ru/1.x/?apikey=${apiKey}&format=json&geocode=${value ||
      "Москва"}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    }
  )
    .then(async res => {
      const { response } = await res.json();
      const coordsStr =
        response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos;
      const coordsArr = coordsStr.split(" ").reverse();

      dispatch({
        type: SET_ADDRESS.success,
        payload: {
          address: value,
          latitude: coordsArr[0],
          longitude: coordsArr[1]
        }
      });
    })
    .catch(error => {
      dispatch({
        type: SET_ADDRESS.error
      });
      console.error(error);
    });
};

export const setDistance = distance => ({
  type: SET_DISTANCE,
  payload: distance
});

export const setOts = ots => ({
  type: SET_OTS,
  payload: ots
});

export const getTypographyList = () => async dispatch => {
  dispatch({
    type: GET_TYPOGRAPHY_LIST.start
  });
  fetch("/api/v1/typography?isActive=true", {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(async res => {
      const response = await res.json();
      const typography = response.data.typography.find(
        tgf => tgf.name === "ООО «РПК Сфера Рекламы»"
      );

      dispatch({
        type: GET_TYPOGRAPHY_LIST.success,
        payload: typography
      });
    })
    .catch(error => {
      dispatch({
        type: GET_TYPOGRAPHY_LIST.error
      });
      console.error(error);
    });
};

export const selectInventory = (id, type) => ({
  type: SELECT_INVENTORY,
  payload: { id, type }
});

export const changePagination = () => ({
  type: CHANGE_PAGINATION
});

export const selectPeriod = period => ({
  type: SELECT_PERIOD,
  payload: period
});

export const toggleAvailable = id => ({
  type: TOGGLE_AVAILABLE,
  payload: id
});

export const resetMultiState = () => ({
  type: RESET_MULTI_STATE
});

export const togglePopup = form => ({
  type: TOGGLE_POPUP,
  payload: form
});

export const setDate = form => ({
  type: SET_DATE,
  payload: form
});

export const changeSize = ids => ({
  type: CHANGE_SIZE,
  payload: ids
});

export const setBugetRange = form => ({
  type: SET_BUGET_RANGE,
  payload: form
});

export const setSort = field => ({
  type: SET_SORT,
  payload: field
});

export const setActiveStation = inventory => ({
  type: SET_ACTIVE_STATION,
  payload: inventory
});

export const selectStation = inventory => ({
  type: SET_SELECTED_STATION,
  payload: inventory
});

export const resetMapInventory = () => ({
  type: RESET_MAP_INVENTORY
});

export const setInventory = inventory => ({
  type: SET_INVENTORY,
  payload: inventory
});

export const setBasketOpen = () => ({
  type: SET_BASKET
});

export const selectTypography = () => ({
  type: SELECT_TYPOGRAPHY
});

export const setMapOpen = value => ({
  type: SET_MAP_OPEN,
  payload: value
});

export const getLightboxesMediaplanAction = lightboxes => async dispatch => {
  dispatch({
    type: GET_LIGHTBOXES_MEDIAPLAN.start
  });
  try {
    const lightboxesWithCombinePeriod = lightboxes.map(lb => {
      const periods = lb.periods.reduce((preventPeriods, currentPeriod) => {
        // сравниваем до месяца YYYY-MM
        const alredyExistMonth = preventPeriods.findIndex(
          period =>
            period.startDate.slice(0, 7) === currentPeriod.startDate.slice(0, 7)
        );

        if (alredyExistMonth === -1) {
          preventPeriods.push(currentPeriod);
        } else {
          // если уже был, то находим min и max для startDate и endDate
          preventPeriods[alredyExistMonth] = {
            startDate: new Date(
              Math.min(
                new Date(preventPeriods[alredyExistMonth].startDate),
                new Date(currentPeriod.startDate)
              )
            ).toISOString(),
            endDate: new Date(
              Math.max(
                new Date(preventPeriods[alredyExistMonth].endDate),
                new Date(currentPeriod.endDate)
              )
            ).toISOString()
          };
        }
        return preventPeriods;
      }, []);

      return { ...lb, periods };
    });

    const res = await getLightboxesMediaplan(lightboxesWithCombinePeriod);

    if (res?.getLightboxesMediaplan?.mediaplanUrl) {
      const link = document.createElement("a");
      link.setAttribute("download", "");
      link.href = res.getLightboxesMediaplan.mediaplanUrl;
      document.body.appendChild(link);
      link.click();
      link.remove();
      dispatch({
        type: GET_LIGHTBOXES_MEDIAPLAN.success
      });
    } else {
      dispatch({
        type: GET_LIGHTBOXES_MEDIAPLAN.error
      });
    }
  } catch {
    dispatch({
      type: GET_LIGHTBOXES_MEDIAPLAN.error
    });
  }
};

export const fillBasket = (
  basket,
  busyBasket,
  ids,
  startDate,
  endDate
) => async dispatch => {
  dispatch({
    type: FILL_BASKET.start,
    payload: {
      basket,
      busyBasket,
      ids,
      startDate,
      endDate
    }
  });
};

export const removeAlredyUsePeriods = id => ({
  type: "REMOVE_ALREDY_USE_PERIODS",
  payload: id
});

export const getFormates = () => async dispatch => {
  dispatch({
    type: GET_FORMATS.start
  });

  try {
    const res = await getFormatesGQL();

    dispatch({
      type: GET_FORMATS.success,
      payload: res.formats.nodes
    });
  } catch (error) {
    dispatch({
      type: GET_FORMATS.error
    });
  }
};

export const recoverFilter = () => async dispatch => {
  dispatch({ type: RECOVER_FILTER.main });
  dispatch({ type: RECOVER_FILTER.end });
};

export const setMobileFilter = payload => ({
  type: SET_MOBILE_FILTER,
  payload
});

export const getMetroLineWithStation = () => async dispatch => {
  dispatch({
    type: GET_METRO_LINE_WITH_STATION.start
  });
  try {
    const { metroLines } = await getMetroLine();
    const { metroStations } = await getMetroStations();

    const stations = metroStations.nodes.map(node => ({
      ...node,
      selected: false,
      colors: [...node.lines.filter(line => line.color).map(line => line.color)]
    }));

    const lines = metroLines.nodes.map(node => ({
      ...node,
      selected: false,
      colors: [node.color]
    }));

    dispatch({
      type: GET_METRO_LINE_WITH_STATION.success,
      payload: { lines, stations }
    });
  } catch {
    dispatch({
      type: GET_METRO_LINE_WITH_STATION.error
    });
  }
};

export const changeSelecteMetroLine = payload => ({
  type: CHANGE_SELECT_METRO_LINE,
  payload
});
export const changeSelecteMetroStation = payload => ({
  type: CHANGE_SELECT_METRO_STATION,
  payload
});
export const resetSelectMetroLine = () => ({
  type: RESET_SELECT_METRO_LINE
});
export const resetSelectMetroStation = () => ({
  type: RESET_SELECT_METRO_STATION
});
export const toggleAllMetroLine = () => ({
  type: TOGGLE_ALL_METRO_LINE
});
export const toggleAllMetroStation = () => ({
  type: TOGGLE_ALL_METRO_STATION
});
export const submitMetroFilter = payload => dispatch => {
  dispatch({
    type: SUBMIT_METRO_FILTER.main,
    payload
  });
  setTimeout(() => {
    dispatch({
      type: SUBMIT_METRO_FILTER.end,
      payload
    });
  }, 1000);
};
export const recoverMetroFilter = () => ({
  type: RECOVER_METRO_FILTER
});
export const setActiveExit = inventory => ({
  type: SET_ACTIVE_EXIT,
  payload: inventory
});
