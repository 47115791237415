import React, { useMemo } from "react";

import LoadBlock from "../LoadBlock";

import {
  StyledPhotoBlock,
  StyledInfoTable,
  StyledInfoTableText,
  StyledTextBlock,
  StyledLoadBlock
} from "./styled";

const PhotoBlock = ({ sides, files }) => {
  const { id } = useMemo(() => sides.find(side => side.selected), [sides]);
  const sideFiles = useMemo(() => files[id], [files, id]);
  return (
    <StyledPhotoBlock>
      <StyledInfoTable>
        <StyledTextBlock>
          <StyledInfoTableText>Фото таблички</StyledInfoTableText>
          {/*<StyledInfoTableLoad>
            {"Загружено 10.10.2021 10:00"}
          </StyledInfoTableLoad>*/}
        </StyledTextBlock>
        <StyledLoadBlock>
          <LoadBlock
            sideId={id}
            previews={sideFiles.table.previews}
            selectedFiles={sideFiles.table.selectedFiles}
            isTableCard
          />
        </StyledLoadBlock>
      </StyledInfoTable>
      <StyledInfoTable>
        <StyledTextBlock>
          <StyledInfoTableText>Фото макета и общий ракурс</StyledInfoTableText>
          {/*<StyledInfoTableLoad>
            {"Загружено 10.10.2021 10:00"}
          </StyledInfoTableLoad>*/}
        </StyledTextBlock>
        <StyledLoadBlock>
          <LoadBlock
            sideId={id}
            previews={sideFiles.stop.previews}
            selectedFiles={sideFiles.stop.selectedFiles}
            multiple
          />
        </StyledLoadBlock>
      </StyledInfoTable>
    </StyledPhotoBlock>
  );
};

export default PhotoBlock;
