import { removeToken, getToken } from "../helpers/auth";
import { saveAs } from "file-saver";

export const createStartAction = (type = "TEST") => (action = {}) => ({
  type,
  action
});
export const createSuccessAction = (
  type = "TEST",
  extractData = (result, action) => ({ result, action })
) => (action = {}, result = {}) => ({
  type,
  action,
  ...extractData(result, action)
});
export const createEndAction = (
  type = "TEST",
  extractEnd = (end, action) => ({ end, action })
) => (action = {}, end = {}) => ({
  type,
  action,
  ...extractEnd(end, action)
});
export const createSuccessActionFile = (
  type = "TEST",
  extractData = (result, action) => ({ result, action })
) => (action = {}, result = {}) => ({
  type,
  action,
  ...extractData(result, action)
});
export const createErrorAction = (
  type = "TEST",
  extractError = (error, action) => ({ error, action })
) => (action = {}, error = {}) => ({
  type,
  action,
  ...extractError(error, action)
});
export const createGetBody = (prop = "test") => (action = {}) => {
  try {
    const body = action[prop] ? JSON.stringify(action[prop]) : null;
    return body;
  } catch (e) {
    return null;
  }
};
export const encodeFormData = prop => action => {
  const formData = new FormData();
  Object.keys(action.form).forEach(key =>
    formData.append(key, action[prop][key])
  );
  return formData;
};
export const toJson = (res = { json: () => Promise.resolve(null) }) =>
  new Promise(resolve =>
    res
      .json()
      .then(resolve)
      .catch(() => resolve(null))
  );

export const toFile = (res = { json: () => Promise.resolve(null) }) =>
  new Promise(resolve =>
    res
      .blob()
      .then(resolve)
      .catch(() => resolve(null))
  );

export const toText = (res = { text: () => Promise.resolve(null) }) =>
  res.text();

export const getTokenHeader = () => ({
  "X-Auth-Token": `${getToken()}`
});
export const getDefaultHeaders = () => ({ Accept: "application/json" });
export const getJsonHeaders = () => ({ "Content-Type": "application/json" });

export const composeHeaders = (...headersFn) => {
  return () => {
    return headersFn.reduce((acc, fn) => Object.assign(acc, fn()), {});
  };
};

export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";

const errorHandler = error => {
  if (error.result && error.result.message === "Unauthorized") {
    removeToken();
    // window.location = '/';
  }
  throw error;
};

const generateSagaWorker = (put, call) => ({
  startAction = createStartAction("TEST_START"),
  url = () => "/api/ping",
  method = "GET",
  meta: {
    mode = "cors",
    credentials = "include",
    cache = "default",
    ...rest
  } = {},
  headers = getDefaultHeaders,
  successAction = createSuccessAction("TEST_SUCCESS", () => ({})),
  getBody = () => null,
  getError = toText,
  getResult = toJson,
  errorAction = createErrorAction("TEST_ERROR"),
  endAction = createEndAction("TEST_END")
} = {}) =>
  function* createFactory(action) {
    try {
      yield put(startAction(action));
      let response = null;
      const result = yield call(() => {
        return fetch(url(action), {
          method,
          mode,
          cache,
          credentials,
          ...rest,
          headers: new Headers(headers(action)),
          body: getBody(action)
        }).then(res => {
          response = res;
          return response.ok ? getResult(res) : getError(res);
        });
      });
      if (!response.ok) {
        const error = { response, result };
        errorHandler(error);
      }
      yield put(successAction(action, result));
    } catch (error) {
      console.error("Error at generateSagaWorker", error);
      yield put(errorAction(action, error));
    } finally {
      yield put(endAction(action));
    }
  };

export default generateSagaWorker;

export const generateSagaWorkerFile = (put, call) => ({
  startAction = createStartAction("TEST_START"),
  url = () => "/api/ping",
  method = "GET",
  meta: {
    mode = "cors",
    credentials = "include",
    cache = "default",
    ...rest
  } = {},
  headers = getDefaultHeaders,
  successAction = createSuccessAction("TEST_SUCCESS", () => ({})),
  getBody = () => null,
  getError = toText,
  // getResult = toJson,
  errorAction = createErrorAction("TEST_ERROR")
} = {}) =>
  function* createFactory(action) {
    try {
      yield put(startAction(action));
      let response = null;
      const result = yield call(() => {
        return fetch(url(action), {
          method,
          mode,
          cache,
          credentials,
          ...rest,
          headers: new Headers(headers(action)),
          body: getBody(action)
        }).then(res => {
          response = res;
          return response.ok ? res : getError(res);
        });
      });
      if (!response.ok) {
        const error = { response, result };
        errorHandler(error);
      }
      yield put(successAction(action, result));
    } catch (error) {
      yield put(errorAction(action, error));
    }
  };

export const generateSagaWorkerSaveBlobFile = (put, call) => ({
  startAction = createStartAction("TEST_START"),
  url = () => "/api/ping",
  method = "GET",
  meta: {
    mode = "cors",
    credentials = "include",
    cache = "default",
    ...rest
  } = {},
  headers = getDefaultHeaders,
  //successAction = createSuccessAction('TEST_SUCCESS', () => ({})),
  getBody = () => null,
  getError = toText,
  getResult = toFile,
  errorAction = createErrorAction("TEST_ERROR")
} = {}) =>
  function* createFactory(action) {
    try {
      yield put(startAction(action));
      let response = null;
      const result = yield call(() => {
        return fetch(url(action), {
          method,
          mode,
          cache,
          credentials,
          ...rest,
          headers: new Headers(headers(action)),
          body: getBody(action)
        }).then(res => {
          response = res;
          return response.ok ? getResult(res) : getError(res);
        });
      });
      if (!response.ok) {
        const error = { response, result };
        errorHandler(error);
      }

      const blob = yield result;
      const disposition = response.headers.get("content-disposition");
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      saveAs(
        blob,
        decodeURIComponent(
          filenameRegex.exec(disposition)[1].replace(/['"]/g, "")
        )
      );

      //yield put(successAction(action, result));
    } catch (error) {
      console.error("Error at generateSagaWorker", error);
      yield put(errorAction(action, error));
    }
  };

//FACADES

export const get = (
  sagaGenerator = () => {},
  url = () => {},
  [START, SUCCESS, ERROR, END] = [],
  headers = getTokenHeader,
  error
) =>
  sagaGenerator({
    startAction: createStartAction(START),
    url,
    method: GET,
    getError: toJson,
    headers,
    successAction: createSuccessAction(SUCCESS),
    errorAction: createErrorAction(ERROR, error),
    endAction: createSuccessAction(END)
  });

export const post = (
  sagaGenerator = () => {},
  url = () => {},
  [START, SUCCESS, ERROR] = [],
  headers = getTokenHeader,
  error // no passing is standard handler
) =>
  sagaGenerator({
    startAction: createStartAction(START),
    url,
    method: POST,
    getError: toJson,
    getBody: encodeFormData("form"),
    headers,
    successAction: createSuccessAction(SUCCESS),
    errorAction: createErrorAction(ERROR, error)
  });

export const putsaga = (
  sagaGenerator = () => {},
  url = () => {},
  [START, SUCCESS, ERROR] = [],
  headers = getTokenHeader,
  error // no passing is standard handler
) =>
  sagaGenerator({
    startAction: createStartAction(START),
    url,
    method: PUT,
    getError: toJson,
    getBody: encodeFormData("form"),
    headers,
    successAction: createSuccessAction(SUCCESS),
    errorAction: createErrorAction(ERROR, error)
  });

export const deleteJson = (
  sagaGenerator = () => {},
  url = () => {},
  [START, SUCCESS, ERROR] = [],
  headers = getTokenHeader,
  error // no passing is standard handler
) =>
  sagaGenerator({
    startAction: createStartAction(START),
    url,
    method: DELETE,
    getError: toJson,
    getBody: encodeFormData("form"),
    headers,
    successAction: createSuccessAction(SUCCESS),
    errorAction: createErrorAction(ERROR, error)
  });

export const postJson = (
  sagaGenerator = () => {},
  url = () => {},
  [START, SUCCESS, ERROR, END] = [],
  headers = composeHeaders(getTokenHeader, getJsonHeaders),
  error // no passing is standard handler
) =>
  sagaGenerator({
    startAction: createStartAction(START),
    url,
    method: POST,
    getError: toJson,
    getBody: createGetBody("form"),
    headers,
    successAction: createSuccessAction(SUCCESS),
    errorAction: createErrorAction(ERROR, error),
    endAction: createErrorAction(END)
  });

export const postJsonFile = (
  sagaGenerator = () => {},
  url = () => {},
  [START, SUCCESS, ERROR] = [],
  headers = composeHeaders(getTokenHeader, getJsonHeaders),
  error // no passing is standard handler
) =>
  sagaGenerator({
    startAction: createStartAction(START),
    url,
    method: POST,
    getError: toJson,
    getBody: createGetBody("form"),
    headers,
    successAction: createSuccessAction(SUCCESS),
    errorAction: createErrorAction(ERROR, error)
  });

export const putJson = (
  sagaGenerator = () => {},
  url = () => {},
  [START, SUCCESS, ERROR] = [],
  headers = composeHeaders(getTokenHeader, getJsonHeaders),
  error // no passing is standard handler
) =>
  sagaGenerator({
    startAction: createStartAction(START),
    url,
    method: PUT,
    getError: toJson,
    getBody: createGetBody("form"),
    headers,
    successAction: createSuccessAction(SUCCESS),
    errorAction: createErrorAction(ERROR, error)
  });

export const generateSagaFaker = (put, call) => ({
  startAction = createStartAction("FAKER_START"),
  successAction = createSuccessAction("FAKER_SUCCESS", () => ({})),
  errorAction = createErrorAction("FAKER_ERROR"),
  data = () => {}
} = {}) =>
  function* createFactory(action) {
    try {
      yield put(startAction(action));
      const result = yield call(() => {
        return new Promise(res => {
          let rand = 100 + Math.random() * (1000 + 1 - 100);
          rand = Math.floor(rand);
          setTimeout(() => {
            res(data(action));
          }, rand);
        }).then(response => {
          return response;
        });
      });
      yield put(successAction(action, result));
    } catch (error) {
      yield put(errorAction(action, error));
    }
  };

export const faker = (
  sagaGenerator = () => {},
  [START, SUCCESS, ERROR] = [],
  data
) =>
  sagaGenerator({
    startAction: createStartAction(START),
    successAction: createSuccessAction(SUCCESS),
    errorAction: createErrorAction(ERROR, {}),
    data
  });
