import styled from "styled-components";

export const StyledLoadBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const StyledPreview = styled.div`
  display: flex;
  height: 80px;
  margin-bottom: 8px;
`;

export const StyledLoadButton = styled.label`
  display: flex;
  width: 100%;
  background: #4a4a4a;
  border-radius: 4px;
  justify-content: center;
  padding: 16px 0;
  cursor: pointer;
  transition: all 300ms;
  color: #fff;

  input {
    display: none;
  }

  &:hover {
    opacity: 0.9;
  }
`;

export const StyledPreviewText = styled.div`
  display: flex;
  margin: 0 8px;
  word-break: break-all;
`;

export const StyledPreviewImg = styled.div`
  display: flex;
  flex: 0 0 30%;
  justify-content: center;

  img {
    width: 100%;
  }
`;

export const StyledPreviewName = styled.div`
  display: flex;
  flex: 0 0 60%;
  align-items: center;
  font-size: 0.875rem;
`;

export const StyledPreviewClose = styled.div`
  display: flex;
  flex: 0 0 10%;
  align-items: center;
  justify-content: center;

  img {
    width: 20px;
    cursor: pointer;
  }
`;
