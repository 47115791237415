import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { connect } from 'react-redux';
import { showOfferArchive, hideOfferArchive, getOfferArchive } from 'store/actions/offerArchive';

import Modal from '../../components/Modal';
import Loader from '../Loader';
import { Title, Line, List, Link } from './styled';

class OfferArchiveContainer extends Component {
  static propTypes = {
    getOfferArchive: PropTypes.func,
    hideOfferArchive: PropTypes.func.isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.string,
        fileName: PropTypes.string
      })
    ).isRequired,
    show: PropTypes.bool.isRequired
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show && !this.props.list.length) {
      this.props.getOfferArchive();
    }
  }

  render() {
    const { show, list } = this.props;

    if (!show) {
      return null;
    }

    return (
      <Modal handleClose={this.props.hideOfferArchive}>
        <Title>Архив оферт</Title>
        <Line />
        {!list.length && <Loader />}
        {Boolean(list.length) && (
          <List showScroll={list.length > 5}>
            {list.map(({ createdAt, fileName }, index) => {
              const to = list[index + 1] ? moment(list[index + 1].createdAt).subtract(1, 'days') : moment();
              const textLink =
                list.length > 1
                  ? `${moment(createdAt).format('DD.MM.YYYY')} - ${to.format('DD.MM.YYYY')}`
                  : `${moment(createdAt).format('DD.MM.YYYY')} - ${moment().format('DD.MM.YYYY')}`;
              return (
                <Link key={index} href={`/api/v1/public/offer/${fileName}`}>
                  {textLink}
                </Link>
              );
            })}
          </List>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = ({ offerArchive }) => ({
  show: offerArchive.show,
  list: offerArchive.list
});

const mapDispatchToProps = {
  showOfferArchive,
  hideOfferArchive,
  getOfferArchive
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferArchiveContainer);
