import React, { Component, Fragment } from 'react';
import { Switch, Route, NavLink, withRouter } from 'react-router-dom';
import Pt from 'prop-types';

class Tabs extends Component {
  static propTypes = {
    tabs: Pt.array
  };

  render() {
    // const {url, path} = this.props.match;
    return (
      <Fragment>
        <div className="tabs-link">
          {this.props.tabs.map(item => (
            <NavLink to={`${item.url}`} onClick={item.onClick} key={item.url} exact>
              {item.label}
            </NavLink>
          ))}
          <hr />
        </div>

        <div className="tabs-content">
          <Switch>
            {this.props.tabs.map(item => (
              <Route path={`${item.url}`} key={item.url} exact>
                {item.component}
              </Route>
            ))}
          </Switch>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(Tabs);
