import React, { Component } from 'react';
import Modal from '../../components/Modal';
import ConfirmForm from './ConfirmForm';

export default class Confirm extends Component {
  render() {
    return (
      <Modal>
        <ConfirmForm {...this.props} />
      </Modal>
    );
  }
}
