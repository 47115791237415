import React from "react";
import Modal from "react-modal";

import { ModalStyle } from "../styled";

import { Title } from "./styled";

import Form from "../Form";

function FormModal({
  title,
  formFeedback,
  handleFormFeedback,
  onSendFeedback,
  submitDisabed,
  topic,
  error,
  ...props
}) {
  return (
    <Modal {...props} style={ModalStyle} ariaHideApp={false}>
      <Title>{title}</Title>
      <Form
        error={error}
        formFeedback={formFeedback}
        handleFormFeedback={handleFormFeedback}
        onSendFeedback={onSendFeedback}
        submitDisabed={submitDisabed}
        topic={topic}
      />
    </Modal>
  );
}

export default FormModal;
