import {
  GET_PUBLIC_METRO_SCHEME,
  GET_ALL_STATIONS_FOR_PUBLIC_METRO_SCHEME,
  GET_ALL_LINES_FOR_PUBLIC_METRO_SCHEME
} from "../constants/scheme";

const initialState = {
  metroSchemeData: null,
  loadingMetroSchemeData: false,
  allStationsForMetroScheme: null,
  allLinesForMetroScheme: null
};

export const scheme = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PUBLIC_METRO_SCHEME.start:
      return {
        ...state,
        loadingMetroSchemeData: true
      };
    case GET_PUBLIC_METRO_SCHEME.success:
      return {
        ...state,
        metroSchemeData: payload
      };
    case GET_PUBLIC_METRO_SCHEME.end:
      return {
        ...state,
        loadingMetroSchemeData: false
      };
    case GET_ALL_STATIONS_FOR_PUBLIC_METRO_SCHEME.success:
      return {
        ...state,
        allStationsForMetroScheme: payload
      };
    case GET_ALL_LINES_FOR_PUBLIC_METRO_SCHEME.success:
      return {
        ...state,
        allLinesForMetroScheme: payload
      };
    default:
      return state;
  }
};
