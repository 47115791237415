import styled from "styled-components";

export const StyledPhotoBlock = styled.div`
  display: flex;
  background: #ffffff;
  border-radius: 0 0 4px 4px;
  padding: 24px 0;
  flex-direction: column;
  cursor: default;
`;

export const StyledTextBlock = styled.div`
  display: flex;
  padding: 0 24px;
  margin-bottom: 8px;
`;

export const StyledInfoTable = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const StyledInfoTableText = styled.div`
  display: flex;
  font-size: 1rem;
  flex: 0 0 80%;
`;

export const StyledInfoTableLoad = styled.div`
  display: flex;
  font-size: 0.625rem;
  color: #4a4a4a;
  align-items: center;
  flex: 0 0 20%;
  justify-content: flex-end;
`;

export const StyledLoadBlock = styled.div`
  padding: 16px 8px;
`;
