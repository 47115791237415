import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import warning from "../../img/field_warning.svg";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  ModalInputGroup,
  ModalRow,
  StyledLinkDefault,
  ModalButton,
  ModalHr,
  ModalInputGroupWrapper,
  ModalWarnStr,
  ModalWarnEma,
  MainFormGroup,
  ModalValid
} from "../../components/Modal/styled";
import { ErrorEma } from "../../components/Layout/form";
import { ErrorStr } from "../../components/Layout/form";
import { signIn } from "../../store/actions/auth";
import cn from "classnames";
import Form from "../../components/Form";
import { GOOGLE_RECAPTCHA_TOKEN } from "../../utils/variables";
import { createEvent } from "analytics";

class SignInForm extends Form {
  static propTypes = {
    emailError: PropTypes.object,
    registerErrors: PropTypes.array,
    submitForm: PropTypes.func
  };

  state = {
    captchaVerified: true, // verify by default, reset on success load
    isAgency: false,
    subscription: false,
    model: {
      email: ""
    },
    validation: {}
  };

  reCaptchaRef = React.createRef();

  validateFields = afterValidate => {
    const fields = Object.keys(this.state.model);
    const errorFields = {};

    for (const key of fields) {
      if (this.state.model[key] === "") {
        errorFields[key] = true;
      } else {
        errorFields[key] = false;
      }
    }

    this.setState(
      ({ validation }) => ({
        validation: {
          ...validation,
          ...errorFields
        }
      }),
      afterValidate
    );
  };

  isInvalid = () => {
    return (
      !this.state.captchaVerified ||
      Object.keys(this.state.model).some(x => !this.state.model[x]) ||
      Object.keys(this.state.validation).some(x => this.state.validation[x])
    );
  };

  submitForm = () => {
    const { isAgency, subscription } = this.state;

    this.validateFields(() => {
      if (!this.isInvalid()) {
        const utmMetrics = JSON.parse(
          localStorage.getItem("new-msb-user-info")
        ) || { landingName: "nebo.digital" };

        createEvent({
          action: `EnterButton.Registration.MakeRegister.${
            isAgency ? "AsAA" : "AsAd"
          }`,
          category: "Landing"
        });

        this.props.submitForm({
          ...this.state.model,
          ...utmMetrics,
          gRecaptchaResponse:
            window.location.hostname === "nebo.digital"
              ? this.state.captchaVerified
              : "",
          isAgency,
          subscription
        });
      }
    });
  };

  updateCheckbox = ({ target }, name) => {
    const value = target.checked || false;
    this.setState(() => ({
      [name]: value
    }));
  };

  getEmailTooltip = () =>
    this.props.emailError
      ? this.props.emailError.message
      : "Введены некорректные данные";

  getRegisterTooltip = () =>
    this.props.registerErrors && this.props.registerErrors.length
      ? this.props.registerErrors[0].message
      : "";

  captchaVerifyCallback = token => {
    this.setState({
      captchaVerified: token ? token : false
    });
  };

  render() {
    return (
      <form>
        {/*
        <>
          <ModalInputGroup
            className={cn({ error: this.state.validation.fullName })}
          >
            <ModalInputGroupWrapper>
              <label htmlFor="">Как к вам обращаться</label>
              <input
                type="text"
                placeholder="Введите ваше ФИО"
                onChange={this.updateName}
                onBlur={this.updateName}
              />
              <span className="input-warning">
                <img src={warning} alt="warning" />
              </span>
            </ModalInputGroupWrapper>
          </ModalInputGroup>
          <ModalInputGroup
            className={cn({ error: this.state.validation.companyName })}
          >
            <ModalInputGroupWrapper>
              <label htmlFor="">Наименование юр. лица</label>
              <input
                type="text"
                placeholder="ООО Клиент"
                onChange={this.updateCompanyNameSign}
                onBlur={this.updateCompanyNameSign}
              />
              <span className="input-warning">
                <img src={warning} alt="warning" />
              </span>
            </ModalInputGroupWrapper>
          </ModalInputGroup>
          <ModalInputGroup className={cn({ error: this.state.validation.phone })}>
            <ModalInputGroupWrapper>
              <label htmlFor="">Телефон</label>
              <InputMask mask="+7 (999) 999 9999" onChange={this.updateTel}>
                {inputProps => (
                  <input
                    {...inputProps}
                    type="tel"
                    placeholder="+7 (...) ... ...."
                  />
                )}
              </InputMask>
              <span className="input-warning">
                <img src={warning} alt="warning" />
              </span>
            </ModalInputGroupWrapper>
          </ModalInputGroup>
        </>
      */}
        <ModalInputGroup
          className={cn({
            error: this.props.emailError || this.state.validation.email
          })}
        >
          <ModalInputGroupWrapper>
            <label htmlFor="">E-mail</label>
            <input
              type="text"
              placeholder="Введите E-mail"
              onChange={this.updateEmail}
              onBlur={this.updateEmail}
            />
            <span className="input-warning">
              <img src={warning} alt="warning" />
            </span>
          </ModalInputGroupWrapper>
        </ModalInputGroup>
        <MainFormGroup className="checkbox">
          Я - представитель рекламного агентства
          <input
            type="checkbox"
            onChange={e => this.updateCheckbox(e, "isAgency")}
          />
          <span />
        </MainFormGroup>
        <MainFormGroup className="checkbox">
          Подписаться на новости
          <input
            type="checkbox"
            onChange={e => this.updateCheckbox(e, "subscription")}
          />
          <span />
        </MainFormGroup>
        <br />
        <p>
          Нажатием кнопки «Зарегистрироваться» я принимаю условия{" "}
          <StyledLinkDefault href={"/license.pdf"} target="_blank">
            пользовательского соглашения
          </StyledLinkDefault>
        </p>
        <br />
        {/*{window.location.hostname === "nebo.digital" && (*/}
        {/*  <ReCAPTCHA*/}
        {/*    size={*/}
        {/*      window.matchMedia("(max-width: 1024px)").matches*/}
        {/*        ? "compact"*/}
        {/*        : "normal"*/}
        {/*    }*/}
        {/*    sitekey={GOOGLE_RECAPTCHA_TOKEN}*/}
        {/*    onChange={this.captchaVerifyCallback}*/}
        {/*  />*/}
        {/*)}*/}
        <ModalHr />
        <ModalValid className={cn({ error: this.state.validation.email })}>
          <ModalWarnStr>
            <span className="input-warning">
              <img src={warning} alt="warning" />
            </span>
          </ModalWarnStr>
          <ErrorStr className="error-text">
            Все поля обязательны для заполнения
          </ErrorStr>
        </ModalValid>
        <ModalValid
          className={cn({
            error: this.props.registerErrors && this.props.registerErrors.length
          })}
        >
          <ModalWarnEma>
            <span className="input-warning">
              <img src={warning} alt="warning" />
            </span>
          </ModalWarnEma>
          <ErrorEma className="error-text">
            {this.getRegisterTooltip()}
          </ErrorEma>
        </ModalValid>
        <ModalRow className="center">
          <ModalButton type="button" onClick={this.submitForm}>
            Зарегистрироваться
          </ModalButton>
        </ModalRow>
      </form>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  registerErrors: auth.registerErrors,
  emailError: auth.emailValidationError
});

const mapDispatchToProps = {
  submitForm: signIn
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);
