import styled from "styled-components";

export const Form = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

Form.Input = styled.input`
  width: 100%;
  max-width: 500px;
  border: none;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  background-color: #f4f4f4;
`;

Form.Submit = styled.input.attrs({
  type: "submit"
})`
  border: none;
  width: 100%;
  max-width: 500px;
  padding: 1rem 2rem;
  text-align: center;
  background-color: #1044a0;
  color: #fff;
  text-transform: uppercase;

  &:disabled {
    opacity: 0.5;
  }
`;

Form.Error = styled.div`
  margin-bottom: 1rem;
  font-size: 1.2rem;
  background-color: #f13737;
  color: white;
  padding: 0.5rem;
  text-align: center;
`;
