import styled from "styled-components";

export const HeadContainer = styled.div`
  display: block;
  background: #fff;
`;

export const BodyContainer = styled.div`
  display: block;
  background: #fff;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 4%;
`;

export const MainBlock = styled.div`
  margin: 0 auto;
  max-width: 500px;
`;

export const MainButton = styled.div`
  background: #ffd900;
  border-radius: 8px;
  color: #000;
  font-size: 1.25rem;
  padding: 15px 0;
  text-align: center;
  cursor: pointer;
  transition: all 300ms;

  &:hover {
    opacity: 0.9;
  }
`;

export const MenuButton = styled.div`
  background: #ffffff;
  border-radius: 4px;
  color: #4a4a4a;
  font-size: 1.5rem;
  padding: 18px 0;
  text-align: center;
  cursor: pointer;
  transition: all 300ms;
  letter-spacing: 0.33px;

  &:hover {
    opacity: 0.9;
  }
`;
