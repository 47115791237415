import { GraphQLClient } from "graphql-request";
import { store } from "../index";

export async function gqlRequest(query, variables) {
  const reduxStore = store.getState();
  const isLocalhost = window.location.host.search("localhost");
  const graphQLClient = new GraphQLClient(
    `/${isLocalhost === -1 ? "api" : "reports-api"}/graphql`,
    {
      headers: {
        "x-auth-token": reduxStore.auth?.envData?.MEDIA_PLANNER_TOKEN
      }
    }
  );
  return await graphQLClient.request(query, variables);
}
