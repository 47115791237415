import styled from "styled-components";

export const ContainerMain = styled.div`
  ${({ train, trainNov }) =>
    train
      ? `
      width: 100%;
    background-image: linear-gradient(to bottom,#eff3fe,#c1cae1);
    height: 16em;
    margin-bottom: 6em;
    display: flex;
    justify-content: center;
    `
      : trainNov
      ? `
    display: flex;
    justify-content: center;
    max-width: unset;
    background-image: linear-gradient(to bottom, #eff3fe, #c1cae1);
    height: 16em;
    margin-bottom: ${trainNov ? "3" : "6"}em;
    `
      : `
    width: 100%;
    max-width: 82.5rem;
    margin: 0 auto;
    `}

  &.m-top-30 {
    margin-top: 30px;
  }
`;
