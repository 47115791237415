import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Header from "../Header";
import InputField from "../InputField";
import Tabs from "../Tabs";
import { Container, MainBlock, HeadContainer, BodyContainer } from "../Layout";

import { signIn, resetLoggedInError } from "store/actions/photoReports";

import { StyledForgotPassword, StyledEnterButton } from "./styled";

const Authorization = ({ history }) => {
  const dispatch = useDispatch();
  const [authForm, setAuthForm] = useState({
    email: "",
    password: ""
  });

  const { loggedIn, loggedInError } = useSelector(
    ({ photoReports }) => photoReports
  );
  const returnToEdit = () => dispatch(signIn({ form: authForm }));

  const editAuthForm = ({ key, target }) => {
    if (loggedInError) {
      dispatch(resetLoggedInError());
    }
    setAuthForm({ ...authForm, [key]: target.value });
  };

  useEffect(() => {
    return () => {
      setAuthForm({ email: "", password: "" });
    };
  }, []);

  useEffect(() => {
    if (loggedIn) {
      history.push("/menu");
    }
  }, [loggedIn, history, dispatch]);

  return (
    <>
      <HeadContainer>
        <MainBlock>
          <Header />
        </MainBlock>
      </HeadContainer>
      <BodyContainer>
        <MainBlock>
          <Container>
            <Tabs />
            <InputField
              label="Логин"
              type="text"
              placeholder="Логин"
              onChange={({ target }) => editAuthForm({ target, key: "email" })}
              defaultValue={authForm.email}
              error={loggedInError}
              authStyle
            />
            <InputField
              label="Пароль"
              type="password"
              placeholder="Пароль"
              onChange={({ target }) =>
                editAuthForm({ target, key: "password" })
              }
              defaultValue={authForm.password}
              error={loggedInError}
              authStyle
            />
            <StyledForgotPassword>Забыли пароль ?</StyledForgotPassword>
            <StyledEnterButton onClick={() => returnToEdit()}>
              Войти
            </StyledEnterButton>
          </Container>
        </MainBlock>
      </BodyContainer>
    </>
  );
};

export default Authorization;
