import React from "react";

import { Form } from "./styled";

function SoftwareForm({
  formFeedback,
  handleFormFeedback,
  onSendFeedback,
  submitDisabed,
  topic,
  error
}) {
  const onChange = e => {
    handleFormFeedback(e.target.name, e.target.value);
  };

  return (
    <Form>
      <Form.Input
        name="firstName"
        value={formFeedback["firstName"]}
        onChange={onChange}
        type="text"
        placeholder="Имя"
      />
      <Form.Input
        name="lastName"
        value={formFeedback["lastName"]}
        onChange={onChange}
        type="text"
        placeholder="Фамилия"
      />
      <Form.Input
        name="phoneNumber"
        value={formFeedback["phoneNumber"]}
        onChange={onChange}
        type="text"
        placeholder="Телефон"
      />
      <Form.Input
        name="email"
        value={formFeedback["email"]}
        onChange={onChange}
        type="text"
        placeholder="Email"
      />
      <Form.Input
        name="message"
        value={formFeedback["message"]}
        onChange={onChange}
        type="text"
        placeholder="Комментарий"
      />
      {error ? (
        <Form.Error>
          Ошибка отправки формы. Попробуйте изменить некоторые поля
        </Form.Error>
      ) : null}
      <Form.Submit
        disabled={submitDisabed}
        value="отправить"
        onClick={() => onSendFeedback(topic)}
      />
    </Form>
  );
}

export default SoftwareForm;
