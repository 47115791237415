import React, { useState } from "react";

import Collapse from "./Collapse";

import {
  StyledSideBlock,
  StyledTabBoard,
  StyledTab,
  StyledSideInfo,
  StyledRedLine
} from "./styled";

const SideBlock = ({ sides, selectSide, selectedWorkOrder }) => {
  const [toggle, toggleCollapse] = useState(false);

  return (
    <>
      <StyledTabBoard>
        {sides.map(({ name, selected, id }, idx) => (
          <StyledTab
            key={`tab${idx}`}
            selected={selected}
            onClick={() => selected && selectSide(id)}
          >
            {name}
          </StyledTab>
        ))}
      </StyledTabBoard>
      <StyledSideBlock>
        <StyledSideInfo>
          <StyledRedLine />
          <div>
            <b>{`Смонтируйте постер "${selectedWorkOrder.rimName}"`}</b>
          </div>
          {selectedWorkOrder.comment && (
            <Collapse
              title={"Комментарий"}
              isOpened={toggle}
              name={"comment"}
              onToggleCollapse={() => toggleCollapse(!toggle)}
            >
              <div>{selectedWorkOrder.comment}</div>
            </Collapse>
          )}
        </StyledSideInfo>
      </StyledSideBlock>
    </>
  );
};

export default SideBlock;
