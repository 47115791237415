import { takeEvery, call, put } from "redux-saga/effects";
import generateSagaWorker, { get } from "./generateSaga";
import { GET_LANDING_DOCS_GROUND_TRANSPORTATION } from "../constants/groundTransportation";
import qs from "qs";

const sagaGenerator = generateSagaWorker(put, call);

const getLandingDocs = get(
  sagaGenerator,
  action => `/api/v1/public/landing-docs?${qs.stringify(action.form)}`,
  GET_LANDING_DOCS_GROUND_TRANSPORTATION.types
);

export default function* beautyMonitors() {
  yield takeEvery(GET_LANDING_DOCS_GROUND_TRANSPORTATION.main, getLandingDocs);
}
