const checkCorrectBasket = basket => {
  let basketIsCorrect = true;
  if (basket !== null) {
    if (!Array.isArray(basket)) basketIsCorrect = false;
    if (basketIsCorrect) {
      basket.forEach(item => {
        if (typeof item.i !== "string" || !Array.isArray(item.p))
          basketIsCorrect = false;
      });
    }
    if (basketIsCorrect) {
      basket.forEach(item => {
        const periodRegExp = /^\d\d-\d\d-\d\d\d\d$/; // DD-MM-YYYY
        item.p.forEach(period => {
          if (!periodRegExp.test(period.f) || !periodRegExp.test(period.t))
            basketIsCorrect = false;
        });
      });
    }

    // ПРИМЕР МАССИВА С ПЕРИОДАМИ
    // [
    //     {
    //         i: '',   ID
    //         p: [     PERIODS
    //             {
    //                 f: '', FROM DD-MM-YYYY
    //                 t: ''  TO   DD-MM-YYYY
    //             }
    //         ]
    //     }
    // ]
  }

  return basketIsCorrect;
};

export const getBasket = () => {
  const basketMarketplace = localStorage.getItem("basketMarketplace");
  try {
    const parseBaskter = JSON.parse(basketMarketplace);
    return basketMarketplace ? parseBaskter : null;
  } catch {
    localStorage.removeItem("basketMarketplace");
    return null;
  }
};

export const getBusyBasket = () => {
  const basketBusy = localStorage.getItem("basketBusy");
  try {
    const parseBaskter = JSON.parse(basketBusy);
    return basketBusy ? parseBaskter : null;
  } catch {
    localStorage.removeItem("basketBusy");
    return null;
  }
};

const generateItem = (id, periods) => ({
  i: id,
  p: periods.map(period => ({
    f: period.from,
    t: period.to
  }))
});

export const updateItem = (id, periods) => {
  let basket = getBasket();

  // УДАЛЯЕМ
  if (!periods.length && basket !== null) {
    localStorage.setItem(
      "basketMarketplace",
      JSON.stringify([...basket.filter(basketItem => basketItem.i !== id)])
    );
    return;
  }

  if (basket === null) {
    // ДОБАВЛЯЕМ ПЕРВЫЙ
    const item = generateItem(id, periods);
    localStorage.setItem("basketMarketplace", JSON.stringify([item]));
  } else {
    const newItem = generateItem(id, periods);

    let alredyInBasket = basket.find(basketItem => basketItem.i === newItem.i);

    if (alredyInBasket) {
      // ОБНОВЛЯЕМ ПЕРИОДЫ СУЩЕСТВУЮЩЕГО

      alredyInBasket.p = newItem.p;
      localStorage.setItem(
        "basketMarketplace",
        JSON.stringify([
          ...basket.filter(item => item.i !== newItem.i),
          alredyInBasket
        ])
      );
    } else {
      // ДОБАВЛЯЕМ РЯДОМ НОВЫЙ
      const updateBasket = [...basket, newItem];
      localStorage.setItem("basketMarketplace", JSON.stringify(updateBasket));
    }
  }
};

// ПРОВЕРЯЕМ КОРЗИНУ при первой загрузке странице
export const initCheckBasket = () => {
  const basket = getBasket();
  if (!basket) return;
  const isCorrectBasket = checkCorrectBasket(basket);
  if (isCorrectBasket) return;
  localStorage.removeItem("basketMarketplace");
};

export const removeItem = (id, period) => {
  let basket = getBasket();

  if (basket !== null) {
    const findSpace = basket.find(b => b.i === id);
    if (findSpace.p.length === 0) {
      return localStorage.setItem(
        "basketMarketplace",
        JSON.stringify(basket.filter(b => b.i !== id))
      );
    }
    if (findSpace) {
      basket = basket.map(b =>
        b.i !== id
          ? b
          : {
              ...b,
              p: b.p.filter(p => {
                const ret = p.f === period.from && p.t === period.to;
                return !ret;
              })
            }
      );
      localStorage.setItem("basketMarketplace", JSON.stringify(basket));
    }
  }
};

export const removeBusyItem = (id, period) => {
  let basket = getBusyBasket();

  if (basket !== null) {
    const findSpace = basket.find(b => b.i === id);
    if (findSpace) {
      if (period === null || findSpace.p.length === 0) {
        // удаляем весть space
        return localStorage.setItem(
          "basketBusy",
          JSON.stringify(basket.filter(b => b.i !== id))
        );
      }
      basket = basket.map(b =>
        b.i !== id
          ? b
          : {
              ...b,
              p: b.p.filter(p => {
                const ret = p.f === period.from && p.t === period.to;
                return !ret;
              })
            }
      );
      localStorage.setItem("basketBusy", JSON.stringify(basket));
    }
  }
};

/**
 * Добавляет период в корзину занятых периодов, если не найдено
 * @param {*} id спейса
 * @param {obj} period { from , to }
 */
export const addBusyItem = (id, period) => {
  let basket = getBusyBasket();

  const item = generateItem(id, [period]);
  if (basket === null) {
    localStorage.setItem("basketBusy", JSON.stringify([item]));
  } else {
    const findSpace = basket.find(b => b.i === id);
    if (findSpace) {
      const findPeriod = findSpace.p.find(
        p => p.f === period.from && p.t === period.to
      );
      if (!findPeriod) {
        // добавляем только уникальный элемент
        findSpace.p.push(...item.p);
        localStorage.setItem(
          "basketBusy",
          JSON.stringify([...basket.filter(item => item.i !== id), findSpace])
        );
      }
    } else {
      // добавляем элемент вместе с id
      localStorage.setItem("basketBusy", JSON.stringify([...basket, item]));
    }
  }
};
