import React from "react";
import PropTypes from "prop-types";
import { Collapse as ReactCollapse } from "react-collapse";
import {
  StyledCollapse,
  StyledHeader,
  StyledCollapseButton,
  StyledContent,
  StyledTitle
} from "./styled";

const Collapse = ({ title, name, isOpened, children, onToggleCollapse }) => {
  return (
    <StyledCollapse>
      <StyledHeader isOpened={isOpened} onClick={onToggleCollapse}>
        <StyledTitle>{title}</StyledTitle>
        <StyledCollapseButton data-name={name} isOpened={isOpened} />
      </StyledHeader>
      <ReactCollapse isOpened={isOpened}>
        <StyledContent>{children}</StyledContent>
      </ReactCollapse>
    </StyledCollapse>
  );
};

Collapse.propTypes = {
  children: PropTypes.element.isRequired,
  isOpened: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onToggleCollapse: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default Collapse;
