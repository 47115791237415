import React from 'react';
import ReactDOM from 'react-dom';
import Pt from 'prop-types';

export default class Portal extends React.PureComponent {
  static propTypes = {
    children: Pt.node,
    overrideDefaults: Pt.bool
  };

  constructor(props) {
    super(props);
    this.portal = window.document.createElement('div');
    window.document.body.appendChild(this.portal);
  }

  componentWillUnmount() {
    setTimeout(() => this.portal.remove(), 100);
  }

  render() {
    const { children } = this.props;
    return ReactDOM.createPortal(children, this.portal);
  }
}
