import styled from "styled-components";

export const ModalBlock = styled.div`
  display: flex;
  justify-content: center;
  cursor: default;
`;

export const Block = styled.div`
  width: 308px;
  padding: 72px 24px 24px;
  position: relative;
  z-index: 1000;
  transition: all 0.3s ease 0s;
  pointer-events: all;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  animation: 0.2s linear 0s 1 normal none running iGalKV;
  position: relative;
`;

export const CloseImg = styled.img`
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
  transition: all 300ms;

  &:hover {
    opacity: 0.8;
  }
`;
