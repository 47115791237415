export const checkAdBlock = new Promise(resolve => {
  const testURL = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

  const testRequest = new Request(testURL, {
    method: 'HEAD',
    mode: 'no-cors'
  });

  fetch(testRequest)
    .then(result => result)
    .then(() => {
      resolve(false);
    })
    .catch(() => {
      resolve(true);
    });
});
