import React, { Component } from "react";
import Portal from "../Portal";
import Pt from "prop-types";
import close from "../../img/close_pop.svg";
import closeGrayIcon from "../../img/close_pop_gray.svg";
import { Link } from "react-router-dom";
import {
  ModalWindowScroller,
  ModalWindowWrapper,
  ModalWindow,
  ModalClose,
  ModalContainer,
  ModalWrap,
  Title,
  Body,
  ModalButtonGroup,
  Image,
  CLOSE_ICON_TYPE
} from "./styled";
import PropTypes from "prop-types";
import FocusLock from "react-focus-lock";

function isDescendant(parent, child) {
  let node = child.parentNode;
  while (node !== null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

function focusLockWhiteListChecker(node) {
  return !isDescendant(
    document.getElementsByClassName("lt-widget-wrap")[0],
    node
  );
}

export default class Modal extends Component {
  static defaultProps = {
    onCloseRedirect: "/",
    closeIconType: CLOSE_ICON_TYPE.DEFAULT
  };

  static propTypes = {
    closeIconType: PropTypes.string,
    containerType: PropTypes.string,
    handleClose: PropTypes.func,
    hiddenModal: PropTypes.bool,
    isLightPhoto: PropTypes.bool,
    lightBox: PropTypes.bool,
    modalContainer: PropTypes.object,
    modalOpen: PropTypes.bool,
    noCloseIcon: PropTypes.bool,
    fullModal: PropTypes.bool,
    onCloseRedirect: PropTypes.string,
    position: PropTypes.string
  };

  static Title = Title;
  static Body = Body;
  static ButtonGroup = ModalButtonGroup;
  static Image = Image;
  static closeIconTypes = CLOSE_ICON_TYPE;

  state = {
    modalOpen: true
  };

  lastHtmlFontSize = React.createRef(null);

  componentDidMount = () => {
    /**
     * скидываем размер шрифта до стандартного
     */
    this.lastHtmlFontSize.current = getComputedStyle(
      document.documentElement
    ).fontSize;
    document.documentElement.style.fontSize = "16px";
    document.body.style.overflow = "hidden";
  };

  componentWillUnmount = () => {
    document.documentElement.style.fontSize = this.lastHtmlFontSize.current;
    document.body.style.overflow = "auto";
  };

  getIconComponent() {
    switch (this.props.closeIconType) {
      case CLOSE_ICON_TYPE.BIG_GRAY:
        return <img height={30} width={30} src={closeGrayIcon} alt="close" />;
      default:
        return <img height={16} width={16} src={close} alt="close" />;
    }
  }

  render() {
    const Container = this.props.modalContainer || ModalContainer;
    return (
      <div>
        <Portal>
          {(this.props.modalOpen || this.state.modalOpen) && (
            <FocusLock whiteList={focusLockWhiteListChecker}>
              <ModalWrap>
                <ModalWindowWrapper fullModal={this.props.fullModal}>
                  {this.props.hiddenModal ? (
                    <span className="background" tabIndex={-1}>
                      {""}
                    </span>
                  ) : this.props.handleClose ? (
                    <span
                      className="background"
                      tabIndex={-1}
                      onClick={this.props.handleClose}
                    >
                      {""}
                    </span>
                  ) : (
                    <Link
                      className="background"
                      tabIndex={-1}
                      onClick={this.props.handleClose}
                      to={this.props.onCloseRedirect}
                    />
                  )}
                  <ModalWindowScroller>
                    <ModalWindow
                      fullModal={this.props.fullModal}
                      style={{ position: this.props.position }}
                      lightBox={this.props.isLightPhoto ? "unset" : null}
                    >
                      {this.props.hiddenModal ||
                      this.props.noCloseIcon ? null : (
                        <ModalClose iconType={this.props.closeIconType}>
                          {this.props.handleClose ? (
                            <span onClick={this.props.handleClose}>
                              {this.getIconComponent()}
                            </span>
                          ) : (
                            <Link
                              onClick={this.props.handleClose}
                              to={this.props.onCloseRedirect}
                            >
                              {this.getIconComponent()}
                            </Link>
                          )}
                        </ModalClose>
                      )}
                      <Container
                        fullModal={this.props.fullModal}
                        className={this.props.containerType}
                      >
                        {this.props.children}
                      </Container>
                    </ModalWindow>
                  </ModalWindowScroller>
                </ModalWindowWrapper>
              </ModalWrap>
            </FocusLock>
          )}
        </Portal>
      </div>
    );
  }
}

Modal.propTypes = {
  children: Pt.any,
  closeStatus: Pt.bool,
  onClose: Pt.func
};
