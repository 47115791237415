import styled from "styled-components";
import collapseIcon from "img/monitors-calculator-collapse-arrow.svg";

export const StyledCollapse = styled.div.attrs({
  className: "collapse"
})`
  margin-top: 24px;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledCollapseButton = styled.img.attrs({
  src: collapseIcon
})`
  cursor: pointer;
  transition: all 300ms;
  transform: rotate(180deg);
  ${({ isOpened }) => isOpened && "transform: rotate(0deg);"}
`;
