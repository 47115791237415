import styled from "styled-components";

export const StyledSidesInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
`;

export const StyledSideBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const StyledSideTr = styled.div`
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;

  &:first-child {
    margin-bottom: 8px;
  }
`;

export const StyledSideTd = styled.div`
  font-size: 0.75rem;
  display: flex;
  flex: 0 0 55%;
  align-items: center;

  ${({ name }) => name && `font-size: 1rem`};

  &:last-child {
    flex: 0 0 40%;
    justify-content: center;
  }
`;
