import { setToken, removeToken, validationErrorParser } from "../helpers/auth";

import createReducer from "./createReducer";
import {
  SUBMIT_SIGN_IN_VALIDATION_ERROR,
  SUBMIT_SIGN_IN_VALIDATION_SUCCESS,
  RESET_SIGN_IN,
  SUBMIT_SIGN_IN_SUCCESS,
  SUBMIT_SIGN_IN_ERROR,
  RESET_LOGIN,
  SUBMIT_LOGIN_SUCCESS,
  SUBMIT_LOGIN_ERROR,
  SUBMIT_LOGIN_START,
  RESET_RESTORE,
  SUBMIT_RESTORE_START,
  SUBMIT_RESTORE_SUCCESS,
  SUBMIT_RESTORE_ERROR,
  SUBMIT_RESTORE_CONFIRM_SUCCESS,
  SUBMIT_SIGN_IN_CONFIRM_SUCCESS,
  CONFIRM_RESET,
  SUBMIT_REQUEST_SUCCESS,
  RESET_REQUEST_SUCCESS,
  SUBMIT_APPLICATION,
  LOGOUT,
  GET_PROFILE,
  SIMPLE_MSB_REGISTRATION,
  RESET_MSB_ERRORS,
  SUBMIT_SIGN_IN_CONFIRM_MSB,
  CHEACK_EMAIL_REGISTER,
  SUBMIT_LOGIN_END,
  GET_ENV
} from "../constants/auth";
import { getReducerError, getReducerErrors } from "../../messages";

const initialState = {
  registerErrors: [],
  emailValidationError: null,
  innValidationError: null,
  successRegistration: false,
  successRestore: false,
  successLogin: false,
  successApplication: false,
  profile: null,
  loadMsbRegistration: false,
  successMsbRegistration: false,
  msbRegistrationErrors: [],
  emailMsbValidationError: [],
  successMsbConfirm: null,
  findUserByEmail: null,
  loadFindUserByEmail: null,
  loginLoading: null,
  confirmedEmail: false,
  envData: null
};

const consumers = {
  [GET_ENV.success]: (state, action) => ({
    ...state,
    envData: { ...action.result }
  }),
  [SUBMIT_SIGN_IN_VALIDATION_ERROR]: (state, action) => ({
    ...state,
    emailValidationError:
      action.action.prop === "email"
        ? getReducerError(action, "email", "auth")
        : state.emailValidationError,
    innValidationError:
      action.action.prop === "inn"
        ? getReducerError(action, "inn", "auth")
        : state.innValidationError
  }),
  [SUBMIT_SIGN_IN_VALIDATION_SUCCESS]: (state, action) => ({
    ...state,
    emailValidationError:
      action.action.prop === "email" ? null : state.emailValidationError,
    innValidationError:
      action.action.prop === "inn" ? null : state.innValidationError
  }),
  [SUBMIT_SIGN_IN_SUCCESS]: state => {
    return {
      ...state,
      successRegistration: true,
      registerErrors: [],
      emailValidationError: null
    };
  },
  [SUBMIT_SIGN_IN_ERROR]: (state, action) => {
    return {
      ...state,
      registerErrors: action.errors.length
        ? getReducerErrors(action, "auth")
        : [],
      emailValidationError: action.errors.length
        ? getReducerError(action, "email", "auth")
        : null
    };
  },
  [RESET_SIGN_IN]: state => ({
    ...state,
    successRegistration: false,
    emailValidationError: null,
    innValidationError: null
  }),
  [SUBMIT_LOGIN_START]: (state, action) => ({
    ...state,
    emailValidationError: null,
    loginLoading: true,
    emailMsbValidationError: []
  }),
  [SUBMIT_LOGIN_SUCCESS]: (state, action) =>
    setToken(action) || {
      ...state,
      loginLoading: false,
      emailValidationError: null,
      token: action.result.data.token,
      successLogin: true
    },
  [SUBMIT_LOGIN_END]: state => ({
    ...state,
    successLogin: false
  }),
  [SUBMIT_LOGIN_ERROR]: (state, action) => ({
    ...state,
    loginLoading: false,
    emailMsbValidationError: [{ message: "Неверный логин/пароль/капча" }],
    emailValidationError: {
      message:
        action.error.result.data.error === "access_denied"
          ? "Введен неверный логин или пароль."
          : action.error.result.data.error === "number_of_attempts_exhausted"
          ? "Превышено количество попыток ввода пароля."
          : action.error.result.data.error === "in_archive"
          ? "Пользователь в архиве."
          : action.error.result.data.error === "user_locked"
          ? "Пользователь заблокирован, повторите попытку через час."
          : null
    }
  }),
  [RESET_LOGIN]: state => ({
    ...state,
    successLogin: false,
    emailValidationError: null,
    successMsbRegistration: false,
    msbRegistrationErrors: [],
    emailMsbValidationError: []
  }),
  [RESET_RESTORE]: state => ({
    ...state,
    successRestore: false,
    emailValidationError: null
  }),
  [RESET_MSB_ERRORS]: state => {
    return {
      ...state,
      msbRegistrationErrors: [],
      emailMsbValidationError: []
    };
  },
  [SIMPLE_MSB_REGISTRATION.start]: (state, action) => {
    return {
      ...state,
      loadMsbRegistration: true,
      successMsbRegistration: false,
      msbRegistrationErrors: []
    };
  },
  [SIMPLE_MSB_REGISTRATION.success]: (state, action) => {
    return {
      ...state,
      loadMsbRegistration: false,
      successMsbRegistration: true
    };
  },
  [SIMPLE_MSB_REGISTRATION.error]: (state, action) => {
    const { errors } = action.error.result;
    return {
      ...state,
      loadMsbRegistration: false,
      msbRegistrationErrors: validationErrorParser(errors) || []
    };
  },
  [SIMPLE_MSB_REGISTRATION.end]: state => ({
    ...state,
    successMsbRegistration: false
  }),
  [SUBMIT_RESTORE_START]: state => ({
    ...state,
    successEmailRecover: null
  }),
  [SUBMIT_RESTORE_SUCCESS]: state => ({
    ...state,
    successEmailRecover: true,
    successRestore: true
  }),
  [SUBMIT_RESTORE_ERROR]: (state, action) => ({
    ...state,
    successEmailRecover: false,
    emailValidationError:
      action.error.result.message === "Advertiser not found"
        ? {
            message:
              "Пользователь с таким адресом электронной почты не зарегистрирован в системе"
          }
        : action.error.result
  }),
  [SUBMIT_RESTORE_CONFIRM_SUCCESS]: state => ({
    ...state,
    successConfirm: true
  }),
  [CONFIRM_RESET]: state => ({
    ...state,
    successConfirm: true
  }),
  [SUBMIT_SIGN_IN_CONFIRM_SUCCESS]: (state, action) => ({
    ...state,
    emailValidationError: null,
    successConfirm: true
  }),
  [SUBMIT_REQUEST_SUCCESS]: state => ({
    ...state,
    questionSuccess: true
  }),
  [RESET_REQUEST_SUCCESS]: state => ({
    ...state,
    questionSuccess: false
  }),
  [SUBMIT_APPLICATION.main]: state => ({
    ...state,
    successApplication: false
  }),
  [SUBMIT_APPLICATION.success]: state => ({
    ...state,
    successApplication: true
  }),
  [GET_PROFILE.success]: (state, action) => ({
    ...state,
    loaded: false,
    profile: action.result.data.advertiser || {}
  }),
  [LOGOUT]: state =>
    removeToken() || {
      ...state,
      token: null
    },
  [SUBMIT_SIGN_IN_CONFIRM_MSB.success]: (state, action) => {
    setToken(action);
    return {
      ...state,
      token: action.result.data.token,
      successMsbConfirm: true
    };
  },
  [SUBMIT_SIGN_IN_CONFIRM_MSB.end]: state => ({
    ...state,
    successMsbConfirm: false
  }),
  [CHEACK_EMAIL_REGISTER.main]: state => ({
    ...state,
    findUserByEmail: null,
    loadFindUserByEmail: true,
    confirmedEmail: false
  }),
  [CHEACK_EMAIL_REGISTER.success]: (state, { result }) => ({
    ...state,
    findUserByEmail: result.data.isRegistered,
    confirmedEmail: result.data.isConfirmed
  }),
  [CHEACK_EMAIL_REGISTER.end]: state => ({
    ...state,
    findUserByEmail: null,
    loadFindUserByEmail: false,
    confirmedEmail: false
  })
};

export default createReducer(initialState, consumers);
