import { takeEvery, call, put } from "redux-saga/effects";
import generateSagaWorker, { get, postJson } from "./generateSaga";
import {
  GET_PACKAGES,
  GET_PACKAGES_FORMATS,
  GET_PACKAGES_FOR_MIN_PRICE,
  GET_PACKAGES_SIZES
} from "../constants/packages";
import qs from "qs";

const sagaGenerator = generateSagaWorker(put, call);

const getPackages = get(
  sagaGenerator,
  action =>
    `/api/v1/public/data-packages?${qs.stringify(action.form, {
      indices: false
    })}`,
  GET_PACKAGES.types
);

const getPackagesForMinPrice = postJson(
  sagaGenerator,
  () => "/api/v1/commercial-campaigns/metroStickers/schedule",
  GET_PACKAGES_FOR_MIN_PRICE.types
);

const getPackagesFormats = get(
  sagaGenerator,
  () =>
    "/api/v1/public/data-formats?isActive=true&offset=0&limit=100&advertisePlaceId=metroStickers",
  GET_PACKAGES_FORMATS.types
);

const getPackageSizes = get(
  sagaGenerator,
  () => "/api/v1/public/data-package-sizes?isActive=true&offset=0&limit=100",
  GET_PACKAGES_SIZES.types
);

export default function* packages() {
  yield takeEvery(GET_PACKAGES.main, getPackages);
  yield takeEvery(GET_PACKAGES_FOR_MIN_PRICE.main, getPackagesForMinPrice);
  yield takeEvery(GET_PACKAGES_FORMATS.main, getPackagesFormats);
  yield takeEvery(GET_PACKAGES_SIZES.main, getPackageSizes);
}
