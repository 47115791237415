export const setToken = ({ result }) => {
  localStorage.setItem("t___", result.data.token);
  localStorage.setItem("loggedIn", true);
  return;
};

export const removeToken = () => {
  if (localStorage.getItem("t___") !== null) {
    localStorage.removeItem("t___");
  }
};

export const getToken = () => localStorage.getItem("t___");

export const validationErrorParser = errors => {
  const result = [];

  const email = errors.find(x => x.field === "email");
  const phone = errors.find(x => x.field === "phone");
  const fullName = errors.find(x => x.field === "fullName");
  const advertisePlaceId = errors.find(x => x.field === "advertisePlaceId");
  const gRecaptchaResponse = errors.find(x => x.field === "gRecaptchaResponse");

  if (fullName && fullName.message) {
    switch (fullName.message) {
      case "required":
        result.push({ field: "fullName", message: "ФИО отсутствует" });
        break;
      default:
        result.push({ field: "fullName", message: "Ошибка в поле ФИО" });
        break;
    }
  }

  if (phone && phone.message) {
    switch (phone.message) {
      case "required":
        result.push({ field: "phone", message: "Телефон отсутствует" });
        break;
      case "already in use":
        result.push({
          field: "phone",
          message: "Этот телефон уже используется"
        });
        break;
      case "wrong format":
        result.push({
          field: "phone",
          message: "Неправильный формат в поле - телефон"
        });
        break;
      default:
        result.push({ field: "phone", message: "Ошибка в поле телефон" });
        break;
    }
  }

  if (email && email.message) {
    switch (email.message) {
      case "required":
        result.push({ field: "email", message: "E-mail отсутствует" });
        break;
      case "already in use":
        result.push({
          field: "email",
          message: "Этот e-mail уже используется"
        });
        break;
      default:
        result.push({ field: "email", message: "Ошибка в поле e-mail" });
        break;
    }
  }

  if (advertisePlaceId && advertisePlaceId.message) {
    switch (advertisePlaceId.message) {
      case "required":
        result.push({
          field: "advertisePlaceId",
          message: "Отсутствует выбранный рекламоноситель"
        });
        break;
      default:
        result.push({
          field: "advertisePlaceId",
          message: "Выбранный рекламоноситель - непредвиденная ошибка"
        });
        break;
    }
  }

  if (gRecaptchaResponse && gRecaptchaResponse.message) {
    switch (gRecaptchaResponse.message) {
      case "required":
        result.push({
          field: "gRecaptchaResponse",
          message: "Отсутствует капча"
        });
        break;
      default:
        result.push({
          field: "gRecaptchaResponse",
          message: "Капча - непредвиденная ошибка"
        });
        break;
    }
  }

  return result;
};
