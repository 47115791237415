import { createStore } from "redux";

import rootReducer from "./reducers";
import enhancer from "./middlewares";
import sagaMiddleware from "./middlewares/saga";
import rootSaga from "./sagas";

export const store = createStore(
  rootReducer,
  { auth: { token: localStorage.getItem("t___") } },
  enhancer
);

sagaMiddleware.run(rootSaga);

export default store;
