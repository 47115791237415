import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ContainerMain } from "components/Container";

import WithAdaptive from "hoc/WithAdaptive";

import logo from "img/logo_new.png";

import SoftwareModal from "./SoftwareModal";
import FormModal from "./FormModal";
import Form from "./Form";

import { sendFeedback, resetErrorFeedback } from "store/actions/feedback";

import {
  Page,
  PageInner,
  Bakcground,
  Header,
  BackWrapper,
  BackButton,
  BackArrowWrapper,
  BackArrow,
  Description,
  HeadTitle,
  ListButtonDoc,
  ButtonDoc,
  Col,
  Row,
  MainTitle,
  MainDescription,
  BackgroundImage,
  PriceInfo
} from "./styled";

function Software() {
  const dispatch = useDispatch();

  const { isFeedbackLoading, errorFeedback, successFeedback } = useSelector(
    ({ feedback }) => feedback
  );

  const [softwareModalOpen, setSoftwareModalOpen] = useState(false);
  const [testVertionModalOpen, setTestVertionModalOpen] = useState(false);
  const [commercialModalOpen, setCommercialModalOpen] = useState(false);

  const initState = {
    phoneNumber: "",
    firstName: "",
    lastName: "",
    email: "",
    message: ""
  };

  const [formFeedback, setFormFeedback] = useState(initState);

  const handleFormFeedback = (key, val) => {
    setFormFeedback(prevState => ({ ...prevState, [key]: val }));
    dispatch(resetErrorFeedback());
  };

  const onSendFeedback = topic => {
    const form = { ...formFeedback };
    form.fio = form.firstName + " " + form.lastName;
    form.topic = topic;

    delete form.firstName;
    delete form.lastName;

    dispatch(sendFeedback(form));
  };

  useEffect(() => {
    if (successFeedback) {
      if (testVertionModalOpen) setTestVertionModalOpen(false);
      if (commercialModalOpen) setCommercialModalOpen(false);

      setFormFeedback(initState);
    }
  }, [commercialModalOpen, initState, successFeedback, testVertionModalOpen]);

  return (
    <Page>
      <PageInner>
        <SoftwareModal
          isOpen={softwareModalOpen}
          onRequestClose={() => setSoftwareModalOpen(false)}
        />
        <FormModal
          error={errorFeedback}
          topic={"software_test_vartion"}
          formFeedback={formFeedback}
          handleFormFeedback={handleFormFeedback}
          onSendFeedback={onSendFeedback}
          submitDisabed={isFeedbackLoading}
          title={"Запросить тестовую версию"}
          isOpen={testVertionModalOpen}
          onRequestClose={() => setTestVertionModalOpen(false)}
        />
        <FormModal
          error={errorFeedback}
          topic={"software_commercial_proposal"}
          formFeedback={formFeedback}
          handleFormFeedback={handleFormFeedback}
          onSendFeedback={onSendFeedback}
          submitDisabed={isFeedbackLoading}
          title={"Запросить коммерческое предложение"}
          isOpen={commercialModalOpen}
          onRequestClose={() => setCommercialModalOpen(false)}
        />
        <Bakcground>
          <ContainerMain>
            <Header>
              <BackWrapper>
                <BackButton href="/">
                  <BackArrowWrapper>
                    <BackArrow />
                  </BackArrowWrapper>
                  <span>на главную</span>
                </BackButton>
              </BackWrapper>
              <img src={logo} height={75} alt="" />
            </Header>
            <MainTitle>Nebo.digital</MainTitle>
            <MainDescription>
              Автоматизированная система медиапланирования и размещения
              медиаконтента Информационно-рекламный сервис (ИРС)
            </MainDescription>
            <Row>
              <Col>
                <BackgroundImage />
              </Col>
              <Col>
                <Description>
                  <p>
                    Программа предназначена для автоматизации процесса
                    размещения (от бронирования до завершения рекламной
                    кампании) рекламных материалов рекламодателей на различных
                    ресурсах и объектах третьих лиц, находящихся в РФ, а также в
                    сети Интернет.
                  </p>
                  <p>
                    Работа пользователя в программе обеспечивается с компьютера
                    или мобильного устройства, подключенного к сети Интернет в
                    веб-интерфейсе рекламодателя и менеджера.
                  </p>
                  <p>
                    Языки:
                    <br />
                    PHP, Javascript, React.
                  </p>
                  <p></p>
                  <p>
                    Вид и версия операционной системы:
                    <br />
                    Windows, Linux.
                  </p>
                </Description>
              </Col>
            </Row>
            <Row>
              <ListButtonDoc>
                <ButtonDoc
                  onClick={() =>
                    window.open(
                      "https://cdn.nebo.digital/assets/docs/%D0%A1%D0%B2%D0%B8%D0%B4%D0%B5%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D1%82%D0%B2%D0%BE%20%D0%BE%20%D1%80%D0%B5%D0%B3%D0%B8%D1%81%D1%82%D1%80%D0%B0%D1%86%D0%B8%D0%B8%20%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D1%8B%20%D0%B4%D0%BB%D1%8F%20%D0%AD%D0%92%D0%9C.pdf",
                      "_blank"
                    )
                  }
                >
                  Свидетельство о регистрации
                </ButtonDoc>
                <ButtonDoc onClick={() => setSoftwareModalOpen(true)}>
                  Скачать документацию
                </ButtonDoc>
                <ButtonDoc onClick={() => setTestVertionModalOpen(true)}>
                  Запросить тестовую версию
                </ButtonDoc>
                <ButtonDoc onClick={() => setCommercialModalOpen(true)}>
                  Запросить коммерческое предложение
                </ButtonDoc>
              </ListButtonDoc>
            </Row>
          </ContainerMain>
        </Bakcground>
        <PriceInfo>
          Стоимость программного обеспечения формируется на основании
          индивидуального коммерческого предложения по запросу.
        </PriceInfo>
        <HeadTitle>обратная связь</HeadTitle>
        <Form
          error={errorFeedback}
          topic={"software_default"}
          formFeedback={formFeedback}
          handleFormFeedback={handleFormFeedback}
          onSendFeedback={onSendFeedback}
          submitDisabed={isFeedbackLoading}
        />
      </PageInner>
    </Page>
  );
}

export default WithAdaptive(Software);
