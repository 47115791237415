import React, { Component } from "react";

export class AuthAuto extends Component {
  componentDidMount() {
    localStorage.removeItem("t___");
    window.addEventListener("message", event => {
      if (
        event.origin === "https://alexadmin.nebo.digital" ||
        event.origin === "https://dev-alexadmin.nebo.digital" ||
        event.origin === "https://stage-alexadmin.nebo.digital"
      ) {
        const urlCampaigns = `${event.data.frontUrl}/app/campaigns`;

        localStorage.setItem("t___", event.data.token);
        window.open(urlCampaigns);
      }
    });
  }
  render() {
    return <div>Wait....</div>;
  }
}
