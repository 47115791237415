import { takeEvery, call, put } from "redux-saga/effects";
import generateSagaWorker, { get, post } from "./generateSaga";
import {
  GET_LANDING_DOCS,
  GET_LANDING_DOC,
  POST_COMMENT,
  POST_FILE
} from "../constants/beautyMonitors";
import qs from "qs";
import { getTokenHeader } from "./generateSaga";
import { createFormData } from "store/helpers/monitorsCalculator";
import { saveAs } from "file-saver";

const sagaGenerator = generateSagaWorker(put, call);

const getLandingDocs = get(
  sagaGenerator,
  action => `/api/v1/public/landing-docs?${qs.stringify(action.form)}`,
  GET_LANDING_DOCS.types
);

function* postComment({ form }) {
  try {
    yield put({
      type: POST_COMMENT.start
    });
    const response = yield call(
      fetch,
      `/api/v1/${form.isAuthorized ? "advertisers" : "public"}/feedback`,
      {
        method: "POST",
        body: createFormData(form),
        headers: {
          ...getTokenHeader()
        }
      }
    );

    if (!response.ok) {
      throw new Error();
    }

    yield put({
      type: POST_COMMENT.success
    });
  } catch (error) {
    yield put({
      type: POST_COMMENT.error
    });
  }
}

function* getLandingDoc(action) {
  try {
    yield put({
      type: GET_LANDING_DOC.start
    });
    const response = yield call(
      fetch,
      `/api/v1/public/landing-docs/download/${action.form.fileId}`,
      {
        method: "GET"
      }
    );

    if (!response.ok) {
      throw new Error();
    }

    const blob = yield response.blob();

    saveAs(blob, action.form.fileName);

    yield put({
      type: GET_LANDING_DOC.success
    });
  } catch (error) {
    yield put({
      type: GET_LANDING_DOC.error
    });
  }
}

const uploadFile = post(
  sagaGenerator,
  () => `/api/v1/public/feedback/file`,
  POST_FILE.types
);

export default function* beautyMonitors() {
  yield takeEvery(GET_LANDING_DOCS.main, getLandingDocs);
  yield takeEvery(GET_LANDING_DOC.main, getLandingDoc);
  yield takeEvery(POST_COMMENT.main, postComment);
  yield takeEvery(POST_FILE.main, uploadFile);
}
