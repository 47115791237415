import styled from "styled-components";

export const StyledPageLoader = styled.div`
  text-align: center;
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`;
