import createReducer from "./createReducer";
import {
  GET_LINES,
  GET_STATIONS,
  SEARCH_STATIONS,
  CLEAR_STATIONS,
  GET_GLOBAL_DISCOUNT_FACTORS,
  GET_ADVERTISER_DISCOUNT_AMOUNTS,
  RESET_DISCOUNTS
} from "../constants/metro";

const initialState = {
  stations: [],
  stationsLoading: false,
  lines: [],
  linesLoading: false,
  searchLoading: false,
  searchResult: [],
  GlobalDiscountFactors: [],
  factorsLoaded: false,
  DiscountsPerVolume: [],
  perVolumeLoaded: false
};

const consumers = {
  [GET_LINES.main]: state => ({
    ...state,
    linesLoading: true
  }),
  [GET_LINES.success]: (state, action) => ({
    ...state,
    linesLoading: false,
    lines:
      action.result.data.metroLines.sort((a, b) =>
        parseInt(a.number) > parseInt(b.number) ? 1 : -1
      ) || []
  }),
  [GET_STATIONS.main]: state => ({
    ...state,
    stationsLoading: true
  }),
  [GET_STATIONS.success]: (state, action) => ({
    ...state,
    stationsLoading: false,
    stations:
      action.result.data.metroStations.sort((a, b) =>
        a.name > b.name ? 1 : -1
      ) || []
  }),
  [SEARCH_STATIONS.main]: state => ({
    ...state,
    searchLoading: true
  }),
  [SEARCH_STATIONS.success]: (state, action) => ({
    ...state,
    searchLoading: false,
    searchResult: action.result.data.metroStations || []
  }),
  [CLEAR_STATIONS]: state => ({
    ...state,
    stations: []
  }),
  [GET_GLOBAL_DISCOUNT_FACTORS.main]: state => ({
    ...state,
    factorsLoaded: false
  }),
  [GET_GLOBAL_DISCOUNT_FACTORS.success]: (state, action) => ({
    ...state,
    GlobalDiscountFactors: action.result.data.GlobalDiscountFactors || [],
    factorsLoaded: true
  }),
  [GET_GLOBAL_DISCOUNT_FACTORS.error]: state => ({
    ...state
  }),
  [GET_ADVERTISER_DISCOUNT_AMOUNTS.main]: state => ({
    ...state,
    perVolumeLoaded: false
  }),
  [GET_ADVERTISER_DISCOUNT_AMOUNTS.success]: (state, action) => ({
    ...state,
    DiscountsPerVolume: action.result.data.DiscountsPerVolume || [],
    perVolumeLoaded: true
  }),
  [GET_ADVERTISER_DISCOUNT_AMOUNTS.error]: state => ({
    ...state
  }),
  [RESET_DISCOUNTS]: state => ({
    ...state,
    perVolumeLoaded: false,
    factorsLoaded: false
  })
};

export default createReducer(initialState, consumers);
