import styled from "styled-components";
import theme from "../../theme";

export const FormGroup = styled.label`
  position: relative;
  margin-bottom: 1.625rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  & .css-ssi0ig-container {
    width: 100%;
  }

  & .css-1hwfws3 {
    padding: 6px 8px;
  }

  &.liner {
    flex-wrap: nowrap;
  }

  &.plain-group {
    align-items: center;
    justify-content: space-between;

    > label {
      position: relative;
      display: inline-block;
      top: auto;
      left: 0;
      transform: none;
      margin: 0 3px 0 0;
      font-size: 0.875rem;
    }
  }

  .Select-arrow {
    border-top-color: #d8dfe5;
  }

  & .Select.has-value .Select-control {
    background-color: #fafbfc !important;
  }

  & .Select.is-disabled .Select-control {
    background-color: #ebebe4 !important;
  }

  .Select-control > *:last-child {
    padding-right: 1.25rem;
  }

  & .Select .Select-value-label {
    color: ${theme.dark};
    font-weight: 400;
  }

  & .Select {
    width: 100%;
    border-radius: 3px;

    input:focus {
      box-shadow: none;
    }
  }

  & .Select-menu-outer {
    .Select-option.is-focused {
      background-color: ${theme.transparentize(theme.primary, 0.1)};
    }
  }

  & .Select--multi {
    & .Select-multi-value-wrapper {
      display: block;
      padding-top: 14px !important;
      padding-left: 20px !important;
    }

    & .Select-value {
      margin-top: 0.5rem;
      background-color: ${theme.transparentize(theme.primary, 0.08)};
      border-radius: 0.125rem;
      border: 1px solid ${theme.transparentize(theme.primary, 0.2)};
      color: ${theme.dark};
    }

    & .Select-value-icon {
      border-right: 1px solid ${theme.transparentize(theme.primary, 0.2)};
    }

    & .Select-value-icon:hover,
    .Select--multi .Select-value-icon:focus {
      background-color: ${theme.transparentize(theme.primary, 0.1)};
      color: ${theme.primary};
    }

    & .Select-input {
      height: auto;
      margin-left: 0.375rem;
      padding-left: 0 !important;
    }
    & .Select-placeholder {
      line-height: 4.125rem;
    }
    & .Select-input > input {
      padding: 0.625rem 0 0.75rem;
    }
  }

  & .Select-control {
    height: 3.25rem;
    border: 1px solid #e0e3e5;
  }

  & .Select-menu-outer {
    z-index: 2;
  }

  & .Select--single > .Select-control .Select-value,
  .Select-placeholder {
    line-height: 3.875rem;
    padding-left: 1.625rem;
    color: #c0c5d8;
  }

  & .Select-input {
    padding-left: 1.625rem;
    border: 0 !important;
  }

  & .Select-input > input {
    padding: 1.375rem 0 0.75rem;
    border: 0;
  }

  & label {
    color: ${theme.gray};
    font-weight: 400;
    font-size: 0.625rem;
    transform: translate(0, -5px) scale(1);
    top: -6px;
    left: 0.75rem;
    position: absolute;
    transform: translate(0, 8px) scale(1);
    z-index: 1;
  }

  & input,
  & textarea {
    font-size: 0.875rem;
    border-radius: 4px;
    border: 1px solid #e0e3e5;
    padding: 1.5rem 0px 0.625rem 1.625rem;
    width: 100%;
    font-weight: 400;

    &:focus {
      outline: 0;
    }

    &::placeholder {
      font-weight: 300;
      color: #c0c5d8;
      opacity: 1;
    }
  }

  & .Select,
  & input,
  & textarea {
    transition: 0.3s ease-in-out;
    &.is-open,
    &:focus {
      box-shadow: 0 0 0 3px ${theme.transparentize(theme.primary, 0.5)};
      border-color: ${theme.white};
    }
  }

  & textarea {
    min-height: 4.125rem;
    resize: vertical;
  }

  & .close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.75rem;
  }

  &.checkbox,
  &.radio {
    display: block;
    position: relative;
    padding-left: 1.625rem;
    padding-top: 0.25rem;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 300;
    width: auto;

    & a {
      color: ${theme.primary};
    }

    & input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      width: 0;
      height: 0;
      visibility: hidden;
    }

    & span {
      display: block;
      position: absolute;
      top: 0.25rem;
      left: 0;
      height: 1.125rem;
      width: 1.125rem;
      background: #fff;
      border-radius: 2px;
      border: solid 1px #e0e3e5;
    }

    &:hover input ~ span,
    & input:focus ~ .span {
      background: #cccccc;
    }

    & input:checked ~ span {
      background: #fff;
      border: 1px solid ${theme.primary};
    }

    &:hover input:not([disabled]):checked ~ span,
    & input:checked:focus ~ span {
      background: #fff;
    }

    & input:disabled ~ span {
      background: #e6e6e6;
      opacity: 0.6;
      pointer-events: none;
    }

    & span:after {
      box-sizing: unset;
      content: "";
      position: absolute;
      display: none;
    }

    & input:checked ~ span:after {
      display: block;
    }

    & input:disabled ~ span:after {
      border-color: #7b7b7b;
    }
  }

  &.checkbox {
    & span:after {
      left: 0.3125rem;
      top: 0.0625rem;
      width: 0.1875rem;
      height: 0.5rem;
      border: solid ${theme.primary};
      border-width: 0 0.125rem 0.125rem 0;
      transform: rotate(45deg);
    }
  }

  &.radio {
    & span:after {
      left: 0.1875rem;
      top: 0.1875rem;
      width: 0.625rem;
      height: 0.625rem;
      background-color: ${theme.primary};
      border-radius: 20%;
    }
  }

  &.switch {
    display: block;
    position: relative;
    cursor: pointer;
    width: 2rem;
    height: 1.125rem;

    & input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      width: 0;
      height: 0;
      visibility: hidden;
    }

    & span {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      transition: 0.4s;
      border-radius: 32px;
      border: solid 1px #e0e3e5;

      &:before {
        position: absolute;
        content: "";
        left: 0.0625rem;
        top: 0.0625rem;
        height: 1rem;
        width: 1rem;
        background-color: #e0e3e5;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
      }
    }

    &:hover input ~ span,
    & input:focus ~ .span {
      background: #e0e3e5;
      &:before {
        background-color: ${theme.primary};
      }
    }

    & input:checked ~ span {
      background-color: ${theme.primary};
      border: 1px solid ${theme.primary};
      &:before {
        background-color: #fff;
        transform: translateX(17px);
      }
    }

    &:hover input:not([disabled]):checked ~ span,
    & input:checked:focus ~ span {
      background: #6fc6ef;
      box-shadow: 0 0 1px #2196f3;
      &:before {
        background-color: #fff;
      }
    }

    & input:disabled ~ span {
      background: #e6e6e6;
      opacity: 0.6;
      pointer-events: none;
    }

    & span:after {
      box-sizing: unset;
      content: "";
      position: absolute;
      display: none;
    }

    & input:checked ~ span:after {
      display: block;
    }

    & input:disabled ~ span:after {
      border-color: #7b7b7b;
    }
  }

  .input-warning {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    align-items: center;
    right: 1.25rem;
  }

  &.error input {
    border-color: #d63a26;
  }

  &.error label {
    opacity: 0.9;
    color: #d63a26;
  }

  &.inline {
    display: inline-block;
    vertical-align: middle;
  }

  &.error span,
  &.password span,
  &.error .error-text,
  &.error .input-warning {
    display: flex;
  }
`;

export const ModalInputGroupWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

export const FormDate = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

export const ErrorInput = styled.div`
  display: none;
  width: 100%;
  color: #d63a26;
  flex: 1 1 auto;
  font-size: 0.75rem;
  padding-top: 0.3125rem;
  font-weight: 400;
  align-items: center;

  img {
    margin: 0.4rem 0.6rem;
  }

  span:first-child img {
    margin-left: 0.2rem;
  }
`;

export const ErrorEma = styled.div`
  display: none;
  width: 75%;
  color: #d63a26;
  flex: 1 1 auto;
  font-size: 0.75rem;
  font-weight: 400;
  align-items: center;
  margin-inline-start: -12px;

  img {
    margin: 0.4rem 0.6rem;
  }

  span:first-child img {
    margin-left: 0.2rem;
  }
`;

export const ErrorStr = styled.div`
  display: none;
  width: 75%;
  color: #d63a26;
  flex: 1 1 auto;
  font-size: 0.75rem;
  font-weight: 400;
  align-items: center;
  margin-inline-start: -12px;

  img {
    margin: 0.4rem 0.6rem;
  }

  span:first-child img {
    margin-left: 0.2rem;
  }
`;

export const MainFormGroup = styled(FormGroup)`
  &.disabled .react-datepicker__input-container input {
    background: #ebebe4;
  }

  & .Select {
    width: 100%;
    font-size: 0.875rem;

    &.is-focused:not(.is-open) > .Select-control {
      border: 1px solid ${theme.primary};
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 0 3px ${theme.transparentize(theme.primary, 0.07)};
    }
  }

  & .Select-input > input {
    border: 0 !important;
  }

  & .close {
    padding-left: 1.5625rem;
    padding-right: 1.9375rem;
    cursor: pointer;
  }
`;
