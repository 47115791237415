import { gqlRequest } from "../";
import { gql } from "graphql-request";

export const signInReport = async ({ form }) => {
  const query = gql`
    mutation {
      signIn (input: {email: "${form.email}" , password: "${form.password}"}){
        user {
          displayName
        }
        token
      }
    }
  `;
  return await gqlRequest(query);
};

export const whoAmIReport = async () => {
  const query = gql`
    query {
      whoAmI {
        name
        displayName
        firstName
        lastName
        id
      }
    }
  `;
  return await gqlRequest(query);
};

export const workOrdersReport = async () => {
  const query = gql`
    query($filter: WorkOrderFilter) {
      workOrders(paging: { limit: 50 }, filter: $filter) {
        nodes {
          advertisingSpace {
            side {
              name
              displayName
            }
            advertisingPlatform {
              platformNumber
            }
          }
          id
          createdAt
          brandNameAMPP
          user {
            name
          }
          photos {
            id
            url
          }
          transitSignPhotoUrl
          advertisingCampaignStartDate
          advertisingCampaignEndDate
        }
      }
    }
  `;
  const variables = {
    filter: {
      advertisingSpace: {
        advertisingPlatform: {
          platformNumber: { like: "4-06-%" }
        },
        side: {
          name: {
            eq: "a"
          }
        }
      }
    }
  };
  return await gqlRequest(query, variables);
};

export const workOrdersByPlatformReport = async ({ form }) => {
  const query = gql`
    query($filter: WorkOrderFilter) {
      workOrders(paging: { limit: 50 }, filter: $filter) {
        nodes {
          advertisingSpace {
            side {
              name
              displayName
            }
            advertisingPlatform {
              platformNumber
              displayName
            }
          }
          id
          createdAt
          brandNameAMPP
          user {
            name
          }
          comment
          photos {
            id
            url
          }
          rimName
          transitSignPhotoUrl
          advertisingCampaignStartDate
          advertisingCampaignEndDate
        }
      }
    }
  `;
  const variables = {
    filter: {
      advertisingSpace: {
        advertisingPlatform: {
          platformNumber: { like: `${form.platformNumber}%` }
        }
      },
      type: {
        name: { eq: "installation" }
      },
      status: {
        name: { eq: "planned" }
      },
      and: [
        { plannedInstallationDate: { lte: form.endDate } },
        { plannedInstallationDate: { gte: form.startDate } }
      ]
    }
  };
  return await gqlRequest(query, variables);
};

export const importInstallationPhotoReport = async ({ form }) => {
  const query = gql`
    mutation($file: Upload!) {
      importInstallationPhoto (workOrderId: "${form.workOrderId}", photoType: ${form.photoType}, photo: $file)
    }
  `;

  const variables = {
    file: form.file
  };
  return await gqlRequest(query, variables);
};

export const updateOneWorkOrderReport = async ({ form }) => {
  const { workOrderId, userId, factualInstallationDate, status } = form;
  const query = gql`
    mutation($input: UpdateOneWorkOrderInput!) {
      updateOneWorkOrder(input: $input) {
        user {
          name
        }
      }
    }
  `;

  const variables = {
    input: {
      id: workOrderId,
      update: {
        user: userId,
        factualInstallationDate,
        status
      }
    }
  };
  return await gqlRequest(query, variables);
};
