import generateTypes from "./constantsGenerator";

export const SUBMIT_REQUEST = "SUBMIT_REQUEST";
export const SUBMIT_REQUEST_START = "SUBMIT_REQUEST_START";
export const SUBMIT_REQUEST_SUCCESS = "SUBMIT_REQUEST_SUCCESS";
export const SUBMIT_REQUEST_ERROR = "SUBMIT_REQUEST_ERROR";

export const SUBMIT_LOGIN = "SUBMIT_LOGIN";
export const SUBMIT_LOGIN_START = "SUBMIT_LOGIN_START";
export const SUBMIT_LOGIN_SUCCESS = "SUBMIT_LOGIN_SUCCESS";
export const SUBMIT_LOGIN_ERROR = "SUBMIT_LOGIN_ERROR";
export const SUBMIT_LOGIN_END = "SUBMIT_LOGIN_END";

export const RESET_LOGIN = "RESET_LOGIN";

export const SUBMIT_SIGN_IN = "SUBMIT_SIGN_IN";
export const SUBMIT_SIGN_IN_START = "SUBMIT_SIGN_IN_START";
export const SUBMIT_SIGN_IN_SUCCESS = "SUBMIT_SIGN_IN_SUCCESS";
export const SUBMIT_SIGN_IN_ERROR = "SUBMIT_SIGN_IN_ERROR";

export const RESET_SIGN_IN = "RESET_SIGN_IN";

export const SUBMIT_SIGN_IN_VALIDATION = "SUBMIT_SIGN_IN_VALIDATION";
export const SUBMIT_SIGN_IN_VALIDATION_START =
  "SUBMIT_SIGN_IN_VALIDATION_START";
export const SUBMIT_SIGN_IN_VALIDATION_SUCCESS =
  "SUBMIT_SIGN_IN_VALIDATION_SUCCESS";
export const SUBMIT_SIGN_IN_VALIDATION_ERROR =
  "SUBMIT_SIGN_IN_VALIDATION_ERROR";

export const SUBMIT_SIGN_IN_CONFIRM = "SUBMIT_SIGN_IN_CONFIRM";
export const SUBMIT_SIGN_IN_CONFIRM_START = "SUBMIT_SIGN_IN_CONFIRM_START";
export const SUBMIT_SIGN_IN_CONFIRM_SUCCESS = "SUBMIT_SIGN_IN_CONFIRM_SUCCESS";
export const SUBMIT_SIGN_IN_CONFIRM_ERROR = "SUBMIT_SIGN_IN_CONFIRM_IN_ERROR";

export const SUBMIT_RESTORE = "SUBMIT_RESTORE";
export const SUBMIT_RESTORE_START = "SUBMIT_RESTORE_START";
export const SUBMIT_RESTORE_SUCCESS = "SUBMIT_RESTORE_SUCCESS";
export const SUBMIT_RESTORE_ERROR = "SUBMIT_RESTORE_ERROR";

export const RESET_RESTORE = "RESET_RESTORE";

export const SUBMIT_RESTORE_CONFIRM = "SUBMIT_RESTORE_CONFIRM";
export const SUBMIT_RESTORE_CONFIRM_START = "SUBMIT_RESTORE_CONFIRM_START";
export const SUBMIT_RESTORE_CONFIRM_SUCCESS = "SUBMIT_RESTORE_CONFIRM_SUCCESS";
export const SUBMIT_RESTORE_CONFIRM_ERROR = "SUBMIT_RESTORE_CONFIRM_ERROR";

export const CONFIRM_RESET = "CONFIRM_RESET";

export const RESET_REQUEST_SUCCESS = "RESET_REQUEST_SUCCESS";

export const LOGOUT = "LOGOUT";

export const SUBMIT_APPLICATION = generateTypes("SUBMIT_APPLICATION");

export const GET_PROFILE = generateTypes("GET_PROFILE");

//new
export const SIMPLE_MSB_REGISTRATION = generateTypes("SIMPLE_MSB_REGISTRATION");
export const RESET_MSB_ERRORS = "RESET_MSB_ERRORS";
export const POST_FAST_ORDER = generateTypes("POST_FAST_ORDER");
export const RESET_FAST_ORDER = "RESET_FAST_ORDER";
export const SUBMIT_SIGN_IN_CONFIRM_MSB = generateTypes(
  "SUBMIT_SIGN_IN_CONFIRM_MSB"
);

export const CHEACK_EMAIL_REGISTER = generateTypes("CHEACK_EMAIL_REGISTER");
export const GET_ENV = generateTypes("GET_ENV");
