import generateTypes from "./constantsGenerator";

export const GET_LANDING_DOCS = generateTypes("GET_LANDING_DOCS");
export const GET_LANDING_DOC = generateTypes("GET_LANDING_DOC");

export const GET_METRO_LINES_REPORT = generateTypes("GET_METRO_LINES_REPORT");
export const GET_CAMPAIGN_PERIODS = generateTypes("GET_CAMPAIGN_PERIODS");
export const GET_CITYFORMATS_REPORT = generateTypes("GET_CITYFORMATS_REPORT");
export const GET_FORMATS_REPORT = generateTypes("GET_FORMATS_REPORT");
export const GET_FORMATS_REPORT_FOR_STEP_MEDIA_CITY_FORMAT = generateTypes(
  "GET_FORMATS_REPORT_FOR_STEP_MEDIA_CITY_FORMAT"
);
export const GET_FORMATS_REPORT_FOR_STEP_MEDIA_CITY_FORMAT_TABLE = generateTypes(
  "GET_FORMATS_REPORT_FOR_STEP_MEDIA_CITY_FORMAT_TABLE"
);
export const GET_CITYFORMAT_PLANNER = generateTypes("GET_CITYFORMAT_PLANNER");
export const UPDATE_BASKET_CITYFORMATS = generateTypes(
  "UPDATE_BASKET_CITYFORMATS"
);
export const INIT_BASKET_CITYFORMATS = generateTypes("INIT_BASKET_CITYFORMATS");
export const GET_CITYFORMATS_REPORT_SEARCH = generateTypes(
  "GET_CITYFORMATS_REPORT_SEARCH"
);
export const GET_ALL_CITYFORMATS_REPORT = generateTypes(
  "GET_ALL_CITYFORMATS_REPORT"
);
export const GET_CITYFORMATS_PRICES_REPORT = generateTypes(
  "GET_CITYFORMATS_PRICES_REPORT"
);
export const GET_METRO_STATIONS_REPORT = generateTypes(
  "GET_METRO_STATIONS_REPORT"
);
export const GET_CITYFORMAT_MEDIAPLAN = generateTypes(
  "GET_CITYFORMAT_MEDIAPLAN"
);

export const SELECT_METRO_LINE = "SELECT_METRO_LINE";
export const SELECT_DURATION = "SELECT_DURATION";
export const SELECT_FREQUENCY = "SELECT_FREQUENCY";
export const SELECT_PERIOD = "SELECT_PERIOD";
export const SELECT_STATION = "SELECT_STATION";
export const SELECT_CITYFORMAT = "SELECT_CITYFORMAT";
export const RESET_CITYFORMATS_STORE = "RESET_CITYFORMATS_STORE";
export const CHANGE_PAGINATION = "CHANGE_PAGINATION";
export const SELECT_CUSTOM_PERIOD = "SELECT_CUSTOM_PERIOD";
export const SELECT_HALF_ARIGINS = "SELECT_HALF_ARIGINS";
export const SELECT_AVAILABLE_FOR_SALE = "SELECT_AVAILABLE_FOR_SALE";
export const CHANGE_SELECTED_SCH = "CHANGE_SELECTED_SCH";
export const TOGGLE_OPEN_ADVERTISE_VIDEO = "TOGGLE_OPEN_ADVERTISE_VIDEO";
export const SET_SEARCH_BY_TYPE = "SET_SEARCH_BY_TYPE";
export const SEARCH_STATION = "SEARCH_STATION";

export const PUT_CITY_FORMAT_SCHEDULE = generateTypes(
  "PUT_CITY_FORMAT_SCHEDULE"
);

export const RENAME_ADVERTISE_VIDEO = generateTypes("RENAME_ADVERTISE_VIDEO");
export const GET_ALL_CITYFORMATS = generateTypes("GET_ALL_CITYFORMATS");

export const GET_CF_FORMATS = generateTypes("GET_CF_FORMATS");
export const GET_CF_PLATFORMS = generateTypes("GET_CF_PLATFORMS");
export const GET_CF_STATIONS = generateTypes("GET_CF_STATIONS");
export const GET_CF_LINES = generateTypes("GET_CF_LINES");
export const GET_CF_HUBS = generateTypes("GET_CF_HUBS");
