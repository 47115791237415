import React, { Component } from 'react';
import Modal from '../../components/Modal';
import RestoreForm from './RestoreForm';

export default class Restore extends Component {
  render() {
    return (
      <Modal>
        <RestoreForm />
      </Modal>
    );
  }
}
