import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Notice } from './styled';

export default class NoticeComponent extends Component {
  static propTypes = {
    children: PropTypes.any,
    closable: PropTypes.bool,
    name: PropTypes.string,
    type: PropTypes.string
  };

  static defaultProps = {
    closable: true
  };

  state = {
    display: true
  };

  close = () => {
    this.setState({
      display: false
    });
    if (this.props.name) {
      localStorage.setItem(`notification.${this.props.name}`, 'false');
    }
  };

  render() {
    return this.state.display ? (
      <Notice className={this.props.type}>
        {this.props.children}
        {this.props.closable ? (
          <div className="close" onClick={this.close}>
            x
          </div>
        ) : null}
      </Notice>
    ) : null;
  }
}
