import styled from "styled-components";

export const Row = styled.div`
  display: flex;
`;

export const Col = styled.div`
  margin: 0 2rem;
`;

export const Doc = {};

Doc.Link = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: inherit;
  text-decoration: none;
`;

Doc.Description = styled.div`
  margin-top: 1rem;
  text-align: center;
`;
