import styled from "styled-components";

export const StyledLabel = styled.label`
  position: absolute;
  top: 0;
  font-size: 0.875rem;
  color: ${({ authStyle }) => (authStyle ? "#666666" : "#fff")};
  transition: all 200ms;
  display: flex;
  opacity: 0.7;

  ${({ isInputEmpty }) =>
    isInputEmpty &&
    `
    opacity: 0;
    `};
`;

export const StyledInputField = styled.div`
  margin-bottom: 28px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  transition: all 300ms;

  input {
    font-family: "Circe", "sans-serif";
    padding-bottom: 8px !important;
    font-size: 1rem;
    border: unset;
    border-bottom: 1px solid
      ${({ authStyle }) => (authStyle ? "#666666" : "#fff")};
    outline: none;
    transition: all 300ms;
    padding: unset;
    width: 100%;
    color: ${({ authStyle }) => (authStyle ? "#4a4a4a" : "#fff")};
    background: unset;
    ${({ authStyle }) =>
      authStyle &&
      `
      box-shadow: inset 0 0 0 50px #fff;
      `}

    &::placeholder {
      color: ${({ authStyle }) => (authStyle ? "#4a4a4a" : "#fff")};
      opacity: 0.5;
    }

    &:focus,
    &:active {
      border-bottom: 1px solid
        ${({ authStyle }) => (authStyle ? "#12b8ff" : "#fff")};
    }

    &.login-err {
      border-bottom: 1px solid #d92f4b;
    }
  }
`;

export const StyledInput = styled.div`
  padding-top: 24px;
  width: 100%;
  display: flex;
  position: relative;
`;

export const StyledError = styled.div`
  position: absolute;
  bottom: -20px;
  color: #d92f4b;
  font-size: 0.875rem;
`;
