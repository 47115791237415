import createReducer from "./createReducer";
import { SET_IS_MOBILE, DISABLE_FOOTER } from "../constants/app";

const initialState = {
  isMobile: null,
  disableFooter: false
};

const consumers = {
  [SET_IS_MOBILE.main]: (state, { isMobile }) => ({
    ...state,
    isMobile
  }),
  [DISABLE_FOOTER]: (state, { value }) => ({
    ...state,
    disableFooter: value
  })
};

export default createReducer(initialState, consumers);
