import createReducer from './createReducer';
import { SHOW_OFFER_ARCHIVE, HIDE_OFFER_ARCHIVE, GET_OFFER_ARCHIVE, GET_LAST_OFFER_ARCHIVE } from '../constants/offerArchive';

const initialState = {
  show: false,
  list: [],
  lastOffer: ''
};

const offerArchive = {
  [SHOW_OFFER_ARCHIVE]: state => ({
    ...state,
    show: true
  }),
  [HIDE_OFFER_ARCHIVE]: (state) => ({
    ...state,
    linesLoading: false,
    show: false
  }),
  [GET_OFFER_ARCHIVE.success]: (state, action) => ({
    ...state,
    list: action.result.data.offers.map(item => ({...item})).reverse()
  }),
  [GET_LAST_OFFER_ARCHIVE.success]: (state, action) => ({
    ...state,
    lastOffer: action.result.data.offer.fileName
  })
};

export default createReducer(initialState, offerArchive);