export const customStyles = {
  container: base => ({
    ...base,
    width: "100%"
  }),
  input: base => ({
    width: "100%",
    color: "#fff"
  }),
  dropdownIndicator: base => ({
    ...base,
    color: "#fff",
    "&:hover": {
      color: "#fff"
    }
  }),
  clearIndicator: base => ({
    ...base,
    color: "#fff",
    "&:hover": {
      color: "#fff"
    }
  }),
  indicatorSeparator: base => ({
    ...base,
    color: "#fff",
    backgroundColor: "#fff",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#fff"
    }
  }),
  control: base => ({
    ...base,
    background: "unset",
    border: "unset",
    borderBottom: "1px solid #fff",
    borderRadius: "unset",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#fff"
    }
  }),
  singleValue: base => ({
    ...base,
    color: "#fff"
  }),
  placeholder: base => ({
    ...base,
    color: "#fff",
    opacity: 0.5,
    margin: "unset"
  }),
  valueContainer: base => ({
    ...base,
    padding: "unset"
  }),
  loadingIndicator: base => ({
    ...base,
    color: "#fff"
  }),
  option: base => ({
    ...base,
    padding: "14px 8px"
  })
};
