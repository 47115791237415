import React from "react";
import { useSelector } from "react-redux";

import {
  StyledProfileCard,
  StyledAvatar,
  StyledProfession,
  StyledFullName
} from "./styled";

const ProfileCard = () => {
  const { user } = useSelector(({ photoReports }) => photoReports);

  return (
    <StyledProfileCard>
      <StyledAvatar>
        <img src="/assets/avatar.png" alt="avatar" />
      </StyledAvatar>
      <StyledProfession>Монтажник</StyledProfession>
      <StyledFullName>{`${user.lastName} ${user.firstName}`}</StyledFullName>
    </StyledProfileCard>
  );
};

export default ProfileCard;
