import styled from "styled-components";

import { MenuButton, BodyContainer } from "../Layout";

export const StyledMenuButton = styled(MenuButton)`
  margin: 10px 0;

  &:first-child {
    margin-top: 80px;
  }

  &:last-child {
    /* margin-bottom: 20px; */
    margin-bottom: 300px;
  }
`;

export const StyledBodyContainer = styled(BodyContainer)`
  background: radial-gradient(
    94.49% 94.49% at 79.83% 96.5%,
    #12b8ff 0%,
    rgba(18, 184, 255, 0.81) 100%
  );
`;
