import moment from "moment";
import { cityFormatCalculation } from "store/helpers/cityFormat";
import { formAvailableDatesCity } from "utils";

import {
  GET_METRO_LINES_REPORT,
  GET_METRO_STATIONS_REPORT,
  SELECT_METRO_LINE,
  SELECT_DURATION,
  SELECT_FREQUENCY,
  SELECT_PERIOD,
  SELECT_STATION,
  GET_CAMPAIGN_PERIODS,
  GET_CITYFORMATS_REPORT,
  GET_CITYFORMATS_REPORT_SEARCH,
  SELECT_CITYFORMAT,
  RESET_CITYFORMATS_STORE,
  CHANGE_PAGINATION,
  UPDATE_BASKET_CITYFORMATS,
  SELECT_CUSTOM_PERIOD,
  GET_FORMATS_REPORT,
  SELECT_HALF_ARIGINS,
  SELECT_AVAILABLE_FOR_SALE,
  GET_CITYFORMAT_PLANNER,
  INIT_BASKET_CITYFORMATS,
  GET_CF_FORMATS,
  GET_CF_PLATFORMS,
  GET_CF_STATIONS,
  GET_CF_LINES,
  GET_CF_HUBS,
  GET_ALL_CITYFORMATS,
  GET_ALL_CITYFORMATS_REPORT,
  SET_SEARCH_BY_TYPE,
  SEARCH_STATION
} from "../constants/cityFormat";

const crudBasketCityFormats = (
  item,
  basket,
  deleteAll,
  metroLines,
  stations
) => {
  if (deleteAll) {
    const deleteAllIds = deleteAll.map(i => i.cityFormat.id);

    return basket.filter(i => deleteAllIds.indexOf(i.cityFormat.id) === -1);
  }
  const indexStation = basket.findIndex(
    b => b.cityFormat.id === item.cityFormat.id
  );

  if (indexStation === -1) {
    basket.push({
      ...item,
      selected: true
    });
  } else {
    basket.splice(indexStation, 1);
  }
  return basket;
};

const initialState = {
  metroLines: { items: [], loading: false }, //список линий метро
  clipDuration: {
    items: [...Array(9)].map((i, idx) => ({
      value: (idx + 1) * 5,
      name: `${(idx + 1) * 5}`,
      selected: idx === 0
    })),
    name: "clipDuration",
    coefficient: 1,
    activeTargeting: false,
    title: "Длительность ролика",
    selectProps: {
      width: "160px",
      placeholder: "Выберите длительность"
    }
  },
  frequency: {
    items: [],
    name: "frequency",
    coefficient: 1,
    activeTargeting: false,
    title: "Кол-во выходов в день",
    selectProps: {
      width: "160px",
      placeholder: "Выберите кол-во выходов в день"
    }
  },
  stations: {
    items: [],
    name: "stations",
    coefficient: 1,
    activeTargeting: false,
    title: "Фильтр по станциям",
    loading: false,
    selectProps: {
      width: "320px",
      placeholder: "Выберите станцию"
    }
  },
  periods: {
    items: formAvailableDatesCity(14).map((period, idx) => ({
      ...period,
      startDateFormat: moment(period.startDate).format("DD-MM-YYYY"),
      endDateFormat: moment(period.endDate).format("DD-MM-YYYY"),
      selected: idx === 0
    })),
    name: "periods",
    coefficient: 1,
    activeTargeting: false,
    title: "Период размещения"
  },
  campaignPeriods: {
    items: []
  },
  cityFormats: {
    items: [],
    pagination: { limit: 10, offset: 0, currentPage: 1 },
    loading: false
  },
  basketCityFormats: {
    items: []
  },
  halfArigins: 1,
  availableForSale: false,
  planner: {
    requestId: false,
    error: "",
    loaded: false,
    loading: false,
    platformDayCount: []
  },
  referenceBook: {
    formats: {
      items: []
    },
    platforms: {
      items: []
    },
    stations: {
      items: []
    },
    lines: {
      items: []
    },
    hubs: {
      items: []
    }
  },
  initRestoreBasket: false,
  initRestoreSearchList: false,
  allCityFormats: {
    items: [],
    loading: false
  },
  searchByType: "select",
  searchStationValue: ""
};

export const cityFormat = (
  state = cityFormatCalculation(initialState),
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_STATION:
      return {
        ...state,
        searchStationValue: payload,
        cityFormats: {
          ...state.cityFormats,
          pagination: { limit: 10, offset: 0, currentPage: 1 }
        }
      };
    case SET_SEARCH_BY_TYPE:
      return {
        ...state,
        searchByType: payload,
        searchStationValue: "",
        cityFormats: {
          ...state.cityFormats,
          pagination: { limit: 10, offset: 0, currentPage: 1 }
        }
      };
    case GET_ALL_CITYFORMATS.success:
      return cityFormatCalculation(
        {
          ...state
        },
        type,
        state
      );
    case GET_CF_FORMATS.success:
      return cityFormatCalculation(
        {
          ...state,
          referenceBook: {
            ...state.referenceBook,
            formats: {
              ...state.referenceBook.formats,
              items: payload
            }
          }
        },
        type,
        state
      );
    case GET_CF_PLATFORMS.success:
      return cityFormatCalculation(
        {
          ...state,
          referenceBook: {
            ...state.referenceBook,
            platforms: {
              ...state.referenceBook.platforms,
              items: payload
            }
          }
        },
        type,
        state
      );
    case GET_CF_STATIONS.success:
      return cityFormatCalculation(
        {
          ...state,
          referenceBook: {
            ...state.referenceBook,
            stations: {
              ...state.referenceBook.stations,
              items: payload
            }
          }
        },
        type,
        state
      );
    case GET_CF_LINES.success:
      return cityFormatCalculation(
        {
          ...state,
          referenceBook: {
            ...state.referenceBook,
            lines: {
              ...state.referenceBook.lines,
              items: payload
            }
          }
        },
        type,
        state
      );
    case GET_CF_HUBS.success:
      return cityFormatCalculation(
        {
          ...state,
          referenceBook: {
            ...state.referenceBook,
            hubs: {
              ...state.referenceBook.hubs,
              items: payload
            }
          }
        },
        type,
        state
      );
    case GET_CITYFORMAT_PLANNER.start:
      return cityFormatCalculation(
        {
          ...state,
          planner: {
            ...state.planner,
            loaded: false,
            loading: true,
            error: "",
            requestId: payload
          }
        },
        type,
        state
      );
    case GET_CITYFORMAT_PLANNER.error:
      return cityFormatCalculation(
        {
          ...state,
          planner: {
            ...state.planner,
            loaded: false,
            loading: false,
            error: payload.error
          }
        },
        type,
        state
      );
    case GET_CITYFORMAT_PLANNER.end:
      return cityFormatCalculation(
        {
          ...state,
          planner: {
            ...state.planner,
            loaded: false,
            loading: false
          }
        },
        type,
        state
      );
    case GET_CITYFORMAT_PLANNER.success:
      const { requestId } = state.planner;
      const { id, platformDayCount, error, requestType } = payload;

      return requestId === id
        ? cityFormatCalculation(
            {
              ...state,
              planner: {
                ...state.planner,
                loaded: true,
                loading: false,
                platformDayCount:
                  requestType === "createCampaign"
                    ? state.planner.platformDayCount
                    : platformDayCount,
                error
              }
            },
            type,
            state
          )
        : state;
    case SELECT_HALF_ARIGINS:
      return cityFormatCalculation(
        {
          ...state,
          halfArigins: payload ? 0.5 : 1
        },
        type,
        state
      );
    case SELECT_AVAILABLE_FOR_SALE:
      return cityFormatCalculation(
        {
          ...state,
          availableForSale: payload
        },
        type,
        state
      );
    case GET_FORMATS_REPORT.success:
      return cityFormatCalculation(
        {
          ...state,
          frequency: {
            ...state.frequency,
            loading: false,
            items: payload.map((format, idx) => ({
              value: parseInt(format, 10),
              name: format,
              selected: idx === 0
            }))
          }
        },
        type,
        state
      );
    case INIT_BASKET_CITYFORMATS.success:
      return cityFormatCalculation(
        {
          ...state,
          basketCityFormats: {
            ...state.basketCityFormats,
            items: payload.cityFormatsWithPeriods.nodes.map(item => ({
              ...item,
              cityFormat: {
                ...item.cityFormat,
                totalPrice: item.periods.reduce(
                  (acc, i) => (acc += i.totalPeriodPrice),
                  0
                )
              },
              selected: true
            }))
          },
          cityFormats: {
            ...state.cityFormats,
            items: state.cityFormats.items.map(item => ({
              ...item,
              selected: !!payload.cityFormatsWithPeriods.nodes.find(
                i => i.cityFormat.id === item.cityFormat.id
              )
            }))
          },
          clipDuration: {
            ...state.clipDuration,
            items: state.clipDuration.items.map(({ value, ...item }) => ({
              ...item,
              value,
              selected: value === payload.clipDuration
            }))
          },
          periods: {
            ...state.periods,
            items: state.periods.items.find(
              item =>
                item.startDateFormat ===
                  moment(payload.campaignDates.startDate).format(
                    "DD-MM-YYYY"
                  ) &&
                item.endDateFormat ===
                  moment(payload.campaignDates.endDate).format("DD-MM-YYYY")
            )
              ? [
                  ...state.periods.items.map(item => ({
                    ...item,
                    selected:
                      item.startDateFormat ===
                        moment(payload.campaignDates.startDate).format(
                          "DD-MM-YYYY"
                        ) &&
                      item.endDateFormat ===
                        moment(payload.campaignDates.endDate).format(
                          "DD-MM-YYYY"
                        )
                  }))
                ]
              : [
                  ...state.periods.items.map(item => ({
                    ...item,
                    selected: false
                  })),
                  {
                    startDate: moment(payload.campaignDates.startDate),
                    endDate: moment(payload.campaignDates.endDate),
                    id: String(Math.random()),
                    startDateFormat: moment(
                      payload.campaignDates.startDate
                    ).format("DD-MM-YYYY"),
                    endDateFormat: moment(payload.campaignDates.endDate).format(
                      "DD-MM-YYYY"
                    ),
                    selected: true,
                    initBasketPeriod: true
                  }
                ]
          },
          halfArigins: payload.airingsCountCoefficient
        },
        type,
        state
      );
    case INIT_BASKET_CITYFORMATS.end:
      return {
        ...state,
        initRestoreBasket: true
      };
    case UPDATE_BASKET_CITYFORMATS.success:
      return cityFormatCalculation(
        {
          ...state,
          basketCityFormats: {
            ...state.basketCityFormats,
            items: payload.nodes.map(item => ({
              ...item,
              cityFormat: {
                ...item.cityFormat,
                totalPrice: item.periods.reduce(
                  (acc, i) => (acc += i.totalPeriodPrice),
                  0
                )
              },
              selected: true
            }))
          }
        },
        type,
        state
      );
    case GET_METRO_LINES_REPORT.start:
      return {
        ...state,
        metroLines: {
          ...state.metroLines,
          loading: true
        }
      };
    case GET_METRO_LINES_REPORT.success:
      return {
        ...state,
        metroLines: {
          ...state.metroLines,
          loading: false,
          items: payload.map((line, idx) => ({
            ...line,
            selected: idx === 0
          }))
        }
      };
    case GET_METRO_LINES_REPORT.error:
      return {
        ...state,
        metroLines: {
          ...state.metroLines,
          loading: false
        }
      };

    case GET_METRO_STATIONS_REPORT.start:
      return {
        ...state,
        stations: {
          ...state.stations,
          loading: true
        }
      };

    case GET_METRO_STATIONS_REPORT.success:
      return {
        ...state,
        stations: {
          ...state.stations,
          loading: false,
          items: payload.map((station, idx) => ({
            ...station,
            value: station.id,
            name: station.name,
            selected: idx === 0
          }))
        }
      };

    case GET_METRO_STATIONS_REPORT.error:
      return {
        ...state,
        stations: {
          ...state.stations,
          loading: false
        }
      };

    case GET_CAMPAIGN_PERIODS.success:
      return cityFormatCalculation(
        {
          ...state,
          campaignPeriods: {
            ...state.campaignPeriods,
            items: payload
          }
        },
        type,
        state
      );
    case GET_ALL_CITYFORMATS_REPORT.start:
      return cityFormatCalculation(
        {
          ...state,
          allCityFormats: {
            ...state.allCityFormats,
            loading: true
          }
        },
        type,
        state
      );
    case GET_ALL_CITYFORMATS_REPORT.error:
      return cityFormatCalculation(
        {
          ...state,
          allCityFormats: {
            ...state.allCityFormats,
            loading: false
          }
        },
        type,
        state
      );
    case GET_ALL_CITYFORMATS_REPORT.success:
      return cityFormatCalculation(
        {
          ...state,
          allCityFormats: {
            items: payload.cityFormatsWithPeriods.nodes.map(item => ({
              ...item,
              visible: true,
              cityFormat: {
                ...item.cityFormat,
                totalPrice: item.periods.reduce(
                  (acc, i) => (acc += i.totalPeriodPrice),
                  0
                )
              },
              selected: true
            })),
            loading: false
          },
          cityFormats: {
            ...state.cityFormats,
            items: state.cityFormats.items.map(item => ({
              ...item,
              selected: true
            })),
            loading: false
          }
        },
        type,
        state
      );

    case GET_ALL_CITYFORMATS_REPORT.end:
      return cityFormatCalculation(
        {
          ...state,
          allCityFormats: {
            ...state.allCityFormats,
            loading: false
          }
        },
        type,
        state
      );
    case GET_CITYFORMATS_REPORT_SEARCH.start:
    case GET_CITYFORMATS_REPORT.start:
      return {
        ...state,
        cityFormats: {
          ...state.cityFormats,
          loading: true
        },
        planner: {
          ...state.planner,
          error: ""
        }
      };
    case GET_CITYFORMATS_REPORT_SEARCH.success:
    case GET_CITYFORMATS_REPORT.success:
      const { limit } = state.cityFormats.pagination;
      return cityFormatCalculation(
        {
          ...state,
          cityFormats: {
            ...state.cityFormats,
            pagination: {
              ...state.cityFormats.pagination,
              maxPages: Math.ceil(
                payload.cityFormatsWithPeriods.totalCount / limit
              )
            },
            items: payload.cityFormatsWithPeriods.nodes.map(item => ({
              ...item,
              visible: true,
              cityFormat: {
                ...item.cityFormat,
                totalPrice: item.periods.reduce(
                  (acc, i) => (acc += i.totalPeriodPrice),
                  0
                )
              },
              selected: !!state.basketCityFormats.items.find(
                i => i.cityFormat.id === item.cityFormat.id
              )
            })),
            loading: false
          }
        },
        type,
        state
      );
    case GET_CITYFORMATS_REPORT_SEARCH.end:
    case GET_CITYFORMATS_REPORT.end:
      return {
        ...state,
        ...(payload.type.end === "GET_CITYFORMATS_REPORT_SEARCH_END"
          ? {
              initRestoreSearchList: true
            }
          : {})
      };
    case SELECT_METRO_LINE:
      return {
        ...state,
        metroLines: {
          ...state.metroLines,
          items: state.metroLines.items.map((line, idx) => ({
            ...line,
            selected: line.id === payload
          }))
        },
        cityFormats: {
          ...state.cityFormats,
          loading: true,
          pagination: { limit: 10, offset: 0, currentPage: 1 }
        }
      };

    case SELECT_DURATION:
      return cityFormatCalculation(
        {
          ...state,
          clipDuration: {
            ...state.clipDuration,
            items: state.clipDuration.items.map(({ value, ...item }) => ({
              ...item,
              value,
              selected: value === payload.value
            }))
          },
          cityFormats: {
            ...state.cityFormats,
            pagination: { limit: 10, offset: 0, currentPage: 1 }
          }
        },
        type,
        state
      );

    case SELECT_FREQUENCY:
      return cityFormatCalculation(
        {
          ...state,
          frequency: {
            ...state.frequency,
            items: state.frequency.items.map(({ value, ...item }) => ({
              ...item,
              value,
              selected: value === payload.value
            }))
          },
          cityFormats: {
            ...state.cityFormats,
            pagination: { limit: 10, offset: 0, currentPage: 1 }
          }
        },
        type,
        state
      );

    case SELECT_PERIOD:
      return cityFormatCalculation(
        {
          ...state,
          campaignPeriods: {
            ...state.campaignPeriods,
            items: []
          },
          cityFormats: {
            ...state.cityFormats,
            pagination: { limit: 10, offset: 0, currentPage: 1 }
          },
          periods: {
            ...state.periods,
            items: state.periods.items.map(
              ({ startDateFormat, endDateFormat, ...item }) => ({
                ...item,
                startDateFormat,
                endDateFormat,
                selected:
                  startDateFormat === payload.startDateFormat &&
                  endDateFormat === payload.endDateFormat
              })
            )
          }
        },
        type,
        state
      );

    case SELECT_CUSTOM_PERIOD:
      return cityFormatCalculation(
        {
          ...state,
          campaignPeriods: {
            ...state.campaignPeriods,
            items: []
          },
          cityFormats: {
            ...state.cityFormats,
            pagination: { limit: 10, offset: 0, currentPage: 1 }
          },
          periods: {
            ...state.periods,
            items: [{ ...payload, selected: true }]
          }
        },
        type,
        state
      );

    case SELECT_STATION:
      return cityFormatCalculation(
        {
          ...state,
          stations: {
            ...state.stations,
            items: state.stations.items.map(({ value, ...item }) => ({
              ...item,
              value,
              selected: value === payload.value
            }))
          },
          cityFormats: {
            ...state.cityFormats,
            pagination: { limit: 10, offset: 0, currentPage: 1 }
          }
        },
        type,
        state
      );

    case SELECT_CITYFORMAT:
      const basketCityFormats = crudBasketCityFormats(
        payload.value,
        state.basketCityFormats.items,
        payload.deleteAll,
        state.metroLines.items,
        state.stations.items
      );
      return cityFormatCalculation(
        {
          ...state,
          planner: {
            ...state.planner,
            error: ""
          },
          basketCityFormats: {
            ...state.basketCityFormats,
            items: basketCityFormats
          },
          cityFormats: {
            ...state.cityFormats,
            items: state.cityFormats.items.map(item => ({
              ...item,
              selected: !!basketCityFormats.find(
                i => i.cityFormat.id === item.cityFormat.id
              )
            })),
            loading: false
          }
        },
        type,
        state
      );

    case CHANGE_PAGINATION:
      return {
        ...state,
        cityFormats: {
          ...state.cityFormats,
          pagination: {
            ...state.cityFormats.pagination,
            currentPage: payload.currentPage,
            offset: payload.coefficient * state.cityFormats.pagination.limit
          }
        }
      };

    case RESET_CITYFORMATS_STORE:
      return {
        ...state,
        metroLines: { ...state.metroLines, items: [], loading: false },
        stations: {
          ...state.stations,
          items: [],
          loading: false
        },
        periods: {
          ...state.periods,
          items: formAvailableDatesCity(14).map((period, idx) => ({
            ...period,
            startDateFormat: moment(period.startDate).format("DD-MM-YYYY"),
            endDateFormat: moment(period.endDate).format("DD-MM-YYYY"),
            selected: idx === 0
          }))
        },
        campaignPeriods: {
          ...state.campaignPeriods,
          items: []
        },
        cityFormats: {
          ...state.cityFormats,
          items: [],
          pagination: { limit: 10, offset: 0, currentPage: 1 }
        },
        basketCityFormats: {
          ...state.basketCityFormats,
          items: []
        },
        halfArigins: 1,
        availableForSale: false,
        searchByType: "select",
        searchStationValue: ""
      };

    default:
      return state;
  }
};
