import styled from 'styled-components';

export const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  font-family: Circe;
  color: #808080;
  padding: 3.75rem 0;
  margin-bottom: 1.875rem;
  font-size: 0.875rem;
`;

export const StyledFooterLogoBlock = styled.div`
  display: flex;
  height: 100%;
  line-height: 1.2rem;
  flex-direction: column;
  flex: 1 0 28%;
`;

export const StyledFooterLogo = styled.img`
  margin-bottom: 0.425rem;
`;

export const StyledFooterList = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 2.5rem;
  flex: 1 0 18%;

  a,
  span {
    color: #808080;
    text-decoration: none;
    transition: all 300ms;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const StyledFooterTitle = styled.div`
  color: #000000;
`;

export const StyledSocialLogo = styled.img`
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.282rem;
  vertical-align: -0.282rem;
`;
