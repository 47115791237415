import React from "react";

import {
  StyledSidesInfo,
  StyledSideBlock,
  StyledSideTr,
  StyledSideTd
} from "./styled";

const SidesInfo = ({ selectedWorkOrder }) => {
  const { rimName, advertisingSpace } = selectedWorkOrder;
  return (
    <StyledSidesInfo>
      <StyledSideBlock>
        <StyledSideTr>
          <StyledSideTd>Постер</StyledSideTd>
          <StyledSideTd>Стороны монтажа</StyledSideTd>
        </StyledSideTr>
        <StyledSideTr>
          <StyledSideTd>{rimName}</StyledSideTd>
          <StyledSideTd name>
            {advertisingSpace.side.name.toUpperCase()}
          </StyledSideTd>
        </StyledSideTr>
      </StyledSideBlock>
    </StyledSidesInfo>
  );
};

export default SidesInfo;
