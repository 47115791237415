import createReducer from "./createReducer";
import moment from "moment";

import {
  GET_PACKAGES,
  GET_PACKAGES_FOR_MIN_PRICE,
  GET_PACKAGES_FORMATS,
  GET_PACKAGES_SIZES,
  POST_ADVERTISE_PACKAGES_SCHEDULE
} from "../constants/packages";

const initialState = {
  packages: [],
  packagesLoading: false,
  formats: [],
  packagesForMinPrice: [],
  packagesForMinPriceLoading: null,
  formatsLoading: false,
  schedule: [],
  scheduleLoading: false
};

function sortSizes(sizes) {
  return sizes.sort((a, b) => (a.ratio * 100 > b.ratio * 100 ? -1 : 1));
}

const consumers = {
  [GET_PACKAGES.main]: state => ({
    ...state,
    packagesLoading: true
  }),
  [GET_PACKAGES_FOR_MIN_PRICE.main]: state => ({
    ...state,
    packagesForMinPriceLoading: true
  }),
  [GET_PACKAGES.success]: (state, action) => ({
    ...state,
    packagesLoading: false,
    packages: action.result.data.advertisePackages
  }),
  [GET_PACKAGES_FOR_MIN_PRICE.success]: (state, action) => ({
    ...state,
    packagesForMinPrice: action.result.data.enabledPackages,
    packagesForMinPriceLoading: false
  }),
  [GET_PACKAGES_FORMATS.main]: state => ({
    ...state,
    formatsLoading: true
  }),
  [GET_PACKAGES_FORMATS.success]: (state, action) => ({
    ...state,
    formatsLoading: false,
    formats: action.result.data.advertiseFormats.sort(
      (a, b) => moment(a.createdAt) - moment(b.createdAt)
    )
  }),
  [GET_PACKAGES_SIZES.main]: state => ({
    ...state,
    sizesLoading: true
  }),
  [GET_PACKAGES_SIZES.success]: (state, action) => ({
    ...state,
    sizesLoading: false,
    sizes: sortSizes(action.result.data.advertisePackageSizes)
  }),
  [POST_ADVERTISE_PACKAGES_SCHEDULE.main]: state => ({
    ...state,
    schedule: [],
    scheduleLoading: true
  }),
  [POST_ADVERTISE_PACKAGES_SCHEDULE.success]: (state, action) => ({
    ...state,
    schedule: action.result.data.enabledPackages || [],
    scheduleLoading: false
  }),
  [POST_ADVERTISE_PACKAGES_SCHEDULE.error]: state => ({
    ...state,
    schedule: [],
    scheduleLoading: false
  })
};

export default createReducer(initialState, consumers);
