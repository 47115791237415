import React, { Component } from "react";
import { Link } from "react-router-dom";
import new_user from "../../img/new_user.svg";
import password_show from "../../img/see_password.svg";
import password_hide from "../../img/hide_password.svg";
import {
  ModalInputGroup,
  ModalRow,
  ModalCol,
  ModalButton,
  ModalHr
} from "../../components/Modal/styled";
import PropTypes from "prop-types";
import { signInConfirm, resetConfirm } from "../../store/actions/auth";
import { checkConfirm } from "../../store/actions/register";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Modal from "../../components/Modal";
import { ErrorInput } from "../../components/Layout/form";
import ReactTooltip from "react-tooltip";
import Loader from "../Loader";

class ConfirmForm extends Component {
  static propTypes = {
    checkConfirm: PropTypes.func,
    checkConfirmCalled: PropTypes.bool,
    checkConfirmCalling: PropTypes.bool,
    match: PropTypes.object,
    resetConfirm: PropTypes.func,
    signInConfirm: PropTypes.func,
    successCheckConfirm: PropTypes.bool,
    successConfirm: PropTypes.bool
  };

  state = {
    model: {
      password: "",
      passwordRepeat: ""
    },
    validation: {},
    type: "password",
    error: ""
  };

  componentWillUnmount() {
    this.props.resetConfirm();
  }

  componentDidMount() {
    if (this.props.match.params.token && !this.props.checkConfirmCalled) {
      this.props.checkConfirm({ token: this.props.match.params.token });
    }
  }

  componentDidUpdate = () => {
    ReactTooltip.rebuild();
  };

  passwordToggle = () => {
    this.setState({
      type: this.state.type === "input" ? "password" : "input"
    });
  };

  updater = (prop, key = "value", condition = x => x) => ({ target }) => {
    const value = condition(target[key]);
    this.setState(({ model }) => ({
      model: {
        ...model,
        [prop]: typeof value === "string" ? value.replace(/^\s+/, "") : value
      }
    }));
  };

  validatePassword = password => {
    this.setState(({ validation }) => ({
      validation: { ...validation, password: !password || password.length < 8 }
    }));
    return password;
  };

  updatePassword = this.updater("password", "value", this.validatePassword);
  updateSecondPassword = this.updater(
    "passwordRepeat",
    "value",
    this.validatePassword
  );

  setPassword = () => {
    const passwordChecker = this.checkPassword(this.state.model.password);
    if (this.state.model.password === this.state.model.passwordRepeat) {
      if (passwordChecker) {
        this.props.signInConfirm({
          password: this.state.model.password,
          token: this.props.match.params.token
        });
      } else {
        this.setState({
          error:
            "Пароль должен содержать заглавные и строчные буквы, цифры и специальные символы!"
        });
      }
    } else {
      this.setState({ error: "Введенные пароли не совпадают!" });
    }
  };

  checkPassword = pass => {
    let count = false;
    if (
      pass.match(/\d/) &&
      pass.match(/[a-zа-я]/) &&
      pass.match(/[A-ZА-Я]/) &&
      pass.match(/.[!,@,#,$,%,^,&,*,?,_,~,),(]/)
    ) {
      count = true;
    }
    return count;
  };

  isInvalid = () =>
    Object.keys(this.state.model).some(x => !this.state.model[x]) ||
    Object.keys(this.state.validation).some(x => this.state.validation[x]);

  getPasswordTooltip = () =>
    "• Пароль должен содержать не менее 8 символов. <br/> • Пароль должен содержать заглавные и строчные буквы, цифры, пробелы и специальные символы.";

  getPasswordShowToolTip = () =>
    this.state.type === "input" ? "Показать пароль" : "Спрятать пароль";

  showErrors = () => {
    return this.state.error;
  };
  render() {
    if (!this.props.match.params.token) {
      return <Redirect to="/login" />;
    }
    return (
      <Modal onCloseRedirect="/login">
        {this.props.checkConfirmCalling ? (
          <Loader />
        ) : this.props.successCheckConfirm || this.props.successConfirm ? (
          <div>
            <ModalRow className="center">
              <ModalCol className="center">
                <img src={new_user} alt="" />
                <h1>Регистрация завершена</h1>
                <ModalHr />
                <p>
                  Теперь Вы можете{" "}
                  <Link to={"/login"}>войти в свой аккаунт</Link>
                </p>
              </ModalCol>
            </ModalRow>
          </div>
        ) : (
          <div>
            <ModalRow>
              <ModalCol>
                <h1 className="inner-title">Регистрация</h1>
                <ModalHr />
                <ReactTooltip
                  id="password"
                  effect={"solid"}
                  html
                  className="maxWidthFixModal"
                />
                <ModalInputGroup
                  className="password"
                  data-for="password"
                  data-tip={this.getPasswordTooltip()}
                >
                  <label htmlFor="">Введите пароль</label>
                  <input
                    type={this.state.type}
                    placeholder="Введите Пароль…"
                    onChange={this.updatePassword}
                  />
                  <span>
                    <img
                      onClick={this.passwordToggle}
                      src={
                        this.state.type === "input"
                          ? password_hide
                          : password_show
                      }
                      alt="password"
                      title={this.getPasswordShowToolTip()}
                    />
                  </span>
                </ModalInputGroup>
                <ModalInputGroup
                  className="password"
                  data-for="password"
                  data-tip={this.getPasswordTooltip()}
                >
                  <label htmlFor="">Продублируйте пароль</label>
                  <input
                    type={this.state.type}
                    placeholder="Продублируйте пароль…"
                    onChange={this.updateSecondPassword}
                  />
                  <span>
                    <img
                      onClick={this.passwordToggle}
                      src={
                        this.state.type === "input"
                          ? password_hide
                          : password_show
                      }
                      alt="password"
                      title={this.getPasswordShowToolTip()}
                    />
                  </span>
                </ModalInputGroup>
              </ModalCol>
            </ModalRow>
            <ErrorInput style={{ display: "block" }}>
              {this.showErrors()}
            </ErrorInput>
            <ModalRow className="center">
              <ModalButton
                type="button"
                onClick={this.setPassword}
                disabled={this.isInvalid()}
              >
                Сохранить
              </ModalButton>
            </ModalRow>
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = ({ auth, register }) => ({
  successConfirm: auth.successConfirm,
  successCheckConfirm: register.successCheckConfirm,
  checkConfirmCalled: register.checkConfirmCalled,
  checkConfirmCalling: register.checkConfirmCalling
});

const mapDispatchToProps = {
  signInConfirm,
  resetConfirm,
  checkConfirm
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmForm);
