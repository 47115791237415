import moment from "moment";

const divideCampaignPeriod = periods => {
  const periodsMonths = periods.map(p => p.month);
  const uniquePeriodsMonths = [...new Set(periodsMonths)];

  return uniquePeriodsMonths.map(item => {
    const periodsArr = periods.filter(p => p.month === item);
    const count = periodsArr.reduce((acc, p) => (acc += p.count), 0);

    return {
      startDate: periodsArr[0].date,
      month: parseInt(moment(periodsArr[0].date).format("M"), 10),
      endDate: periodsArr[periodsArr.length - 1].date,
      count,
      amount: periodsArr.filter(p => !!p.count).length
    };
  });
};

export const dayPlatformCountToArr = (days, arigins) => {
  const keys = Object.keys(days);
  const values = Object.values(days);
  const arr = keys.map((key, idx) => {
    const periods = values[idx];
    const periodsKeys = Object.keys(periods);
    const periodsValues = Object.values(periods);
    return {
      cityFormatId: key,
      disabledByPlanner: periodsValues.some(i => i !== arigins),
      periods: periodsKeys.map((pk, index) => ({
        date: pk,
        month: parseInt(moment(pk).format("M"), 10),
        count: periodsValues[index]
      }))
    };
  });

  return arr.map(item => {
    return {
      ...item,
      periods: divideCampaignPeriod(item.periods)
    };
  });
};

export const getPlannerError = (dates, advice_result) => {
  const isDiffBetweenDates =
    dates.fromFormat !== dates.newFrom.format("YYYY-MM-DD HH:mm:ss") ||
    dates.toFormat !== dates.newTo.format("YYYY-MM-DD HH:mm:ss");

  if (isDiffBetweenDates) {
    return "Период автоматически изменен";
  }

  switch (advice_result) {
    case "success":
      return "";
    case "dontfit":
      return "Кампания не может быть размещена с указанными параметрами";
    case "notimplemented":
      return "Данный способ расчетов еще не реализован";
    default:
      return "Неизвестная ошибка в расчетах, попробуйте изменить параметры кампании";
  }
};

const checkIsAdjacency = (item, metroLines, stations) => {
  const selectedLine = metroLines.find(item => item.selected);
  const selectedStation = stations.find(item => item.selected);

  return (
    item.cityFormat.metroPlatform.metroStation.id !== selectedStation.id ||
    item.cityFormat.metroPlatform.metroStation.lines[0].id !== selectedLine.id
  );
};

const populateCityFormats = (
  cityFormatItems,
  referenceBook,
  metroLines,
  stations
) => {
  return cityFormatItems.map(i => {
    const findedFormat = referenceBook.formats.items.find(
      format => format.id === i.cityFormat.formatId
    );
    const findedPlatform = referenceBook.platforms.items.find(
      platform => platform.id === i.cityFormat.metroPlatformId
    );
    const findedStation = referenceBook.stations.items.find(
      station => station.id === findedPlatform.metroStationId
    );
    const findedLines = findedStation.lineIds.map(lineId =>
      referenceBook.lines.items.find(line => line.id === lineId)
    );
    const findedHub = referenceBook.hubs.items.find(
      hub => hub.id === findedStation.hubId
    );
    const populatedCityFormat = {
      cityFormat: {
        ...i.cityFormat,
        format: findedFormat,
        metroPlatform: {
          metroStation: {
            ...findedStation,
            lines: findedLines,
            hub: findedHub
          }
        }
      }
    };

    return {
      ...i,
      ...populatedCityFormat,
      isAdjacency: checkIsAdjacency(
        populatedCityFormat,
        metroLines.items,
        stations.items
      )
    };
  });
};

export const cityFormatCalculation = (state, actionType, lastState) => {
  const {
    campaignPeriods,
    periods,
    cityFormats,
    clipDuration,
    frequency,
    halfArigins,
    availableForSale,
    referenceBook,
    metroLines,
    stations
  } = state;
  let { basketCityFormats, allCityFormats, planner } = state;
  let populatedCityFormats = cityFormats;
  let populatedBasketCityFormats = basketCityFormats;
  const isReferenceBookLoaded = Object.entries(referenceBook)
    .map(e => e[1].items)
    .every(i => i.length);

  /*при выборе всех ситиков и подгрузке по ним данных из планера,
    добавляем ситики в корзину, обнуляем объект allCityFormats,
    сбрасываем загрузку планера*/
  if (allCityFormats.items.length && planner.loaded) {
    const checkDisabledallCityFormats = allCityFormats.items.map(item => {
      const findedCityFormat = planner.platformDayCount.find(
        i => i.cityFormatId === item.cityFormat.id
      );
      return {
        ...item,
        disabledByPlanner: findedCityFormat
          ? findedCityFormat.disabledByPlanner
          : false
      };
    });
    const basketCityFormatIds = basketCityFormats.items.map(
      cf => cf.cityFormat.id
    );
    const uniqueCityFormats = checkDisabledallCityFormats.filter(
      i =>
        basketCityFormatIds.indexOf(i.cityFormat.id) === -1 &&
        !i.disabledByPlanner
    );

    allCityFormats = {
      ...allCityFormats,
      items: []
    };
    planner = {
      ...planner,
      loaded: false
    };
    basketCityFormats = {
      ...basketCityFormats,
      items: basketCityFormats.items.concat(uniqueCityFormats)
    };
  }

  //Наполняем ситики данными из справочников
  if (isReferenceBookLoaded) {
    populatedCityFormats = {
      ...cityFormats,
      items: populateCityFormats(
        cityFormats.items,
        referenceBook,
        metroLines,
        stations
      )
    };

    populatedBasketCityFormats = {
      ...basketCityFormats,
      items: populateCityFormats(
        basketCityFormats.items,
        referenceBook,
        metroLines,
        stations
      )
    };
  }

  const selectedClipDuration = clipDuration.items.find(item => item.selected);
  const selectedFrequency = frequency.items.find(item => item.selected) || {
    value: 0
  };
  const selectedPeriod = periods.items.find(period => period.selected);

  const checkDisabledBasketCityFormats = populatedBasketCityFormats.items.map(
    item => {
      const findedCityFormat = planner.platformDayCount.find(
        i => i.cityFormatId === item.cityFormat.id
      );
      return {
        ...item,
        disabledByPlanner: findedCityFormat
          ? findedCityFormat.disabledByPlanner
          : false
      };
    }
  );

  const cityFormatPeriodsPrices = checkDisabledBasketCityFormats
    .filter(i => !i.disabledByPlanner)
    .reduce((acc, item, idx) => {
      if (idx === 0) {
        acc.push(...item.periods);
      } else {
        const updateAcc = acc.map(period => {
          const findedPeriod = item.periods.find(
            p =>
              p.startDate === period.startDate && p.endDate === period.endDate
          );
          return {
            ...period,
            basePriceWithSeasonal: findedPeriod
              ? period.basePriceWithSeasonal +
                findedPeriod.basePriceWithSeasonal
              : period.basePriceWithSeasonal,
            totalPeriodPrice: findedPeriod
              ? period.totalPeriodPrice + findedPeriod.totalPeriodPrice
              : period.totalPeriodPrice
          };
        });

        acc = updateAcc;
      }

      return acc;
    }, []);

  const cityFormatPeriodsPricesWithAmount = cityFormatPeriodsPrices.map(i => ({
    ...i,
    basePriceWithSeasonal: i.basePriceWithSeasonal * i.amount
  }));

  //Проверяем выкупленные ситики по ответу из планера
  const checkDisabledCityFormats = populatedCityFormats.items.map(item => {
    const findedCityFormat = planner.platformDayCount.find(
      i => i.cityFormatId === item.cityFormat.id
    );
    return {
      ...item,
      disabledByPlanner: findedCityFormat
        ? findedCityFormat.disabledByPlanner
        : false
    };
  });

  //Проверяем доступные ситики по периодам
  const checkAvailableCityFormats = checkDisabledCityFormats.map(
    (item, idx) => {
      const connected = item.periods.every(p => p.isAvailableForSale);
      return {
        ...item,
        visible: availableForSale ? connected : true
      };
    }
  );

  //Длительность кампании(в днях)
  const daysAmount =
    moment(selectedPeriod.endDate).diff(
      moment(selectedPeriod.startDate),
      "days"
    ) + 1;

  //Стоимость кампании
  const totalPrice = cityFormatPeriodsPrices.reduce(
    (acc, item) => (acc += item.totalPeriodPrice),
    0
  );

  const periodsDuration = campaignPeriods.items.map(i => ({
    ...i,
    duration: moment(i.endDate).diff(moment(i.startDate), "days") + 1
  }));
  const periodsPresentationCount = periodsDuration.map(i => {
    return {
      ...i,
      presentationCount:
        i.duration *
        selectedFrequency.value *
        halfArigins *
        checkDisabledBasketCityFormats.filter(i => !i.disabledByPlanner).length
    };
  });
  const totalPresentationCount = periodsPresentationCount.reduce(
    (acc, p) => (acc += p.presentationCount),
    0
  );

  return {
    ...state,
    allCityFormats,
    planner,
    cityFormats: {
      ...populatedCityFormats,
      items: checkAvailableCityFormats
    },
    basketCityFormats: {
      ...populatedBasketCityFormats,
      items: checkDisabledBasketCityFormats
    },
    total: {
      duration: selectedClipDuration.value,
      totalPresentationCount,
      totalPrice,
      daysAmount,
      cityFormatPeriodsPrices: cityFormatPeriodsPricesWithAmount,
      countBasketCityFormats: checkDisabledBasketCityFormats.filter(
        i => !i.disabledByPlanner
      ).length,
      countDisabledBasketCityFormats: checkDisabledBasketCityFormats.filter(
        i => i.disabledByPlanner
      ).length,
      campaignPeriods: periodsPresentationCount,
      monthStart: moment(selectedPeriod.startDate).format("MMMM"),
      monthEnd: moment(selectedPeriod.endDate).format("MMMM")
    }
  };
};
