import styled from "styled-components";
import InputMask from "react-input-mask";

export const StyledSelect = styled.div`
  padding-top: 16px;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 28px;
`;

export const StyledLabel = styled.label`
  position: absolute;
  top: 0;
  font-size: 0.875rem;
  color: #fff;
  transition: all 200ms;
  display: flex;
  opacity: 0.7;
`;

export const StyledInputMask = styled(InputMask)`
  border: unset;
  color: #fff;
  width: 100%;
  background: unset;
  z-index: 1;
`;
