import styled from "styled-components";
import theme from "../../theme";

export const Button = styled.div`
  text-align: center;

  &.group {
    display: flex;
    justify-content: space-around;
  }

  & button,
  & input,
  & a {
    display: inline-block;
    position: relative;
    min-width: 150px;
    margin-bottom: 10px;
    cursor: pointer;
    border: solid 2px ${theme.primary};
    border-radius: 4px;
    font-family: Circe, sans-serif;
    font-size: 18px;
    font-weight: normal;
    color: #fff !important;
    background-color: ${theme.primary};
    padding: 12px 20px;
    text-align: center;
    transition: 0.3s ease-in-out;
    z-index: 1;
    overflow: hidden;
    vertical-align: middle;
    text-decoration: none;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to right,
        ${theme.saturate(theme.primary, 20)} 0%,
        ${theme.shade(theme.saturate(theme.primary, 5), 5)} 100%
      );
      transition: 0.3s ease-in-out;
      opacity: 0;
      z-index: -1;
    }
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 3px ${theme.transparentize(theme.blue, 0.5)};
    }
    &:enabled:hover {
      color: #fff !important;
      &:before {
        opacity: 1;
      }
    }
  }
  // SHAPES
  &.block {
    a,
    button {
      width: 100%;
      height: auto;
      display: block;
      margin: 0;
      border-width: ${({ borderWidth }) => `${borderWidth || 0}px`};
      padding: 12px;
      box-sizing: border-box;
    }
  }
  &.wide {
    a,
    button {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
  &.sharp {
    a,
    button {
      border-radius: 0;
    }
  }
  &.rounded {
    a,
    button {
      border-radius: 15.5px;
    }
  }
  &.inline {
    a,
    button {
      display: inline-block;
      min-width: auto;
      margin: 0 0.2rem;
    }
  }
  &.align-left {
    text-align: left;
    & a {
      color: #fff !important;
      padding: 8px 20px !important;
    }
  }
  &.uppercase {
    a,
    button {
      text-transform: uppercase;
      font-weight: 600;
    }
  }
  &.group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a,
    button {
      border-radius: 0;
      min-width: auto;
    }
    > div {
      width: auto;
      flex-grow: 2;
      a,
      button {
        width: 100%;
      }
    }
    > a,
    > button {
      width: auto;
      flex-grow: 2;
    }
    > a:first-child,
    > button:first-child,
    > div:first-child a,
    > div:first-child button {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    > a:last-child,
    > button:last-child,
    > div:last-child a,
    > div:last-child button {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
  // SIZES
  &.xs {
    a,
    button {
      height: 30px;
      font-size: 0.75rem;
      padding: 0 14px;
    }
    &.wide {
      a,
      button {
        min-width: 90px;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
  &.pagination {
    a,
    button {
      height: 30px;
      width: 30px;
      font-size: 0.75rem;
      padding: 0;
      border-radius: 20px;
      border: 1px;
    }
    &.wide {
      a,
      button {
        min-width: 90px;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
  &.sm {
    a,
    button {
      height: 30px;
      font-size: 0.95rem;
      padding: 0 14px;
      min-width: 90px;
    }
    &.wide {
      a,
      button {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
  &.md {
    a,
    button {
      font-size: 1rem;
      font-weight: bold;
    }
  }
  &.lg {
    a,
    button {
      height: 40px;
      font-size: 1rem;
      padding: 0 14px;
      min-width: 170px;
      width: auto;
    }
    &.wide {
      a,
      button {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
  &.white {
    a,
    button {
      color: ${theme.primary} !important;
      background-color: #fff;
      &:before {
        display: none;
      }
      &:enabled:hover {
        background-color: ${theme.primary};
        color: #fff !important;
      }
    }
  }
  &.flat {
    a,
    button {
      color: ${theme.primary} !important;
      border-color: #fff;
      background-color: #fff;
      &:before {
        display: none;
      }
      &:hover {
        background-color: ${theme.primary};
        color: #fff !important;
      }
    }
  }
  &.flat-purple {
    a,
    button {
      color: ${theme.dark} !important;
      background: transparent;
      border-color: ${theme.pink};
      text-align: center;
      &:before {
        display: none;
      }
      &:hover {
        background-color: transparent;
        color: ${theme.dark} !important;
      }
    }
  }
  &.purple {
    a,
    button {
      &:before {
        display: none;
      }
      background: transparent;
      color: #4b2387 !important;
      border-color: #4b2387;
      &:focus {
        border-color: #4b2387;
        box-shadow: 0 0 0 3px rgba(75, 35, 135, 0.15);
      }
      &:hover {
        background: #4b2387;
        border-color: #4b2387;
        color: #fff !important;
      }
    }
    &.active {
      a,
      button {
        border-color: #4b2387;
        background: #4b2387;
        color: #fff !important;
      }
    }
    &.disabled {
      a,
      button {
        border-color: #babcc0;
        color: #babcc0 !important;
        background: transparent;
        cursor: default;
        opacity: 0.3;
        outline: 0;
        &:hover {
          border-color: #babcc0;
          color: #babcc0 !important;
          background: transparent;
        }
      }
    }
  }
  &.gold {
    a,
    button {
      color: #000000 !important;
      background-color: ${theme.yellow};
      border-color: ${theme.yellow};
      font-weight: bold;
      font-size: 1rem;
      &:before {
        display: none;
      }
      &:focus {
        box-shadow: 0 0 0 3px rgba(255, 217, 0, 0.15);
      }
      &:hover {
        color: #000000 !important;
        background-color: ${theme.shade(theme.yellow, 10)};
        border-color: ${theme.shade(theme.yellow, 10)};
      }
    }
    &.lamp {
      a,
      button {
        &:hover {
          box-shadow: 0px 8px 40px ${theme.transparentize(theme.yellow, 0.25)};
        }
      }
    }
  }
  &.border {
    a,
    button {
      margin-top: 12px;
    }
  }
  // not tested yet, fixes may be needed
  &.outline {
    a,
    button {
      border-color: ${theme.primary};
      background: transparent;
    }
    &.purple {
      a,
      button {
        border-color: #4b2387;
        background: transparent;
      }
    }
    &.gold {
      a,
      button {
        border-color: ${theme.yellow};
        background: transparent;
      }
      &.active {
        a,
        button {
          background: ${theme.yellow};
          color: #000;
        }
      }
    }
    &.gray {
      a,
      button {
        border: 1px solid #babcc0;
        color: #4a4a4a !important;
        &:before {
          display: none;
        }
        &:hover {
          border-color: ${theme.blue};
          background: ${theme.transparentize(theme.blue, 0.2)};
        }
      }
    }
    &.disabled {
      a,
      button {
        border: 1px solid #babcc0;
        color: #4a4a4a !important;
        &:disabled {
          color: #4a4a4a;
          opacity: 1;
        }
      }
    }
  }
  & button:disabled {
    opacity: 0.23;
    cursor: default;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    width: 100%;
  }
  a,
  button {
    width: 100%;
    border-radius: 0;
  }

  > a:first-child,
  > button:first-child,
  > div:first-child a,
  > div:first-child button {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  > a:last-child,
  > button:last-child,
  > div:last-child a,
  > div:last-child button {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;
