import generateTypes from "./constantsGenerator";

//Actions with generate types
export const UPDATE_CUSTOMER_CAMPAIGNAD = generateTypes(
  "[Monitors Calculator] -> Request to update customer campaignad"
);
export const GET_ROUTES = generateTypes(
  "[Monitors Calculator] -> Request to get routes"
);
export const GET_ADVERTISE_PLACES = generateTypes(
  "[Monitors Calculator] -> Get advirtise places"
);
export const GET_SEASONAL_DISCOUNT = generateTypes(
  "[Monitors Calculator] -> Get seasonal discount"
);
export const UPDATE_SEASONAL_DISCOUNT = generateTypes(
  "[Monitors Calculator] -> Update seasonal discount"
);
export const GET_VOLUME_DISCOUNT = generateTypes(
  "[Monitors Calculator] -> Get volume discount"
);
export const GET_FACTOR_DISCOUNT = generateTypes(
  "[Monitors Calculator] -> Get factor discount"
);
export const GET_DISTRICTS = generateTypes(
  "[Monitors Calculator] -> Get districts"
);
export const GET_REPORTS_MLINES = generateTypes(
  "[Monitors Calculator] -> Get mlines from reports-service"
);
export const GET_REPORTS_TIMEINTERVALS = generateTypes(
  "[Monitors Calculator] -> Get timeintervals from reports-service"
);
export const GET_REPORTS_OTS = generateTypes(
  "[Monitors Calculator] -> Get ots for periods from reports-service"
);
export const GET_MONITORS_ALONE = generateTypes(
  "[Monitors Calculator] -> Get moniotrs alone"
);
export const GET_SEASONAL_TABLE = generateTypes("GET_SEASONAL_TABLE");
export const GET_METRO_MONITORS = generateTypes("GET_METRO_MONITORS");

//Const actions
export const RESET_STATE = "[Monitors Calculator] -> reset state";
export const SET_SELECTION_BLOCK =
  "[Monitors Calculator] -> set selection block";
export const TOGGLE_TOTAL_COLLAPSE =
  "[Monitors Calculator] -> Toggle total collapse";
export const INPUT_BUDGET = "[Monitors Calculator] -> Input budget";
export const FIXED_BUDGET = "[Monitors Calculator] -> Fixed budget";
export const RESET_GROUPS = "[Monitors Calculator] -> Reset groups by name";
export const UPDATE_CLIP_DURATION =
  "[Monitors Calculator] -> Update clip duration";
export const UPDATE_CLIP_DURATION_COMPLETED =
  "[Monitors Calculator] -> Update clip duration.completed";
export const UPDATE_PRESENTATION_COUNT =
  "[Monitors Calculator] -> Update presentation count";
export const UPDATE_PRESENTATION_COUNT_COMPLETED =
  "[Monitors Calculator] -> Update presentation count.completed";
export const UPDATE_PRESENTATION_PERIOD =
  "[Monitors Calculator] -> Update presentation period";
export const TOGGLE_MLINE = "[Monitors Calculator] -> Toggle metro line";
export const REPLACE_ALONE_STATE =
  "[Monitors Calculator] -> Replace standAlone state";
export const REPLACE_METRO_TRAM_STATE =
  "[Monitors Calculator] -> Replace metroMonitors and tram state";
export const TOGGLE_PRESENTATION_TIME =
  "[Monitors Calculator] -> Toggle presentation time";
export const SELECT_PRESENTATION_TIME =
  "[Monitors Calculator] -> Select presentation time";
export const HOVER_PRESENTATION_TIME =
  "[Monitors Calculator] -> Hover presentation time";
export const TOGGLE_PRESENTATION_FREQUENCY =
  "[Monitors Calculator] -> Toggle presentation frequency";
export const TOGGLE_DAYS_WEEK = "[Monitors Calculator] -> Toggle days of week";
export const CLEAR_BUDGET_ERROR = "[Monitors Calculator] -> Clear budget error";
export const CLEAR_PERIOD_ERROR = "[Monitors Calculator] -> Clear period error";
export const ENABLE_END_DATE_EDITING =
  "[Monitors Calculator] -> Enable end date editing";
export const UPDATE_STATE = "[Monitors Calculator] -> Update all state";
export const TOGGLE_ROUTE = "[Monitors Calculator] -> Toggle route";
export const SELECT_DISTRICT = "[Monitors Calculator] -> Select district";
export const HOVER_ROUTE = "[Monitors Calculator] -> Hover route";
export const TOGGLE_MONITOR = "[Monitors Calculator] -> Toggle monitor";
export const FIXED_BUDGET_STANDALONE =
  "[Monitors Calculator] -> Fixed budget standAlone";
export const DIVIDE_TOOGLE_ITEMS =
  "[Monitors Calculator] -> Divide toggle items";
export const HANDLE_TOGGLE_COLLAPSE =
  "[Monitors Calculator] -> Handle toggle collapse";
export const HANDLE_POPUP = "[Monitors Calculator] -> Handle popup collapse";
export const RECALCULATE_STORE = "[Monitors Calculator] -> Recalculate store";
