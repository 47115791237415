import styled from "styled-components";

export const StyledSideBlock = styled.div`
  display: flex;
  background: #ffffff;
  /* border-radius: 0 0 4px 4px; */
  padding: 24px 0;
  flex-direction: column;
`;

export const StyledTabBoard = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const StyledTab = styled.div`
  display: flex;
  flex: 0 0 50%;
  justify-content: center;
  border-radius: 4px 4px 0 0;
  padding: 16px 0;
  cursor: pointer;
  transition: all 300ms;
  color: #4a4a4a;

  &:hover {
    opacity: 0.9;
  }

  ${({ selected }) =>
    selected
      ? `
      background: #4A4A4A;
      color: #fff;
  `
      : "cursor: not-allowed;"}
`;

export const StyledSideInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: #666666;
  cursor: default;
  padding: 0 24px;
  position: relative;

  div {
    text-align: center;
    letter-spacing: 0.38px;

    &:first-child {
      margin-bottom: 8px;
    }
  }
`;

export const StyledRedLine = styled.div`
  position: absolute;
  background: #d92f4b;
  height: 100%;
  width: 6px;
  left: 0;
  margin-bottom: unset;
`;

export const StyledLoadBlock = styled.div`
  padding: 0 24px;
  margin-top: 16px;
`;
